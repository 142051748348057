import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppSvgIconComponent} from "@smartdining/lib-sd-web-shared";

@Component({
  selector: 'app-filter-button',
  standalone: true,
  templateUrl: './filter-button.component.html',
  styleUrl: './filter-button.component.scss',
  imports: [AppSvgIconComponent]
})
export class FilterButtonComponent {

  @Output()
  filterClicked = new EventEmitter();

  onFilterClicked() {
    this.filterClicked.emit();
  }

}
