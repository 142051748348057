<div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6">

  <p class="text-body1 font-medium"
     i18n="Upcoming Reservations Title|Title for the upcoming bookings section@@upcomingReservations.title">
    Bookings
  </p>

  <div class="my-2">
    <app-bookings-tab [isUpcomingBooking]="true"></app-bookings-tab>
  </div>

  @if (bookingState.loading()) {
    <div class="flex flex-col gap-5">
      <lib-shimmer></lib-shimmer>
      <lib-shimmer></lib-shimmer>
      <lib-shimmer></lib-shimmer>
    </div>
  }

  <div class="flex flex-col gap-5">
    @for (booking of bookingState.response()?.data; track booking) {
      <app-booking-card
        (click)="onUpcomingReservationClicked(booking)"
        [booking]="booking">
      </app-booking-card>
    }
  </div>

  @if (bookingState.success() && (bookingState.response()?.data?.length ?? 0) <= 0) {
    <div class="flex flex-col w-full h-full justify-center items-center">
      <lib-no-data class="self-center py-2"
                   message="No Bookings found"
                   i18n-message="No Bookings Message|Shown when there are no upcoming bookings@@upcomingReservations.noBookings">
      </lib-no-data>
    </div>
  }

</div>
