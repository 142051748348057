import {Injectable} from '@angular/core';
import {BaseApiService} from "@core/base/base-api.service";
import {MenuResponse} from "./menu/menu-response";
import {URLs} from "../../../core/base/urls";
import {PickupScheduleResponse} from "./pickup-schedule/pickup-schedule-response";
import {PickupScheduleRequest} from "./pickup-schedule/pickup-schedule-request";
import {OrderInformationResponse} from "./order-information/order-information-response";
import {CompletedOrdersRequest} from "./completed-orders/completed-orders-request";
import {ActiveOrdersRequest} from "./active-orders/active-orders-request";
import {GetOrderSurveyQuestionsResponse} from "./survey-questions/get-order-survey-questions-response";
import {SubmitCustomerSurveyRequest} from "../../booking/data/bookings/survey/submit-customer-survey-request";
import {GenericResponse} from "../../../core/models/generic-response";
import {PlaceOrderRequest} from "./place-order/place-order-request";
import {PlaceOrderResponse} from "./place-order/place-order-response";
import {CancelOrderRequest} from "./cancel-order/cancel-order-request";
import {CancelOrderResponse} from "./cancel-order/cancel-order-response";
import {CheckInRequest} from "./check-in/check-in-request";
import {CheckInResponse} from "./check-in/check-in-response";

@Injectable({
  providedIn: 'root'
})
export class PickupOrderService extends BaseApiService {

  getPickupSchedule(request: PickupScheduleRequest) {
    return this.get<PickupScheduleResponse>(URLs.getPickupSchedule, request);
  }

  getPickupMenus(restaurantId: string) {
    return this.get<MenuResponse>(URLs.getPickupMenus(restaurantId));
  }

  getOrderDetails(orderId: string) {
    return this.get<OrderInformationResponse>(URLs.getOrderInformation(orderId));
  }

  getCompletedOrdersByCustomerId(request: CompletedOrdersRequest) {
    return this.get<CompletedOrdersRequest>(URLs.getCompletedOrdersByCustomerId, request);
  }

  getUpcomingOrders(request: ActiveOrdersRequest) {
    return this.get<ActiveOrdersRequest>(URLs.getUpcomingOrders, request);
  }

  getOrderSurveyQuestions() {
    return this.get<GetOrderSurveyQuestionsResponse>(URLs.getOrderSurveyQuestions)
  }

  submitCustomerSurvey(request: SubmitCustomerSurveyRequest) {
    return this.post<GenericResponse>(URLs.submitCustomerSurvey, request);
  }

  getOrderPrice(placeOrderRequest: PlaceOrderRequest) {
    return this.post(URLs.getOrderPrice, placeOrderRequest);
  }

  placeOrder(placeOrderRequest: PlaceOrderRequest) {
    return this.post<PlaceOrderResponse>(URLs.placeOrder, placeOrderRequest);
  }

  cancelOrder(orderId: string, request: CancelOrderRequest) {
    return this.put<CancelOrderResponse>(URLs.cancelOrder(orderId), request)
  }

  checkInPickup(orderId: string, request: CheckInRequest) {
    return this.put<CheckInResponse>(URLs.checkInPickup(orderId), request)
  }
}
