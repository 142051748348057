import {Component, signal} from '@angular/core';
import {BaseOverlayComponent, OverlayWizard, OverlayWizardUtils} from "@smartdining/lib-sd-web-shared";
import {AuthComponent} from '../../auth.component';

@Component({
  selector: 'app-auth-wizard-overlay',
  imports: [
    BaseOverlayComponent,
    AuthComponent
  ],
  templateUrl: './auth-wizard-overlay.component.html',
  styleUrl: './auth-wizard-overlay.component.scss'
})
export class AuthWizardOverlayComponent {

  wizards = signal<OverlayWizard[]>([]);

  wizardUtil?: OverlayWizardUtils;

  onWizardUpdated($event: OverlayWizardUtils) {
    this.wizardUtil = $event;
  }
}




