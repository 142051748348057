import {inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlyToElementService {

  private renderer: Renderer2;
  rendererFactory = inject(RendererFactory2);

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  fly(
    fromEl: HTMLElement,
    toEl: HTMLElement,
    options?: {
      flyingEl: HTMLElement;
      duration?: number;
      width?: string;
      height?: string;
      opacity?: number;
      onComplete?: () => void;
    }
  ) {

    const fromRect = fromEl.getBoundingClientRect();
    const toRect = toEl.getBoundingClientRect();

    const flyingEl = options?.flyingEl as any;

    flyingEl.classList.remove('hidden');

    const duration = options?.duration ?? 700;
    const finalWidth = options?.width ?? '24px';
    const finalHeight = options?.height ?? '24px';
    const finalOpacity = options?.opacity ?? 0.5;

    // Reset transition first
    flyingEl.style.transition = 'none';

    // Apply initial position of fromEl to flyingEl
    Object.assign(flyingEl.style, {
      position: 'fixed',
      top: `${fromRect.top}px`,
      left: `${fromRect.left}px`,
      width: `${fromRect.width}px`,
      height: `${fromRect.height}px`,
      opacity: '1',
      zIndex: '1000',
      pointerEvents: 'none',
    });

    // Force reflow to apply initial styles
    void flyingEl.offsetWidth;

    // Then set transition & target state
    flyingEl.style.transition = `all ${duration}ms cubic-bezier(0.42, 0, 1, 1)`;

    // Animate to cart icon
    requestAnimationFrame(() => {
      Object.assign(flyingEl.style, {
        top: `${toRect.top}px`,
        left: `${toRect.left}px`,
        width: finalWidth,
        height: finalHeight,
        opacity: `${finalOpacity}`,
      });
    });

    // Cleanup after animation
    setTimeout(() => {
      // Optional: remove inline styles
      flyingEl.style.transition = 'none';
      flyingEl.style.opacity = '0';

      flyingEl.classList.add('hidden');
      options?.onComplete?.();
    }, duration);
  }
}
