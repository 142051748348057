import {Component, computed, ElementRef, inject, input, OnInit, signal} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {
  AppSvgIconComponent,
  ButtonComponent,
  NetworkImageComponent,
  NoDataComponent,
  ShimmerComponent,
  State
} from "@smartdining/lib-sd-web-shared";
import {BookingsService} from "../../../data/bookings/bookings.service";
import {arrayToCSV} from "@shared/utils/string-utils";
import {DirectionButtonComponent} from "@core/components/direction-button/direction-button.component";
import {GeoPoint} from "@core/services/location-service/location.service";
import {DatePipe, DOCUMENT, NgClass} from "@angular/common";
import {openLink} from "@shared/utils/html-utils";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  ReservationInviteGuestRequest
} from "../../../../invitations/data/reservation-invite-guest/reservation-invite-guest-request";
import {GenericResponse} from "@core/models/generic-response";
import {isValidEmail} from "@shared/utils/validation-utils";
import {InvitationsService} from "../../../../invitations/data/invitations.service";
import {
  BookingInvitationsResponse,
  BookingInvitationsResponseReceivers
} from "../../../../invitations/data/booking-invitations/booking-invitations-response";
import {
  CancelReservationAction,
  CancelReservationConfirmationOverlayComponent
} from "../../overlays/cancel-reservation-confirmation-overlay/cancel-reservation-confirmation-overlay.component";
import {Constants} from "@core/constants/constants";
import {CancelReservationRequest} from "../../../data/cancel-reservation/cancel-reservation-request";
import {MatDialog} from "@angular/material/dialog";
import {
  BookingStatusSummary,
  getBookingStatusSummary,
  getBookingStatusText,
  getBookingStatusTextColor
} from "../../../utilities/booking-status-utils";
import {
  UpdateReservationOverlayComponent,
  UpdateReservationStatus
} from "../../overlays/update-reservation-overlay/update-reservation-overlay.component";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {CancelInvitationRequest} from "../../../../invitations/data/cancel-invitation/cancel-invitation-request";
import {
  CancelInvitationOverlayComponent,
  InvitationCancelAction
} from "../../../../invitations/presentation/overlay/cancel-invitation-overlay/cancel-invitation-overlay.component";
import {ReceivedInvitationResponse} from "../../../../invitations/data/invitations/received-invitation-response";
import {BookingInformationResponse} from "../../../data/bookings/bookings/booking-information-response";
import {
  BookingSurveyOverlayComponent,
  BookingSurveyStatus
} from "../../overlays/booking-review-overlay/booking-survey-overlay.component";
import {TimeAgoPipe} from "@shared/pipes/time-ago.pipe";

@Component({
  selector: 'app-booking-information',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    NetworkImageComponent,
    DirectionButtonComponent,
    DatePipe,
    ButtonComponent,
    ReactiveFormsModule,
    NoDataComponent,
    NgClass,
    ShimmerComponent,
    TimeAgoPipe
  ],
  templateUrl: './booking-information.component.html',
  styleUrl: './booking-information.component.scss'
})
export class BookingInformationComponent extends BaseComponent implements OnInit {

  protected readonly arrayToCSV = arrayToCSV;

  bookingId = input<string>('');
  invitation = input<ReceivedInvitationResponse>();

  bookingsService = inject(BookingsService);
  document = inject(DOCUMENT);
  elementRef = inject(ElementRef);
  formBuilder = inject(FormBuilder);
  invitationService = inject(InvitationsService);
  dialog = inject(MatDialog);
  invitationsService = inject(InvitationsService);
  authStoreService = inject(AuthStoreService);

  bookingDetailsState = new State<BookingInformationResponse>();
  sendInvitationState = new State<GenericResponse>();
  invitedGuestsState = new State<BookingInvitationsResponse>();
  cancelReservationState = new State<GenericResponse>();

  booking = signal<BookingInformationResponse | null>(null);
  invitationReceivers = signal<BookingInvitationsResponseReceivers[]>([]);

  protected readonly getBookingStatusText = getBookingStatusText;

  isPastBooking = computed(() => {
    const booking = this.bookingDetailsState.response()?.data;
    const currentDate = new Date();
    if (booking?.startTime != null) {
      const bookingStartTime = new Date(booking.startTime);
      return bookingStartTime.getTime() < currentDate.getTime();
    }

    return null;
  });

  inviteForm!: FormGroup;

  destinationGeoPoint = computed(() => {
    let geoPoint: GeoPoint = {
      latitude: this.booking()?.data?.restaurant?.location?.location?.coordinates[1],
      longitude: this.booking()?.data?.restaurant?.location?.location?.coordinates[0],
    };
    return geoPoint;
  });

  ngOnInit(): void {
    this.inviteForm = this.formBuilder.group({
      emailOrPhone: ['', Validators.required]
    });
    this.getBookingDetails();
    this.getInvitedGuests();
  }

  getBookingDetails() {
    this.executeRequest<BookingInformationResponse>({
      state: this.bookingDetailsState,
      request: this.bookingsService.getBookingDetails(this.bookingId()),
      onSuccess: response => {
        this.booking.set(response);
      }
    });
  }

  getInvitedGuests() {
    this.executeRequest<BookingInvitationsResponse>({
      state: this.invitedGuestsState,
      request: this.invitationService.getBookingInvitations(this.bookingId()),
      onSuccess: response => {
        const receivers = response.data.receivers.filter(receiver => {
          return receiver.status != 'CANCELED';
        });
        this.invitationReceivers.set(receivers);
      }
    });
  }

  onCallClicked() {
    openLink(`tel:${this.booking()?.data?.restaurant.phoneNumber}`, false, this.document, this.elementRef);
  }

  onInvitePressed() {
    if (this.inviteForm.invalid) {
      return;
    }
    const emailOrPhone = this.inviteForm.value.emailOrPhone;
    const reservationDetails = this.bookingDetailsState.response()?.data;
    const request: ReservationInviteGuestRequest = {
      restaurantId: reservationDetails?.restaurant?._id ?? '',
      bookingId: reservationDetails?._id ?? '',
      email: isValidEmail(emailOrPhone) ? emailOrPhone : null,
      phoneNumber: isValidEmail(emailOrPhone) ? null : emailOrPhone,
      isUser: false
    };

    this.executeRequest<GenericResponse>({
      state: this.sendInvitationState,
      request: this.invitationService.inviteGuest(request),
      handleSuccess: true,
      successMessage: 'Guest invited successfully!',
      onSuccess: response => {
        this.getInvitedGuests();
        this.inviteForm.reset();
      }
    });
  }



  getStatusColor(): string {
    return getBookingStatusTextColor(this.booking()?.data?.status ?? '');
  }

  protected readonly getBookingStatusSummary = getBookingStatusSummary;
  protected readonly BookingStatusSummary = BookingStatusSummary;
  unknownGuest = $localize`:@@bookingInfo.unknownGuest:Unknown Guest|Fallback guest name`;

  updateBookingClicked() {
    this.dialog.open(UpdateReservationOverlayComponent, {
      ...Constants.defaultDialogConfig,
      data: this.bookingId()
    }).afterClosed()
      .subscribe((result: UpdateReservationStatus) => {
        if (result == UpdateReservationStatus.success) {
          this.getBookingDetails();
        }
      });
  }
  cancelReservation() {
    const reservationId = this.bookingId() ?? '';
    const request: CancelReservationRequest = {
      reservationId: reservationId
    };
    this.executeRequest({
      state: this.cancelReservationState,
      request: this.bookingsService.cancelBookings(reservationId, request),
      showLoader: true,
      successMessage: 'Reservation cancelled successfully',
      onSuccess: response => {
        this.getBookingDetails();
      }
    });
  }

  onCancelClicked() {
    this.dialog.open(CancelReservationConfirmationOverlayComponent, {
      ...Constants.defaultDialogConfig,
    }).afterClosed()
      .subscribe((data: CancelReservationAction) => {
        switch (data) {
          case CancelReservationAction.confirmed:
            this.cancelReservation();
            break;
          case CancelReservationAction.rejected:
            break;
        }
      });
  }

  onInviteCancelClicked(receiver: BookingInvitationsResponseReceivers) {
    const dialogRef = this.dialog.open(CancelInvitationOverlayComponent, {
      ...Constants.defaultDialogConfig,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == InvitationCancelAction.cancelInvitation) {
        const booking = this.bookingDetailsState.response()?.data;
        const request: CancelInvitationRequest = {
          inviteId: receiver._id,
          bookingId: booking?._id
        };
        this.executeRequest({
          state: new State<any>(),
          request: this.invitationsService.cancelInvite(request),
          showLoader: true,
          successMessage: 'Cancelled invitation',
          onSuccess: response => {
            this.getInvitedGuests();
          }
        });
      }
    });

  }

   onRateReviewClicked() {
    this.dialog.open(BookingSurveyOverlayComponent, {
      ...Constants.defaultDialogConfig,
      data: this.booking()?.data._id
    }).afterClosed().subscribe((result: BookingSurveyStatus) => {
      if (result == BookingSurveyStatus.success) {
        this.getBookingDetails();
      }
    });
  }
}
