<div class="container-fluid mx-auto h-screen bg flex flex-col justify-center">
  <div class="flex flex-col justify-center items-center gap-4 mx-6">
    <img src="../../../../assets/images/branding/app-logo.png" class="w-16 -mt-20" alt="App Logo" />

    <p class="text-h3 text-center"
       i18n="404 Title|Main heading when page is not found@@pageNotFound.title">
      Page not found
    </p>

    <p class="text-body2 max-w-96 text-center text-neutral-500"
       i18n="404 Description|Explanation message on page not found@@pageNotFound.description">
      We are sorry for the inconvenience. It looks like you're trying to access a page that has been deleted or never even existed.
    </p>

    <lib-button routerLink="/home"
                i18n="Back to Homepage Button|Button text to return to homepage@@pageNotFound.backButton">
      BACK TO HOMEPAGE
    </lib-button>
  </div>
</div>

