<app-base-dialog [title]="'Add Payment Method'" i18n-title="Add Payment Method Title@@addPayment.title">
  <div body>
    @if(elementsOptions.clientSecret) {
      <ng-container class="mb-5">
        <ngx-stripe-payment #stripeContent [clientSecret]="elementsOptions.clientSecret"></ngx-stripe-payment>
      </ng-container>
    }
  </div>
  <div actions>
    <div class="flex justify-end gap-3">
      <lib-button (click)="onCancelClicked()" appearance="outline"
                  i18n="Cancel Button@@addPayment.cancel">
        Cancel
      </lib-button>
      <lib-button (click)="onAddPaymentMethodClicked()"
                  i18n="Save Card Button@@addPayment.saveCard">
        Save Card
      </lib-button>
    </div>
  </div>
</app-base-dialog>

