
<lib-base-overlay [title]="'Booking Survey'" i18n-title="Booking Survey Title|Title of the booking survey overlay@@bookingSurveyOverlay.title">


  <div body>
    <form [formGroup]="surveyForm">

      <!-- Description -->
      <div class="flex flex-col gap-2 text-center justify-center items-center">
        <div>
          <p class="text-body2 text-neutral-900">
            {{ bookingSurveyQuestionsResponseState.response()?.data?.description }}
          </p>
        </div>
      </div>

      <!-- Questions -->
      <div formArrayName="surveyAnswers">
        <div class="flex flex-col py-10 items-center gap-3">
          @for (surveyQuestion of bookingSurveyQuestionsResponseState.response()?.data?.surveyQuestions; track surveyQuestion; let index = $index) {
            <ng-container [formGroupName]="index">
              <div class="grid grid-cols-4 border-b border-neutral-100 pb-3">
                <div class="col-span-2 flex items-center gap-3">
                  <lib-svg-icon src="assets/icons/question-mark-circle.svg"
                                [matTooltip]="surveyQuestion.surveyQuestion"
                                [size]="20"></lib-svg-icon>
                  <p class="text-body2"
                     i18n="Survey Question Label|Label for a survey question@@bookingSurveyOverlay.questionLabel">
                    {{ surveyQuestion.surveyName }}
                  </p>
                </div>
                <div class="col-span-2">
                  <lib-rating-input formControlName="surveyRating"></lib-rating-input>
                </div>
              </div>
            </ng-container>
          }
        </div>
      </div>

      <!-- Comment Box -->
      <lib-text-area-input
        class="w-full"
        formControlName="comments"
        label="Review"
        i18n-label="Survey Comment Label|Label for comment field@@bookingSurveyOverlay.reviewLabel"
        placeholder="Please write your feedback."
        i18n-placeholder="Survey Comment Placeholder|Placeholder for feedback text area@@bookingSurveyOverlay.reviewPlaceholder"
        [fullWidth]="true">
      </lib-text-area-input>

    </form>
  </div>

  <!-- Actions -->
  <div actions>
    <div class="flex gap-4 items-center justify-end">
      <lib-button
        appearance="outline"
        (click)="onCancelClicked()"
        i18n="Cancel Button|Closes the survey without submitting@@bookingSurveyOverlay.cancel">
        CANCEL
      </lib-button>

      <lib-button
        (click)="onSubmitPressed()"
        [loading]="createSurveyState.loading()"
        i18n="Submit Survey Button|Submits the booking survey@@bookingSurveyOverlay.submit">
        SUBMIT SURVEY
      </lib-button>
    </div>
  </div>
</lib-base-overlay>

