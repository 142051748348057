<app-base-dialog
  [title]="'Modify Booking'"
  i18n-title="Modify Booking Title|Dialog title for modifying a reservation@@updateReservation.title">

  <div body>
    <form [formGroup]="updateReservationForm"
          (ngSubmit)="onUpdateReservationClicked()"
          class="flex flex-col gap-3">

      <div class="flex flex-col">

        <div class="flex items-start gap-6">
          <lib-date-selector-input
            formControlName="date"
            [title]="'Date'"
            i18n-title="Date Label|Title for date input@@updateReservation.dateTitle"
            [minDate]="minimumBookingDate"
            (valueChanged)="onDateSelected($event)">
          </lib-date-selector-input>

          <app-party-size-input
            formControlName="partySize"
            (valueChanged)="onPartSizeChanged($event)">
          </app-party-size-input>
        </div>

        <lib-single-selection-field
          formControlName="time"
          [title]="'Select Time'"
          i18n-title="Select Time Title|Label for selecting a reservation time@@updateReservation.timeTitle"
          [items]="availabilities()"
          [display]="'timeString'"
          [state]="tableAvailabilitiesState"
          noDataMessage="No tables available"
          i18n-noDataMessage="No Tables Message|Shown when no table is available@@updateReservation.noTablesMessage">
        </lib-single-selection-field>

        <lib-single-selection-field
          formControlName="occasion"
          [title]="'Select Occasion'"
          i18n-title="Occasion Title|Label for selecting occasion@@updateReservation.occasionTitle"
          [items]="occasionsState.response()?.data ?? []"
          [display]="'name'"
          [dynamicIconPath]="'icon'"
          [dynamicIconColor]="'iconColor'">
        </lib-single-selection-field>

        <lib-single-selection-field
          formControlName="seatingPreferences"
          [title]="'Select Seating Preferences'"
          i18n-title="Seating Preference Title|Label for selecting seating preferences@@updateReservation.seatingTitle"
          [items]="seatingPreferenceState.response()?.data ?? []"
          [display]="'name'"
          [dynamicIconPath]="'icon'"
          [dynamicIconColor]="'iconColor'">
        </lib-single-selection-field>

        <lib-single-selection-field
          formControlName="tags"
          [title]="'Select Tags'"
          i18n-title="Tags Title|Label for selecting tags@@updateReservation.tagsTitle"
          [items]="tagsState.response()?.data ?? []"
          [display]="'name'"
          [dynamicIconPath]="'icon'"
          [dynamicIconColor]="'iconColor'">
        </lib-single-selection-field>

        <lib-text-input
          formControlName="note"
          class="mt-2"
          [label]="'Special notes'"
          i18n-label="Special Notes Label|Label for input field for notes@@updateReservation.notesLabel"
          type="text">
        </lib-text-input>

        <p class="text-body2 text-black"
           i18n="Cancellation Policy Title|Heading for cancellation policy@@updateReservation.cancellationTitle">
          Cancellation Policy
        </p>

        <p class="text-body2"
           i18n="Cancellation Policy Description|Text explaining cancellation terms@@updateReservation.cancellationDescription">
          While you won’t be charged if you need to cancel, we ask that you notify us at least 24 hours in advance
        </p>

        <p class="text-body2"
           i18n="Terms Agreement|Text explaining user agreement acknowledgement@@updateReservation.termsAgreement">
          By clicking reserve now, you agree to the Smart Dining LLC User Agreement, Privacy Policy, and Cookie Policy
        </p>

      </div>
    </form>
  </div>

  <div actions>
    <div class="flex gap-3 justify-end">
      <lib-button
        type="submit"
        [loading]="updateReservationState.loading()"
        [fullWidth]="true"
        (click)="onUpdateReservationClicked()"
        i18n="Update Reservation Button|Button to submit updated reservation@@updateReservation.updateButton">
        Update reservation
      </lib-button>
    </div>
  </div>
</app-base-dialog>
