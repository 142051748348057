import {Injectable} from '@angular/core';
import {BaseApiService} from "@core/base/base-api.service";
import {MenuResponse} from "./menu/menu-response";
import {URLs} from "../../../core/base/urls";

@Injectable({
  providedIn: 'root'
})
export class DiningOrderService extends BaseApiService {

  getDiningMenus(restaurantId: string) {
    return this.get<MenuResponse>(URLs.getDiningMenus(restaurantId));
  }

}
