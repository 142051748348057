<mat-dialog-content>
  <div class="flex flex-col gap-2 text-start">
    <p class="text-body1 text-error-500" i18n="Delete Card Title@@deletePayment.title">Delete this card</p>
    <p class="text-body2" i18n="Delete Card Message@@deletePayment.message">
      Are you sure, do you want to delete this card?
    </p>
    <div class="flex justify-end gap-4 mt-3">
      <lib-button appearance="outline" (click)="onNoClicked()" i18n="Cancel Button@@deletePayment.cancel">
        Cancel
      </lib-button>
      <lib-button
        buttonColor="bg-error-500"
        [loading]="state.loading()"
        (click)="onYesClicked()"
        i18n="Delete Button@@deletePayment.confirm">
        Delete
      </lib-button>
    </div>
  </div>
</mat-dialog-content>











