<form [formGroup]="form" class="flex flex-col gap-4">
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">

    <lib-outline-text-input
      formControlName="firstName"
      type="text"
      placeholder="First Name"
      label="First Name"
      i18n-label="First Name Label|Label for first name field in account form@@account.firstNameLabel"
      i18n-placeholder="First Name Placeholder|Placeholder for first name@@account.firstNamePlaceholder">
    </lib-outline-text-input>

    <lib-outline-text-input
      formControlName="lastName"
      type="text"
      placeholder="Last Name"
      label="Last Name"
      i18n-label="Last Name Label|Label for last name field in account form@@account.lastNameLabel"
      i18n-placeholder="Last Name Placeholder|Placeholder for last name@@account.lastNamePlaceholder">
    </lib-outline-text-input>

    <lib-outline-text-input
      formControlName="email"
      type="email"
      placeholder="you@company.com"
      label="Email"
      i18n-label="Email Label|Label for email field in account form@@account.emailLabel"
      i18n-placeholder="Email Placeholder|Placeholder for email@@account.emailPlaceholder">
    </lib-outline-text-input>

    <lib-outline-text-input
      formControlName="phoneNumber"
      placeholder="Phone Number"
      label="Phone Number"
      i18n-label="Phone Number Label|Label for phone number in account form@@account.phoneLabel"
      i18n-placeholder="Phone Number Placeholder|Placeholder for phone number@@account.phonePlaceholder">
    </lib-outline-text-input>

    <lib-date-input
      label="Birthday"
      formControlName="birthday"
      [allowOnlyPast]="true"
      i18n-label="Birthday Label|Label for birthday field in account form@@account.birthdayLabel">
    </lib-date-input>

  </div>

  <div class="flex flex-col items-center sm:items-end">
    <lib-button
      (click)="onUpdateButtonClicked()"
      i18n="Update Button|CTA to update account information@@account.updateButton">
      Update
    </lib-button>
  </div>
</form>


