import {Component, input, OnInit} from '@angular/core';
import {IndividualRatingComponent} from "../individual-rating/individual-rating.component";

@Component({
  selector: 'app-review-summary',
  standalone: true,
  imports: [
    IndividualRatingComponent
  ],
  templateUrl: './review-summary.component.html',
  styleUrl: './review-summary.component.scss'
})
export class ReviewSummaryComponent implements OnInit {

  review = input<OverallReview>();

  ngOnInit(): void {

  }

  getPercentage(star: number): number {
    const starCount = {
      5: this.review()?.fiveStarRatings ?? 0,
      4: this.review()?.fourStarRatings ?? 0,
      3: this.review()?.threeStarRatings ?? 0,
      2: this.review()?.twoStarRatings ?? 0,
      1: this.review()?.oneStarRatings ?? 0
    }[star] || 0;

    return (this.review()?.ratingCount ?? 0) ? (starCount / (this.review()?.ratingCount ?? 0)) * 100 : 0;
  }

  getStarRatingCount(star: number): number {
    const reviewData = this.review(); // or however you're accessing it
    if (!reviewData) return 0;

    return {
      5: reviewData.fiveStarRatings ?? 0,
      4: reviewData.fourStarRatings ?? 0,
      3: reviewData.threeStarRatings ?? 0,
      2: reviewData.twoStarRatings ?? 0,
      1: reviewData.oneStarRatings ?? 0
    }[star] || 0;
  }

}

export interface OverallReview {
  rating: number;
  ratingCount: number;
  fiveStarRatings: number;
  fourStarRatings: number;
  threeStarRatings: number;
  twoStarRatings: number;
  oneStarRatings: number;
}

