@if (dineInOption() == DineInOption.dineIn) {
  <div
    class="rounded-lg w-full flex items-center justify-between py-3.5 sm:justify-center sm:w-min sm:gap-12 sm:px-6 sm:shadow-2">

    <lib-date-selector-input
      title="Select Date"
      [(ngModel)]="restaurantsFilterService.selectedDate"
      (valueChanged)="onDateSelected($event)"
      [minDate]="currentDate">
    </lib-date-selector-input>


    <app-dine-in-time-filter
      [availableTimes]="availableTimes()"
      [(ngModel)]="restaurantsFilterService.selectedTime"
      (valueChanged)="onTimeChanged($event)">
    </app-dine-in-time-filter>

    <app-party-size-input
      [(ngModel)]="restaurantsFilterService.selectedPartySize"
      (valueChanged)="onPartySizeChanged($event)"></app-party-size-input>

  </div>
}
