@switch (type()) {
  @case ('TYPE_RESERVATION') {
    <p class="booking-type"
       i18n="Booking Type Reservation|Label for reservation type@@bookingType.reservation">
      Reservation
    </p>
  }
  @case ('TYPE_WAITLIST') {
    <p class="booking-type"
       i18n="Booking Type Waitlist|Label for waitlist type@@bookingType.waitlist">
      Waitlist
    </p>
  }
  @case ('TYPE_WALK_IN') {
    <p class="booking-type"
       i18n="Booking Type Walk-in|Label for walk-in type@@bookingType.walkIn">
      Walk-in
    </p>
  }
  @default {
    <p class="booking-type"
       i18n="Booking Type Unknown|Label when booking type is unknown@@bookingType.unknown">
      Unknown
    </p>
  }
}
