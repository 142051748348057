<mat-dialog-content>
  <div class="flex flex-col gap-2 text-start">
    <p class="text-body1 text-error-500"
       i18n="Cancel Invitation Title|Header for canceling invitation@@cancelInvitationOverlay.title">
      Cancel Invitation
    </p>
    <p class="text-body2"
       i18n="Cancel Invitation Message|Confirmation prompt for canceling invitation@@cancelInvitationOverlay.message">
      Are you sure, do you want to cancel this invitation?
    </p>
    <div class="flex justify-end gap-4 mt-3">
      <lib-button appearance="outline"
                  (click)="onNoClicked()"
                  i18n="Cancel Button|Button to dismiss the overlay@@cancelInvitationOverlay.cancel">
        Cancel
      </lib-button>
      <lib-button buttonColor="bg-error-500"
                  [loading]="state.loading()"
                  (click)="onYesClicked()"
                  i18n="Confirm Cancel Button|Button to confirm invitation cancellation@@cancelInvitationOverlay.confirm">
        Cancel Invitation
      </lib-button>
    </div>

  </div>
</mat-dialog-content>











