<form [formGroup]="form" class="flex flex-col gap-3 text-center text-neutral-700">

  <p class="text-subtitle2"
     i18n="Reset Password Info|Instruction after user requests password reset@@resetPasswordOverlay.checkEmailText">
    Check your {{ email() }} for an email to reset your password. You'll receive one if there is an associated Smart Dining Account
  </p>

  <lib-outline-text-input
    formControlName="newPassword"
    type="password"
    label="New password"
    i18n-label="New Password Label|Label for new password input@@resetPasswordOverlay.newPasswordLabel">
  </lib-outline-text-input>

  <lib-outline-text-input
    formControlName="confirmPassword"
    type="password"
    label="Confirm Password"
    i18n-label="Confirm Password Label|Label for confirming new password@@resetPasswordOverlay.confirmPasswordLabel">
  </lib-outline-text-input>

  <lib-outline-text-input
    formControlName="otp"
    type="text"
    label="Email verification code"
    i18n-label="Email Code Label|Label for OTP input sent to email@@resetPasswordOverlay.emailOtpLabel">
  </lib-outline-text-input>

  <p class="text-subtitle2 text-neutral-500"
     i18n="Email Not Found Prompt|Prompt when email might not be found and allows resending@@resetPasswordOverlay.resendPrompt">
    Don't see an email? Try your spam folder or
    <span class="text-primary-500 font-semibold">
      Resend new code
    </span>
  </p>

  <lib-button
    [fullWidth]="true"
    [loading]="forgotPasswordState.loading()"
    (click)="resetPassword()"
    i18n="Reset Password Button|CTA to reset password@@resetPasswordOverlay.resetPasswordButton">
    Reset Password
  </lib-button>

</form>

