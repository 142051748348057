export class Constants {
  static userAppIOSDownloadLink = 'https://apps.apple.com/in/app/smart-dining-app/id6467118468';
  static userAppAndroidDownloadLink = 'https://play.google.com/store/apps/details?id=com.smartdining.guests';

  static defaultDialogConfig = {
    // minWidth: '200px',
    // maxWidth: '600px',
    panelClass: ['w-full', 'sm:w-4/5', 'md:w-3/5', 'lg:w-2/5'],
    maxHeight: '95vh',
  };

  static partySizeList = [
    2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,14,15
  ];

  static getNumberRange(start: number, end: number) {
    if (start > end) {
      return [0];
    }

    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }

  static pageSize = 15;

  static currency = '$';
}
