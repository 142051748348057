import {Component, inject, input, OnInit} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  OrderInformationResponseData
} from "../../../data/order-information/order-information-response";
import {DatePipe} from "@angular/common";
import {AppSvgIconComponent, BaseDialogComponent} from "@smartdining/lib-sd-web-shared";

@Component({
  selector: 'app-check-in-confirmation-overlay',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    DatePipe,
    BaseDialogComponent,
  ],
  templateUrl: './check-in-confirmation-overlay.component.html',
  styleUrl: './check-in-confirmation-overlay.component.scss'
})
export class CheckInConfirmationOverlayComponent extends BaseComponent implements OnInit {
  data: OrderInformationResponseData = inject(MAT_DIALOG_DATA);


  dialogRef = inject(MatDialogRef<CheckInConfirmationOverlayComponent>);


  ngOnInit(): void {
  }


}
