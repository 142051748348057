import {Component, inject, OnInit} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";

import {AddressService} from "../../../../data/address.service";
import {ButtonComponent, State} from "@smartdining/lib-sd-web-shared";
import {BaseComponent} from "@core/base/base-component";
import {GenericResponse} from "@core/models/generic-response";



@Component({
  selector: 'app-delete-address',
  standalone: true,
  imports: [
    FormsModule,
    MatDialogContent,
    ReactiveFormsModule,
    ButtonComponent
],
  templateUrl: './delete-address.component.html',
  styleUrl: './delete-address.component.scss'
})
export class DeleteAddressComponent extends BaseComponent implements OnInit {
  private addressService = inject(AddressService);
  dialogRef = inject(MatDialogRef<DeleteAddressComponent>);
  data: string = inject(MAT_DIALOG_DATA);
  state = new State<GenericResponse>();

  ngOnInit(): void {

  }

  onYesClicked() {
    this.executeRequest({
      state: this.state,
      request: this.addressService.deleteAddress(this.data),
      successMessage: 'Address deleted successfully!',
      handleSuccess: true,
      onSuccess: response => {
        this.dialogRef.close(true);
      }
    });
  }

  onCloseClicked() {
    this.dialogRef.close();
  }

  onNoClicked() {
    this.dialogRef.close();
  }

}
