<div class="header flex justify-center items-center px-3 py-6 border-b border-neutral-200">
  <p class="text-body1 font-bold"
     i18n="Change Password Title|Title of the change password dialog@@changePassword.title">
    Change Password
  </p>
  <p class="absolute right-3 rounded-lg p-3 border border-neutral-300 cursor-pointer hover:bg-neutral-50"
     (click)="onCloseClicked()"
     i18n-title="Close Button Tooltip|Tooltip for closing the dialog@@changePassword.closeTooltip"
     title="Close">
    <lib-svg-icon src="assets/icons/close.svg" class="text-neutral-800" [size]="12"></lib-svg-icon>
  </p>
</div>

<mat-dialog-content class="body overflow-y-auto max-h-60vh py-6 px-8 h-50 my-12">
  <form [formGroup]="changePasswordForm">
    <lib-text-input
      type="password"
      formControlName="currentPassword"
      [label]="'Current Password'"
      i18n-label="Current Password Label|Label for current password input@@changePassword.currentPasswordLabel">
    </lib-text-input>

    <lib-text-input
      type="password"
      formControlName="newPassword"
      [label]="'New Password'"
      i18n-label="New Password Label|Label for new password input@@changePassword.newPasswordLabel">
    </lib-text-input>

    <lib-text-input
      type="password"
      formControlName="confirmPassword"
      [label]="'Confirm New Password'"
      i18n-label="Confirm New Password Label|Label for confirm new password input@@changePassword.confirmPasswordLabel">
    </lib-text-input>
  </form>
</mat-dialog-content>

<div class="border-t">
  <mat-dialog-actions>
    <div class="action flex w-full justify-end gap-4 items-center px-3 py-2 border-neutral-200">
      <lib-button
        appearance="outline"
        (click)="onClearClicked()"
        i18n="Clear All Button|Button to reset all password fields@@changePassword.clearAllButton">
        Clear All
      </lib-button>

      <lib-button
        (click)="onChangePasswordClicked()"
        i18n="Change Password Button|Button to submit password change@@changePassword.submitButton">
        Change Password
      </lib-button>
    </div>
  </mat-dialog-actions>
</div>
