import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  input,
  OnDestroy,
  OnInit, PLATFORM_ID,
  signal,
  viewChild,
} from '@angular/core';
import {DineInGlobalFilterComponent} from "../../components/dine-in-global-filter/dine-in-global-filter.component";
import {
  DineInRestaurantCardComponent
} from "../../components/dine-in-restaurant-card/dine-in-restaurant-card.component";
import {RestaurantService} from "../../../data/restaurant.service";
import {BaseComponent} from "@core/base/base-component";
import {
  RestaurantSearchResponse,
  RestaurantSearchResponseData,
} from "../../../data/restaurant-search/restaurant-search-response";
import {DineInRestaurantSearchRequest} from "../../../data/restaurant-search/dine-in-restaurant-search-request";
import {AppDataService} from "@core/services/app-data/app-data.service";
import {FilterButtonComponent} from "@core/components/filter/filter-button/filter-button.component";
import {ClearFilterComponent} from "@core/components/filter/clear-filter/clear-filter.component";
import {FilterChipComponent} from "@core/components/filter/filter-chip/filter-chip.component";
import {MatDialog} from "@angular/material/dialog";
import {DineInFilterOverlayComponent} from "../../overlay/dine-in-filter-overlay/dine-in-filter-overlay.component";
import {CuisinesResponse} from "../../../data/cuisines/cuisines-response";
import {RestaurantsFilterService} from "../../../data/restaurants-filter.service";
import {numberToPricePoint, pricePointToNumber} from "@core/utils/price-point-utils";
import {skip, Subject, Subscription} from "rxjs";
import {
  RestaurantAvailabilitiesRequest
} from "../../../data/restaurant-availabilities/restaurant-availabilities-request";

import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {LoginStatus} from "../../../../auth/presentation/pages/user-login-overlay/user-login-overlay.component";
import {Constants} from "@core/constants/constants";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {BookingsRequest} from "../../../../booking/data/bookings/common/bookings-request";
import {BookingsResponse, BookingsResponseData} from "../../../../booking/data/bookings/bookings/bookings-response";
import {DOCUMENT, isPlatformBrowser, NgClass} from "@angular/common";
import {BookingsService} from "../../../../booking/data/bookings/bookings.service";
import {
  BookingSurveyOverlayComponent,
  BookingSurveyStatus
} from "../../../../booking/presentation/overlays/booking-review-overlay/booking-survey-overlay.component";
import {BookingInformationResponse} from "../../../../booking/data/bookings/bookings/booking-information-response";
import {GetCompletedOrdersResponseData} from "../../../../pickup-order/data/completed-orders/completed-orders-response";

import {
  SetBookingStatusNotInterestedRequest
} from "../../../../booking/data/set-booking-survey-not-interested/set-booking-status-not-interested-request";
import {
  SetBookingStatusNotInterestedResponse
} from "../../../../booking/data/set-booking-survey-not-interested/set-booking-status-not-interested-response";
import {DineInOption} from "../../../../layout/top-nav/top-nav.component";
import {formatDate} from "date-fns";
import {
  ClearCartConfirmationOverlayComponent
} from "../../../../pickup-order/presentation/overlay/clear-cart-confirmation-overlay/clear-cart-confirmation-overlay.component";
import {CartService} from "../../../../pickup-order/data/cart.service";
import {LocationService} from "@core/services/location-service/location.service";
import {
  LocationSelectionOverlayComponent
} from "../../../../common/presentation/overlay/location-selection-overlay/location-selection-overlay.component";
import {
  SelectAddressOverlayComponent
} from "../../../../common/presentation/overlay/select-address-overlay/select-address-overlay.component";
import {
  RestaurantAvailabilitiesResponse,
  RestaurantsAvailabilitiesResponseData
} from "../../../data/restaurant-availabilities/restaurant-availabilities-response";
import {
  AppSvgIconComponent,
  ButtonComponent,
  NetworkImageComponent,
  NoDataComponent,
  ShimmerComponent, State
} from '@smartdining/lib-sd-web-shared';
import {DebounceHelper} from "@shared/utils/debounce-utils";

@Component({
  selector: 'app-restaurants',
  standalone: true,
  imports: [
    DineInGlobalFilterComponent,
    DineInRestaurantCardComponent,
    ShimmerComponent,
    NoDataComponent,
    ButtonComponent,
    FilterButtonComponent,
    ClearFilterComponent,
    FilterChipComponent,
    AppSvgIconComponent,
    InfiniteScrollModule,
    NgClass,
    NetworkImageComponent,
  ],
  templateUrl: './restaurants.component.html',
  styleUrl: './restaurants.component.scss'
})
export class RestaurantsComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {

  date = input<string | null>(null);
  time = input<string | null>(null);
  partSize = input<number | null>(null);
  rating = input<number | null>(null);
  cuisines = input<string | null>(null);
  pricePoint = input<number | null>(null);
  pastReservation = signal<BookingsResponseData | null>(null);
  dineInOption = input<string>('dine-in');
  platformId = inject(PLATFORM_ID);

  pastOrder = signal<GetCompletedOrdersResponseData | null>(null);

  bookingId = input<string>('');
  booking = signal<BookingInformationResponse | null>(null);
  showLeftArrow = signal(false);
  showRightArrow = signal(true);
  scrollContainer = viewChild<ElementRef>('cuisinesScrollContainer');


  protected readonly DineInOption = DineInOption;

  restaurantsService = inject(RestaurantService);
  appDataService = inject(AppDataService);
  restaurantsFilterService = inject(RestaurantsFilterService);
  authStoreService = inject(AuthStoreService);
  bookingService = inject(BookingsService);
  dialog = inject(MatDialog);
  bookingsService = inject(BookingsService);
  document = inject(DOCUMENT);
  elementRef = inject(ElementRef);
  cartService = inject(CartService);
  locationService = inject(LocationService);

  private scrollListener: (() => void) | null = null;

  cuisinesState = new State<CuisinesResponse>();
  restaurantsState = new State<RestaurantSearchResponse>();
  restaurantAvailabilities = new State<RestaurantAvailabilitiesResponse>();
  bookingState = new State<BookingsResponse>();
  setBookingStatusNotInterestedState = new State<SetBookingStatusNotInterestedResponse>();
  private locationChangeSubscription: Subscription | null = null;


  restaurants = signal<RestaurantSearchResponseData[]>([]);
  selectedDineInOption = this.restaurantsFilterService.dineInOption;
  restaurantRequestExecuted = signal(false);

  filterObserver?: Subscription;
  loginListener?: Subscription;
  locationChangeListener?: Subscription;

  protected readonly numberToPricePoint = numberToPricePoint;

  private restaurantSearchDebounceHelper = new DebounceHelper<boolean>(500);

  ngOnInit(): void {
    this.initEventListeners();
    this.initFilterListener();
    this.processQueryParameter();
    this.getCuisines();
    this.getPastReservation();
    this.initLocationChangeListener();
  }

  ngAfterViewInit() {
    this.checkScrollPosition();
    this.scrollListener = this.checkScrollPosition.bind(this);
    this.scrollContainer()?.nativeElement.addEventListener('scroll', this.scrollListener);
  }

  ngOnDestroy(): void {
    this.filterObserver?.unsubscribe();
    this.loginListener?.unsubscribe();
    this.locationChangeListener?.unsubscribe();
    this.restaurantSearchDebounceHelper.destroy();
    if (this.scrollListener) {
      this.scrollContainer()?.nativeElement.removeEventListener('scroll', this.scrollListener);
    }
    if (this.locationChangeSubscription) {
      this.locationChangeSubscription.unsubscribe();
    }
  }

  onSelectLocationClicked() {
    if (this.authStoreService.isAuthenticated()) {
      this.dialog.open(LocationSelectionOverlayComponent, {
        ...Constants.defaultDialogConfig
      }).afterClosed().subscribe(result => {
        if (result) {
          this.getRestaurantsWithDebounce(true);
        }
      });
    } else {
      this.dialog.open(SelectAddressOverlayComponent, {
        ...Constants.defaultDialogConfig
      }).afterClosed().subscribe(result => {
        if (result) {
          this.getRestaurantsWithDebounce(true);
        }
      });
    }
  }

  private initLocationChangeListener() {
    this.locationChangeSubscription = this.appDataService.$locationUpdated
      .subscribe(() => {
        this.getRestaurantsWithDebounce(true);
      });
  }

  private initFilterListener() {
    this.filterObserver = this.restaurantsFilterService.filterUpdated$.subscribe((value) => {
      const cuisinesNames = this.restaurantsFilterService.selectedCuisines().map(cuisine => cuisine);
      const cuisinesCsvNames = cuisinesNames.join(',');
      let dateString = formatDate(this.restaurantsFilterService.selectedDate(), 'yyyy-MM-dd');
      let partySize: number | null;
      if (this.restaurantsFilterService.dineInOption() == DineInOption.dineIn) {
        partySize = Number(this.restaurantsFilterService.selectedPartySize() ?? 4);
      } else {
        partySize = null;
      }
      this.mergeQueryParams({
        date: dateString,
        time: this.restaurantsFilterService.selectedTime()?.timeString12,
        partSize: partySize,
        rating: this.restaurantsFilterService.selectedRating() ?? null,
        cuisines: cuisinesCsvNames,
        pricePoint: this.restaurantsFilterService.selectedPricePoint() ?? null,
        dineInOption: this.restaurantsFilterService.dineInOption(),
      });
      this.getRestaurantsWithDebounce(true);
    });
  }

  private checkScrollPosition(): void {
    let element = this.scrollContainer()?.nativeElement;
    const scrollLeft = element?.scrollLeft ?? 0;
    const scrollWidth = element?.scrollWidth ?? 0;
    const clientWidth = element?.clientWidth ?? 0;

    this.showLeftArrow.set(scrollLeft > 0);
    this.showRightArrow.set(scrollLeft < (scrollWidth - clientWidth - 1));
  }


  scroll(direction: 'left' | 'right'): void {
    if (this.scrollContainer()) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      this.scrollContainer()?.nativeElement.scrollBy({left: scrollAmount, behavior: 'smooth'});
    }
  }

  onCuisineClicked(cuisineName: string) {
    if (this.restaurantsFilterService.selectedCuisines().includes(cuisineName)) {
      this.restaurantsFilterService.removeCuisineFilter(cuisineName);
    } else {
      this.restaurantsFilterService.addCuisineFilter(cuisineName);
    }
    this.updateUrlWithFilters();
    this.getRestaurantsWithDebounce(true);
  }

  isCuisineSelected(cuisineName: string): boolean {
    return this.restaurantsFilterService.selectedCuisines().includes(cuisineName);
  }

  updateUrlWithFilters() {
    const queryParams = {
      date: formatDate(this.restaurantsFilterService.selectedDate(), 'yyyy-MM-dd'),
      time: this.restaurantsFilterService.selectedTime()?.timeString12,
      cuisines: this.restaurantsFilterService.selectedCuisines().join(','),
      dineInOption: this.restaurantsFilterService.dineInOption(),
      rating: this.restaurantsFilterService.selectedRating() ?? null,
      pricePoint: this.restaurantsFilterService.selectedPricePoint() ?? null,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  private initEventListeners() {
    this.loginListener = this.authStoreService.$loginStatus
      .pipe(skip(1))
      .subscribe(status => {
        switch (status) {
          case LoginStatus.loggedOut:
          case LoginStatus.loginSuccess:
            this.getRestaurantsWithDebounce(true);
            break;
          case LoginStatus.loginFailed:
            break;
        }
      });
    this.locationChangeListener = this.appDataService.$locationUpdated
      .pipe(skip(1))
      .subscribe(status => {
        this.getRestaurantsWithDebounce(true);
      });
  }


  processQueryParameter() {
    let dateString = formatDate(this.restaurantsFilterService.selectedDate(), 'yyyy-MM-dd');
    const partySize = Number(this.partSize() ?? 4);
    const cuisines = this.cuisines() ?? null;
    const cuisinesFilter = (cuisines != null && cuisines != '') ? cuisines.split(',') : [];
    const pricePointFilter = (this.pricePoint() != null && (this.pricePoint() as any) != '') ? Number(this.pricePoint()) : null;
    let formattedDate: Date = new Date();
    if (this.date() != null) {
      formattedDate = new Date(this.date()!);
    }

    this.restaurantsFilterService.setDateFilter(formattedDate);
    this.restaurantsFilterService.selectedPartySize.set(partySize);
    this.restaurantsFilterService.selectedRating.set((this.rating() != null && this.rating() != 0) ? Number(this.rating()) : null);
    this.restaurantsFilterService.selectedCuisines.set(cuisinesFilter);
    this.restaurantsFilterService.selectedPricePoint.set(pricePointFilter);

    if (this.dineInOption() === 'dine-in') {
      this.restaurantsFilterService.setDineInOptionFilter(DineInOption.dineIn);
    }

    if (this.dineInOption() === 'pickup-order') {
      this.restaurantsFilterService.setDineInOptionFilter(DineInOption.pickupOrder);
    }
  }

  onFilterClicked() {
    this.dialog.open(DineInFilterOverlayComponent, {
      minWidth: '350px',
      maxWidth: '600px',
      maxHeight: '90vh',
      data: {
        cuisines: this.cuisinesState.response()
      }
    });
  }


  getCuisines() {
    this.executeRequest({
      state: this.cuisinesState,
      request: this.restaurantsService.getCuisines()
    });
  }

  getRestaurantsWithDebounce(resetPagination: boolean = false) {
    this.restaurantSearchDebounceHelper.debounce((resetPagination) => this.getRestaurants(resetPagination), resetPagination);
  }

  getRestaurants(resetPagination: boolean = false) {
    if (resetPagination) {
      this.restaurantRequestExecuted.set(false);
    }

    const selectedDate = this.restaurantsFilterService.selectedDate();
    const selectedTime = this.restaurantsFilterService.selectedTime();

    if (selectedTime == null) {
      return;
    }

    let utcTime = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      selectedTime.hour24,
      selectedTime.minute
    );

    const utcTimestamp = Math.floor(utcTime.getTime() / 1000);

    let location = this.appDataService.getAddress();
    let locationFilter: any[] = location ? [location.longitude, location.latitude] : [];


    let cuisinesFilter: string[] = this.restaurantsFilterService.selectedCuisines().map(cuisine => cuisine)

    let current = this.restaurantsState.response()?.metadata.page.current_page;
    let pageNumber = 1;

    if (current == null || resetPagination) {
      pageNumber = 1;
      this.restaurantsState.clearState();
      this.restaurants.set([]);
    } else {
      pageNumber = (current ?? 0) + 1;
    }

    let responseLastPage = this.restaurantsState.response()?.metadata?.page?.total_pages;
    if (current != null && responseLastPage != null && current == responseLastPage) {
      return;
    }

    let request: DineInRestaurantSearchRequest = {
      filters: {
        pricePoint: this.restaurantsFilterService.selectedPricePoint() ?? undefined,
        rating: this.restaurantsFilterService.selectedRating() ?? undefined,
        cuisine: cuisinesFilter,
      }, geolocation: locationFilter,
      page: {
        current: pageNumber ?? 1,
        size: Constants.pageSize
      },
      pivot: 100000,
      searchOptions: {
        reservationOption: {
          partySize: Number(this.restaurantsFilterService.selectedPartySize()),
          year: this.restaurantsFilterService.selectedDate().getFullYear(),
          month: this.restaurantsFilterService.selectedDate().getMonth() + 1,
          day: this.restaurantsFilterService.selectedDate().getDate(),
          utcTime: utcTimestamp,
        }
      },
      searchText: ''
    };

    switch (this.restaurantsFilterService.dineInOption()) {
      case DineInOption.dineIn:
        request.filters.enableOnlineReservation = true;
        break;
      case DineInOption.pickupOrder:
        request.filters.enablePickupOrder = true;
        break;
    }
    this.executeRequest<RestaurantSearchResponse>({
      state: this.restaurantsState,
      request: this.restaurantsService.getRestaurants(request),
      onSuccess: response => {
        if (this.restaurantsFilterService.dineInOption() == DineInOption.dineIn) {
          // TODO
          this.handleRestaurantAvailabilities([], pageNumber);
          // this.getRestaurantAvailabilities(pageNumber);
        } else {
          this.restaurantRequestExecuted.set(true);
          this.handleRestaurantAvailabilities([], pageNumber);
        }
      }
    });
  }


  getRestaurantAvailabilities(pageNumber: number) {
    const restaurantIds = this.restaurantsState.response()?.data.map(restaurant => restaurant._id) ?? [];
    if (!restaurantIds.length) {
      this.restaurantRequestExecuted.set(true);
      return;
    }

    let selectedTime = this.restaurantsFilterService.selectedTime();
    if (this.restaurantsFilterService.selectedTime == null) {
      return;
    }


    const request: RestaurantAvailabilitiesRequest = {
      restaurantIds,
      date: selectedTime!.time.toISOString(),
      partySize: Number(this.restaurantsFilterService.selectedPartySize()),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    this.executeRequest<RestaurantAvailabilitiesResponse>({
      state: this.restaurantAvailabilities,
      request: this.restaurantsService.getRestaurantAvailabilities(request),
      onSuccess: response => {
        this.restaurantRequestExecuted.set(true);
        this.handleRestaurantAvailabilities(response.data ?? [], pageNumber)
      }
    });
  }

  handleRestaurantAvailabilities(availabilities: RestaurantsAvailabilitiesResponseData[], pageNumber: number) {
    const restaurants = this.restaurantsState.response()?.data ?? [];

    restaurants.forEach(restaurant => {
      const restaurantAvailability = availabilities.find(slot => slot.restaurantId === restaurant._id);

      if (restaurantAvailability?.availableShiftSlots?.length) {
        restaurant.availabilities = restaurantAvailability.availableShiftSlots.map(slot => ({
          shiftId: slot.shiftId,
          time: new Date(slot.time),
          isAvailable: slot.isAvailable,
          partySize: slot.partySize,
          estimatedEndTime: slot.estimatedEndTime,
          cancellationCutOffTime: slot.cancellationCutOffTime,
          changeCutOffTime: slot.changeCutOffTime,
          tableTypes: slot.tableTypes
        }));
      } else {
        restaurant.availabilities = [];
      }
    });

    this.restaurants.update(prev => pageNumber === 1 ? restaurants : [...prev, ...restaurants]);
  }

  onRestaurantSelected(restaurant: RestaurantSearchResponseData) {
    switch (this.restaurantsFilterService.dineInOption()) {
      case DineInOption.dineIn:
        this.gotoDinePage(restaurant);
        break;
      case DineInOption.pickupOrder:
        this.gotoPickupOrderPage(restaurant);
        break;
    }
  }

  gotoDinePage(restaurant: RestaurantSearchResponseData) {
    this.router.navigate([`dine-in/${restaurant._id}`], {
      queryParams: {
        date: this.restaurantsFilterService.selectedDate().toISOString(),
        partySize: this.restaurantsFilterService.selectedPartySize()
      }
    });
  }

  gotoPickupOrderPage(restaurant: RestaurantSearchResponseData) {
    const parameters = {
      date: this.restaurantsFilterService.selectedDate().toISOString(),
      partySize: this.restaurantsFilterService.selectedPartySize()
    };
    const currentCartRestaurantId = this.cartService.restaurantId;
    if (currentCartRestaurantId && currentCartRestaurantId != restaurant._id) {
      this.dialog.open(ClearCartConfirmationOverlayComponent, {
        ...Constants.defaultDialogConfig,
      }).afterClosed().subscribe(status => {
        if (status) {
          this.cartService.resetCart();
          this.router.navigate([`pickup/${restaurant._id}`], {queryParams: parameters});
        }
      });
      return;
    }

    this.router.navigate([`pickup/${restaurant._id}`], {
      queryParams: parameters
    });
  }

  removedCuisineFilter(cuisineFilter: string) {
    this.restaurantsFilterService.removeCuisineFilter(cuisineFilter);
  }

  removedPricePointFilter() {
    this.restaurantsFilterService.removePricePointFilter();
  }

  removedRatingFilter() {
    this.restaurantsFilterService.removeRatingFilter();
  }

  clearFilter() {
    this.restaurantsFilterService.clearSeparateFilter();
  }

  getPastReservation() {
    const appUser = this.authStoreService.getAppUser()?.data;
    if (appUser) {
      const request: BookingsRequest = {
        phoneNumber: appUser.phoneNumber,
        email: appUser.email,
        page: 1,
        size: 1,
        surveyStatus: 'PENDING'
      }

      this.executeRequest<BookingsResponse>({
        state: this.bookingState,
        request: this.bookingService.getPastReservation(request),
        onSuccess: response => {
          let pastReservations = response.data ?? [];
          if (pastReservations.length > 0) {
            this.pastReservation.set(pastReservations[0]);
          } else {
            this.pastReservation.set(null);
          }
        }
      });
    }
  }

  protected readonly pricePointToNumber = pricePointToNumber;

  onWriteReviewClicked() {
    const bookingId = this.pastReservation()?._id;
    this.dialog.open(BookingSurveyOverlayComponent, {
      ...Constants.defaultDialogConfig,
      data: bookingId
    }).afterClosed().subscribe((result: BookingSurveyStatus) => {
      this.pastReservation.set(null);
    });
  }

  onNotInterestedClicked() {
    const bookingId = this.pastReservation()?._id;

    const request: SetBookingStatusNotInterestedRequest = {
      bookingId: bookingId
    };
    this.executeRequest({
      state: this.setBookingStatusNotInterestedState,
      request: this.bookingsService.setBookingStatusNotInterested(request),
      onSuccess: () => {
        this.pastReservation.set(null);
      }
    });
  }

  onDineOptionSelected(dineInOption: DineInOption) {
    this.restaurantsFilterService.setDineInOptionFilter(dineInOption);
  }

  protected readonly isPlatformBrowser = isPlatformBrowser;
}
