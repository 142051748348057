import {
  Component, ElementRef, inject, OnInit, ViewChild
} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {FormsModule} from '@angular/forms';
import {CommonModule} from "@angular/common";
import {BaseComponent} from "../../base/base-component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {MatFormFieldModule} from "@angular/material/form-field";
import {
  MatAutocompleteModule,
} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {
  AppSvgIconComponent, DateInputComponent,
  DatePickerComponent,
  OverlayService,
  State
} from "@smartdining/lib-sd-web-shared";
import {OccasionsResponse} from "@core/services/common-data/occasions/occasions-response";
import {FlyToElementService} from "@core/services/animation-service/fly-to-element.service";

@Component({
  selector: 'app-test',
  standalone: true,
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss',
  imports: [
    ReactiveFormsModule,
    InfiniteScrollModule,
    FormsModule,
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    DatePickerComponent,
    AppSvgIconComponent,
    DateInputComponent,
  ]
})
export class TestComponent extends BaseComponent implements OnInit {

  @ViewChild('trigger', {static: true}) trigger!: ElementRef;

  overlayService = inject(OverlayService);

  flyToElementService = inject(FlyToElementService)

  occasionsState = new State<OccasionsResponse>();

  ngOnInit(): void {

  }

  @ViewChild('cartIcon', {static: true}) cartRef!: ElementRef;
  @ViewChild('customFlyingElement') customFlyingElementRef!: ElementRef;

  addToCart(productImage: HTMLElement) {
    const to = this.cartRef.nativeElement;
    const flyingEl = this.customFlyingElementRef.nativeElement;
    this.flyToElementService.fly(productImage, to, {flyingEl});
  }
}

