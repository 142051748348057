import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {
  AppSvgIconComponent,
  BaseControlValueAccessorV3,
  BaseInputComponent,
  OverlayService
} from "@smartdining/lib-sd-web-shared";
import {PartySizeSelectorOverlayComponent} from "./party-size-selector-overlay/party-size-selector-overlay.component";

@Component({
  selector: 'app-party-size-input',
  imports: [
    AppSvgIconComponent,
    BaseInputComponent,
  ],
  templateUrl: './party-size-selector-input.component.html',
  standalone: true,
  styleUrl: './party-size-selector-input.component.scss'
})
export class PartySizeSelectorInputComponent extends BaseControlValueAccessorV3<number> {

  @ViewChild('trigger', {static: true}) trigger!: ElementRef;

  overlayService = inject(OverlayService);

  protected override onValueReady(value: number): void {
  }

  onPartySizeClicked() {
    let dialogRef = this.overlayService.openNearElement(PartySizeSelectorOverlayComponent, this.trigger.nativeElement, {
      isMobileResponsive: true
    });

    dialogRef.closed.subscribe((partySize: number) => {
      if (partySize) {
        this.onValueChange(partySize);
      }
    });
  }
}
