import {Component, inject, input, OnChanges, OnInit, output, signal, SimpleChanges} from '@angular/core';
import {ButtonComponent, CheckboxComponent, OutlineTextInputComponent, State} from "@smartdining/lib-sd-web-shared";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {AuthService} from "../../../data/auth.service";
import {GenericResponse} from "@core/models/generic-response";
import {BaseComponent} from "@core/base/base-component";
import {SignupData} from "../login-overlay/login-overlay.component";

@Component({
  selector: 'app-signup-overlay',
  imports: [
    ButtonComponent,
    FormsModule,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    CheckboxComponent
  ],
  templateUrl: './signup-overlay.component.html',
  styleUrl: './signup-overlay.component.scss'
})
export class SignupOverlayComponent extends BaseComponent implements OnInit, OnChanges {

  onSignupPerformed = output<SignupInfo>();

  private fb = inject(FormBuilder);
  private authService = inject(AuthService);

  signUpForm!: FormGroup;

  signUpState = new State<GenericResponse>();

  signUpData = input<SignupData | null>();

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.signUpForm) {
      this.initForm();
    }
  }

  ngOnInit(): void {
  }

  initForm() {
    this.signUpForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [this.signUpData()?.email, [Validators.required, Validators.email]],
      phoneNumber: [this.signUpData()?.phoneNumber, [Validators.required]],
      password: ['', [Validators.required]],
      subscribeToNewsletter: [true, Validators.required]
    });
  }

  onSignupClicked() {
    this.validateForm(this.signUpForm);
    if (this.signUpForm.invalid) {
      return;
    }
    this.executeRequest<GenericResponse>({
      state: this.signUpState,
      request: this.authService.signUp(this.signUpForm.value),
      handleSuccess: true,
      onSuccess: response => {
        this.onSignupPerformed.emit(this.signUpForm.value);
      }
    })
  }
}

export interface SignupInfo {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
  subscribeToNewsletter: boolean;
}
