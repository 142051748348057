<app-base-dialog [title]="'Check In Confirmation'">
  <div body class="flex flex-col justify-center items-center gap-4">
        <span
          class="flex justify-center items-center self-center rounded-full h-12 w-12 bg-success-500 bg-opacity-10 flex-shrink-0">
      <lib-svg-icon [size]="36" class="text-success-500" src="assets/icons/check-circle.svg"></lib-svg-icon>
    </span>

    <p class="text-subtitle1 text-neutral-600">Your curb side pickup at<span
      class="text-subtitle1 text-info-500"> {{ data.fulfillmentDate | date: "MMM d, y - h:mm a" }} </span></p>

    <span class="flex gap-2 items-center">
      <p class="text-body2 text-black">Order ID - </p>
      <p class="text-body2 text-info-500 ">#{{ data.orderNumber }}</p>
    </span>

    <div class="flex items-center justify-center gap-6 w-full px-4 py-3 bg-neutral-50 rounded-2xl">
      <div class="flex gap-2 items-center">
        <p class="text-body2">Parking slot: {{ data.curbside.parkingNumber }}</p>
      </div>
    </div>
  </div>
</app-base-dialog>
