import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

export class DebounceHelper<T> {
  private subject = new Subject<{ fn: (data: T) => void; data: T }>();
  private destroy$ = new Subject<void>();

  constructor(debounceTimeMs: number = 500) {
    this.subject
      .pipe(debounceTime(debounceTimeMs), takeUntil(this.destroy$))
      .subscribe(({ fn, data }) => fn(data));
  }

  debounce(fn: (data: T) => void, data: T) {
    this.subject.next({ fn, data });
  }

  destroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subject.complete();
  }
}
