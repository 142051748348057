<app-base-dialog [title]="'Order Confirmation'">
  <div body class="flex flex-col justify-center items-center gap-4">
        <span
          class="flex justify-center items-center self-center rounded-full h-12 w-12 bg-success-500 bg-opacity-10 flex-shrink-0">
      <lib-svg-icon [size]="36" class="text-success-500" src="assets/icons/check-circle.svg"></lib-svg-icon>
    </span>

    <p class="text-subtitle1 text-neutral-600">We have received your <span class="text-subtitle1 text-info-500"> {{ orderScheduleOption() }} - {{ orderType() }} </span> order and will notify the status soon. Thank you!</p>

    <span class="flex gap-2 items-center">
      <p class="text-body2 text-black">Order ID - </p>
      <p class="text-body2 text-info-500 ">#{{ data.orderNumber }}</p>
    </span>

    <div class="flex items-center justify-center gap-6 w-full px-4 py-3 bg-neutral-50 rounded-2xl">
      @switch (data.orderScheduleOption) {
        @case ('ASAP') {
          <p class="text-body2 px-3 py-1 bg-green-500 rounded-xl text-white">ASAP</p>
        }
        @case('LATER') {
          <p class="text-body2 px-3 py-1 bg-primary-500 rounded-xl text-white">SCHEDULED</p>
        }
      }

      <div class="flex gap-2 items-center">
        <lib-svg-icon src="assets/icons/calendar.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
        <p class="text-body2">{{ data.orderProcessStartedDateTime | date:'d MMMM y' }}</p>
      </div>
      <div class="flex gap-2 items-center">
        <lib-svg-icon src="assets/icons/clock.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
        <p class="text-body2">{{ data.orderProcessStartedDateTime | date:'h:mm a' }}</p>
      </div>
    </div>

  </div>
</app-base-dialog>
