<lib-base-input [title]="'Time'" (click)="onOpenTimeOverlayClicked()">
  <div #trigger input>
    <p class="text-caption sm:text-body2 whitespace-nowrap">{{ controlValue?.timeString12 ?? '-' }}</p>
  </div>

  <div icon>
    <lib-svg-icon src="assets/icons/chevron-down.svg" class="text-neutral-400" [size]="12"></lib-svg-icon>
  </div>

</lib-base-input>
