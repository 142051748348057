<lib-base-overlay [title]="'Share'">

  <div body>
    <div class="flex justify-between gap-3">

      <div class="flex flex-col gap-2 items-center cursor-pointer" (click)="onCopyLinkPressed()">
        <lib-svg-icon src="assets/icons/copy.svg"
                      class="text-neutral-700 bg-neutral-100 rounded-full p-4"></lib-svg-icon>
        <p class="text-body2 font-semibold"
           i18n="Share Method Copy|Label for copying link@@shareLink.copy">
          Copy
        </p>
      </div>

      <div class="flex flex-col gap-2 items-center cursor-pointer" (click)="onSmsClicked()">
        <lib-svg-icon src="assets/icons/message.svg"
                      class="text-blue-500 bg-neutral-100 rounded-full p-4"></lib-svg-icon>
        <p class="text-body2 font-semibold"
           i18n="Share Method SMS|Label for sending via SMS@@shareLink.sms">
          SMS
        </p>
      </div>

      <a class="flex flex-col gap-2 items-center cursor-pointer" (click)="onEmailClicked()">
        <lib-svg-icon src="assets/icons/mail.svg"
                      class="text-red-500 bg-neutral-100 rounded-full p-4"></lib-svg-icon>
        <p class="text-body2 font-semibold"
           i18n="Share Method Email|Label for sending via email@@shareLink.email">
          Email
        </p>
      </a>

      <div class="flex flex-col gap-2 items-center cursor-pointer" (click)="onWhatsAppClicked()">
        <lib-svg-icon src="assets/icons/whatsapp.svg"
                      class="bg-neutral-100 rounded-full text-[#25D366] p-4"></lib-svg-icon>
        <p class="text-body2 font-semibold"
           i18n="Share Method WhatsApp|Label for sending via WhatsApp@@shareLink.whatsapp">
          Whatsapp
        </p>
      </div>

    </div>

    <div class="flex flex-col gap-2 py-6">
      <p class="text-body2 font-bold"
         i18n="Page Link Title|Label above the shared link@@shareLink.pageLinkTitle">
        Page Link
      </p>
      <div class="flex gap-3 items-center rounded-3xl bg-neutral-50 w-full px-3 py-2">
        <p class="text-body2 whitespace-pre-wrap break-words overflow-hidden">{{ data.url }}</p>
        <lib-svg-icon
          (click)="onCopyLinkPressed()"
          src="assets/icons/copy.svg"
          class="text-neutral-700 cursor-pointer"
          i18n-title="Copy Link Tooltip|Tooltip for copy icon near link@@shareLink.copyTooltip"
          title="Copy Link">
        </lib-svg-icon>
      </div>
    </div>
  </div>

  <div actions></div>
</lib-base-overlay>
