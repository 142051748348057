@switch (wizardUtil.activeWizard().key) {
  @case (AuthWizardStep.login) {
    <app-login-overlay (signupClicked)="onSignupClicked($event)" (forgotPasswordClicked)="onForgotPasswordClicked()"
                       (onLoginSuccessful)="onLoginSuccessful()"></app-login-overlay>
  }
  @case (AuthWizardStep.signup) {
    <app-signup-overlay [signUpData]="signupData" (onSignupPerformed)="onSignupPerformed($event)"></app-signup-overlay>
  }
  @case (AuthWizardStep.verifySignupPhone) {
    <app-verify-signup-phone-overlay [signupInfo]="signInfoData"
                                     (onSignupPhoneVerified)="onSignupPhoneVerified()"></app-verify-signup-phone-overlay>
  }
  @case (AuthWizardStep.verifySignupEmail) {
    <app-verify-signup-email-overlay [signupInfo]="signInfoData"
                                     (onSignupEmailVerified)="onSignupEmailVerified()"></app-verify-signup-email-overlay>
  }
  @case (AuthWizardStep.forgotPassword) {
    <app-forgot-password-overlay
      (forgotPasswordPerformed)="onForgotPasswordPerformed($event)"></app-forgot-password-overlay>
  }
  @case (AuthWizardStep.resetPassword) {
    <app-reset-password-overlay [email]="forgotPasswordEmailData"
                                (onResetPasswordCompleted)="onResetPasswordCompleted()"></app-reset-password-overlay>
  }
}
