<div class="flex flex-col h-full w-full items-center justify-center gap-10 p-10">

  <lib-date-input></lib-date-input>
  <lib-date-picker [disabledDays]="['monday', 'sunday']" [allowOnlyPast]="true"></lib-date-picker>

  <div class="product" #productImage (click)="addToCart(productImage)">
    <lib-svg-icon src="assets/icons/cart.svg"></lib-svg-icon>
    <p>Item</p>
  </div>

  <!-- cart icon -->
  <div class="cart-icon fixed top-3 right-3" #cartIcon>
    🛒
  </div>

</div>


<div #customFlyingElement class="hidden">
  <div class="flex justify-center items-center h-12 w-12 bg-green-200 text-white rounded-full">
    <lib-svg-icon src="assets/icons/food-orders.svg"></lib-svg-icon>
  </div>
</div>

