<div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6 flex justify-center">
  <form [formGroup]="waitListForm" class="flex flex-col gap-5">

    <div class="flex gap-4 bg-white rounded shadow-1 overflow-hidden">
      <lib-network-image class="h-auto w-32 object-cover"
                         [imageUrl]="restaurantDetailState.response()?.data?.banner?.url">
      </lib-network-image>
      <div class="flex flex-col gap-2 py-4">
        <p class="text-body2 text-primary-500">
          {{ restaurantDetailState.response()?.data?.restaurantName }}
        </p>
        <p class="text-body2 text-neutral-400">
          {{
            arrayToCSV([
              restaurantDetailState.response()?.data?.location?.address1,
              restaurantDetailState.response()?.data?.location?.city,
              restaurantDetailState.response()?.data?.location?.state
            ])
          }}
        </p>
      </div>
    </div>

    <div class="flex items-center gap-12">
      <app-party-size-input
        formControlName="partySize"
        (valueChanged)="onPartSizeChanged($event)">
      </app-party-size-input>

      <lib-base-input
        [title]="'Wait time'"
        i18n-title="Wait Time Label|Label for the estimated wait time@@joinWaitlist.waitTimeLabel">
        <div input>
          @if (waitTimesState.success()) {
            <p class="text-body2 font-bold text-primary-500">
              {{ waitTimesState.response()?.data?.tableWaitTime ?? 'N/A' }} minutes
            </p>
          }
          @if (waitTimesState.loading()) {
            <lib-shimmer class="w-full" type="multiline"></lib-shimmer>
          }
        </div>
      </lib-base-input>
    </div>

    <lib-single-selection-field
      formControlName="occasion"
      [title]="'Select Occasion'"
      i18n-title="Occasion Field Label|Label for occasion selection@@joinWaitlist.occasionTitle"
      [items]="occasionsState.response()?.data ?? []"
      [display]="'name'"
      value="name"
      [dynamicIconPath]="'icon'"
      [dynamicIconColor]="'iconColor'">
    </lib-single-selection-field>

    <lib-single-selection-field
      formControlName="seatingPreferences"
      [title]="'Select Seating Preferences'"
      i18n-title="Seating Preferences Field Label|Label for seating preference selection@@joinWaitlist.seatingTitle"
      [items]="seatingPreferenceState.response()?.data ?? []"
      [display]="'name'"
      value="name"
      [dynamicIconPath]="'icon'"
      [dynamicIconColor]="'iconColor'">
    </lib-single-selection-field>

    <lib-multi-selection-field
      formControlName="tags"
      [title]="'Select Tags'"
      i18n-title="Tags Field Label|Label for optional tags selection@@joinWaitlist.tagsTitle"
      [items]="tagsState.response()?.data ?? []"
      [display]="'name'"
      value="name"
      identifier="name"
      [dynamicIconPath]="'icon'"
      [dynamicIconColor]="'iconColor'">
    </lib-multi-selection-field>

    <lib-text-input
      formControlName="note"
      class="mt-2"
      [label]="'Special notes'"
      i18n-label="Special Notes Label|Label for optional notes field@@joinWaitlist.notesLabel"
      type="text">
    </lib-text-input>

    <p class="text-body2 text-black"
       i18n="Cancellation Policy Title|Label before cancellation policy@@joinWaitlist.cancellationTitle">
      Cancellation Policy
    </p>

    <p class="text-body2"
       i18n="Cancellation Policy Message|Explanation of waitlist cancellation policy@@joinWaitlist.cancellationMessage">
      While you won’t be charged if you need to cancel, we ask that you notify us at least 24 hours in advance
    </p>

    <p class="text-body2"
       i18n="Terms Agreement Message|Agreement to terms for joining waitlist@@joinWaitlist.termsMessage">
      By clicking reserve now, you agree to the Smart Dining LLC User Agreement, Privacy Policy, and Cookie Policy
    </p>

    <lib-button
      type="submit"
      class="w-full"
      [fullWidth]="true"
      [loading]="joinWaitListState.loading()"
      (click)="onJoinWaitListClicked()"
      i18n="Join Waitlist Button|Button to join the restaurant waitlist@@joinWaitlist.joinButton">
      Join Waitlist
    </lib-button>
  </form>
</div>
