<div class="container-fluid bg-neutral-50">
  <div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6">

    @if (bookingDetailsState.loading()) {
      <div class="flex flex-col gap-10 p-4 bg-white">
        <lib-shimmer type="list"></lib-shimmer>
        <lib-shimmer type="list"></lib-shimmer>
        <lib-shimmer type="list"></lib-shimmer>
      </div>
    }

    <div class="flex flex-col gap-4 py-2">
      <div class="flex flex-col gap-2">
        <div class="flex justify-center py-2">
          <span class="flex justify-center items-center self-center rounded-full h-20 w-20 bg-success-500 bg-opacity-10 flex-shrink-0">
            <lib-svg-icon [size]="64" class="text-success-500" src="assets/icons/check-circle.svg"></lib-svg-icon>
          </span>
        </div>
        <div class="flex justify-center">
          <p i18n="Success Message|Confirmation that invitation was accepted@@acceptInvitation.success">
            Invitation accepted successfully
          </p>
        </div>
        <p class="text-body2 text-neutral-700 text-center pb-2"
           i18n="Booking ID Label|Booking confirmation number label@@acceptInvitation.bookingId">
          Booking ID - #{{ booking()?.confirmationNumber }}
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div class="col-span-3 flex flex-col gap-4 px-4 py-6 bg-white">

          <div class="flex flex-col gap-3">
            <p class="text-body2 text-primary-500 font-medium"
               i18n="Restaurant Details Section Title|@@acceptInvitation.restaurantDetails">
              Restaurant Details
            </p>
            <div class="flex gap-3">
              <lib-network-image class="h-auto w-32 object-cover"
                                 [imageUrl]="booking()?.restaurant?.banner?.url">
              </lib-network-image>
              <div class="flex flex-col">
                <p class="text-body2 text-neutral-700">{{ booking()?.restaurant?.restaurantName }}</p>
                <div class="flex flex-col gap-2">
                  <p class="text-body2 text-neutral-400">
                    {{
                      arrayToCSV([
                        booking()?.restaurant?.location?.address1,
                        booking()?.restaurant?.location?.city,
                        booking()?.restaurant?.location?.state
                      ])
                    }}
                  </p>
                  <div class="flex gap-5">
                    @if (booking()?.restaurant?.phoneNumber) {
                      <div class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-neutral-50 cursor-pointer"
                           (click)="onCallClicked()">
                        <lib-svg-icon src="assets/icons/phone.svg" class="text-blue-500 cursor-pointer"></lib-svg-icon>
                      </div>
                    }
                    @if (destinationGeoPoint().latitude && destinationGeoPoint().longitude) {
                      <app-direction-button appearance="iconButton"
                                            class="text-blue-500 cursor-pointer"
                                            [destinationLatitude]="destinationGeoPoint().latitude"
                                            [destinationLongitude]="destinationGeoPoint().longitude">
                      </app-direction-button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="border-b border-neutral-100 my-3"></div>

          <div class="flex flex-col gap-3">
            <p class="text-body2 text-primary-500 font-medium"
               i18n="Booking Details Section Title|@@acceptInvitation.bookingDetails">
              Booking Details
            </p>
            <div class="flex items-center gap-6">
              <div class="flex justify-start gap-2">
                <lib-svg-icon src="assets/icons/calendar.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
                <p class="text-body2">{{ booking()?.startTime | date:'d MMMM y' }}</p>
              </div>
              <div class="flex gap-2 items-center rounded-2xl">
                <lib-svg-icon src="assets/icons/clock.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
                <p class="text-body2">{{ booking()?.startTime | date:'h:mm a' }}</p>
              </div>
              <div class="flex gap-2 items-center rounded-2xl">
                <lib-svg-icon src="assets/icons/group.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
                <p class="text-body2">{{ booking()?.partySize }} Guest(s)</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
