import {inject, Injectable} from '@angular/core';
import {BaseApiService} from "@core/base/base-api.service";
import {URLs} from "../../../core/base/urls";
import {FavoriteRestaurantsResponse} from "./favorites/favorite-restaurants-response";
import {GenericResponse} from "../../../core/models/generic-response";
import {RequestService} from "../../../shared/utils/request.service";
import {FavoriteRestaurantRequest} from "../../restaurants/data/favourite-restaurant/favorite-restaurant-request";

@Injectable({
  providedIn: 'root'
})
export class RestaurantFavoriteService extends BaseApiService {

  addToFavorite(request: FavoriteRestaurantRequest) {
    return this.post<FavoriteRestaurantRequest>(URLs.createFavorite, request);
  }

  getFavoriteRestaurants(userId: string) {
    return this.get<FavoriteRestaurantsResponse>(URLs.getFavoriteRestaurants(userId));
  }

  deleteFavoriteRestaurant(favoriteId: string) {
    return this.delete<GenericResponse>(URLs.deleteFavoriteRestaurant(favoriteId));
  }

}
