<div>
  <form [formGroup]="signUpForm" class="flex flex-col items-center gap-4">
    <img src="assets/images/branding/app-logo.png" width="48" alt="">

    <p class="text-body2 font-medium text-neutral-500 text-left w-full"
       i18n="Signup Intro|Short onboarding message encouraging signup@@signupOverlay.introText">
      Sign up in seconds and start exploring top-rated restaurants near you 🚀
    </p>

    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
      <lib-outline-text-input
        [fullWidth]="true"
        class="w-full"
        formControlName="firstName"
        label="First Name"
        placeholder="Ex: Tiffany"
        i18n-label="First Name Label|Label for first name input@@signupOverlay.firstNameLabel"
        i18n-placeholder="First Name Placeholder|Example placeholder for first name@@signupOverlay.firstNamePlaceholder">
      </lib-outline-text-input>

      <lib-outline-text-input
        [fullWidth]="true"
        class="w-full"
        formControlName="lastName"
        label="Last Name"
        placeholder="Ex: Helton"
        i18n-label="Last Name Label|Label for last name input@@signupOverlay.lastNameLabel"
        i18n-placeholder="Last Name Placeholder|Example placeholder for last name@@signupOverlay.lastNamePlaceholder">
      </lib-outline-text-input>
    </div>

    <lib-outline-text-input
      [fullWidth]="true"
      class="w-full"
      formControlName="email"
      label="Email"
      placeholder="Ex: name@company.com"
      i18n-label="Email Label|Label for email input@@signupOverlay.emailLabel"
      i18n-placeholder="Email Placeholder|Example placeholder for email@@signupOverlay.emailPlaceholder">
    </lib-outline-text-input>

    <lib-outline-text-input
      [fullWidth]="true"
      class="w-full"
      formControlName="phoneNumber"
      label="Phone Number"
      placeholder="+1 123 456 7890"
      i18n-label="Phone Label|Label for phone input@@signupOverlay.phoneLabel"
      i18n-placeholder="Phone Placeholder|Example phone number placeholder@@signupOverlay.phonePlaceholder">
    </lib-outline-text-input>

    <lib-outline-text-input
      [fullWidth]="true"
      class="w-full"
      formControlName="password"
      label="Password"
      type="password"
      placeholder="*********"
      i18n-label="Password Label|Label for password input@@signupOverlay.passwordLabel"
      i18n-placeholder="Password Placeholder|Password placeholder@@signupOverlay.passwordPlaceholder">
    </lib-outline-text-input>

    <div class="flex gap-4 px-6 py-4 bg-neutral-100 rounded-2xl">
      <lib-checkbox
        formControlName="subscribeToNewsletter"
        title="Join our newsletter for reservation tips, event updates, and curated dining recommendations."
        i18n-title="Newsletter Checkbox|Checkbox title for newsletter opt-in@@signupOverlay.newsletterCheckbox">
      </lib-checkbox>
    </div>

    <lib-button
      class="w-full"
      [fullWidth]="true"
      [loading]="signUpState.loading()"
      (click)="onSignupClicked()"
      i18n="Create Account Button|CTA to submit signup form@@signupOverlay.createAccountButton">
      Create Account
    </lib-button>
  </form>
</div>
