import {Component, inject, OnInit, signal} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {MakeReservationResponse} from "../../../data/make-reservation/make-reservation-response";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {InvitationsService} from "../../../../invitations/data/invitations.service";
import {GenericResponse} from "@core/models/generic-response";
import {
  AppSvgIconComponent,
  BaseDialogComponent,
  ButtonComponent,
  NoDataComponent,
  State
} from "@smartdining/lib-sd-web-shared";
import {
  ReservationInviteGuestRequest
} from "../../../../invitations/data/reservation-invite-guest/reservation-invite-guest-request";
import {
  BookingInvitationsResponse,
  BookingInvitationsResponseReceivers
} from "../../../../invitations/data/booking-invitations/booking-invitations-response";
import {DatePipe} from "@angular/common";
import {isValidEmail} from "@shared/utils/validation-utils";
import {CancelInvitationRequest} from "../../../../invitations/data/cancel-invitation/cancel-invitation-request";

@Component({
  selector: 'app-reservation-confirmation-overlays',
  standalone: true,
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    AppSvgIconComponent,
    ReactiveFormsModule,
    FormsModule,
    DatePipe,
    NoDataComponent
],
  templateUrl: './booking-confirmation-overlay.component.html',
  styleUrl: './booking-confirmation-overlay.component.scss'
})
export class BookingConfirmationOverlayComponent extends BaseComponent implements OnInit{
  data: MakeReservationResponse = inject(MAT_DIALOG_DATA);
  formBuilder = inject(FormBuilder);
  invitationService = inject(InvitationsService);

  inviteForm!: FormGroup;
  sendInvitationState = new State<GenericResponse>();
  invitedGuestsState = new State<BookingInvitationsResponse>();
  invitationReceivers = signal<BookingInvitationsResponseReceivers[]>([]);
  unknownGuest = $localize`:@@bookingConfirmationOverlay.unknownGuest:Unknown Guest`;

  ngOnInit(): void {
    this.inviteForm = this.formBuilder.group({
      emailOrPhone: ['', Validators.required]
    });
    this.getInvitedGuests();
  }

  onInvitePressed() {
    if(this.inviteForm.invalid) {
      return;
    }
    const emailOrPhone = this.inviteForm.value.emailOrPhone;
    const reservationDetails = this.data.data;
    const request: ReservationInviteGuestRequest = {
      restaurantId: reservationDetails.restaurant,
      bookingId: reservationDetails._id,
      email: isValidEmail(emailOrPhone) ? emailOrPhone : null,
      phoneNumber: isValidEmail(emailOrPhone) ? null : emailOrPhone,
      isUser: false
    };

    this.executeRequest<GenericResponse>({
      state: this.sendInvitationState,
      request: this.invitationService.inviteGuest(request),
      handleSuccess: true,
      successMessage: 'Guest invited successfully!',
      onSuccess: response => {
        this.getInvitedGuests();
        this.inviteForm.reset();
      }
    });
  }

  getInvitedGuests() {
    const bookingDetails = this.data.data;
    this.executeRequest<BookingInvitationsResponse>({
      state: this.invitedGuestsState,
      request: this.invitationService.getBookingInvitations(bookingDetails._id),
      onSuccess: response => {
        const receivers = response.data.receivers.filter(receiver => {
          return receiver.status != 'CANCELED';
        });
        this.invitationReceivers.set(receivers);
      }
    });
  }


  onCancelInvitationPressed(receiver: BookingInvitationsResponseReceivers) {
    const request: CancelInvitationRequest = {
      inviteId: receiver._id,
      bookingId: this.data.data._id
    };
    this.executeRequest({
      state: new State<any>(),
      request: this.invitationService.cancelInvite(request),
      successMessage: 'Cancelled Invitation',
      showLoader: true,
      onSuccess: (res) =>  {
        this.getInvitedGuests();
      }
    });
  }
}
