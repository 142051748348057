import {Component, inject} from '@angular/core';
import { MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {BaseComponent} from "@core/base/base-component";
import {ButtonComponent} from "@smartdining/lib-sd-web-shared";

@Component({
  selector: 'app-cancel-order-confirmation-overlay',
  standalone: true,
  imports: [
    ButtonComponent,
    MatDialogContent
  ],
  templateUrl: './cancel-order-confirmation-overlay.component.html',
  styleUrl: './cancel-order-confirmation-overlay.component.scss'
})
export class CancelOrderConfirmationOverlayComponent extends BaseComponent {

  dialogRef = inject(MatDialogRef<CancelOrderConfirmationOverlayComponent>);

  onYesClicked() {
    this.dialogRef.close(CancelOrderAction.confirmed);
  }

  onNoClicked() {
    this.dialogRef.close(CancelOrderAction.rejected);
  }
}


export enum CancelOrderAction {
  confirmed,
  rejected
}
