import {inject, Injectable, signal} from '@angular/core';
import {TimeObject} from "@shared/utils/date-utils";
import {BehaviorSubject} from "rxjs";
import {PricePointResponseData} from "@core/services/common-data/price-point/price-point-response";
import {DineInOption} from "../../layout/top-nav/top-nav.component";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RestaurantsFilterService {

  router = inject(Router);
  dineInOption = signal(DineInOption.dineIn);

  selectedDate = signal<Date>(new Date());
  selectedTime = signal<TimeObject | null>(null);
  selectedPartySize = signal(4);

  selectedRating = signal<number | null>(null);
  selectedCuisines = signal<string[]>([]);
  selectedPricePoint = signal<number | null>(null);

  private filterUpdated = new BehaviorSubject<boolean>(false);
  filterUpdated$ = this.filterUpdated.asObservable();

  private dineInOptionUpdated = new BehaviorSubject<DineInOption | null>(null);
  dineInOptionUpdated$ = this.dineInOptionUpdated.asObservable();

  setDateFilter(value: Date) {
    this.selectedDate.update(c => value);
    this.filterUpdated.next(true);
  }

  setTimeFilter(value: TimeObject) {
    this.selectedTime.update(c => value);
    this.filterUpdated.next(true);
  }

  setPartSizeFilter(value: number) {
    this.selectedPartySize.update(c => value);
    this.filterUpdated.next(true);
  }

  setSeparateFilter(rating: number | null, cuisines: string[], pricePoint: PricePointResponseData | null) {
    this.selectedRating.set(Number(rating));
    this.selectedCuisines.set(cuisines);
    this.selectedPricePoint.set(pricePoint?.value ?? null);
    this.filterUpdated.next(true);
  }


  setDineInOptionFilter(dineInOption: DineInOption) {
    this.dineInOption.set(dineInOption);
    this.filterUpdated.next(true);
    this.dineInOptionUpdated.next(dineInOption);
  }

  removeCuisineFilter(cuisineFilter: string) {
    this.selectedCuisines.update(prev => {
      const index = prev.findIndex((cuisine: string) => cuisine == cuisineFilter);
      if (index != -1) {
        prev.splice(index, 1);
      }
      return prev;
    });
    this.filterUpdated.next(true);
  }

  removePricePointFilter() {
    this.selectedPricePoint.set(null);
    this.filterUpdated.next(true);
  }

  removeRatingFilter() {
    this.selectedRating.set(null);
    this.filterUpdated.next(true);
  }

  clearSeparateFilter() {
    this.selectedRating.set(null);
    this.selectedCuisines.set([]);
    this.selectedPricePoint.set(null);
    this.filterUpdated.next(!this.filterUpdated.value);
  }

  addCuisineFilter(cuisineFilter: string) {
    this.selectedCuisines.update(prev => {
      if (!prev.includes(cuisineFilter)) {
        return [...prev, cuisineFilter];
      }
      return prev;
    });
    this.filterUpdated.next(!this.filterUpdated.value);
  }

  isCuisineSelected(cuisineName: string): boolean {
    return this.selectedCuisines().includes(cuisineName);
  }
}
