import {Component, inject, input, OnDestroy, OnInit, signal} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {BaseComponent} from "@core/base/base-component";
import {
  ButtonComponent,
  DateSelectorInputComponent,
  MultiSelectionFieldComponent,
  NetworkImageComponent,
  OutlineTextInputComponent, OverlayService,
  SingleSelectionFieldComponent,
  State,
} from "@smartdining/lib-sd-web-shared";
import {RestaurantDetailResponse} from "../../../../restaurants/data/restaurant-details/restaurant-detail-response";
import {RestaurantService} from "../../../../restaurants/data/restaurant.service";
import {Constants} from "@core/constants/constants";
import {TableAvailabilitiesRequest} from "../../../data/table-availabilities/table-availabilities-request";
import {TableAvailabilitiesResponse} from "../../../data/table-availabilities/table-availabilities-response";
import {BookingsService} from "../../../data/bookings.service";
import {DatePipe} from "@angular/common";
import {OccasionsResponse} from "@core/services/common-data/occasions/occasions-response";
import {TagsResponse} from "@core/services/common-data/tags/tags-response";
import {CommonDataService} from "@core/services/common-data/common-data.service";
import {LoginUserType} from "@core/models/login-user-type";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {
  MakeReservationRequest,
  MakeReservationRequestCustomerInfo
} from "../../../data/make-reservation/make-reservation-request";
import {MakeReservationResponse} from "../../../data/make-reservation/make-reservation-response";
import {
  BookingConfirmationOverlayComponent
} from "../../overlays/booking-confirmation-overlay/booking-confirmation-overlay.component";
import {MatDialog} from "@angular/material/dialog";
import {arrayToCSV} from "@shared/utils/string-utils";
import {
  SeatingPreferenceResponse
} from "@core/services/common-data/seating-preference/seating-preference-response";
import {
  TimeAvailabilityForView
} from "../../../../restaurants/presentation/pages/dine-in-restaurant-details/dine-in-restaurant-details.component";
import {
  AuthWizardOverlayComponent
} from "../../../../auth/presentation/overlays/auth-wizard-overlay/auth-wizard-overlay.component";


@Component({
  selector: 'app-make-reservation',
  standalone: true,
  imports: [
    ButtonComponent,
    DateSelectorInputComponent,
    ReactiveFormsModule,
    NetworkImageComponent,
    MultiSelectionFieldComponent,
    SingleSelectionFieldComponent,
    OutlineTextInputComponent
],
  providers: [
    DatePipe
  ],
  templateUrl: './make-reservation.component.html',
  styleUrl: './make-reservation.component.scss'
})
export class MakeReservationComponent extends BaseComponent implements OnInit, OnDestroy {
  restaurantId = input('');
  date = input<string | null>(null);
  partySize = input<number | null>(4);

  dialog = inject(MatDialog);
  formBuilder = inject(FormBuilder);
  restaurantService = inject(RestaurantService);
  bookingService = inject(BookingsService);
  datePipe = inject(DatePipe);
  commonDataService = inject(CommonDataService);
  authStoreService = inject(AuthStoreService);
  bookingsService = inject(BookingsService);
  overlayService = inject(OverlayService)

  reservationForm!: FormGroup;

  private formSubscription!: Subscription;

  availabilities = signal<TimeAvailabilityForView[]>([]);

  minimumBookingDate = new Date();

  restaurantDetailState = new State<RestaurantDetailResponse>();
  tableAvailabilitiesState = new State<TableAvailabilitiesResponse>();
  occasionsState = new State<OccasionsResponse>();
  tagsState = new State<TagsResponse>();
  reservationState = new State<MakeReservationResponse>();
  seatingPreferenceState = new State<SeatingPreferenceResponse>();

  ngOnInit(): void {
    this.initReservationForm();
    this.getRestaurantDetails();
    this.getTableAvailabilities();
    this.getOccasions();
    this.getTags();
    this.getSeatingPreference();
  }

  initReservationForm() {
    let date = this.date() ? new Date(this.date()!) : new Date();
    date.setHours(0, 0, 0, 0);

    this.reservationForm = this.formBuilder.group({
      date: [date, [Validators.required]],
      partySize: [Number(this.partySize() ?? 4), [Validators.required]],
      time: [null, [Validators.required]],
      occasion: [],
      seatingPreferences: [],
      tags: [[]],
      note: [''],
    });
    this.formSubscription = this.reservationForm.valueChanges.subscribe(data => {
      this.mergeQueryParams({date: data.date.toISOString(), partySize: data.partySize});
    });
  }


  getRestaurantDetails() {
    this.executeRequest({
      state: this.restaurantDetailState,
      request: this.restaurantService.getRestaurantDetails(this.restaurantId()),
    });
  }

  private getOccasions() {
    this.executeRequest<OccasionsResponse>({
      state: this.occasionsState,
      request: this.commonDataService.getOccasions(),
    });
  }

  private getSeatingPreference() {
    this.executeRequest<SeatingPreferenceResponse>({
      state: this.seatingPreferenceState,
      request: this.commonDataService.getSeatingPreference(),
    });
  }

  private getTags() {
    this.executeRequest<TagsResponse>({
      state: this.tagsState,
      request: this.commonDataService.getTags(),
    });
  }

  onDateSelected($event: Date) {
    this.getTableAvailabilities();
  }

  onPartSizeChanged(event: number) {
    this.getTableAvailabilities();
  }

  getTableAvailabilities() {
    this.availabilities.set([]);
    const formDate = this.reservationForm.get('date')?.value;
    const isoDate = formDate ? new Date(formDate).toISOString() : new Date().toISOString();

    const request: TableAvailabilitiesRequest = {
      restaurantId: this.restaurantId(),
      date: isoDate,
      partySize: this.reservationForm.get('partySize')?.value,
      tableIds: []
    };
    this.executeRequest<TableAvailabilitiesResponse>({
      state: this.tableAvailabilitiesState,
      request: this.bookingService.getTableAvailabilities(request),
      onSuccess: response => {
        let availabilities: TimeAvailabilityForView[] = [];
        response.data.forEach(shift => {
          shift.availabilities.forEach(time => {
            availabilities.push({
              shiftId: shift.shiftId,
              partySize: time.partySize,
              time: new Date(time.time),
              timeString: this.datePipe.transform(time.time, 'hh:mm a'),
              estimatedEndTime: new Date(time.estimatedEndTime),
              cancellationCutOffTime: new Date(time.cancellationCutOffTime),
              changeCutOffTime: new Date(time.changeCutOffTime),
              tableTypes: time.tableTypes
            });
          });
        });
        this.availabilities.set(availabilities);
      }
    });
  }

  onReserveClicked() {
    if (this.reservationForm.invalid) {
      return;
    }

    this.handleReservation();
  }

  handleReservation() {
    if (this.authStoreService.isAppUserLoggedIn()) {
      this.reserveTable(LoginUserType.appUser);
      return;
    }

    this.overlayService.openModal(AuthWizardOverlayComponent);
    this.authStoreService.loginSuccessful = () => {
      this.onReserveClicked();
    };
  }

  reserveTable(userType: LoginUserType) {
    const appUser = this.authStoreService.getAppUser();

    let customerInfo: MakeReservationRequestCustomerInfo;
    switch (userType) {
      case LoginUserType.appUser:
        customerInfo = {
          firstName: appUser?.data.firstName ?? '',
          lastName: appUser?.data.lastName ?? '',
          phoneNumber: appUser?.data.phoneNumber ?? '',
          email: appUser?.data.email ?? ''
        };
        break;
    }

    let formValue = this.reservationForm.value;

    let availability = this.reservationForm.get('time')?.value;

    let tags = formValue.tags ?? [];

    if (tags.length <= 0) {
      tags = ['NO'];
    }

    const occasion = formValue.occasion ?? 'NO';

    const seatingOption = formValue.seatingPreferences;

    const request: MakeReservationRequest = {
      restaurantId: this.restaurantId(),
      shiftId: availability?.shiftId,
      partySize: this.reservationForm.value.partySize,
      time: availability?.time,
      autoAssignTable: true,
      customerInfo: customerInfo,
      note: this.reservationForm.value.note,
      tags: tags,
      occasion: occasion,
      seatingOption: seatingOption,
      coverSource: 'GUEST_WEB_APP',
      tableIds: []
    };

    this.executeRequest<MakeReservationResponse>({
      state: this.reservationState,
      request: this.bookingsService.makeReservation(request),
      onSuccess: response => {
        this.dialog.open(BookingConfirmationOverlayComponent, {
          ...Constants.defaultDialogConfig,
          data: response
        }).afterClosed().subscribe(data => {
          this.router.navigate([`/dine-in/${this.restaurantId()}`]);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  protected readonly arrayToCSV = arrayToCSV;
}
