<section class="flex flex-col bg-white border-b" libClickOutside (clickOutside)="clickedOutSideMobileNav()">
  <div class="container flex top-bar-height justify-between items-center mx-auto px-4 sm:px-6">

    <div class="flex gap-3 items-center">
      <img routerLink="/home" class="cursor-pointer" src="assets/images/branding/app-logo.png" width="32"
           alt="app-logo">

      <app-location-selector
        [location]="appDataService.currentAddress()?.address ?? ('Select an address')"
        (click)="onLocationClicked()"
        i18n-location="Location Placeholder|Default location text@@topNav.selectAddress">
      </app-location-selector>

      @if (topNavService.showBookingOption()) {
        <div class="hidden lg:flex gap-2 items-center border rounded-full px-1.5 py-1.5 bg-neutral-50">

          <div class="flex items-center gap-2 cursor-pointer px-2 py-1.5 rounded-full"
               [ngClass]="dineInOption() == DineInOption.dineIn ? 'bg-primary-500 text-white' : 'text-primary-500'"
               (click)="onDineOptionSelected(DineInOption.dineIn)">
            <p class="text-caption whitespace-nowrap" i18n="Booking Option|Dine-in option label@@topNav.dineIn">Dine
              In</p>
          </div>

          <div class="flex items-center gap-2 cursor-pointer px-2 py-1.5 rounded-full"
               [ngClass]="dineInOption() == DineInOption.pickupOrder ? 'bg-primary-500 text-white' : 'text-primary-500'"
               (click)="onDineOptionSelected(DineInOption.pickupOrder)">
            <p class="text-caption whitespace-nowrap" i18n="Booking Option|Pickup option label@@topNav.pickup">
              Pickup</p>
          </div>

        </div>
      }
    </div>


    <div class="flex items-center gap-5 sm:hidden">
      @if (topNavService.showRestaurantButton()) {
        <a
          class="hidden text-body2 text-primary-500 hover:text-primary-900 p-2 hover:bg-primary-50 hover:bg-opacity-50 rounded-xl"
          href="https://restaurant.gosmartdining.com"
          target="_blank"
          i18n="For Business CTA|Link to restaurant portal@@topNav.forBusiness">
          For business
        </a>
      }
      @if (authStoreService.isAuthenticated()) {

        <div class="flex items-center cursor-pointer p-2 rounded-md hover:bg-neutral-100 transition-all duration-500 font-bold"
             (click)="onViewCartPressed()"
             i18n-title="Cart Icon Tooltip|Tooltip for cart icon@@topNav.cart.tooltip"
             title="View Cart">
          <lib-svg-icon src="assets/icons/cart.svg"></lib-svg-icon>
          <span class="pl-1 text-xs font-semibold text-white rounded-full px-1 py-0.5 min-w-[20px] text-center"
                [ngClass]="cartService.cart().quantity > 0 ? 'bg-red-500' : 'bg-red-400'">
            {{ cartService.cart().quantity }}
          </span>
        </div>

        <lib-svg-icon [matMenuTriggerFor]="menu" src="assets/icons/account-circle.svg"
                      class="text-neutral-500 cursor-pointer" [size]="24"></lib-svg-icon>
        <mat-menu #menu="matMenu" class="mat-elevation-z3 " xPosition="before">
          <div routerLink="/settings/account"
               class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
            <lib-svg-icon src="assets/icons/account-circle.svg" class="text-neutral-500 cursor-pointer"
                          [size]="18"></lib-svg-icon>
            <span class="text-body2"
                  i18n="Account Menu Item|Label for Account menu option in nav@@topNav.account">
              Account
            </span>
          </div>

          <div routerLink="/bookings/past-bookings"
               class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
            <lib-svg-icon src="assets/icons/calendar.svg" class="text-neutral-500 cursor-pointer"
                          [size]="18"></lib-svg-icon>
            <span class="text-body2"
                  i18n="Bookings Menu Item|Label for Bookings menu option in nav@@topNav.bookings">
              Bookings
            </span>
          </div>

          <div routerLink="/orders/active-orders"
               class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
            <lib-svg-icon src="assets/icons/food-orders.svg" class="text-neutral-500 cursor-pointer"
                          [size]="18"></lib-svg-icon>
            <span class="text-body2"
                  i18n="Orders Menu Item|Label for Orders menu option in nav@@topNav.orders">
              Orders
            </span>
          </div>

          <div routerLink="/invitations/received-invitations"
               class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
            <lib-svg-icon src="assets/icons/email-open-heart-outline.svg" class="text-neutral-500 cursor-pointer"
                          [size]="18"></lib-svg-icon>
            <span class="text-body2"
                  i18n="Invitations Menu Item|Label for Invitations menu option in nav@@topNav.invitations">
              Invitations
            </span>
          </div>

          <div class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100"
               (click)="onLogoutClick()">
            <lib-svg-icon src="assets/icons/logout.svg" class="text-neutral-500 cursor-pointer"
                          [size]="18"></lib-svg-icon>
            <span class="text-body2"
                  i18n="Logout Menu Item|Label for Logout menu option in nav@@topNav.logout">
              Logout
            </span>
          </div>

        </mat-menu>
      }

      @if (expanded()) {
        <lib-svg-icon src="assets/icons/close.svg" (click)="onHamburgerClick()" alt="menu-icon" [size]="16"
                      class="text-neutral-500 w-5 cursor-pointer"></lib-svg-icon>

      } @else {
        <lib-svg-icon src="assets/icons/menu.svg" (click)="onHamburgerClick()" alt="menu-icon" [size]="24"
                      class="text-neutral-500 w-5 cursor-pointer"></lib-svg-icon>
      }
    </div>

    <div class="hidden sm:flex items-center gap-5">
      <div class="flex items-center gap-2 ml-5">
        <div class="hidden sm:block px-5">
          @if (topNavService.showRestaurantSuggestion()) {
            <app-restaurant-suggestion></app-restaurant-suggestion>
          }
        </div>

        @if (topNavService.showRestaurantButton()) {
          <div
            class="flex items-center gap-2 cursor-pointer hover:text-primary-900 p-2 hover:bg-primary-50 hover:bg-opacity-50 rounded-xl"
            (click)="onForBusinessClicked()">
            <lib-svg-icon src="assets/icons/open-in-new.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
            <p
              class="flex items-center gap-2 text-body2 whitespace-nowrap text-primary-500"
              i18n="For Business CTA|Top nav link to restaurant portal@@topNav.forBusiness">
              For business
            </p>
          </div>
        }

        @if (authStoreService.isAuthenticated()) {
          <lib-svg-icon
            [matMenuTriggerFor]="menu"
            src="assets/icons/account-circle.svg"
            class="text-neutral-500 cursor-pointer"
            [size]="24">
          </lib-svg-icon>

          <mat-menu #menu="matMenu" class="mat-elevation-z3" xPosition="before">
            <div
              routerLink="/settings/account"
              class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
              <lib-svg-icon src="assets/icons/account-circle.svg" class="text-neutral-500 cursor-pointer"
                            [size]="18"></lib-svg-icon>
              <span class="text-body2" i18n="Top nav Account menu item@@topNav.account">Account</span>
            </div>
            <div
              routerLink="/bookings/upcoming-bookings"
              class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
              <lib-svg-icon src="assets/icons/calendar.svg" class="text-neutral-500 cursor-pointer"
                            [size]="18"></lib-svg-icon>
              <span class="text-body2" i18n="Top nav Bookings menu item@@topNav.bookings">Bookings</span>
            </div>
            <div
              routerLink="/orders/active-orders"
              class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
              <lib-svg-icon src="assets/icons/food-orders.svg" class="text-neutral-500 cursor-pointer"
                            [size]="18"></lib-svg-icon>
              <span class="text-body2" i18n="Top nav Orders menu item@@topNav.orders">Orders</span>
            </div>
            <div
              routerLink="/invitations/received-invitations"
              class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
              <lib-svg-icon src="assets/icons/email-open-heart-outline.svg" class="text-neutral-500 cursor-pointer"
                            [size]="18"></lib-svg-icon>
              <span class="text-body2" i18n="Top nav Invitations menu item@@topNav.invitations">Invitations</span>
            </div>
            <div
              class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100"
              (click)="onLogoutClick()">
              <lib-svg-icon src="assets/icons/logout.svg" class="text-neutral-500 cursor-pointer"
                            [size]="18"></lib-svg-icon>
              <span class="text-body2" i18n="Top nav Logout menu item@@topNav.logout">Logout</span>
            </div>
          </mat-menu>
        } @else {

          <lib-button size="small" (click)="onLoginClicked()" class="text-white" appearance="outline">
            <span i18n="Login button text@@topNav.login">Login</span>
          </lib-button>
        }


        @if(false) {
          <div class="flex items-center cursor-pointer p-2 rounded-md hover:bg-neutral-100 transition-all duration-500"
               (click)="onViewCartPressed()">
            <lib-svg-icon src="assets/icons/cart.svg"></lib-svg-icon>
            <span class="pl-1 text-xs font-semibold text-white rounded-full px-1 py-0.5 min-w-[20px] text-center"
                  [ngClass]="cartService.cart().quantity > 0 ? 'bg-red-500' : 'bg-red-400'">
            {{ cartService.cart().quantity }}
          </span>
          </div>
        }

      </div>
    </div>

  </div>

  @if (expanded()) {
    <div class="container px-5 py-8 sm:mx-auto">
      <div class="grid gap-y-7">
        <a
          class="text-body2 text-primary-700"
          routerLink="/restaurants"
          i18n="Nav link to restaurants page@@topNav.restaurants">
          Restaurants
        </a>

        @if (!authStoreService.isAuthenticated()) {
          <a
            class="text-body2 text-primary-700"
            routerLink="/signup"
            i18n="Nav link to sign up page@@topNav.signup">
            Sign up
          </a>
          <lib-button
            class="w-full"
            [fullWidth]="true"
            (click)="onLoginClicked()"
            i18n="Button text for login@@topNav.loginButton">
            Login
          </lib-button>
        }
      </div>
    </div>
  }

</section>



