<app-base-dialog [title]="'Order Survey'">

  <div body>
    <form [formGroup]="surveyForm">
      <div class="flex flex-col gap-2 text-center justify-center items-center">
        <div>
          <p class="text-body2 text-neutral-900">{{ orderSurveyQuestionsResponseState.response()?.data?.description }}</p>
        </div>
      </div>
      <div formArrayName="surveyAnswers">
        <div class="flex flex-col py-10 items-center gap-3">
          @for (surveyQuestion of orderSurveyQuestionsResponseState.response()?.data?.surveyQuestions; track surveyQuestion; let index = $index) {
            <ng-container [formGroupName]="index">
              <div class="grid grid-cols-4 border-b border-neutral-100 pb-3">
                <div class="col-span-2 flex items-center gap-3">
                  <lib-svg-icon src="assets/icons/question-mark-circle.svg" [matTooltip]="surveyQuestion.surveyQuestion" [size]="20"></lib-svg-icon>
                  <p class="text-body2"> {{ surveyQuestion.surveyName }} </p>
                </div>
                <div class="col-span-2">
                  <lib-rating-input formControlName="surveyRating"></lib-rating-input>
                </div>
              </div>
            </ng-container>
          }
        </div>
      </div>

      <lib-text-area-input class="w-full" formControlName="comments" label="Review" [fullWidth]="true"
                           placeholder="Please write your feedback."></lib-text-area-input>
    </form>
  </div>
  <div actions>
    <div class="flex gap-4 items-center justify-end">
      <lib-button appearance="outline" (click)="onCancelClicked()">CANCEL</lib-button>
      <lib-button (click)="onSubmitPressed()" [loading]="createSurveyState.loading()">SUBMIT SURVEY</lib-button>
    </div>
  </div>
</app-base-dialog>







