import {Component, input, inject} from '@angular/core';
import {GeoPoint, LocationService} from "../../services/location-service/location.service";
import {BaseComponent} from "../../base/base-component";
import {AppDataService} from "../../services/app-data/app-data.service";
import {AppSvgIconComponent, State} from "@smartdining/lib-sd-web-shared";

@Component({
  selector: 'app-direction-button',
  standalone: true,
  templateUrl: './direction-button.component.html',
  styleUrl: './direction-button.component.scss',
  imports: [AppSvgIconComponent]
})
export class DirectionButtonComponent extends BaseComponent {

  appearance = input<'button' | 'iconButton'>('button');
  destinationLatitude = input.required<number | undefined>();
  destinationLongitude = input.required<number | undefined>();

  locationService = inject(LocationService);
  appDataService = inject(AppDataService);

  currentLocationState = new State<GeoPoint>();

  onDirectionButtonClicked() {
    this.directToCurrentLocation();
  }

  directToCurrentLocation() {
    const currentAddress = this.appDataService.getAddress();

    if (currentAddress?.latitude != null && currentAddress.longitude != null) {
      this.openGoogleNavigation(currentAddress.latitude, currentAddress.longitude);
    } else {
      this.gotoCurrentLocation();
    }
  }

  gotoCurrentLocation() {
    this.executeRequest<GeoPoint>({
      state: this.currentLocationState,
      request: this.locationService.getCurrentLocation(),
      showLoader: true,
      onSuccess: () => {
        if (this.currentLocationState.response()?.latitude &&
          this.currentLocationState.response()?.latitude &&
          this.destinationLatitude() &&
          this.destinationLongitude()) {
          this.openGoogleNavigation(this.currentLocationState.response()?.latitude, this.currentLocationState.response()?.latitude);
        }
      }
    });
  }

  openGoogleNavigation(currentLatitude?: number, currentLongitude?: number) {
    let url = `https://www.google.com/maps/dir/${currentLatitude},${currentLongitude}/${this.destinationLatitude()},${this.destinationLongitude()}`;
    window.open(url, "_blank");
  }

}
