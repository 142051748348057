import {Component, inject, OnInit} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {RestaurantFavoriteService} from "../../../data/restaurant-favorite.service";
import {FavoriteRestaurantsResponse} from "../../../data/favorites/favorite-restaurants-response";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  AppSvgIconComponent,
  NetworkImageComponent,
  NoDataComponent,
  ShimmerComponent,
  State
} from "@smartdining/lib-sd-web-shared";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {AppDataService} from "@core/services/app-data/app-data.service";

@Component({
  selector: 'app-favorites',
  standalone: true,
  imports: [
    NoDataComponent,
    AppSvgIconComponent,
    NetworkImageComponent,
    ShimmerComponent
  ],
  templateUrl: './favorites.component.html',
  styleUrl: './favorites.component.scss'
})
export class FavoritesComponent extends BaseComponent implements OnInit {
  favoriteService = inject(RestaurantFavoriteService);
  tokenService = inject(AuthStoreService);
  formBuilder = inject(FormBuilder);
  appDataService = inject(AppDataService);

  addFavouritesForm!: FormGroup;
  favoriteResponseState = new State<FavoriteRestaurantsResponse>();


  ngOnInit(): void {
    this.getFavorites();
    this.addFavouritesForm = this.formBuilder.group({
      companyUID: ['', [Validators.required]],
      user: ['', [Validators.required]],
      restaurant: ['', [Validators.required]],
    });
  }

  getFavorites() {
    let userId = this.tokenService.getAppUser()?.data?.user ?? '';
    this.executeRequest({
      state: this.favoriteResponseState,
      request: this.favoriteService.getFavoriteRestaurants(userId),
    });
  }

  removeFavorite(favoriteId: string) {
    this.executeRequest({
      state: this.favoriteResponseState,
      request: this.favoriteService.deleteFavoriteRestaurant(favoriteId),
      successMessage: 'Restaurant successfully removed from favorites.',
      handleSuccess: true,
      onSuccess: response => {
        this.getFavorites();
        this.appDataService.saveFavoriteRestaurants();
      }
    });

  }
}
