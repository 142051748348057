<div class="flex gap-3">

  <p class="py-2 px-5 border border-primary-500 rounded cursor-pointer"
     [ngClass]="isUpcomingBooking() ? 'bg-primary-500 text-white' : 'bg-transparent text-primary-500'"
     (click)="onUpcomingBookingsClicked()"
     i18n="Upcoming Tab Label|Tab for showing upcoming bookings@@bookingsTab.upcoming">
    Upcoming
  </p>

  <p class="py-2 px-5 border border-primary-500 bg-primary-500 rounded cursor-pointer"
     [ngClass]="isPastBooking() ? 'bg-primary-500 text-white' : 'bg-transparent text-primary-500'"
     (click)="onPastBookingsClicked()"
     i18n="Past Tab Label|Tab for showing past bookings@@bookingsTab.past">
    Past
  </p>

</div>
