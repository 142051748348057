<app-top-nav class="fixed top-0 left-0 right-0 z-50 bg-white"></app-top-nav>

<div class="mt-20"></div>

<div class="container-fluid sm:container mx-auto py-8 px-4 flex flex-col sm:flex-row gap-6">

  <!-- Mobile Tabs -->
  <div class="block sm:hidden">
    <nav mat-tab-nav-bar [tabPanel]="tabPanel">
      <a mat-tab-link routerLink="/settings/account"
         i18n="Settings Tab|Link to account settings@@settings.tab.account">
        Account
      </a>
      <a mat-tab-link routerLink="/settings/addresses"
         i18n="Settings Tab|Link to address settings@@settings.tab.address">
        Address
      </a>
      <a mat-tab-link routerLink="/settings/payment-methods"
         i18n="Settings Tab|Link to payment methods settings@@settings.tab.payment">
        Payment methods
      </a>
      <a mat-tab-link routerLink="/settings/security"
         i18n="Settings Tab|Link to security settings@@settings.tab.security">
        Security
      </a>
      <a mat-tab-link routerLink="/settings/favorites"
         i18n="Settings Tab|Link to favorites settings@@settings.tab.favorites">
        Favorites
      </a>
    </nav>
  </div>

  <!-- Desktop Side Menu -->
  <div class="hidden sm:flex gap-3 sm:flex-col">
    <app-setting-menu-item
      routerLink="/settings/account"
      title="Account"
      description="Personal Information"
      i18n-title="Setting Title|Account settings section title@@settings.account.title"
      i18n-description="Setting Description|Description for account settings@@settings.account.description"
      icon="assets/icons/guest.svg"
      [isSelected]="activeRoute() == '/settings/account'">
    </app-setting-menu-item>

    <app-setting-menu-item
      routerLink="/settings/addresses"
      title="Address"
      description="Address details"
      i18n-title="Setting Title|Address settings section title@@settings.address.title"
      i18n-description="Setting Description|Description for address settings@@settings.address.description"
      icon="assets/icons/location.svg"
      [isSelected]="activeRoute() == '/settings/addresses'">
    </app-setting-menu-item>

    <app-setting-menu-item
      routerLink="/settings/payment-methods"
      title="Payment method"
      description="Saved credit/dedit cards"
      i18n-title="Setting Title|Payment method settings section title@@settings.payment.title"
      i18n-description="Setting Description|Description for payment method settings@@settings.payment.description"
      icon="assets/icons/payment-method.svg"
      [isSelected]="activeRoute() == '/settings/payment-methods'">
    </app-setting-menu-item>

    <app-setting-menu-item
      routerLink="/settings/security"
      title="Security"
      description="Password 2FA"
      i18n-title="Setting Title|Security settings section title@@settings.security.title"
      i18n-description="Setting Description|Description for security settings@@settings.security.description"
      icon="assets/icons/security.svg"
      [isSelected]="activeRoute() == '/settings/security'">
    </app-setting-menu-item>

    <app-setting-menu-item
      routerLink="/settings/favorites"
      title="Favorites"
      description="Your Favorites"
      i18n-title="Setting Title|Favorites settings section title@@settings.favorites.title"
      i18n-description="Setting Description|Description for favorites settings@@settings.favorites.description"
      icon="assets/icons/heart-outline.svg"
      [isSelected]="activeRoute() == '/settings/favorites'">
    </app-setting-menu-item>
  </div>

  <!-- Tab Content Panel -->
  <div class="px-6 py-8 shadow-1 border border-neutral-200 rounded-3xl w-full">
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </div>
</div>

<app-footer></app-footer>
