import {v4 as uuidv4} from 'uuid';
import {signal} from "@angular/core";
import {CartItemUtils} from "../../utilities/cart-item-utils";
import {Price, SizePrice} from "../menu/food-menu/price";
import {FoodItem} from "../menu/menu";
import {MenuItemToCartItemUtil} from "../../utilities/menu-item-to-cart-item-util";
import {FoodItemPriceType, ModifierGroupSelectionType} from "@core/constants/food-item-constants";

export class Cart {

  stateTaxPercentage: number = 0;
  localTaxPercentage: number = 0;
  discountAmount: number = 0;
  tipAmount: number = 0;

  constructor() {
  }

  selections: CartItem[] = [];

  addFoodItem(item: FoodItem, quantity: number) {
    if (item.isSimpleItem()) {
      this.addSimpleItem(item);
    }

    if (item.isCustomizableItem()) {
      this.addCustomizableItem(item, quantity);
    }
  }

  private addSimpleItem(item: FoodItem) {
    let matchedItem = this.selections.find(x => x.menuItem.id == item._id);
    if (matchedItem) {
      matchedItem.quantity.update(prev => prev + 1);
    } else {
      let cartItem = MenuItemToCartItemUtil.covert(item, 1);
      this.selections.push(cartItem);
    }
  }

  private addCustomizableItem(item: FoodItem, quantity: number) {
    let cartItem = MenuItemToCartItemUtil.covert(item, quantity);
    this.selections.push(cartItem);
  }

  public get quantity(): number {
    let quantity = 0;
    for (const selection of this.selections) {
      quantity = quantity + selection.quantity();
    }
    return quantity;
  }

  public get getSubtotal(): number {
    let totalPrice = 0;
    for (const selection of this.selections) {
      totalPrice = totalPrice + selection.getSubtotal();
    }
    return totalPrice;
  }


  setTaxAmount(stateTaxRate: number | undefined, localTaxRate: number | undefined) {
    this.stateTaxPercentage = stateTaxRate ?? 0;
    this.localTaxPercentage = localTaxRate ?? 0;
  }

  public get taxAmount(): number {
    let taxAmount = 0;
    let amountAfterDiscount = this.getSubtotal - this.discountAmount;
    if (this.stateTaxPercentage) {
      taxAmount = taxAmount + (amountAfterDiscount * (this.stateTaxPercentage / 100));
    }

    if (this.localTaxPercentage) {
      taxAmount = taxAmount + (amountAfterDiscount * (this.localTaxPercentage / 100));
    }
    return taxAmount;
  }

  setDiscountAmount(discountAmount: number) {
    this.discountAmount = discountAmount;
  }

  public get totalAmount() {
    let total = this.getSubtotal + this.tipAmount - this.discountAmount + this.taxAmount;
    return total;
  }

  setTipAmount(tipAmount: number) {
    this.tipAmount = tipAmount;
  }

  setTipPercentage(tipPercentage: number) {
    this.tipAmount = this.getSubtotal * (tipPercentage / 100);
  }

}

export class CartItem {
  id?: string;
  isCustomizableItem: boolean = false;
  quantity = signal(0);
  notes?: string;

  constructor(public menuItem: CartItemDetail) {
    if (this.id == null) {
      this.id = uuidv4();
    }
  }

  getSubtotal() {
    return this.menuItem.getPrice() * this.quantity();
  }

}

export class CartItemDetail {

  constructor(public id?: string,
              public menuId?: string,
              public categoryId?: string,
              public name?: string,
              public description?: string,
              public price?: Price,
              public modifierGroups: CartModifierGroup[] = []) {
  }

  public getSelectedPriceType(): string {
    let sizePrices = this.price?.sizePrices ?? [];
    let selectedSizePrice = sizePrices.find(x => x.isSelected());
    if (selectedSizePrice) {
      return 'SIZE_PRICE';
    } else {
      return 'BASE_PRICE';
    }
  }

  getSelectedSizePrice(): SizePrice | undefined {
    let sizePrices = this.price?.sizePrices ?? [];
    let selectedSizePrice = sizePrices.find(x => x.isSelected());
    return selectedSizePrice;
  }

  public getPrice() {
    if (CartItemUtils.isSimpleItem(this)) {
      return (this.price?.basePrice ?? 0);
    }

    if (CartItemUtils.isCustomizableItem(this)) {
      return this.getItemPrice() + this.getModifiersPrice();
    }

    return 0;
  }

  getItemPrice(): number {
    if (this.price?.type == FoodItemPriceType.BASE_PRICE) {
      return this.price?.basePrice ?? 0;
    }

    if (this.price?.type == FoodItemPriceType.SIZE_PRICE) {
      let sizePrices = this.price?.sizePrices ?? [];
      let selectedSizePrice = sizePrices.find(x => x.isSelected());
      let defaultSizePrice = sizePrices.find(x => x.isDefault);

      return selectedSizePrice?.price ?? defaultSizePrice?.price ?? 0;
    }

    return 0;
  }

  getModifiersPrice() {
    let totalPrice = 0;
    for (const modifierGroup of this.modifierGroups ?? []) {
      for (const modifier of modifierGroup.modifiers ?? []) {
        let modifierPrice = modifier.price ?? 0;
        switch (modifierGroup.selection) {
          case ModifierGroupSelectionType.SINGLE_CHOICE:
            if (modifier.isSelected()) {
              totalPrice = totalPrice + modifierPrice;
            }
            break;
          case ModifierGroupSelectionType.MULTI_CHOICE:
            if (modifier.isSelected()) {
              totalPrice = totalPrice + modifierPrice;
            }
            break;
          case ModifierGroupSelectionType.QUANTITY:
            totalPrice = totalPrice + (modifierPrice * modifier.selectedQuantity());
            break;
        }
      }
    }
    return totalPrice;
  }

}

export class CartModifierGroup {
  public id?: string;
  public selection?: string;
  public name?: string;
  public description?: string;
  public modifiers: CartModifier[] = [];

  isModifierSelected(): boolean {
    let selectedAny = false;
    for (const modifier of this.modifiers) {
      if (modifier.isSelected() || modifier.selectedQuantity() > 0) {
        selectedAny = true;
      }
    }

    return selectedAny;
  }
}

export class CartModifier {

  public id?: string;
  public name?: string;
  public price?: number;

  // FF
  isSelected = signal(false);
  selectedQuantity = signal(0);
}
