import {Component, inject, signal} from '@angular/core';
import {Constants} from "@core/constants/constants";
import {DialogRef} from "@angular/cdk/dialog";

@Component({
  selector: 'app-dine-in-party-size-overlay',
  imports: [],
  templateUrl: './party-size-selector-overlay.component.html',
  styleUrl: './party-size-selector-overlay.component.scss'
})
export class PartySizeSelectorOverlayComponent {

  dialogRef = inject(DialogRef);

  partySizeList = signal(Constants.getNumberRange(2, 70));

  onPartySizeSelected(partySize: number) {
    this.dialogRef.close(partySize);
  }
}
