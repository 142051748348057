import {signal} from "@angular/core";
import {Constants} from "@core/constants/constants";

export class Price {
  public type?: string;
  public basePrice?: number;
  public sizePrices: SizePrice[] = [];

  getPriceString() {
    if (this.type == 'BASE_PRICE') {
      return `${Constants.currency}${this.basePrice?.toFixed(2)}`;
    }

    if (this.type == 'SIZE_PRICE') {
      let minPrice = Math.min(...this.sizePrices.map(x => x.price ?? 0));
      let maxPrice = Math.max(...this.sizePrices.map(x => x.price ?? 0));
      return `${Constants.currency}${minPrice.toFixed(2)} - ${Constants.currency}${maxPrice.toFixed(2)}`;
    }

    return `${Constants.currency}${this.basePrice?.toFixed(2)}`;
  }
}

export class SizePrice {
  public size?: string;
  public price?: number;
  public isDefault?: boolean;

  // FF
  isSelected = signal(false);

  onSelected() {
    this.isSelected.set(!this.isSelected());
  }

}
