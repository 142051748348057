import {Routes} from '@angular/router';
import {PageNotFoundComponent} from "./features/layout/page-not-found/page-not-found.component";
import {LandingPageComponent} from "./features/landing/presentation/pages/landing-page.component";
import {TestComponent} from "@core/components/test/test.component";
import {HomeComponent} from "./features/layout/home/home.component";
import {RestaurantsComponent} from "./features/restaurants/presentation/pages/restaurants/restaurants.component";
import {
  DineInRestaurantDetailsComponent
} from "./features/restaurants/presentation/pages/dine-in-restaurant-details/dine-in-restaurant-details.component";
import {PickupCheckoutComponent} from "./features/pickup-order/presentation/pages/pickup-checkout/pickup-checkout.component";
import {SettingsComponent} from "./features/settings/presentation/pages/settings.component";
import {AddressesComponent} from "./features/settings/presentation/pages/addresses/addresses.component";
import {AccountComponent} from "./features/settings/presentation/pages/account/account.component";
import {OrdersComponent} from "./features/settings/presentation/pages/orders/orders.component";
import {ReservationsComponent} from "./features/settings/presentation/pages/reservations/reservations.component";
import {InvitationsComponent} from "./features/settings/presentation/pages/invitations/invitations.component";
import {FavoritesComponent} from "./features/settings/presentation/pages/favorites/favorites.component";
import {SecurityComponent} from "./features/settings/presentation/pages/security/security.component";
import {
  MakeReservationComponent
} from "./features/booking/presentation/pages/make-reservation/make-reservation.component";
import {
  UpcomingReservationsComponent
} from "./features/booking/presentation/pages/upcoming-reservations/upcoming-reservations.component.";
import {
  PastReservationsComponent
} from "./features/booking/presentation/pages/past-reservations/past-reservations.component";
import {JoinWaitlistComponent} from "./features/booking/presentation/pages/join-waitlist/join-waitlist.component";
import {
  BookingInformationComponent
} from "./features/booking/presentation/pages/booking-information/booking-information.component";
import {
  TermsAndConditionsComponent
} from "./features/landing/presentation/pages/terms-and-conditions/terms-and-conditions.component";
import {PrivacyPolicyComponent} from "./features/landing/presentation/pages/privacy-policy/privacy-policy.component";
import {
  ReceivedInvitationsComponent
} from "./features/invitations/presentation/pages/received-invitations/received-invitations.component";
import {
  SentInvitationsComponent
} from "./features/invitations/presentation/pages/sent-invitations/sent-invitations.component";
import {
  AcceptInvitationComponent
} from "./features/invitations/presentation/pages/accept-invitation/accept-invitation.component";
import {
  DeclineInvitationComponent
} from "./features/invitations/presentation/pages/decline-invitation/decline-invitation.component";
import {GalleryComponent} from "./features/restaurants/presentation/pages/gallery/gallery.component";

import {ContactUsComponent} from "./features/landing/presentation/pages/contact-us/contact-us.component";
import {PickupOrderRestaurantDetailsComponent} from "./features/pickup-order/presentation/pages/pickup-order-restaurant-details/pickup-order-restaurant-details.component";
import {ActiveOrdersComponent} from "./features/pickup-order/presentation/pages/active-orders/active-orders.component";
import {PastOrdersComponent} from "./features/pickup-order/presentation/pages/past-orders/past-orders.component";
import {
  OrderInformationComponent
} from "./features/pickup-order/presentation/pages/order-information/order-information.component";
import {
  SavedPaymentMethodsComponent
} from "./features/payment/presentation/pages/saved-payment-methods/saved-payment-methods.component";


export const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: LandingPageComponent},
  {
    path: '', component: HomeComponent, children: [
      {path: 'restaurants', component: RestaurantsComponent},
      {path: 'dine-in/:restaurantId', component: DineInRestaurantDetailsComponent, runGuardsAndResolvers: 'paramsChange'},
      {path: 'pickup/:restaurantId', component: PickupOrderRestaurantDetailsComponent},
      {path: 'gallery/:restaurantId', component: GalleryComponent},
      {path: 'checkout/:restaurantId', component: PickupCheckoutComponent},
      {path: 'account', component: AccountComponent},
      {path: 'reservation', component: MakeReservationComponent},
      {path: 'join-waitlist', component: JoinWaitlistComponent},
      {path: 'bookings/past-bookings', component: PastReservationsComponent},
      {path: 'bookings/upcoming-bookings', component: UpcomingReservationsComponent},
      {path: 'orders/active-orders', component: ActiveOrdersComponent},
      {path: 'orders/past-orders', component: PastOrdersComponent},
      {path: 'booking/:bookingId', component: BookingInformationComponent},
      {path: 'order/:orderId', component: OrderInformationComponent},
      {path: 'invitations/received-invitations', component: ReceivedInvitationsComponent},
      {path: 'invitations/sent-invitations', component: SentInvitationsComponent},
      {path: 'invite/accept', component: AcceptInvitationComponent},
      {path: 'invite/decline', component: DeclineInvitationComponent},
      {path: 'terms-and-conditions', component: TermsAndConditionsComponent},
      {path: 'privacy-policy', component: PrivacyPolicyComponent},
      {path: 'contact-us', component: ContactUsComponent}
    ]
  },
  {
    path: 'settings', component: SettingsComponent, children: [
      {path: 'account', component: AccountComponent},
      {path: 'addresses', component: AddressesComponent},
      {path: 'payment-methods', component: SavedPaymentMethodsComponent},
      {path: 'orders', component: OrdersComponent},
      {path: 'reservation', component: ReservationsComponent},
      {path: 'invitation', component: InvitationsComponent},
      {path: 'security', component: SecurityComponent},
      {path: 'favorites', component: FavoritesComponent}
    ]
  },
  {path: 'test', component: TestComponent},
  {path: '**', component: PageNotFoundComponent}
];
