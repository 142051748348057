import {Component, inject, OnInit, output} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {OccasionsResponse} from "@core/services/common-data/occasions/occasions-response";
import {TagsResponse} from "@core/services/common-data/tags/tags-response";
import {
  BaseInputComponent,
  ButtonComponent,
  MultiSelectionFieldComponent,
  ShimmerComponent,
  SingleSelectionFieldComponent,
  State,
  TextInputComponent
} from "@smartdining/lib-sd-web-shared";
import {CommonDataService} from "@core/services/common-data/common-data.service";
import {BookingsService} from "../../../../data/bookings.service";
import {WaitTimesRequest} from "../../../../data/wait-times/wait-times-request";
import {MatDialog} from "@angular/material/dialog";
import {WaitTimesResponse} from "../../../../data/wait-times/wait-times-response";
import {
  MakeReservationRequestCustomerInfo
} from "../../../../data/make-reservation/make-reservation-request";
import {LoginUserType} from "@core/models/login-user-type";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {
  JoinWaitListRequest
} from "../../../../data/join-wait-list/join-wait-list-request";
import {GenericResponse} from "@core/models/generic-response";
import {
  WaitlistConfirmationOverlayComponent
} from "../../waitlist-confirmation-overlay/waitlist-confirmation-overlay.component";
import {Constants} from "@core/constants/constants";
import {
  SeatingPreferenceResponse
} from "@core/services/common-data/seating-preference/seating-preference-response";
import {
  PartySizeSelectorInputComponent
} from "../../../../../restaurants/presentation/components/dine-in-global-filter/party-size-selector-input/party-size-selector-input.component";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";

@Component({
  selector: 'app-join-wait-list',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SingleSelectionFieldComponent,
    TextInputComponent,
    ButtonComponent,
    BaseInputComponent,
    ShimmerComponent,
    MultiSelectionFieldComponent,
    PartySizeSelectorInputComponent,
  ],
  templateUrl: './join-wait-list.component.html',
  styleUrl: './join-wait-list.component.scss'
})
export class JoinWaitListComponent extends BaseComponent implements OnInit {

  onLoginNeeded = output();

  data = inject(DIALOG_DATA);
  dialog = inject(MatDialog);
  dialogRef = inject(DialogRef);
  authStoreService = inject(AuthStoreService);
  formBuilder = inject(FormBuilder);
  commonDataService = inject(CommonDataService);
  bookingService = inject(BookingsService);

  waitListForm!: FormGroup;

  occasionsState = new State<OccasionsResponse>();
  tagsState = new State<TagsResponse>();
  waitTimesState = new State<WaitTimesResponse>();
  joinWaitListState = new State<GenericResponse>();
  seatingPreferenceState = new State<SeatingPreferenceResponse>();


  ngOnInit(): void {
    this.waitListForm = this.formBuilder.group({
      partySize: [4, [Validators.required]],
      tags: [[]],
      occasion: [],
      note: [''],
      seatingPreferences: []

    });
    this.getOccasions();
    this.getTags();
    this.getWaitTimes();
    this.getSeatingPreference();

  }

  private getOccasions() {
    this.executeRequest<OccasionsResponse>({
      state: this.occasionsState,
      request: this.commonDataService.getOccasions(),
    });
  }

  private getTags() {
    this.executeRequest<TagsResponse>({
      state: this.tagsState,
      request: this.commonDataService.getTags(),
    });
  }

  private getSeatingPreference() {
    this.executeRequest<SeatingPreferenceResponse>({
      state: this.seatingPreferenceState,
      request: this.commonDataService.getSeatingPreference(),
    });
  }

  onPartSizeChanged($event: number) {
    this.getWaitTimes();
  }

  private getWaitTimes() {
    const request: WaitTimesRequest = {
      restaurantId: this.data.restaurantId,
      partySize: this.waitListForm.value.partySize
    };
    this.executeRequest<WaitTimesResponse>({
      state: this.waitTimesState,
      request: this.bookingService.getWaitTimes(request)
    });
  }

  onJoinWaitListClicked() {
    this.validateForm(this.waitListForm);
    if (this.waitListForm.invalid) {
      return;
    }


    this.handleJoinWaitList();

  }

  handleJoinWaitList() {
    if (this.authStoreService.isAppUserLoggedIn()) {
      this.joinWaitList(LoginUserType.appUser);
      return;
    }

    this.onLoginNeeded.emit();
    this.authStoreService.loginSuccessful = () => {
      this.onJoinWaitListClicked();
    };
  }

  private joinWaitList(userType: LoginUserType) {
    const tableWaitTime = this.waitTimesState.response()?.data?.tableWaitTime;
    if (tableWaitTime == null) {
      this.toasterService.error('Tables are not available at this moment, kindly contact the restaurant host.');
      return;
    }
    const appUser = this.authStoreService.getAppUser();

    let customerInfo: MakeReservationRequestCustomerInfo;
    switch (userType) {
      case LoginUserType.appUser:
        customerInfo = {
          firstName: appUser?.data.firstName ?? '',
          lastName: appUser?.data.lastName ?? '',
          phoneNumber: appUser?.data.phoneNumber ?? '',
          email: appUser?.data.email ?? ''
        };
        break;
    }

    const waitTime = this.waitTimesState.response()?.data;

    let formValue = this.waitListForm.value;

    let tags = formValue.tags ?? [];

    if (tags.length <= 0) {
      tags = ['NO'];
    }

    const occasion = formValue.occasion ?? 'NO';

    const seatingOption = formValue.seatingPreferences;
    const partySize = formValue.partySize;
    const currentTime = new Date().toISOString();


    const request: JoinWaitListRequest = {
      restaurantId: waitTime?.restaurantId,
      partySize: partySize,
      autoAssignTable: true,
      time: currentTime,
      quotedWaitTime: tableWaitTime,
      customerInfo: customerInfo,
      occasion: occasion,
      seatingOption: seatingOption,
      tags: tags,
      note: formValue.note,
      coverSource: 'GUEST_WEB_APP',
      tableIds: []
    };

    this.executeRequest({
      state: this.joinWaitListState,
      request: this.bookingService.joinWaitList(request),
      onSuccess: response => {
        this.dialogRef.close();
        this.dialog.open(WaitlistConfirmationOverlayComponent, {
          ...Constants.defaultDialogConfig,
          data: response
        });
      }
    });
  }
}
