<div class="bg">
  <div class="px-8 sm:px-16 container mx-auto py-20 grid grid-cols-1 md:grid-cols-2">

    <div class="flex flex-col justify-center gap-3">
      <h1 class="text-h4 font-bold text-neutral-800"
          i18n="Mobile App Section Title|Headline for Smart Dining app promo@@homeMobileApp.title">
        All in one app
      </h1>

      <p class="text-body1 text-neutral-500"
         i18n="Mobile App Section Subtitle|Subtext describing the app features@@homeMobileApp.subtitle">
        Discover the most popular cuisines available for dine-in in your area, make a reservation, and order ahead of time.
      </p>

      <div class="flex gap-3">
        <a [href]="Constants.userAppAndroidDownloadLink" target="_blank">
          <img src="assets/images/home/google.png" alt="Download on Google Play" class="w-28">
        </a>
        <a [href]="Constants.userAppIOSDownloadLink" target="_blank">
          <img src="assets/images/home/apple.png" alt="Download on Apple Store" class="w-28">
        </a>
      </div>
    </div>

    <div>
      <img src="assets/images/home/phone-1.png" alt="Smart Dining mobile app preview">
    </div>

  </div>
</div>

