import {Component, input, signal} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-bookings-tab',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './bookings-tab.component.html',
  styleUrl: './bookings-tab.component.scss'
})
export class BookingsTabComponent extends BaseComponent {

  isUpcomingBooking = input(false);
  isPastBooking = input(false);

  onUpcomingBookingsClicked() {
    this.router.navigate(['/bookings/upcoming-bookings']);
  }

  onPastBookingsClicked() {
    this.router.navigate(['/bookings/past-bookings']);
  }
}
