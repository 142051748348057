import {Component, computed, ElementRef, inject, input, OnInit, signal} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {InvitationsService} from "../../../data/invitations.service";
import {AppSvgIconComponent, NetworkImageComponent, ShimmerComponent, State} from "@smartdining/lib-sd-web-shared";
import {GenericResponse} from "@core/models/generic-response";
import {AcceptInvitationRequest} from "../../../data/accept-invitation/accept-invitation-request";
import {DatePipe, DOCUMENT} from "@angular/common";
import {arrayToCSV} from "@shared/utils/string-utils";
import {DirectionButtonComponent} from "@core/components/direction-button/direction-button.component";
import {
  BookingDetailsResponse,
  BookingDetailsResponseData
} from "../../../../booking/data/bookings/bookings/booking-details-response";
import {openLink} from "@shared/utils/html-utils";
import {GeoPoint} from "@core/services/location-service/location.service";
import {BookingsService} from "../../../../booking/data/bookings/bookings.service";

@Component({
  selector: 'app-accept-invitation',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    DirectionButtonComponent,
    NetworkImageComponent,
    DatePipe,
    ShimmerComponent
],
  templateUrl: './accept-invitation.component.html',
  styleUrl: './accept-invitation.component.scss'
})
export class AcceptInvitationComponent extends BaseComponent implements OnInit {

  guest = input<string>();
  inviteId = input<string>();
  bookingId = input<string>('');

  booking = signal<BookingDetailsResponseData | null>(null);

  document = inject(DOCUMENT);
  elementRef = inject(ElementRef);
  invitationService = inject(InvitationsService);
  bookingsService = inject(BookingsService);


  sendInvitationState = new State<GenericResponse>();
  bookingDetailsState = new State<BookingDetailsResponse>();


  ngOnInit(): void {
    this.acceptInvitation();
    this.getBookingDetails();

  }

  acceptInvitation() {
    const request: AcceptInvitationRequest = {
      inviteId: this.inviteId(),
      bookingId: this.bookingId()
    };
    this.executeRequest({
      state: this.sendInvitationState,
      request: this.invitationService.acceptInvite(request),
      successMessage: 'Invitation accepted successfully'
    });
  }

  getBookingDetails() {
    this.executeRequest<BookingDetailsResponse>({
      state: this.bookingDetailsState,
      request: this.bookingsService.getBookingDetails(this.bookingId()),
      onSuccess: response => {
        this.booking.set(response.data);
      }
    });
  }

  destinationGeoPoint = computed(() => {
    let geoPoint: GeoPoint = {
      latitude: this.booking()?.restaurant?.location?.location?.coordinates[1],
      longitude: this.booking()?.restaurant?.location?.location?.coordinates[0],
    };
    return geoPoint;
  });

  onCallClicked() {
    openLink(`tel:${this.booking()?.restaurant.phoneNumber}`, false, this.document, this.elementRef);
  }

  protected readonly arrayToCSV = arrayToCSV;
}

