<div class="flex gap-5 shadow-1 bg-white border border-neutral-200 rounded-xl overflow-hidden cursor-pointer">
  <lib-network-image class="h-40 w-32 object-cover"
                     [imageUrl]="order().restaurant.logo.url">
  </lib-network-image>

  <div class="flex flex-grow py-3 pr-4">
    <div class="flex flex-col flex-grow gap-2">
      <div class="flex gap-3 items-center">
        <p class="text-body2 text-primary-500">{{ order().restaurant.restaurantName }}</p>
        <div class="flex-grow"></div>
        <app-order-type [type]="order().diningOption"></app-order-type>
      </div>
      <p class="text-body2 text-neutral-400">
        {{ order().restaurant.location.address1 }}, {{ order().restaurant.location.city }}
        , {{ order().restaurant.location.state }}
      </p>
      <div class="flex items-center gap-4">
        <div class="flex items-center gap-2">
          @switch (order().orderScheduleOption) {
            @case ('ASAP') {
              <p class="py-1 px-3 text-white bg-success-500 text-caption rounded">ASAP</p>
            }
            @case ('LATER') {
              <p class="py-1 px-3 text-white bg-success-500 text-caption rounded">Scheduled</p>
            }
          }
          <lib-svg-icon src="assets/icons/calendar.svg" [size]="16" class="text-primary-500"></lib-svg-icon>
          <p class="text-caption text-neutral-600">{{ order().fulfillmentDate | date:'d MMM y - h:mm a' }}</p>
        </div>
      </div>
      <div class="flex items-center">
        <p class="rounded-md px-3 py-2 text-caption bg-opacity-5"
           [ngClass]="getOrderColor(order().orderStatus)">{{ getOrderStatusText(order().orderStatus) }}</p>
        <div class="flex-grow"></div>
        <div class="flex items-center gap-4">
          <div class="flex justify-center items-center h-10 w-10 rounded-full hover:bg-neutral-50 cursor-pointer"
               (click)="onCallClicked(order().customerInfo.phoneNumber)">
            <lib-svg-icon src="assets/icons/phone.svg" class="text-blue-500"></lib-svg-icon>
          </div>
          @if (destinationGeoPoint().latitude && destinationGeoPoint().longitude) {
            <app-direction-button appearance="iconButton"
                                  [destinationLatitude]="destinationGeoPoint().latitude"
                                  [destinationLongitude]="destinationGeoPoint().longitude">
            </app-direction-button>
          }
        </div>
      </div>
    </div>
  </div>
</div>
