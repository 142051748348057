import {Component, inject, OnInit, signal} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";



import {BaseComponent} from "@core/base/base-component";

import {MatDialog} from "@angular/material/dialog";


import {ChangePasswordComponent} from "./change-password/change-password.component";
import {AuthService} from "../../../../auth/data/auth.service";
import {AuthenticatedUserResponse} from "../../../../auth/data/authenticated-user/authenticated-user-response";
import {AlertDialogService, State} from "@smartdining/lib-sd-web-shared";
import {CommonDataService} from "@core/services/common-data/common-data.service";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {EnableUserMFARequest} from "@core/services/common-data/MFA/enable-mfa/enable-user-MFA-request";
import { DisableUserMFARequest } from '@core/services/common-data/MFA/disable-mfa/disable-user-MFA-request';

@Component({
  selector: 'app-security',
  standalone: true,
  imports: [
    ReactiveFormsModule
],
  templateUrl: './security.component.html',
  styleUrl: './security.component.scss'
})
export class SecurityComponent extends BaseComponent implements OnInit {
  dialog = inject(MatDialog);
  commonDataService = inject(CommonDataService);
  authStoreService = inject(AuthStoreService);
  enableUser2FAState = new State<EnableUserMFARequest>();
  authService = inject(AuthService);
  alertService = inject(AlertDialogService);

  authenticatedUserState = new State<AuthenticatedUserResponse>();
  disableUser2FAState = new State<DisableUserMFARequest>();

  isMfaEnabled = signal<boolean | null>(null);

  ngOnInit(): void {
    this.getUser();
  }

  getUser() {
    this.executeRequest<AuthenticatedUserResponse>({
      state: this.authenticatedUserState,
      request: this.authService.getAuthenticatedUser(),
      onSuccess: response => {
        this.isMfaEnabled.set(response.data.enabledMFA ?? false);
      }
    });
  }

  onChangePasswordClicked() {
    this.dialog.open(ChangePasswordComponent, {
      minWidth: '350px',
      maxWidth: '600px',
      maxHeight: '90vh',
    });
  }

  enable2FA() {
    const userId = null;
    const request: EnableUserMFARequest = {
      userId: userId
    };
    this.executeRequest({
      state: this.enableUser2FAState,
      request: this.commonDataService.enableUserMFA(userId, request),
      showLoader: true,
      successMessage: '2FA enabled successfully',
      onSuccess: response => {
        this.isMfaEnabled.set(true);
      }
    });
  }

  disable2FA() {
    const userId = null;
    const request: DisableUserMFARequest = {
      userId: userId
    };
    this.executeRequest({
      state: this.disableUser2FAState,
      request: this.commonDataService.disableUserMFA(userId, request),
      showLoader: true,
      successMessage: '2FA disabled successfully',
      onSuccess: response => {
        this.isMfaEnabled.set(false);
      }
    });
  }

  onEnable2FAClicked() {

    if (this.isMfaEnabled()) {
      this.alertService.show('Disable MFA', 'Are you sure, do you want to disable 2FA?')
        .subscribe((data) => {
          if (data) {
            this.disable2FA();
          }
        });
    } else {
      this.alertService.show('Enable MFA', 'Are you sure, do you want to enable 2FA?')
        .subscribe((data) => {
          if (data) {
            this.enable2FA();
          }
        });
    }
  }

}

