import {Injectable} from '@angular/core';
import {BaseApiService} from "@core/base/base-api.service";
import {SavedCardsRequest} from "./saved-cards/saved-cards-request";
import {URLs} from "../../../core/base/urls";
import {
  CreatePaymentIntentForOrderRequest
} from "./create-payment-intent-for-order/create-payment-intent-for-order-request";
import {CreateSetupPaymentIntentRequest} from "./create-setup-payment-intent/create-setup-payment-intent-request";
import {CreateSetupPaymentIntentResponse} from "./create-setup-payment-intent/create-setup-payment-intent-response";
import {CapturePaymentIntentRequest} from "./capture-payment-intent/capture-payment-intent-request";

@Injectable({
  providedIn: 'root'
})
export class PaymentService extends BaseApiService {

  createPaymentIntent(request: any) {
    return this.post(URLs.createPaymentIntent, request);
  }

  createSetupPaymentIntent(request: CreateSetupPaymentIntentRequest) {
    return this.post<CreateSetupPaymentIntentResponse>(URLs.createSetupPaymentIntent, request);
  }

  confirmPaymentIntent(data: any) {
    return this.post(URLs.confirmPaymentIntent, data)
  }

  createPaymentIntentForOrder(request: CreatePaymentIntentForOrderRequest) {
    return this.post(URLs.createPaymentIntentForOrder, request);
  }

  capturePaymentIntent(request: CapturePaymentIntentRequest) {
    return this.post(URLs.capturePaymentIntent, request);
  }

  deletePaymentMethod(paymentMethodId: string) {
    return this.delete(URLs.deletePaymentMethod(paymentMethodId));
  }

  getSavedCards(request: SavedCardsRequest) {
    return this.get(URLs.getSavedCards, request);
  }

  setDefaultPaymentMethod(paymentMethodId: string) {
    return this.post(URLs.setDefaultPaymentMethod(paymentMethodId), {});
  }

}
