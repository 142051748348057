import {Component, inject, OnInit} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ButtonComponent} from "@smartdining/lib-sd-web-shared";

@Component({
  selector: 'app-clear-cart-confirmation-overlay',
  standalone: true,
  imports: [
    ButtonComponent,
    MatDialogContent
],
  templateUrl: './clear-cart-confirmation-overlay.component.html',
  styleUrl: './clear-cart-confirmation-overlay.component.scss'
})
export class ClearCartConfirmationOverlayComponent extends BaseComponent {
  dialogRef = inject(MatDialogRef);

  onYesClicked() {
    this.dialogRef.close(true);
  }

  onNoClicked() {
    this.dialogRef.close(false);
  }

}
