<div class="text-end">
  <lib-button (click)="onAddPaymentClicked()" i18n="Add Payment Button@@savedPayments.addPayment">
    Add Payment
  </lib-button>
</div>

<div class="flex flex-col gap-3 w-full">
  <p class="text-body2 py-3" i18n="Saved Cards Heading@@savedPayments.savedCards">Saved Cards</p>

  @if (savedCardsState.loading()) {
    <div class="flex flex-col gap-3">
      <lib-shimmer type="list"></lib-shimmer>
      <lib-shimmer type="list"></lib-shimmer>
      <lib-shimmer type="list"></lib-shimmer>
    </div>
  }

  @let savedCards = savedCardsState.response()?.data ?? [];

  @if (savedCardsState.success() && savedCards.length <= 0) {
    <div class="flex justify-center">
      <lib-no-data message="You have no saved cards." i18n-message="No saved cards message@@savedPayments.noCards"></lib-no-data>
    </div>
  }

  @for (card of savedCardsState.response()?.data ?? []; track card) {
    <div class="flex items-center justify-between px-5 py-3 bg-white shadow-1 rounded-md cursor-pointer">
      <div class="flex items-center gap-3">
        <lib-svg-icon [src]="CreditCardImageUtils.getCardImage(card.card.brand)"></lib-svg-icon>
        <p class="text-body2">
          <ng-container i18n="Card number placeholder@@savedPayments.cardNumber">
            XXXX- XXXX - XXXX - {{ card.card.last4 }}
          </ng-container>
        </p>
      </div>
      <div class="flex items-center gap-3">
        <!-- <lib-button (click)="onSetAsDefaultClicked(card)" appearance="outline" class="text-primary-500" i18n="Set as Default Button@@savedPayments.setDefault">Set as Default</lib-button> -->
        <lib-svg-icon
          src="assets/icons/delete.svg"
          class="hover:bg-neutral-100 rounded-full px-2 text-neutral-400 py-2 cursor-pointer"
          (click)="onDeleteClicked(card)"
          i18n-title="Delete card icon tooltip@@savedPayments.deleteCard"
          title="Delete Card">
        </lib-svg-icon>
      </div>
    </div>
  }
</div>
