<form class="w-full flex flex-col items-center gap-4" [formGroup]="verifySignupPhoneOtpForm">

  <div class="flex gap-2 items-center w-full">
    <p class="text-body2 text-neutral-500"
       i18n="Signup Phone OTP Prompt|Prompt to enter the verification code sent to user's phone@@verifySignupPhoneOverlay.prompt">
      Enter the verification codes sent to
    </p>
    <p class="text-body2 text-black font-bold">{{ signupInfo()?.phoneNumber ?? '' }}</p>
  </div>

  <lib-outline-text-input class="w-full"
                          formControlName="otp"
                          type="text"
                          placeholder="_ _ _ _ _ _"
                          label="Phone Verification Code"
                          i18n-label="Signup Phone OTP Label|Label for phone verification code input@@verifySignupPhoneOverlay.otpLabel"
                          i18n-placeholder="Signup Phone OTP Placeholder|Placeholder for phone OTP input@@verifySignupPhoneOverlay.otpPlaceholder">
  </lib-outline-text-input>

  <div class="w-full flex items-center gap-4 mt-3">
    <lib-button class="w-full"
                type="submit"
                [fullWidth]="true"
                appearance="primary"
                (click)="onSignupPhoneOtpVerificationClicked()"
                [loading]="verifySignupPhoneOtpState.loading()"
                i18n="Verify Button|CTA to verify signup phone OTP@@verifySignupPhoneOverlay.verifyButton">
      Verify
    </lib-button>
  </div>

</form>

