<div class="container-fluid mx-auto bg-neutral-50 py-20"
     style="background-image: url('assets/images/home/bg-line.svg')">
  <div class="container mx-auto px-8 sm:px-16 py-8">
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 items-center">

      <div class="py-20 sm:py-0">
        <img src="assets/images/home/image-f.png" alt="Feature image" />
      </div>

      <div class="flex justify-center items-center">
        <div class="flex flex-col gap-5">

          <p class="text-h6 text-neutral-500"
             i18n="Feature 3 Intro|Small headline above main title@@homeFeature3.intro">
            Easy and Fast
          </p>

          <p class="text-h4 text-neutral-800 font-bold"
             i18n="Feature 3 Title|Main title for discount and loyalty features@@homeFeature3.title">
            Save money On every meal in 3 easy steps
          </p>

          <div class="flex gap-5">
            <div class="flex flex-col justify-center items-center w-[64px] h-[58px] bg-yellow-500 rounded-lg">
              <lib-svg-icon src="assets/icons/cellphone.svg" class="text-white"></lib-svg-icon>
            </div>
            <div class="flex flex-col gap-2">
              <p class="text-h6 text-neutral-500"
                 i18n="Step 1 Title@@homeFeature3.step1.title">
                Download Smart Dining App
              </p>
              <p class="text-body1 text-neutral-500"
                 i18n="Step 1 Description@@homeFeature3.step1.description">
                Download Smart Dining for 100's of exclusive deals every day.
              </p>
            </div>
          </div>

          <div class="flex gap-5">
            <img src="assets/images/home/check.svg" alt="Check Icon" class="w-15" />
            <div class="flex flex-col gap-2">
              <p class="text-h6 text-neutral-500"
                 i18n="Step 2 Title@@homeFeature3.step2.title">
                100's of exclusive deals
              </p>
              <p class="text-body1 text-neutral-500"
                 i18n="Step 2 Description@@homeFeature3.step2.description">
                100's of exclusive deals every day from local favorite restaurants.
              </p>
            </div>
          </div>

          <div class="flex gap-5">
            <img src="assets/images/home/premium.svg" alt="Loyalty Program" class="w-15" />
            <div class="flex flex-col gap-2">
              <p class="text-h6 text-neutral-500"
                 i18n="Step 3 Title@@homeFeature3.step3.title">
                Premium loyalty Program
              </p>
              <p class="text-body1 text-neutral-500"
                 i18n="Step 3 Description@@homeFeature3.step3.description">
                You and your guests can pre-order online before the reservation date and time.
              </p>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
