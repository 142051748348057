import { Component } from '@angular/core';
import {AppSvgIconComponent} from "@smartdining/lib-sd-web-shared";

@Component({
  selector: 'app-feature-3-section',
  standalone: true,
  imports: [
    AppSvgIconComponent
  ],
  templateUrl: './feature-3-section.component.html',
  styleUrl: './feature-3-section.component.scss'
})
export class Feature3SectionComponent {

}
