import {Component, inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ButtonComponent} from "@smartdining/lib-sd-web-shared";

@Component({
  selector: 'app-cancel-reservation-confirmation-overlay',
  standalone: true,
  imports: [
    ButtonComponent,
    MatDialogContent
  ],
  templateUrl: './cancel-reservation-confirmation-overlay.component.html',
  styleUrl: './cancel-reservation-confirmation-overlay.component.scss'
})
export class CancelReservationConfirmationOverlayComponent {

  dialogRef = inject(MatDialogRef<CancelReservationConfirmationOverlayComponent>);

  onYesClicked() {
    this.dialogRef.close(CancelReservationAction.confirmed);
  }

  onNoClicked() {
    this.dialogRef.close(CancelReservationAction.rejected);
  }

}

export enum CancelReservationAction {
  confirmed,
  rejected
}
