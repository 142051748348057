<form [formGroup]="form" class="flex flex-col gap-3 px-8 py-4">
  <p class="text-body2 text-neutral-400"
     i18n="Forgot Password Instructions|Prompt to enter email for reset verification code@@forgotPassword.instructions">
    Please enter your email address and we will send you a verification code to reset your password.
  </p>

  <lib-outline-text-input
    formControlName="email"
    type="email"
    label="Email"
    i18n-label="Email Input Label|Label for email input field in forgot password form@@forgotPassword.emailLabel">
  </lib-outline-text-input>

  <lib-button class="mt-4"
              [loading]="forgotPasswordState.loading()"
              (click)="verifyEmailClicked()"
              [fullWidth]="true"
              i18n="Verify Email Button|CTA to verify email for password reset@@forgotPassword.verifyEmailButton">
    Verify Email
  </lib-button>
</form>

