import {Component, inject, input} from '@angular/core';
import {MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ButtonComponent, State} from "@smartdining/lib-sd-web-shared";
import {GenericResponse} from "@core/models/generic-response";
import {BaseComponent} from "@core/base/base-component";

@Component({
  selector: 'app-decline-invitation',
  standalone: true,
  imports: [
    ButtonComponent,
    MatDialogContent
  ],
  templateUrl: './decline-invitation-overlay.component.html',
  styleUrl: './decline-invitation-overlay.component.scss'
})
export class DeclineInvitationOverlayComponent extends BaseComponent {
  state = new State<GenericResponse>();

  dialogRef = inject(MatDialogRef<DeclineInvitationOverlayComponent>);


  onNoClicked() {
    this.dialogRef.close(InvitationDeclineAction.noAction);

  }

  onYesClicked() {
    this.dialogRef.close(InvitationDeclineAction.declineInvitation);
  }
}

export enum InvitationDeclineAction {
  declineInvitation,
  noAction
}
