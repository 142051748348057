import {inject, Injectable, PLATFORM_ID, signal} from '@angular/core';
import {SavedAddress} from "./saved-address.model";
import {isPlatformBrowser} from "@angular/common";
import {ServerStorage} from "../auth-store-service/server-storage";
import {AuthStoreService} from "../auth-store-service/auth-store.service";
import {RequestService} from "@shared/utils/request.service";
import {RestaurantFavoriteService} from "../../../features/settings/data/restaurant-favorite.service";
import {
  FavoriteRestaurantsResponse,
  FavoritesResponseData
} from "../../../features/settings/data/favorites/favorite-restaurants-response";
import {BehaviorSubject} from "rxjs";
import {State} from "@smartdining/lib-sd-web-shared";

@Injectable({
  providedIn: 'root'
})
export class AppDataService {

  authStoreService = inject(AuthStoreService);
  requestService = inject(RequestService);
  favoritesService = inject(RestaurantFavoriteService);
  private platformId = inject(PLATFORM_ID);

  storage!: Storage;

  public static ADDRESS = 'address';
  public static FAVORITE_RESTAURANTS = 'favorite_restaurants';
  public static SKIP_MOBILE_APP_DOWNLOAD = 'skip_mobile_app_download';


  private currentAddressSignal = signal<SavedAddress | null>(null);
  currentAddress = this.currentAddressSignal.asReadonly();

  skipMobileAppDownloadDialog = signal(false);

  $locationUpdated = new BehaviorSubject<boolean>(false);

  constructor() {
    this.storage = isPlatformBrowser(this.platformId) ? localStorage : new ServerStorage();

    const address = this.getAddress();
    this.currentAddressSignal.update((c) => address);
    this.initAuthListener();

    this.skipMobileAppDownloadDialog.set(this.storage.getItem(AppDataService.SKIP_MOBILE_APP_DOWNLOAD) == 'true');
  }

  private initAuthListener() {
    this.authStoreService.$loginStatus.subscribe(response => {
      this.saveFavoriteRestaurants();
    });
  }

  saveAddress(address: SavedAddress) {
    this.storage.setItem(AppDataService.ADDRESS, JSON.stringify(address));
    this.currentAddressSignal.update((c) => address);
    this.$locationUpdated.next(true);
  }

  getAddress(): SavedAddress | null {
    const addressJsonString = this.storage.getItem(AppDataService.ADDRESS);
    if (addressJsonString == null) {
      return null;
    }
    return JSON.parse(addressJsonString);
  }

  saveFavoriteRestaurants() {
    if (!this.authStoreService.isAuthenticated()) {
      return;
    }

    const user = this.authStoreService.getAppUser();
    this.requestService.executeRequest<FavoriteRestaurantsResponse>({
      state: new State(),
      request: this.favoritesService.getFavoriteRestaurants(user?.data.user ?? ''),
      onSuccess: response => {
        this.storage.setItem(AppDataService.FAVORITE_RESTAURANTS, JSON.stringify(response.data ?? []));
      }
    });
  }

  getFavoriteRestaurants(): FavoritesResponseData[] {
    const favoriteRestaurantsJsonString = this.storage.getItem(AppDataService.FAVORITE_RESTAURANTS);
    if (favoriteRestaurantsJsonString == null) {
      return [];
    }
    return JSON.parse(favoriteRestaurantsJsonString);
  }


  isRestaurantLiked(restaurantId: string): boolean {
    const favoriteRestaurants: FavoritesResponseData[] = this.getFavoriteRestaurants();
    const restaurant = favoriteRestaurants.find(x => x.restaurant._id == restaurantId);
    if (restaurant) {
      return true;
    } else {
      return false;
    }
  }

  getFavoriteRestaurantId(restaurantId: string): string | null {
    const favoriteRestaurants: FavoritesResponseData[] = this.getFavoriteRestaurants();
    const restaurant = favoriteRestaurants.find(x => x.restaurant._id == restaurantId);
    return restaurant?._id ?? null;
  }

  skipShowMobileAppDownloadDialog() {
    this.storage.setItem(AppDataService.SKIP_MOBILE_APP_DOWNLOAD, 'true');
  }
}
