<div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6">

  <p class="text-body1 font-medium"
     i18n="Received Invitations Title|Title for the received invitations page@@receivedInvitations.title">
    Received Invitations
  </p>

  <div class="my-2">
    <app-invitations-tab [isReceivedInvitation]="true"></app-invitations-tab>
  </div>

  @if (receivedInvitationsState.loading()) {
    <div class="flex flex-col gap-5">
      <lib-shimmer></lib-shimmer>
      <lib-shimmer></lib-shimmer>
      <lib-shimmer></lib-shimmer>
    </div>
  }

  <div class="flex flex-col gap-5">
    @for (receivedInvitation of receivedInvitationsState.response()?.data; track receivedInvitation) {
      <app-invitation-card
        [isReceivedInvitation]="true"
        [invitation]="receivedInvitation"
        (statusChanged)="getReceivedInvitations()">
      </app-invitation-card>
    }
  </div>

  @if (receivedInvitationsState.success() && (receivedInvitationsState.response()?.data?.length ?? 0) <= 0) {
    <div class="flex flex-col w-full h-full justify-center items-center">
      <lib-no-data
        class="self-center py-2"
        message="No Bookings found"
        i18n-message="No Received Invitations Message|Displayed when no invitations have been received@@receivedInvitations.noData">
      </lib-no-data>
    </div>
  }

</div>

