import {Component, inject, OnInit} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {AuthService} from "../../../../auth/data/auth.service";
import {AddressService} from "../../../data/address.service";
import {AddressListResponse, Address} from "../../../data/address-list/address-list-response";
import {MatDialog} from "@angular/material/dialog";
import {AddOrUpdateAddressComponent} from "./add-or-update-address/add-or-update-address.component";
import {DeleteAddressComponent} from "./delete-address/delete-address.component";
import {
  AppSvgIconComponent,
  arrayToCSV,
  ButtonComponent,
  NoDataComponent,
  ShimmerComponent,
  State
} from '@smartdining/lib-sd-web-shared';
import { BaseComponent } from '@core/base/base-component';
import {Constants} from "@core/constants/constants";

@Component({
  selector: 'app-addresses',
  standalone: true,
  imports: [
    ButtonComponent,
    ReactiveFormsModule,
    AppSvgIconComponent,
    NoDataComponent,
    ShimmerComponent
],
  templateUrl: './addresses.component.html',
  styleUrl: './addresses.component.scss'
})
export class AddressesComponent extends BaseComponent implements OnInit {

  private dialog = inject(MatDialog);
  private tokenService = inject(AuthService);
  private authService = inject(AuthService);
  private addressService = inject(AddressService);
  form!: FormGroup
  addressState = new State<AddressListResponse>();

  ngOnInit(): void {
    this.getAddressDetails();
  }

  onAddAddressClicked() {
    const dialogRef = this.dialog.open(AddOrUpdateAddressComponent, {
      ...Constants.defaultDialogConfig
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAddressDetails();
      }
    });
  }

  onEditClicked(address: Address) {
    const dialogRef = this.dialog.open(AddOrUpdateAddressComponent, {
      ...Constants.defaultDialogConfig,
      data: address
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAddressDetails();
      }
    });
  }

  getAddressDetails() {
    this.executeRequest({
      state: this.addressState,
      request: this.addressService.getSavedAddresses()
    });
  }

  onDeleteClicked(address: Address) {
    const dialogRef = this.dialog.open(DeleteAddressComponent, {
      ...Constants.defaultDialogConfig,
      data: address._id,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAddressDetails();
      }
    });
  }


  protected readonly arrayToCSV = arrayToCSV;
}
