import {Component, inject, input, OnInit, output} from '@angular/core';
import {SignupInfo} from "../signup-overlay/signup-overlay.component";
import {ButtonComponent, OutlineTextInputComponent, State} from "@smartdining/lib-sd-web-shared";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {AuthService} from "../../../data/auth.service";
import {VerifySignupPhoneOtpRequest} from "../../../data/verify-signup-phone-otp/verify-signup-phone-otp-request";
import {BaseComponent} from "@core/base/base-component";

@Component({
  selector: 'app-verify-signup-phone-overlay',
  imports: [
    ButtonComponent,
    FormsModule,
    OutlineTextInputComponent,
    ReactiveFormsModule
  ],
  templateUrl: './verify-signup-phone-overlay.component.html',
  styleUrl: './verify-signup-phone-overlay.component.scss'
})
export class VerifySignupPhoneOverlayComponent extends BaseComponent implements OnInit {
  signupInfo = input<SignupInfo | null>();

  onSignupPhoneVerified = output();

  formBuilder = inject(FormBuilder);
  authService = inject(AuthService);

  verifySignupPhoneOtpState = new State<VerifySignupPhoneOtpRequest>();

  verifySignupPhoneOtpForm!: FormGroup;

  ngOnInit(): void {
    this.initSignupPhoneOtpVerificationForm();
  }

  private initSignupPhoneOtpVerificationForm() {
    this.verifySignupPhoneOtpForm = this.formBuilder.group({
      otp: [null, [Validators.required]]
    });
  }

  onSignupPhoneOtpVerificationClicked() {
    this.verifySignupPhoneOtp();
  }

  verifySignupPhoneOtp() {
    let request: VerifySignupPhoneOtpRequest = {
      phoneNumber: this.signupInfo()?.phoneNumber ?? '',
      otp: this.verifySignupPhoneOtpForm.value.otp
    };

    this.executeRequest({
      state: this.verifySignupPhoneOtpState,
      request: this.authService.verifySignupPhoneOtp(request),
      onSuccess: response => {
        this.onSignupPhoneVerified.emit();
      }
    });
  }
}
