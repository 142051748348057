<div class="flex flex-col gap-4 shadow-2 rounded-lg overflow-hidden hover:cursor-pointer"
     (click)="onRestaurantClicked()">

  <div class="group">
    <div class="relative image-container">
      @if (isError()) {
        <img
          [ngSrc]="'assets/images/background/restaurant-placeholder.png'"
          class="object-cover w-full h-full transition-all duration-200 transform group-hover:scale-105"
          alt="Image"
          fill>
      } @else {
        <img
          [src]="restaurant()?.banner?.url ? restaurant()?.banner?.url : 'assets/images/background/restaurant-placeholder.png'"
          (error)="handleImageError()" alt=""
          class="object-cover w-full h-full transition-all duration-200 transform group-hover:scale-105">
      }

      <div class="absolute right-3 bottom-3 p-2 rounded-lg bg-white">
        <app-like-button (click)="onLikeClicked($event)" [isLiked]="restaurantLiked()"></app-like-button>
      </div>
    </div>
  </div>

  <div class="flex flex-col gap-2 px-3 py-2">
    <div class="flex items-center justify-between">
      <p class="text-body1">{{ restaurant()?.restaurantName }}</p>
      <app-rating-count [rating]="restaurant()?.rating ?? 0"
                        [count]="restaurant()?.ratingCount ?? 0"></app-rating-count>
    </div>

    <div class="flex items-center gap-2">
      @if (distanceInMiles()) {
        <p class="text-body2 text-blue-500" i18n="@@restaurantCard.distance">{{ distanceInMiles() }} mi away</p>
        <p class="text-body2 text-neutral-500">-</p>
      } @else {
        <p class="text-body2 text-blue-500" i18n="@@restaurantCard.na">n/a</p>
        <p class="text-body2 text-neutral-500">-</p>
      }

      <p class="text-body2 text-neutral-500">{{ restaurant()?.location?.city }}</p>
    </div>

    <div class="flex gap-2">
      @for (cuisine of restaurant()?.cuisines; track cuisine) {
        <app-chip [name]="cuisine.name"></app-chip>
      }
    </div>

    @switch (dineInOption()) {
      @case (DineInOption.dineIn) {
        <div class="flex gap-2 overflow-x-scroll">
          @for (availability of restaurant()?.availabilities; track availability) {
            <!-- Future availability formatting could go here -->
          } @empty {
<!--            <p class="text-caption text-red-300 py-2 px-3 rounded-md cursor-auto" i18n="@@restaurantCard.noBooking">-->
<!--              Not available for booking-->
<!--            </p>-->
          }
        </div>
      }
      @case (DineInOption.pickupOrder) {
        <!-- Optional: add i18n if anything is rendered here -->
      }
    }

  </div>
</div>
