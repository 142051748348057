import {Injectable, signal} from '@angular/core';
import {Cart} from "./cart/cart";
import {BaseApiService} from "@core/base/base-api.service";

@Injectable({
  providedIn: 'root'
})
export class CartService {
  cart = signal<Cart>(new Cart());
  restaurantId?: string | null;
  pickupOption?: string;
  pickupTime?: string;

  setPickupOptions(pickupOption: string, pickupTime: string) {
    this.pickupOption = pickupOption;
    this.pickupTime = pickupTime;
  }

  setRestaurantId(restaurantId: string) {
    this.restaurantId = restaurantId;
  }

  resetCart() {
    this.cart.set(new Cart());
    this.restaurantId = null;
  }
}
