<app-base-dialog
  [title]="'Waitlist Confirmation'"
  i18n-title="Waitlist Confirmation Title|Title of the waitlist confirmation modal@@waitlistConfirmation.title"
  [showAction]="false">
  <div body class="flex flex-col justify-center items-center gap-4">

    <span class="flex justify-center items-center self-center rounded-full h-12 w-12 bg-success-500 bg-opacity-10 flex-shrink-0">
      <lib-svg-icon [size]="96" class="text-success-500" src="assets/icons/check-circle.svg"></lib-svg-icon>
    </span>

    <p class="text-subtitle1 text-neutral-500"
       i18n="Waitlist Confirmation Message|Confirmation text after joining waitlist@@waitlistConfirmation.confirmationMessage">
      You're on the waitlist!
    </p>

    <span class="flex gap-2 items-center">
      <p class="text-body2 text-black"
         i18n="Booking ID Label|Prefix for confirmation number@@waitlistConfirmation.bookingIdLabel">
        Booking ID -
      </p>
      <p class="text-body2 text-info-500">#{{ data.data.confirmationNumber }}</p>
    </span>

    <div class="flex items-center justify-center gap-6 w-full px-4 py-3 bg-neutral-50 rounded-2xl">
      <div class="flex gap-2 items-center">
        <lib-svg-icon src="assets/icons/clock.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
        <p class="text-body2"
           i18n="Quoted Wait Time Text|Estimated wait time in minutes@@waitlistConfirmation.quotedWaitTime">
          {{ data.data.quotedWaitTime }} minutes
        </p>
      </div>
      <div class="flex gap-2 items-center">
        <lib-svg-icon src="assets/icons/group.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
        <p class="text-body2"
           i18n="Party Size Text|Number of guests in waitlist@@waitlistConfirmation.partySize">
          {{ data.data.partySize }} Guest(s)
        </p>
      </div>
    </div>

    <p class="text-body1 text-primary-500 mt-6 self-start"
       i18n="Invite Guests Section Title|Title above invite form@@waitlistConfirmation.inviteGuestsTitle">
      Invite Guest(s)
    </p>

    <form [formGroup]="inviteForm" class="flex gap-6 items-center w-full" (ngSubmit)="onInvitePressed()">
      <input
        formControlName="emailOrPhone"
        class="rounded py-3 px-3 border-neutral-400 focus:border-primary-500 focus:outline-primary-500 border w-full"
        placeholder="Phone (or) Email"
        i18n-placeholder="Invite Guest Placeholder|Placeholder for phone or email@@waitlistConfirmation.invitePlaceholder" />

      <lib-button
        [loading]="invitedGuestsState.loading()"
        iconSrc="assets/icons/invite-guest.svg"
        type="submit"
        i18n="Invite Button Text|Button to send guest invitation@@waitlistConfirmation.inviteButton">
        Invite
      </lib-button>
    </form>

    @for (receiver of invitedGuestsState.response()?.data?.receivers; track receiver) {
      <div class="self-start flex justify-start item-center py-2">
        <p class="text-body1">{{ receiver?.email ?? receiver?.phoneNumber ?? unknownGuest }}</p>
      </div>
    }

  </div>
</app-base-dialog>
