<div class="container-fluid bg-neutral-50">
  <div class="relative container-fluid lg:container mx-auto px-4 py-6">
    @if (orderDetailsState.loading()) {
      <div class="flex flex-col gap-10 p-4 bg-white">
        <lib-shimmer type="list"></lib-shimmer>
        <lib-shimmer type="list"></lib-shimmer>
        <lib-shimmer type="list"></lib-shimmer>
      </div>
    }

    @if (orderDetailsState.success()) {
      <div class="flex flex-col gap-4 py-2">
        <div class="flex flex-col gap-2">
          <div class="flex justify-center py-2">
            @switch (getOrderStatusSummary(order()?.data?.orderStatus ?? '')) {
              @case (OrderStatusSummary.confirmed) {
                <span
                  class="flex justify-center items-center self-center rounded-full h-20 w-20 bg-success-500 bg-opacity-10 flex-shrink-0">
                  <lib-svg-icon [size]="64" class="text-success-500" src="assets/icons/check-circle.svg"></lib-svg-icon>
                </span>
              }
              @case (OrderStatusSummary.finished) {
                <span
                  class="flex justify-center items-center self-center rounded-full h-20 w-20 bg-success-500 bg-opacity-10 flex-shrink-0">
                  <lib-svg-icon [size]="64" class="text-success-500" src="assets/icons/check-circle.svg"></lib-svg-icon>
                </span>
              }
              @case (OrderStatusSummary.cancelled) {
                <span
                  class="flex justify-center items-center self-center rounded-full h-20 w-20 bg-error-500 bg-opacity-10 flex-shrink-0">
                  <lib-svg-icon [size]="30" class="text-error-500" src="assets/icons/close.svg"></lib-svg-icon>
                </span>
              }
            }
          </div>
          <div class="flex justify-center">
            <p class="text-body-2"
               [ngClass]="getStatusColor()">{{ getOrderStatusText(order()?.data?.orderStatus ?? '') }}</p>
          </div>
          <p class="text-body2 text-neutral-700 text-center pb-2">Order ID - #{{ order()?.data?.orderNumber }}</p>
        </div>
        <div class="flex flex-row gap-4">
          <div class="flex flex-col gap-4 px-4 py-6 bg-white w-2/3">
            <div class="flex flex-col gap-3">
              <p class="text-body2 text-primary-500 font-medium">Restaurant Details</p>
              <div class="flex gap-3">
                <lib-network-image class="h-auto w-32 object-cover"
                                   [imageUrl]="order()?.data?.restaurant?.banner?.url"></lib-network-image>
                <div class="flex flex-col">
                  <p class="text-body2 text-neutral-700">{{ order()?.data?.restaurant?.restaurantName }}</p>
                  <div class="flex flex-col gap-2">
                    <p class="text-body2 text-neutral-400">
                      {{
                        arrayToCSV([
                          order()?.data?.restaurant?.location?.address1,
                          order()?.data?.restaurant?.location?.city,
                          order()?.data?.restaurant?.location?.state
                        ])
                      }}
                    </p>
                    <div class="flex gap-5">
                      @if (this.order()?.data?.restaurant?.phoneNumber) {
                        <div
                          class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-neutral-50 cursor-pointer"
                          (click)="onCallClicked()">
                          <lib-svg-icon src="assets/icons/phone.svg"
                                        class="text-blue-500 cursor-pointer"></lib-svg-icon>
                        </div>
                      }
                      @if (destinationGeoPoint().latitude && destinationGeoPoint().longitude) {
                        <app-direction-button appearance="iconButton" class="text-blue-500 cursor-pointer"
                                              [destinationLatitude]="destinationGeoPoint().latitude"
                                              [destinationLongitude]="destinationGeoPoint().longitude"></app-direction-button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="border-b border-neutral-100 my-3"></div>

            <div class="flex flex-col gap-3">
              <p class="text-body2 text-primary-500 font-medium">Order Details</p>
              <div class="flex gap-4 items-center">
                @switch (order()?.data?.orderScheduleOption) {
                  @case ('ASAP') {
                    <p class="py-1 px-3 text-white bg-success-500 text-caption rounded">ASAP</p>
                  }
                  @case ('LATER') {
                    <p class="py-1 px-3 text-white bg-success-500 text-caption rounded">Scheduled</p>
                  }
                }
                <lib-svg-icon src="assets/icons/calendar.svg" [size]="16" class="text-primary-500"></lib-svg-icon>
                <p
                  class="text-caption text-neutral-600">{{ order()?.data?.fulfillmentDate | date:'d MMM y - h:mm a' }}</p>
              </div>

              @if (order()?.data?.diningOption == 'CURBSIDE_PICK_UP') {
                <div class="flex flex-col gap-4">
                  <p class="text-body2 text-primary-500">Curbside pickup details</p>

                  <div class="flex gap-3">
                    <p class="text-body2 text-neutral-500 min-w-52">Driver name</p>
                    <p class="text-body2">{{ order()?.data?.curbside?.driverName }}</p>
                  </div>

                  <div class="flex gap-3">
                    <p class="text-body2 text-neutral-500 min-w-52">Driver phone</p>
                    <p class="text-body2">{{ order()?.data?.curbside?.driverPhone }}</p>
                  </div>

                  <div class="flex gap-3">
                    <p class="text-body2 text-neutral-500 min-w-52">Vehicle type</p>
                    <p class="text-body2">{{ order()?.data?.curbside?.vehicleType }}</p>
                  </div>

                  <div class="flex gap-3">
                    <p class="text-body2 text-neutral-500 min-w-52">Vehicle color</p>
                    <p class="text-body2">{{ order()?.data?.curbside?.vehicleColor }}</p>
                  </div>

                </div>

              }

                <div class="flex gap-4 items-center space-x-4 py-3">
                  @if (!isPastOrder() && !order()?.data?.curbside?.checkedIn && order()?.data?.orderStatus !== 'CANCELLED') {
                  @if (orderDetailsState.response()?.data?.curbside?.curbsideOption) {
                    <span class="flex gap-2 items-center cursor-pointer text-success-500">
                     <lib-svg-icon src="assets/icons/tick.svg" [size]="16"></lib-svg-icon>
                     <p class="text-body2" (click)="onCheckInClicked()">Check in</p>
                    </span>
                  }
                }

                @if (!isPastOrder()) {
                  @switch (getOrderStatusSummary(order()?.data?.orderStatus ?? '')) {
                    @case (OrderStatusSummary.confirmed) {
                      <span class="flex gap-2 items-center cursor-pointer text-error-500">
                        <lib-svg-icon src="assets/icons/close.svg" [size]="14"></lib-svg-icon>
                        <p class="text-body2" (click)="onCancelOrderClicked()">Cancel Order</p>
                      </span>
                    }
                  }
                }
              </div>
            </div>

            <div class="border-b border-neutral-100 my-3"></div>

            <div class="flex flex-col gap-3">
              <p class="text-body2 text-primary-500 font-medium">Customer Details</p>
              <p class="text-body2">{{ order()?.data?.customerInfo?.firstName }}</p>
              <p class="text-body2">{{ order()?.data?.customerInfo?.phoneNumber }}</p>
            </div>

            @if (order()?.data?.orderStatus == "COMPLETED" && order()?.data?.surveyStatus == "PENDING") {
              <div class="border-t py-6 border-neutral-100">
                <lib-button appearance="outline" [iconSrc]="'assets/icons/done.svg'" (click)="onRateYourOrderClicked()">
                  RATE YOUR ORDER
                </lib-button>
              </div>
            }
            @if (order()?.data?.surveyStatus == "SUBMITTED") {
              <div class="border-t py-6 flex items-center border-neutral-100">
                <div class="flex items-center gap-4">
                  <lib-svg-icon src="assets/icons/done.svg" class="text-success-500"></lib-svg-icon>
                  <p class="text-body2">Thank you for submitting your survey</p>
                </div>
              </div>
            }
          </div>
          <div class="flex flex-col border px-4 py-4 rounded-md shadow-1 bg-white gap-4 w-1/3">
            <p class="text-body2 font-medium">Your Order</p>
            <p class="border-b"></p>
            <div class="flex flex-col gap-3">
              <div class="flex justify-between">
                <div class="flex flex-col gap-6">
                  @for (check of order()?.data?.checks; track check) {
                    @for (selection of check?.selections; track selection) {
                      <div class="flex flex-col gap-3">
                        <p class="text-body2">{{ selection.quantity }} x {{ selection.menuItemName }}</p>
                        <div class="flex flex-col gap-3">
                          @for (modifierGroup of selection?.modifierGroups ?? []; track modifierGroup) {
                            @for (modifier of modifierGroup.modifiers; track modifier) {
                              <span class="text-body2 text-nowrap text-neutral-500">{{ modifier.quantity }}
                                x {{ modifier.name }}</span>
                            }
                          }
                        </div>
                      </div>
                    }
                  }
                </div>
              </div>
              <div class="border-b border-neutral-200"></div>
            </div>
            <div class="flex flex-col gap-3">
              <div class="flex flex-col gap-3">
                <div class="flex justify-between">
                  <p class="text-body2 text-neutral-500">Subtotal</p>
                  <p class="text-body2 text-neutral-900">$ {{ checkInfo()?.amount | number:'1.2-2' }}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-body2 text-neutral-500">Taxes</p>
                  <p class="text-body2 text-neutral-900">$ {{ checkInfo()?.taxAmount | number:'1.2-2' }}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-body2 text-neutral-500">Discount</p>
                  <p class="text-body2 text-neutral-900">$ {{ checkInfo()?.totalDiscountAmount | number:'1.2-2' }}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-body2 text-neutral-500">Waiter Tip</p>
                  <p class="text-body2 text-neutral-900">$ {{ checkInfo()?.tipAmount | number:'1.2-2' }}</p>
                </div>
                <div class="flex justify-between font-bold">
                  <p class="text-body2">Total Amount</p>
                  <p class="text-body2">$ {{ checkInfo()?.totalAmount | number:'1.2-2' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
</div>
