import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RestaurantSuggestionService {

  restaurantSelected: ((data: string) => void) | null = null;

  constructor() { }
}
