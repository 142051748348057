<div class="px-6 flex flex-col items-center gap-4">

  <img src="assets/images/branding/app-logo.png" width="32" alt="" srcset="">

  <p class="text-body1 font-medium">Welcome back!</p>

  @switch (uiState()) {
    @case (LoginTypeUIState.enterEmailPassword) {
      <form class="w-full flex flex-col items-center gap-4" [formGroup]="emailPasswordLoginForm">

        <button
          class="h-[32px] w-full flex justify-center items-center text-body2 border border-neutral-200 px-4 py-5 rounded-md text-neutral-800"
          (click)="onLoginUsingPhoneNumberClicked()"
          i18n="Phone Login Button|CTA to log in using phone number instead of email/password@@loginOverlay.phoneLogin">
          Login using Phone Number
        </button>

        <div class="flex items-center gap-5 w-full">
          <div class="grow border-b border-neutral-200"></div>
          <span class="text-caption"
                i18n="Login Divider Text|Separator text between login methods@@loginOverlay.orText">OR</span>
          <div class="grow border-b border-neutral-200"></div>
        </div>

        <lib-outline-text-input class="w-full"
                                formControlName="email"
                                type="email"
                                placeholder="you@company.com"
                                label="Email"
                                i18n-label="Email Label|Label for email input in login form@@loginOverlay.emailLabel"
                                i18n-placeholder="Email Placeholder|Placeholder for email input@@loginOverlay.emailPlaceholder">
        </lib-outline-text-input>

        <lib-outline-text-input class="w-full"
                                formControlName="password"
                                type="password"
                                placeholder="Password"
                                label="Password"
                                i18n-label="Password Label|Label for password input@@loginOverlay.passwordLabel"
                                i18n-placeholder="Password Placeholder|Placeholder for password input@@loginOverlay.passwordPlaceholder">
        </lib-outline-text-input>

        <div class="w-full flex justify-between items-center">
          <a class="text-body2 text-info-500 cursor-pointer"
             (click)="onLoginUsingEmailClicked()"
             i18n="Email Code Link|Link to log in using email verification code@@loginOverlay.emailCodeLink">
            Email Verification Code
          </a>
          <a class="text-body2 text-info-500 cursor-pointer"
             (click)="onForgotPasswordClicked()"
             i18n="Forgot Password Link|Link to reset forgotten password@@loginOverlay.forgotPasswordLink">
            Forgot Password?
          </a>
        </div>

        <lib-button class="w-full"
                    type="submit"
                    [fullWidth]="true"
                    appearance="primary"
                    (click)="onEmailPasswordLoginClicked()"
                    [loading]="emailPasswordLoginState.loading()"
                    i18n="Login Button|CTA to submit login form@@loginOverlay.loginButton">
          Login
        </lib-button>

      </form>
    }
    @case (LoginTypeUIState.enterEmail) {
      <form class="w-full flex flex-col items-center gap-4" [formGroup]="emailLoginForm">

        <button
          class="h-[32px] w-full flex justify-center items-center text-body2 border border-neutral-200 px-4 py-5 rounded-md text-neutral-800"
          (click)="onLoginUsingPhoneNumberClicked()"
          i18n="Phone Login Button|CTA to log in using phone number instead of email@@loginOverlay.phoneLogin">
          Login using Phone Number
        </button>

        <div class="flex items-center gap-5 w-full">
          <div class="grow border-b border-neutral-200"></div>
          <span class="text-caption"
                i18n="Login Divider Text|Separator between login options@@loginOverlay.orText">OR</span>
          <div class="grow border-b border-neutral-200"></div>
        </div>

        <lib-outline-text-input class="w-full"
                                formControlName="email"
                                type="email"
                                placeholder="you@company.com"
                                label="Email"
                                i18n-label="Email Label|Label for email input field@@loginOverlay.emailLabel"
                                i18n-placeholder="Email Placeholder|Placeholder for email input@@loginOverlay.emailPlaceholder">
        </lib-outline-text-input>

        <div class="w-full flex justify-between items-center">
          <a class="text-body2 text-info-500 cursor-pointer"
             (click)="onLoginUsingEmailPasswordOTPClicked()"
             i18n="Enter Password Instead|Link to switch to email/password login@@loginOverlay.enterPasswordLink">
            Enter Password Instead
          </a>
          <a class="text-body2 text-info-500 cursor-pointer"
             i18n="Forgot Password Link|Link to trigger forgot password flow@@loginOverlay.forgotPasswordLink">
            Forgot Password?
          </a>
        </div>

        <lib-button class="w-full"
                    type="submit"
                    [fullWidth]="true"
                    appearance="primary"
                    (click)="onSendEmailOtpClicked()"
                    [loading]="sendEmailOtpState.loading()"
                    i18n="Send Verification Code Button|CTA to send a login verification code to email@@loginOverlay.sendCodeButton">
          Send Verification Code
        </lib-button>

      </form>
    }
    @case (LoginTypeUIState.enterPhoneNumber) {
      <form class="w-full flex flex-col items-center gap-4" [formGroup]="phoneNumberLoginForm">

        <button
          class="h-[32px] w-full flex justify-center items-center text-body2 border border-neutral-200 px-4 py-5 rounded-md text-neutral-800"
          (click)="onLoginUsingEmailPasswordOTPClicked()"
          i18n="Email Login Button|CTA to switch to email-based login@@loginOverlay.emailLogin">
          Login using Email
        </button>

        <div class="flex items-center gap-5 w-full">
          <div class="grow border-b border-neutral-200"></div>
          <span class="text-caption"
                i18n="Login Divider Text|Separator between login options@@loginOverlay.orText">OR</span>
          <div class="grow border-b border-neutral-200"></div>
        </div>

        <lib-outline-text-input class="w-full"
                                formControlName="phoneNumber"
                                type="text"
                                placeholder="+1 (123) 456-7890"
                                label="Phone Number"
                                i18n-label="Phone Number Label|Label for phone number input field@@loginOverlay.phoneLabel"
                                i18n-placeholder="Phone Number Placeholder|Placeholder for phone input@@loginOverlay.phonePlaceholder">
        </lib-outline-text-input>

        <lib-button class="w-full"
                    type="submit"
                    [fullWidth]="true"
                    appearance="primary"
                    (click)="onSendPhoneOtpClicked()"
                    [loading]="sendPhoneOtpState.loading()"
                    i18n="Send Verification Code Button|CTA to send verification code to phone number@@loginOverlay.sendCodeButton">
          Send Verification Code
        </lib-button>

      </form>
    }
    @case (LoginTypeUIState.verifyEmailOTP) {
      <form class="w-full flex flex-col items-center gap-4" [formGroup]="verifyEmailOtpForm">

        <div class="flex gap-2 items-center w-full">
          <p class="text-body2 text-neutral-500"
             i18n="Email OTP Prompt|Prompt to enter the code sent to the user's email@@loginOverlay.emailOtpPrompt">
            Enter the verification codes sent to
          </p>
          <p class="text-body2 text-black font-bold">{{ emailLoginForm.value.email }}</p>
        </div>

        <lib-outline-text-input class="w-full"
                                formControlName="otp"
                                type="text"
                                placeholder="_ _ _ _ _ _"
                                label="Email Verification Code"
                                i18n-label="Email OTP Label|Label for email verification code input@@loginOverlay.emailOtpLabel"
                                i18n-placeholder="Email OTP Placeholder|Placeholder for OTP input@@loginOverlay.emailOtpPlaceholder">
        </lib-outline-text-input>

        <div class="w-full flex items-center gap-4 mt-3">
          <lib-button class="w-full"
                      iconSrc="assets/icons/arrow-left.svg"
                      [fullWidth]="true"
                      appearance="outline"
                      (click)="onLoginUsingEmailClicked()"
                      i18n="Back Button|Go back to previous login step@@loginOverlay.backButton">
            Back
          </lib-button>

          <lib-button class="w-full"
                      type="submit"
                      [fullWidth]="true"
                      appearance="primary"
                      (click)="onEmailOtpVerificationClicked()"
                      [loading]="loginWithEmailOtpState.loading()"
                      i18n="Verify and Login Button|CTA to submit email OTP and log in@@loginOverlay.verifyAndLoginButton">
            Verify & Login
          </lib-button>
        </div>

      </form>
    }
    @case (LoginTypeUIState.verifyPhoneNumberOTP) {
      <form class="w-full flex flex-col items-center gap-4" [formGroup]="verifyPhoneOtpForm">

        <div class="flex gap-2 items-center w-full">
          <p class="text-body2 text-neutral-500"
             i18n="Phone OTP Prompt|Prompt to enter the verification code sent to the user's phone@@loginOverlay.phoneOtpPrompt">
            Enter the verification codes sent to
          </p>
          <p class="text-body2 text-black font-bold">{{ phoneNumberLoginForm.value.phoneNumber }}</p>
        </div>

        <lib-outline-text-input class="w-full"
                                formControlName="otp"
                                type="text"
                                placeholder="_ _ _ _ _ _"
                                label="Phone Verification Code"
                                i18n-label="Phone OTP Label|Label for phone verification code input@@loginOverlay.phoneOtpLabel"
                                i18n-placeholder="Phone OTP Placeholder|Placeholder for phone OTP input@@loginOverlay.phoneOtpPlaceholder">
        </lib-outline-text-input>

        <div class="w-full flex items-center gap-4 mt-3">
          <lib-button class="w-full"
                      iconSrc="assets/icons/arrow-left.svg"
                      [fullWidth]="true"
                      appearance="outline"
                      (click)="onLoginUsingPhoneNumberClicked()"
                      i18n="Back Button|Go back to phone number login@@loginOverlay.backButton">
            Back
          </lib-button>

          <lib-button class="w-full"
                      type="submit"
                      [fullWidth]="true"
                      appearance="primary"
                      (click)="onPhoneOtpVerificationClicked()"
                      [loading]="loginWithPhoneOtpState.loading()"
                      i18n="Verify and Login Button|CTA to submit phone OTP and log in@@loginOverlay.verifyAndLoginButton">
            Verify & Login
          </lib-button>
        </div>

      </form>
    }
  }

  <div class="py-4 flex items-center gap-2">
    <p class="text-body2"
       i18n="Signup Prompt|Prompt asking if the user has an account@@loginOverlay.noAccountPrompt">
      Don’t have an account?
    </p>
    <p class="text-body2 text-info-500 cursor-pointer font-medium"
       (click)="onSignupClicked()"
       i18n="Signup Link|Link to navigate to signup flow@@loginOverlay.signupLink">
      Signup
    </p>
  </div>

</div>
