import {CartItem, CartModifier, CartModifierGroup} from "../data/cart/cart";
import {
  PlaceOrderModifier,
  PlaceOrderModifierGroup,
  PlaceOrderSelection
} from "../data/place-order/place-order-request";

export class CartSelectionsToPlaceOrderSelections {
  static convert(selections: CartItem[]): PlaceOrderSelection[] {
    let placeOrderSelections: PlaceOrderSelection[] = [];
    for (const selection of selections) {
      let placeOrderSelection: PlaceOrderSelection = {
        menuId: selection.menuItem?.menuId ?? '',
        menuItemId: selection.menuItem?.id ?? '',
        menuCategoryId: selection.menuItem?.categoryId ?? '',
        quantity: selection.quantity(),
        menuItemName: selection.menuItem.name,
        priceType: selection.menuItem.getSelectedPriceType(),
        basePrice: selection.menuItem.price?.basePrice,
        sizePrice: selection.menuItem.getSelectedSizePrice()?.price,
        size: selection.menuItem.getSelectedSizePrice()?.size,
        modifiersPrice: 0,
        selectionPrice: 0,
        modifierGroups: this.getModifierGroups(selection.menuItem.modifierGroups),
        notes: selection.notes
      }
      placeOrderSelections.push(placeOrderSelection);
    }
    return placeOrderSelections;
  }

  private static getModifierGroups(modifierGroups: CartModifierGroup[]): PlaceOrderModifierGroup[] {
    let poModifierGroups: PlaceOrderModifierGroup[] = [];
    for (const modifierGroup of modifierGroups) {
      if(modifierGroup.isModifierSelected()) {
        let mg: PlaceOrderModifierGroup = {
          modifierGroupId: modifierGroup.id,
          selection: modifierGroup.selection,
          modifiers: this.getModifiers(modifierGroup.modifiers)
        };
        poModifierGroups.push(mg);
      }
    }

    return poModifierGroups;
  }

  private static getModifiers(modifiers: CartModifier[]): PlaceOrderModifier[] {
    let poModifier: PlaceOrderModifier[] = [];
    for (const modifier of modifiers) {
      if(modifier.selectedQuantity() > 0) {
        let m: PlaceOrderModifier = {
          modifierId: modifier.id,
          name: modifier.name,
          price: modifier.price,
          quantity: modifier.selectedQuantity(),
        };
        poModifier.push(m);
      }
    }
    return poModifier;
  }
}
