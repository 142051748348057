import {Injectable} from '@angular/core';
import {BaseApiService} from "@core/base/base-api.service";
import {MakeReservationRequest} from "../make-reservation/make-reservation-request";
import {URLs} from "../../../../core/base/urls";
import {MakeReservationResponse} from "../make-reservation/make-reservation-response";
import {TableAvailabilitiesRequest} from "../table-availabilities/table-availabilities-request";
import {TableAvailabilitiesResponse} from "../table-availabilities/table-availabilities-response";
import {BookingsResponse} from "./bookings/bookings-response";
import {BookingsRequest} from "./common/bookings-request";
import {WaitTimesRequest} from "../wait-times/wait-times-request";
import {CancelReservationRequest} from "../cancel-reservation/cancel-reservation-request";
import {BookingDetailsResponse} from "./bookings/booking-details-response";
import {UpdateReservationRequest} from "../update-reservation/update-reservation-request";
import {GenericResponse} from "../../../../core/models/generic-response";
import {RestaurantSurveyRequest} from "./survey/restaurant-survey-request";
import {BookingInformationResponse} from "./bookings/booking-information-response";
import {
  SetBookingStatusNotInterestedResponse
} from "../set-booking-survey-not-interested/set-booking-status-not-interested-response";
import {
  SetBookingStatusNotInterestedRequest
} from "../set-booking-survey-not-interested/set-booking-status-not-interested-request";

@Injectable({
  providedIn: 'root'
})
export class BookingsService extends BaseApiService {

  getTableAvailabilities(request: TableAvailabilitiesRequest) {
    return this.post<TableAvailabilitiesResponse>(URLs.getTimeAvailabilities, request);
  }

  getWaitTimes(request: WaitTimesRequest) {
    return this.get(URLs.getTableWaitTimes, request);
  }

  makeReservation(request: MakeReservationRequest) {
    return this.post<MakeReservationResponse>(URLs.makeReservation, request);
  }

  updateReservation(request: UpdateReservationRequest) {
    return this.put<GenericResponse>(URLs.updateReservation, request);
  }

  getUpcomingReservations(request: BookingsRequest) {
    return this.get<BookingsResponse>(URLs.getUpcomingReservation, request);
  }

  getPastReservation(request: BookingsRequest) {
    return this.get<BookingsResponse>(URLs.getPastReservation, request);
  }

  cancelBookings(reservationId: string, request: CancelReservationRequest) {
    return this.put<BookingsResponse>(URLs.cancelBookings(reservationId), request);
  }


  getBookingDetails(bookingId: string) {
    return this.get<BookingInformationResponse>(URLs.getBookingDetails(bookingId));
  }

  setBookingStatusNotInterested(request: SetBookingStatusNotInterestedRequest) {
    return this.put<SetBookingStatusNotInterestedResponse>(URLs.setBookingStatusNotInterested, null, request);
  }


}
