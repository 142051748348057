<div class="flex flex-col gap-4">
  <form [formGroup]="waitListForm" class="flex flex-col gap-4">
    <div class="flex items-center gap-12">
      <app-party-size-input
        formControlName="partySize"
        (valueChanged)="onPartSizeChanged($event)">
      </app-party-size-input>

      <lib-base-input
        title="Wait time"
        i18n-title="Wait Time Label|Label for estimated wait time@@joinWaitListOverlay.waitTimeLabel">
        <div input>
          @if (waitTimesState.success()) {
            <p class="text-body2 font-bold text-primary-500">
              {{ waitTimesState.response()?.data?.tableWaitTime ?? 'N/A' }} minutes
            </p>
          }
          @if (waitTimesState.loading()) {
            <lib-shimmer class="w-full" type="multiline"></lib-shimmer>
          }
        </div>
      </lib-base-input>
    </div>

    <lib-single-selection-field
      formControlName="occasion"
      [title]="'Select Occasion'"
      i18n-title="Occasion Field Label|Label for selecting occasion@@joinWaitListOverlay.occasionLabel"
      [items]="occasionsState.response()?.data ?? []"
      [display]="'name'"
      value="name"
      [dynamicIconPath]="'icon'"
      [dynamicIconColor]="'iconColor'">
    </lib-single-selection-field>

    <lib-single-selection-field
      formControlName="seatingPreferences"
      [title]="'Select Seating Preferences'"
      i18n-title="Seating Preferences Label|Label for selecting seating preference@@joinWaitListOverlay.seatingLabel"
      [items]="seatingPreferenceState.response()?.data ?? []"
      [display]="'name'"
      value="name"
      [dynamicIconPath]="'icon'"
      [dynamicIconColor]="'iconColor'">
    </lib-single-selection-field>

    <lib-multi-selection-field
      formControlName="tags"
      [title]="'Select Tags'"
      i18n-title="Tags Field Label|Label for selecting tags@@joinWaitListOverlay.tagsLabel"
      [items]="tagsState.response()?.data ?? []"
      [display]="'name'"
      value="name"
      identifier="name"
      [dynamicIconPath]="'icon'"
      [dynamicIconColor]="'iconColor'">
    </lib-multi-selection-field>

    <lib-text-input
      formControlName="note"
      class="mt-2"
      [label]="'Special notes'"
      i18n-label="Special Notes Label|Label for adding special notes@@joinWaitListOverlay.specialNotes"
      type="text">
    </lib-text-input>

    <p class="text-body2 text-black"
       i18n="Cancellation Policy Label|Label for cancellation policy section@@joinWaitListOverlay.cancellationPolicyTitle">
      Cancellation Policy
    </p>
    <p class="text-body2"
       i18n="Cancellation Policy Text|Text explaining cancellation policy@@joinWaitListOverlay.cancellationPolicyText">
      While you won’t be charged if you need to cancel, we ask that you notify us at least 24 hours in advance.
    </p>
    <p class="text-body2" i18n="Agreement Text|Legal agreement text for reservation@@joinWaitListOverlay.agreementText">
      By clicking reserve now, you agree to the Smart Dining LLC User Agreement, Privacy Policy, and Cookie Policy.
    </p>
  </form>

  <lib-button
    class="w-full"
    [fullWidth]="true"
    [loading]="joinWaitListState.loading()"
    (click)="onJoinWaitListClicked()"
    i18n="Join Waitlist Button Label|Button to confirm joining the waitlist@@joinWaitListOverlay.joinButton">
    Join Waitlist
  </lib-button>

</div>
