import {Component, inject, OnInit} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {RestaurantDetailsResponseData} from "../../../data/restaurant-details/restaurant-detail-response";
import {WriteRestaurantReviewRequest} from "../../../data/write-restaurant-review/write-restaurant-review-request";
import {WriteRestaurantReviewResponse} from "../../../data/write-restaurant-review/write-restaurant-review-response";
import {ReviewsService} from "../../../data/reviews.service";
import {
  AppSvgIconComponent,
  BaseOverlayComponent,
  ButtonComponent, RatingInputComponent,
  State, TextAreaInputComponent
} from '@smartdining/lib-sd-web-shared';
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";

@Component({
  selector: 'app-review-overlay',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    ButtonComponent,
    ReactiveFormsModule,
    TextAreaInputComponent,
    BaseOverlayComponent,
    RatingInputComponent
  ],
  templateUrl: './write-review-overlay.component.html',
  styleUrl: './write-review-overlay.component.scss'
})
export class WriteReviewOverlayComponent extends BaseComponent implements OnInit {

  restaurantDetails: RestaurantDetailsResponseData = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);
  formBuilder = inject(FormBuilder);
  reviewsService = inject(ReviewsService);

  reviewForm!: FormGroup;

  writeReviewState = new State<WriteRestaurantReviewResponse>();

  ngOnInit(): void {
    this.initReviewForm();
  }

  private initReviewForm() {
    this.reviewForm = this.formBuilder.group({
      atmosphereRating: [0],
      serviceRating: [0],
      foodRating: [0],
      qualityRating: [0],
      waitTimeRating: [0],
      feedback: [null, [Validators.required]]
    });
  }

  onSubmitPressed() {
    this.validateForm(this.reviewForm);
    if (this.reviewForm.invalid) {
      return;
    }

    const reviewFormValue = this.reviewForm.value;
    const request: WriteRestaurantReviewRequest = {
      restaurantId: this.restaurantDetails._id,
      rating: {
        atmosphereRating: reviewFormValue.atmosphereRating,
        serviceRating: reviewFormValue.serviceRating,
        foodRating: reviewFormValue.foodRating,
        qualityRating: reviewFormValue.qualityRating,
        waitTimeRating: reviewFormValue.waitTimeRating,
      },
      feedback: reviewFormValue.feedback,
      followUp: false
    };

    this.executeRequest({
      state: this.writeReviewState,
      request: this.reviewsService.writeRestaurantReview(request),
      successMessage: 'Thank you for your review! We appreciate your feedback and value your opinion.',
      onSuccess: response => {
        this.dialogRef.close(RatingActionStatus.success);
      }
    });
  }

  onCancelClicked() {
    this.dialogRef.close();
  }
}

export enum RatingActionStatus {
  success,
  failed
}
