<div class="flex flex-col h-dvh px-4 md:px-6 bg-sky-100">
  <div class="grow mx-1 sm:mx-0 py-24 md:py-44 lg:py-52 flex justify-center items-center">

    <img class="md:block absolute top-[10px] md:top-0 right-0" src="assets/images/home/food-1.png" alt="food-1" width="30%">
    <img class="md:block absolute bottom-0 right-0" src="assets/images/home/food-2.png" alt="food-2" width="30%">
    <img class="md:block absolute bottom-0 left-0" src="assets/images/home/food-3.png" alt="food-3" width="18%">

    <div class="flex flex-col items-center gap-4 md:gap-6 mx-10 md:mx-20 lg:mx-20 relative">

      <p class="text-center text-h6 md:text-h3 max-w-3xl"
         i18n="Hero Headline|Main homepage title@@homeHero.headline">
        Discover the best restaurants, cafes, and bars in your area
      </p>

      <p class="hidden md:inline text-neutral-500 text-body1 text-center max-w-3xl"
         i18n="Hero Subtext|Subtext below the main headline@@homeHero.subtext">
        Smart Dining helps you find top restaurants, book tables, order online, and enjoy exclusive deals effortlessly.
      </p>

      <div class="flex items-center rounded-full w-full bg-white px-1.5 py-1.5">
        <lib-svg-icon src="assets/icons/location.svg" class="text-orange-500 px-3"></lib-svg-icon>
        <input #addressInputField type="text" name=""
               placeholder="Type address or zipcode"
               i18n-placeholder="Search Placeholder|Placeholder in address input@@homeHero.searchPlaceholder"
               class="block border-transparent focus:outline-0 focus:border-transparent focus:ring-0 flex-grow py-6 pr-6 placeholder-neutral-500 bg-white rounded-full sm:py-5 h-[36px]" />
        <div class="flex right-1.5 inset-y-1.5 items-center gap-3 px-3">
          <div class="flex items-center justify-center gap-2 cursor-pointer" (click)="onGpsClicked()">
            <lib-svg-icon src="assets/icons/gps.svg" class="text-neutral-400" [size]="16"></lib-svg-icon>
            <p class="hidden md:inline text-neutral-400"
               i18n="Locate Button Text|Button label next to GPS icon@@homeHero.locateText">Locate</p>
          </div>
        </div>
      </div>

      <lib-button size="large" class="w-full" appearance="primaryRounded" [fullWidth]="true"
                  iconSrc="assets/icons/search.svg" (click)="onFindRestaurantsClicked()"
                  i18n="Find Restaurants Button|Main call-to-action on homepage@@homeHero.findRestaurantsButton">
        Find Restaurants
      </lib-button>

    </div>
  </div>

  @if (platform.ANDROID || platform.IOS) {
    <div class="flex flex-col items-center py-10 px-10 gap-4">
      <p class="text-h6 text-neutral-900 text-center"
         i18n="App Prompt Title|Prompt to download the app on mobile@@homeHero.appPromptTitle">
        Enhance Your Experience! 🚀
      </p>
      <p class="text-caption text-neutral-800 text-center"
         i18n="App Prompt Subtext|Subtext encouraging app download@@homeHero.appPromptSubtext">
        For a smoother and more convenient experience, download our app today!
      </p>

      <div class="gap-3 flex flex-col items-center">
        @if (platform.ANDROID) {
          <a [href]="Constants.userAppAndroidDownloadLink" target="_blank">
            <img src="assets/images/home/google.png" alt="Download on Google Play" class="w-28">
          </a>
        }
        @if (platform.IOS) {
          <a [href]="Constants.userAppIOSDownloadLink" target="_blank">
            <img src="assets/images/home/apple.png" alt="Download on App Store" class="w-28">
          </a>
        }
      </div>
    </div>
  }

  <div class="hidden md:flex gap-5 md:flex-row items-center justify-between py-5 bg-white">
    <img src="assets/icons/deco-left.svg" alt="">
    <p class="text-base md:text-base lg:text-lg"
       i18n="Trusted Tagline|Line about restaurant partnerships@@homeHero.trustedTagline">
      Your local favorite restaurants are on Smart Dining
    </p>
    <img src="assets/images/home/stuff.png" alt="">
    <img src="assets/images/home/austin.png" alt="">
    <img src="assets/icons/deco-left.svg" alt="">
  </div>
</div>
