<div class="container mx-auto py-10 bg-white sm:pt-16 md:pt-24">
  <div class="px-4 mx-auto sm:px-6 md:px-8">
    <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-12 gap-y-12 gap-x-8 xl:gap-x-12">

      <div class="col-span-2 md:col-span-4 xl:pr-8 flex flex-col gap-2">
        <div class="flex items-center gap-3">
          <img class="w-auto h-9" src="assets/images/home/logo-white.png" alt="Logo" />
          <p class="text-h6 text-neutral-800" i18n="Company Name|Smart Dining footer label@@footer.companyName">
            Smart Dining
          </p>
        </div>

        <p class="text-body2 text-neutral-500 mt-2"
           i18n="Footer Description|Short brand tagline@@footer.tagline">
          Save Big on Every Dining Experience with A Technology You Will Love
        </p>
      </div>

      <div class="lg:col-span-2">
        <div class="flex flex-col text-subtitle2 text-neutral-800 hover:text-primary-500">
          <p class="font-semibold text-neutral-900" i18n="Company Header@@footer.companySection">Company</p>
          <div class="mt-6 gap-5 flex flex-col">
            <div routerLink="/contact-us">
              <app-footer-link i18n="Contact Link@@footer.contact">Contact</app-footer-link>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:col-span-2">
        <div class="flex flex-col text-subtitle2 text-neutral-800 hover:text-primary-500">
          <p class="font-semibold text-neutral-900" i18n="Help Header@@footer.helpSection">Help</p>
          <div class="mt-6 gap-5 flex flex-col">
            <app-footer-link routeUrl="/terms-and-conditions" i18n="Terms Link@@footer.terms">Terms & Conditions</app-footer-link>
            <div routerLink="/privacy-policy">
              <app-footer-link i18n="Privacy Link@@footer.privacy">Privacy Policy</app-footer-link>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:col-span-2">
        <p class="font-semibold text-neutral-900" i18n="Restaurants Header@@footer.restaurantsSection">For Restaurants</p>
        <div class="flex flex-col text-subtitle2 text-neutral-800 hover:text-primary-500">
          <div class="mt-6 gap-5 flex flex-col">
            <app-footer-link [openInNewTab]="true" url="https://restaurant.gosmartdining.com/request-a-demo"
                             i18n="Request Demo Link@@footer.requestDemo">
              Request a Demo
            </app-footer-link>
          </div>
        </div>
      </div>

      <div class="lg:col-span-2">
        <p class="font-semibold text-neutral-900" i18n="Download Header@@footer.downloadSection">Download</p>
        <div class="flex flex-col text-subtitle2 text-neutral-800 hover:text-primary-500 focus:text-primary-500">
          <div class="mt-6 gap-5 flex flex-col">
            <a [href]="Constants.userAppAndroidDownloadLink" target="_blank">
              <img src="assets/images/home/google.png" alt="Download on Google Play" class="w-28" />
            </a>
            <a [href]="Constants.userAppIOSDownloadLink" target="_blank">
              <img src="assets/images/home/apple.png" alt="Download on Apple Store" class="w-28" />
            </a>
          </div>
        </div>
      </div>

    </div>

    <hr class="mt-16 mb-10 border-neutral-200" />

    <div class="sm:flex sm:items-center sm:justify-between">
      <p class="text-subtitle2 text-neutral-600"
         i18n="Copyright Footer@@footer.copyright">
        © Copyright, All Rights Reserved by Smart Dining ™
      </p>

      <div class="flex items-center gap-5 pt-2">
        <a href="https://www.facebook.com/mysmartdining/" target="_blank">
          <lib-svg-icon src="assets/images/home/facebook.svg" [size]="18" alt="facebook" />
        </a>
        <a href="https://www.instagram.com/gosmartdining/" target="_blank">
          <lib-svg-icon src="assets/images/home/instagram.svg" [size]="18" alt="instagram" />
        </a>
        <a href="https://twitter.com/smartdiningapp/" target="_blank">
          <lib-svg-icon src="assets/images/home/twitter.svg" [size]="18" alt="twitter" />
        </a>
        <a href="https://www.youtube.com/@gosmartdining" target="_blank">
          <lib-svg-icon src="assets/images/home/youtube.svg" [size]="18" alt="youtube" />
        </a>
        <a href="https://www.tiktok.com/@smartdining" target="_blank">
          <lib-svg-icon src="assets/images/home/tiktok.svg" [size]="18" alt="tiktok"/>
        </a>
      </div>
    </div>
  </div>
</div>
