import {Component, inject, input, OnInit, output} from '@angular/core';
import {SignupInfo} from "../signup-overlay/signup-overlay.component";
import {ButtonComponent, OutlineTextInputComponent, State} from "@smartdining/lib-sd-web-shared";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {AuthService} from "../../../data/auth.service";
import {VerifySignupEmailOtpRequest} from "../../../data/verify-signup-email-otp/verify-signup-email-otp-request";
import {BaseComponent} from "@core/base/base-component";
import {VerifySignupEmailOtpResponse} from "../../../data/verify-signup-email-otp/verify-signup-email-otp-response";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {AuthenticatedUserResponse} from "../../../data/authenticated-user/authenticated-user-response";

@Component({
  selector: 'app-verify-signup-email-overlay',
  imports: [
    ButtonComponent,
    FormsModule,
    OutlineTextInputComponent,
    ReactiveFormsModule
  ],
  templateUrl: './verify-signup-email-overlay.component.html',
  styleUrl: './verify-signup-email-overlay.component.scss'
})
export class VerifySignupEmailOverlayComponent extends BaseComponent implements OnInit {
  signupInfo = input<SignupInfo | null>();
  onSignupEmailVerified = output();

  formBuilder = inject(FormBuilder);
  authService = inject(AuthService);
  authStoreService = inject(AuthStoreService);

  verifySignupEmailOtpState = new State<VerifySignupEmailOtpResponse>();
  authenticatedUserState = new State<AuthenticatedUserResponse>();

  verifySignupEmailOtpForm!: FormGroup;

  ngOnInit(): void {
    this.initSignupEmailOtpVerificationForm();
  }

  private initSignupEmailOtpVerificationForm() {
    this.verifySignupEmailOtpForm = this.formBuilder.group({
      otp: [null, [Validators.required]]
    });
  }

  onEmailOtpVerificationClicked() {
    this.verifySignupEmailOtp();
  }

  verifySignupEmailOtp() {
    let request: VerifySignupEmailOtpRequest = {
      email: this.signupInfo()?.email ?? '',
      otp: this.verifySignupEmailOtpForm.value.otp
    };

    this.executeRequest<VerifySignupEmailOtpResponse>({
      state: this.verifySignupEmailOtpState,
      request: this.authService.verifySignupEmailOtp(request),
      onSuccess: response => {
        this.handleLogin(response);
      }
    });
  }

  private handleLogin(response: VerifySignupEmailOtpResponse) {
    this.authStoreService.saveToken(response.data.refreshToken, response.data.token);
    this.executeRequest<AuthenticatedUserResponse>({
      state: this.authenticatedUserState,
      request: this.authService.getAuthenticatedUser(),
      onSuccess: response => {
        if (response.data.userType == 'USER_TYPE_USER') {
          this.authStoreService.saveAppUser(response);
          this.onSignupEmailVerified.emit();
        } else {
          this.authStoreService.logout();
          this.toasterService.error('Invalid credentials, kindly use a valid credentials');
        }
      }
    });
  }
}
