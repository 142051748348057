import {ApplicationConfig, ErrorHandler, importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {
  InMemoryScrollingFeature,
  InMemoryScrollingOptions,
  provideRouter, withComponentInputBinding,
  withInMemoryScrolling, withViewTransitions
} from '@angular/router';

import {routes} from './app.routes';
import {provideClientHydration, withEventReplay} from '@angular/platform-browser';
import {provideAngularSvgIcon} from "angular-svg-icon";
import {provideHttpClient, withFetch, withInterceptors} from "@angular/common/http";
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {getPerformance, providePerformance} from '@angular/fire/performance';
import {environment} from "../environments/environment";
import {httpTokenInterceptor} from "./core/base/api/interceptors/http-token.interceptor";
import {provideNgxStripe} from "ngx-stripe";
import {provideNgxMask} from "ngx-mask";
import {provideNativeDateAdapter} from "@angular/material/core";

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig | any = {
  providers: [
    provideRouter(routes, withComponentInputBinding(), withViewTransitions()),
    provideZoneChangeDetection({eventCoalescing: true}),
    provideAnimationsAsync(),
    provideClientHydration(withEventReplay()),
    provideHttpClient(withFetch()),
    provideHttpClient(withInterceptors([httpTokenInterceptor])),
    provideAngularSvgIcon(),
    provideNgxStripe(environment.stripePublishableKey),
    provideNativeDateAdapter(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    provideNativeDateAdapter(),
    provideNgxMask(),
    ScreenTrackingService,
    UserTrackingService,
  ]
};
