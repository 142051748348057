import {Injectable} from '@angular/core';
import {BaseApiService} from "@core/base/base-api.service";
import {DineInRestaurantSearchRequest} from "./restaurant-search/dine-in-restaurant-search-request";
import {RestaurantSearchResponse} from "./restaurant-search/restaurant-search-response";
import {URLs} from "../../../core/base/urls";
import {CuisinesResponse} from "./cuisines/cuisines-response";
import {RestaurantDetailResponse} from "./restaurant-details/restaurant-detail-response";
import {RestaurantAvailabilitiesRequest} from "./restaurant-availabilities/restaurant-availabilities-request";
import {TableAvailabilitiesResponse} from "../../booking/data/table-availabilities/table-availabilities-response";
import {TableAvailabilitiesRequest} from "../../booking/data/table-availabilities/table-availabilities-request";
import {FavoriteRestaurantRequest} from "./favourite-restaurant/favorite-restaurant-request";
import {RestaurantPhotoGalleryResponse} from "./photo-gallery/restaurant-photo-gallery-response";
import {PhotoGalleryRequest} from "./photo-gallery/photo-gallery-request";
import {RestaurantSuggestionResponse} from "./restaurant-suggestion/restaurant-suggestion-response";
import {RestaurantSuggestionRequest} from "./restaurant-suggestion/restaurant-suggestion-request";
import {RestaurantAvailabilitiesResponse} from "./restaurant-availabilities/restaurant-availabilities-response";

@Injectable({
  providedIn: 'root'
})
export class RestaurantService extends BaseApiService {
  getRestaurants(request: DineInRestaurantSearchRequest) {
    return this.post<RestaurantSearchResponse>(URLs.getRestaurants, request);
  }

  getRestaurantAvailabilities(request: RestaurantAvailabilitiesRequest) {
    return this.post<RestaurantAvailabilitiesResponse>(URLs.getRestaurantAvailabilities, request);
  }

  getRestaurantDetails(restaurantId: string) {
    return this.get<RestaurantDetailResponse>(URLs.getRestaurantDetails(restaurantId));
  }

  getCuisines() {
    return this.get<CuisinesResponse>(URLs.getCuisines);
  }

  getPhotoGallery(restaurantId: string, page: number, size: number) {
    const url = URLs.getPhotoGallery(restaurantId);
    const queryParams = {page, size};
    return this.get<RestaurantPhotoGalleryResponse>(url, queryParams);
  }

  suggestRestaurants(request: RestaurantSuggestionRequest) {
    return this.post<RestaurantSuggestionResponse>(URLs.suggestRestaurants, null, request);
  }

}
