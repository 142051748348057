<div class="grid grid-cols-1 gap-y-8 lg:grid-cols-2 gap-x-16">
  <div class="flex flex-col gap-3 items-">
    <p class="text-neutral-800 text-body1 font-medium">Reviews</p>
    <p class="text-primary-500 text-body1 font-medium">Customer reviews & ratings</p>
    <div class="flex gap-3 items-center">
      <div class="flex gap-1">
        <app-individual-rating [readonly]="true" [rating]="review()?.rating ?? 0"></app-individual-rating>
      </div>
      <p class="text-body2">({{ review()?.rating }} out of 5)</p>
    </div>
    <p class="text-neutral-600 text-body2">Based on {{ review()?.ratingCount }} reviews</p>
  </div>

  <div class="flex flex-col gap-3">
    @for (star of [5, 4, 3, 2, 1]; track $index) {
      <div class="grid items-center grid-cols-5 gap-x-4">
        <span class="text-body2 text-neutral-700 whitespace-nowrap"> {{ star }} stars </span>
        <div class="col-span-3 relative w-full bg-neutral-200 rounded-full h-1.5">
          <div class="absolute inset-y-0 left-0 bg-neutral-900 rounded-full"
               [style.width.%]="getPercentage(star)"></div>
        </div>
        <span class="text-body2 text-neutral-700"> {{ getStarRatingCount(star) }}  </span>
      </div>
    }
  </div>
</div>

