import {
  CategoryInfo,
  FoodItem, FoodItemModifier, FoodItemModifierGroup,
  FoodItemVisibility,
  FoodMenu,
  MenuCategory,
  MenuVisibility
} from "../data/menu/menu";
import {
  MenuResponseData,
  MenuResponseFoodItem,
  MenuResponseFoodItemPrice,
  MenuResponseFoodItemSizePrice,
  MenuResponseMenuCategory,
  MenuResponseMenuVisibility,
  MenuResponseModifierGroup, MenuResponseModifierItem
} from "../data/menu/menu-response";
import {Image} from "../data/menu/food-menu/image";
import {Price, SizePrice} from "../data/menu/food-menu/price";

export class MenuResponseToMenuUtil {
  static convert(menuResponse: MenuResponseData): FoodMenu {
    let foodMenu = new FoodMenu();
    foodMenu.id = menuResponse._id
    foodMenu.enable = menuResponse.enable;
    foodMenu.name = menuResponse.name;
    foodMenu.description = menuResponse.description;
    foodMenu.image = this.getImage(foodMenu.image);
    foodMenu.visibility = this.getMenuVisibility(menuResponse.visibility);
    foodMenu.categories = this.getCategories(menuResponse, menuResponse.categories);
    return foodMenu;
  }

  static getImage(image: Image | undefined): Image {
    let _image = new Image();
    _image.url = image?.url;
    _image.publicId = image?.publicId;
    return _image;
  }

  private static getMenuVisibility(visibility: MenuResponseMenuVisibility | undefined): MenuVisibility {
    let menuVisibility = new MenuVisibility();
    menuVisibility.dineIn = visibility?.dineIn;
    menuVisibility.kiosk = visibility?.kiosk;
    menuVisibility.pickup = visibility?.pickup;
    menuVisibility.reservation = visibility?.reservation;
    menuVisibility.partner = visibility?.partner;
    menuVisibility.catering = visibility?.catering;
    menuVisibility.waitlist = visibility?.waitlist;
    return menuVisibility;
  }

  private static getCategories(menuResponse: MenuResponseData, categories: MenuResponseMenuCategory[]): MenuCategory[] {
    let _categories: MenuCategory[] = [];
    for (const category of categories) {
      let _category = new MenuCategory();
      _category.category = this.getCategoryInfo(category);
      _category.menuItems = this.getMenuItems(menuResponse, category, category.menuItems);

      _categories.push(_category);
    }
    return _categories;
  }

  private static getCategoryInfo(category: MenuResponseMenuCategory): CategoryInfo {
    let categoryInfo = new CategoryInfo();
    categoryInfo._id = category?._id ?? '';
    categoryInfo.name = category?.name ?? '';
    categoryInfo.description = category?.description ?? '';
    categoryInfo.enable = category?.enable ?? false;
    categoryInfo.createdInTestMode = category?.createdInTestMode ?? false;
    categoryInfo.createdDate = category?.createdDate;
    categoryInfo.restaurant = category?.restaurant ?? '';
    categoryInfo.companyUID = category?.companyUID ?? '';
    return categoryInfo;
  }


  private static getMenuItems(menuResponse: MenuResponseData, category: MenuResponseMenuCategory, menuItems: MenuResponseFoodItem[]): FoodItem[] {
    let items: FoodItem[] = [];
    for (const menuItem of menuItems ?? []) {
      let item = new FoodItem();
      item.image = this.getImage(menuItem.image);
      item.price = this.getPrice(menuItem.price);
      item._id = menuItem._id;
      item.menuId = menuResponse._id;
      item.menuCategoryId = category._id;
      item.name = menuItem.name;
      item.description = menuItem.description;
      item.enable = menuItem.enable;
      item.createdInTestMode = menuItem.createdInTestMode;
      item.prepTimeMinutes = menuItem.prepTimeMinutes;
      item.tags = menuItem.tags;
      item.rating = menuItem.rating;
      item.ratingCount = menuItem.ratingCount;
      item.modifierGroups = this.getModifierGroups(menuItem.modifierGroups);
      items.push(item);
    }
    return items;
  }


  private static getFoodItemVisibility(visibility: FoodItemVisibility | undefined): FoodItemVisibility {
    let foodItemVisibility = new FoodItemVisibility();
    foodItemVisibility.inherited = visibility?.inherited;
    foodItemVisibility.dineIn = visibility?.dineIn;
    foodItemVisibility.pickup = visibility?.pickup;
    foodItemVisibility.partner = visibility?.partner;
    foodItemVisibility.catering = visibility?.catering;
    foodItemVisibility.pos = visibility?.pos;
    foodItemVisibility.pickup = visibility?.pickup;
    foodItemVisibility.kiosk = visibility?.kiosk;
    foodItemVisibility.takeout = visibility?.takeout;
    foodItemVisibility.reservation = visibility?.reservation;

    return foodItemVisibility;
  }

  private static getPrice(price: MenuResponseFoodItemPrice | undefined): Price {
    let itemPrice = new Price();
    itemPrice.type = price?.type;
    itemPrice.basePrice = price?.basePrice;
    itemPrice.sizePrices = this.getSizePrices(price?.sizePrices);

    return itemPrice;
  }

  private static getSizePrices(sizePrices: MenuResponseFoodItemSizePrice[] | undefined): SizePrice[] {
    let _sizePrices: SizePrice[] = [];
    for (let sizePrice of sizePrices ?? []) {
      let _sizePrice = new SizePrice();
      _sizePrice.size = sizePrice.size;
      _sizePrice.price = sizePrice.price;
      _sizePrice.isDefault = sizePrice.isDefault;

      _sizePrices.push(_sizePrice);
    }

    return _sizePrices;
  }

  private static getModifierGroups(modifierGroups: MenuResponseModifierGroup[]): FoodItemModifierGroup[] {
    let _modifierGroups: FoodItemModifierGroup[] = [];
    for (const modifierGroup of modifierGroups) {
      let _modifierGroup = new FoodItemModifierGroup();
      _modifierGroup._id = modifierGroup._id;
      _modifierGroup.name = modifierGroup.name;
      _modifierGroup.description = modifierGroup.description;
      _modifierGroup.isRequired = modifierGroup.isRequired;
      _modifierGroup.selection = modifierGroup.selection;
      _modifierGroup.groupPrepTimeMinutes = modifierGroup.groupPrepTimeMinutes;
      _modifierGroup.createdInTestMode = modifierGroup.createdInTestMode;
      _modifierGroup.restaurant = modifierGroup.restaurant;
      _modifierGroup.companyUID = modifierGroup.companyUID;
      _modifierGroup.modifierItems = MenuResponseToMenuUtil._getModifierItems(modifierGroup.modifierItems ?? []);
      _modifierGroups.push(_modifierGroup);
    }
    return _modifierGroups;
  }

  private static _getModifierItems(modifierItems: MenuResponseModifierItem[]) {
    let _modifierItems: FoodItemModifier[] = [];

    for (const modifierItem of modifierItems) {
      let _modifierItem = new FoodItemModifier();
      _modifierItem._id = modifierItem._id;
      _modifierItem.name = modifierItem.item.name;
      _modifierItem.type = modifierItem.item.price.type;
      _modifierItem.price = modifierItem.item.price.basePrice;
      _modifierItem.prepTimeMinutes = modifierItem.item.prepTimeMinutes;

      _modifierItems.push(_modifierItem);
    }
    return _modifierItems;
  }


}
