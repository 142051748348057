<div class="flex flex-col items-center py-10 px-10 gap-4">
  <p class="text-h6 text-neutral-900 text-center">Enhance Your Experience! 🚀</p>
  <p class="text-caption text-neutral-800 text-center">For a smoother and more convenient experience, download our app today!</p>

  <div class="mt-6 gap-3 flex flex-col items-center">
    @if (platform.ANDROID) {
      <a [href]="Constants.userAppAndroidDownloadLink" target="_blank">
        <img src="assets/images/home/google.png" alt="" class="w-28">
      </a>
    }
    @if (platform.IOS) {
      <a [href]="Constants.userAppIOSDownloadLink" target="_blank">
        <img src="assets/images/home/apple.png" alt="" class="w-28">
      </a>
    }
    <lib-button appearance="textType" (click)="onSkipForNowClick()">Skip for now</lib-button>
  </div>

</div>
