import { Injectable } from '@angular/core';
import {URLs} from "../../../core/base/urls";
import {BaseApiService} from "@core/base/base-api.service";
import {UpdateProfileRequestData} from "../../auth/data/update-profile-request/update-profile-request";

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseApiService{

  updateProfileDetails(userId: string, request: any) {
    return this.put<UpdateProfileRequestData>(URLs.updateProfileDetails(userId), request);
  }

}
