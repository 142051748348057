<mat-dialog-content>
  <div class="flex flex-col gap-2 text-start">

    <p class="text-body1 text-error-500"
       i18n="Cancel Reservation Title|Heading for cancel confirmation dialog@@cancelReservationOverlay.title">
      Cancel Reservation
    </p>

    <p class="text-body2"
       i18n="Cancel Confirmation Question|Prompt asking user to confirm cancellation@@cancelReservationOverlay.confirmationText">
      Are you sure, do you want to cancel the reservation?
    </p>

    <div class="flex justify-end gap-4 mt-3">
      <lib-button appearance="outline"
                  (click)="onNoClicked()"
                  i18n="No Button Label|Button to dismiss cancel dialog@@cancelReservationOverlay.noButton">
        No
      </lib-button>

      <lib-button buttonColor="bg-error-500"
                  (click)="onYesClicked()"
                  i18n="Yes Button Label|Button to confirm reservation cancellation@@cancelReservationOverlay.yesButton">
        Yes
      </lib-button>
    </div>
  </div>
</mat-dialog-content>











