<div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6">
  <p class="text-body1 font-medium">Orders</p>
  <div class="my-2">
    <app-orders-tab [isPastOrder]="true"></app-orders-tab>
  </div>
@if (orderState.loading()) {
  <div class="flex flex-col gap-5">
    <lib-shimmer></lib-shimmer>
    <lib-shimmer></lib-shimmer>
    <lib-shimmer></lib-shimmer>
  </div>
}
<div class="flex flex-col gap-5">
  @for (order of orderState.response()?.data; track order) {
    <app-orders-card (click)="onCompletedOrdersClicked(order)" [order]="order"></app-orders-card>
  }
</div>
@if (orderState.success() && (orderState.response()?.data?.length ?? 0) <= 0) {
  <div class="flex flex-col w-full h-full justify-center items-center">
    <lib-no-data class="self-center py-2" message="No Orders found"></lib-no-data>
  </div>
}
</div>




<!--hard coded -->


<!--<div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6">-->
<!--  <div class="flex gap-5 shadow-1 bg-white border border-neutral-200 rounded-xl overflow-hidden cursor-pointer"-->
<!--       (click)="onOrderInformationClicked()">-->
<!--    <img class="h-auto w-32 object-cover" src="../../../../../../assets/images/background/restaurant-placeholder.png">-->
<!--    <div class="flex flex-grow py-3 pr-4">-->
<!--      <div class="flex flex-col flex-grow gap-2">-->
<!--        <div class="flex gap-3 items-center">-->
<!--          <p class="text-body2 text-primary-500">Still Whiskey</p>-->
<!--          <div class="flex-grow"></div>-->
<!--          <p>ASAP</p>-->
<!--        </div>-->
<!--        <p class="text-body2 text-neutral-400">-->
<!--          Number 12 USA-->
<!--        </p>-->
<!--        <div class="flex items-center gap-4">-->
<!--          <div class="flex items-center gap-2">-->
<!--            <lib-svg-icon src="assets/icons/calendar.svg" [size]="16" class="text-primary-500"></lib-svg-icon>-->
<!--            <p class="text-caption text-neutral-600">16/5/2024</p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="flex items-center">-->
<!--          <p class="rounded-md px-3 py-2 text-caption bg-opacity-5">ASAP</p>-->
<!--          <div class="flex-grow"></div>-->
<!--          <div class="flex items-center gap-4">-->
<!--            <div class="flex justify-center items-center h-10 w-10 rounded-full hover:bg-neutral-50 cursor-pointer">-->
<!--              <lib-svg-icon src="assets/icons/phone.svg" class="text-blue-500"></lib-svg-icon>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

