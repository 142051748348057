<mat-dialog-content>
  <div class="flex flex-col gap-2 text-start">

    <p class="text-body1 text-error-500"
       i18n="Delete Address Title|Title shown when confirming deletion of an address@@deleteAddress.title">
      Delete Address
    </p>

    <p class="text-body2"
       i18n="Delete Address Confirmation|Confirmation question before address is deleted@@deleteAddress.confirmation">
      Are you sure, do you want to delete this address?
    </p>

    <div class="flex justify-end gap-4 mt-3">
      <lib-button
        appearance="outline"
        (click)="onNoClicked()"
        i18n="Cancel Button|Cancel deletion and close dialog@@deleteAddress.cancelButton">
        Cancel
      </lib-button>

      <lib-button
        buttonColor="bg-error-500"
        [loading]="state.loading()"
        (click)="onYesClicked()"
        i18n="Delete Button|Confirm deletion of the address@@deleteAddress.deleteButton">
        Delete
      </lib-button>
    </div>

  </div>
</mat-dialog-content>

