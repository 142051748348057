import {Component, inject, OnInit, output} from '@angular/core';
import {ButtonComponent, OutlineTextInputComponent, State} from "@smartdining/lib-sd-web-shared";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ForgotPasswordResponse} from "../../../data/forgot-password/forgot-password-response";
import {AuthService} from "../../../data/auth.service";
import {BaseComponent} from "@core/base/base-component";

@Component({
  selector: 'app-forgot-password-overlay',
  imports: [
    ButtonComponent,
    ReactiveFormsModule,
    OutlineTextInputComponent
  ],
  templateUrl: './forgot-password-overlay.component.html',
  styleUrl: './forgot-password-overlay.component.scss'
})
export class ForgotPasswordOverlayComponent extends BaseComponent implements OnInit {

  forgotPasswordPerformed = output<{ email: string }>();

  form!: FormGroup;

  forgotPasswordState = new State<ForgotPasswordResponse>();

  private fb = inject(FormBuilder);
  private authService = inject(AuthService);

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  verifyEmailClicked() {
    if (this.form.invalid) {
      return;
    }
    this.executeRequest<ForgotPasswordResponse>({
      state: this.forgotPasswordState,
      request: this.authService.forgotPassword(this.form.value),
      handleSuccess: true,
      onSuccess: response => {
        this.forgotPasswordPerformed.emit({email: this.form.value.email});
      }
    });
  }
}
