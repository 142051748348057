<form class="w-full flex flex-col items-center gap-4" [formGroup]="verifySignupEmailOtpForm">

  <div class="flex gap-2 items-center w-full">
    <p class="text-body2 text-neutral-500"
       i18n="Signup Email OTP Prompt|Prompt to enter the verification code sent to the user's email@@verifySignupEmailOverlay.prompt">
      Enter the verification codes sent to
    </p>
    <p class="text-body2 text-black font-bold">{{ signupInfo()?.email ?? '' }}</p>
  </div>

  <lib-outline-text-input class="w-full"
                          formControlName="otp"
                          type="text"
                          placeholder="_ _ _ _ _ _"
                          label="Email Verification Code"
                          i18n-label="Signup OTP Label|Label for signup email verification code input@@verifySignupEmailOverlay.otpLabel"
                          i18n-placeholder="Signup OTP Placeholder|Placeholder for signup OTP input@@verifySignupEmailOverlay.otpPlaceholder">
  </lib-outline-text-input>

  <div class="w-full flex items-center gap-4 mt-3">
    <lib-button class="w-full"
                type="submit"
                [fullWidth]="true"
                appearance="primary"
                (click)="onEmailOtpVerificationClicked()"
                [loading]="verifySignupEmailOtpState.loading()"
                i18n="Verify Button|CTA to submit email verification code and continue signup@@verifySignupEmailOverlay.verifyButton">
      Verify
    </lib-button>
  </div>

</form>

