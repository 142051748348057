import {Component, input, model, signal} from '@angular/core';
import {NgClass} from "@angular/common";
import {AppSvgIconComponent} from "@smartdining/lib-sd-web-shared";

@Component({
  selector: 'app-setting-menu-item',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    NgClass
  ],
  templateUrl: './setting-menu-item.component.html',
  styleUrl: './setting-menu-item.component.scss'
})
export class SettingMenuItemComponent {
  icon = input();
  title = input();
  description = input();
  isSelected = input(true);
  value = model(true);

  onSettingSelected() {

  }

}
