<div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6">
  <p class="text-body1 font-medium">Orders</p>
  <div class="my-2">
    <app-orders-tab [isActiveOrder]="true"></app-orders-tab>
  </div>
  @if (orderState.loading()) {
    <div class="flex flex-col gap-5">
      <lib-shimmer></lib-shimmer>
      <lib-shimmer></lib-shimmer>
      <lib-shimmer></lib-shimmer>
    </div>
  }
  <div class="flex flex-col gap-5">
    @for (order of orderState.response()?.data; track order) {
      <app-orders-card (click)="onCompletedOrdersClicked(order)" [order]="order"></app-orders-card>
    }
  </div>
  @if (orderState.success() && (orderState.response()?.data?.length ?? 0) <= 0) {
    <div class="flex flex-col w-full h-full justify-center items-center">
      <lib-no-data class="self-center py-2" message="No Orders found"></lib-no-data>
    </div>
  }
</div>
