import {Component, EventEmitter, input, Output} from '@angular/core';
import {AppSvgIconComponent} from "@smartdining/lib-sd-web-shared";

@Component({
  selector: 'app-like-button',
  standalone: true,
  imports: [
    AppSvgIconComponent
  ],
  templateUrl: './like-button.component.html',
  styleUrl: './like-button.component.scss'
})
export class LikeButtonComponent {

  isLiked = input(true);

  @Output()
  onLikeClicked = new EventEmitter();

  @Output()
  onUnLikeClicked = new EventEmitter();

  onLiked() {
    this.onLikeClicked.emit();
  }

  onUnLiked() {
    this.onUnLikeClicked.emit();
  }
}
