import {Injectable} from '@angular/core';
import {BaseApiService} from "@core/base/base-api.service";
import {URLs} from "@core/base/urls";
import {ForgotPasswordResponse} from "./forgot-password/forgot-password-response";
import {AuthenticatedUserResponse} from "./authenticated-user/authenticated-user-response";
import {SendGuestConfirmCodeRequest} from "./send-guest-confirm-code/send-guest-confirm-code-request";
import {VerifyGuestConfirmationCode} from "./verify-guest-confirmation-code/verify-guest-confirmation-code";
import {GenericResponse} from "@core/models/generic-response";
import {AddressListResponse} from "../../settings/data/address-list/address-list-response";
import {ChangePasswordRequest} from "./change-password/change-password-request";
import {LoginWithEmailPasswordRequest} from "./login/login-with-email-password-request";
import {SendPhoneOtpRequest} from "./send-phone-otp/send-phone-otp-request";
import {SendEmailOtpRequest} from "./send-email-otp/send-email-otp-request";
import {LoginWithEmailOtpRequest} from "./login/login-with-email-otp-request";
import {LoginWithPhoneOtpRequest} from "./login/login-with-phone-otp-request";
import {VerifySignupPhoneOtpRequest} from "./verify-signup-phone-otp/verify-signup-phone-otp-request";
import {VerifySignupEmailOtpRequest} from "./verify-signup-email-otp/verify-signup-email-otp-request";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseApiService {

  signUp(request: any) {
    return this.post(URLs.signUp, request);
  }

  verifySignupPhoneOtp(request: VerifySignupPhoneOtpRequest) {
    return this.post(URLs.verifySignupPhoneOtp, request);
  }

  verifySignupEmailOtp(request: VerifySignupEmailOtpRequest) {
    return this.post(URLs.verifySignupEmailOtp, request);
  }


  activateAccount(request: any) {
    return this.post(URLs.activateAccount, request);
  }

  sendEmailOtp(request: SendEmailOtpRequest) {
    return this.post(URLs.sendEmailOtp, request);
  }

  sendPhoneOtp(request: SendPhoneOtpRequest) {
    return this.post(URLs.sendPhoneOtp, request);
  }

  loginWithEmailPassword(request: LoginWithEmailPasswordRequest) {
    return this.post(URLs.login, request);
  }

  loginWithEmailOtp(request: LoginWithEmailOtpRequest) {
    return this.post(URLs.loginWithEmailOtp, request);
  }

  loginWithPhoneOtp(request: LoginWithPhoneOtpRequest) {
    return this.post(URLs.loginWithPhoneOtp, request);
  }

  getRefreshToken() {
    return this.post(URLs.refreshToken, null);
  }

  forgotPassword(request: any) {
    return this.post<ForgotPasswordResponse>(URLs.forgotPassword, request);
  }

  resetPassword(request: any) {
    return this.post(URLs.resetPassword, request);
  }

  getAuthenticatedUser() {
    return this.post<AuthenticatedUserResponse>(URLs.getAuthenticatedUser, {});
  }

  sendGuestConfirmCode(request: SendGuestConfirmCodeRequest) {
    return this.post<GenericResponse>(URLs.sendGuestConfirmCode, request);
  }

  verifyGuestConfirmationCode(request: VerifyGuestConfirmationCode) {
    return this.post<GenericResponse>(URLs.verifyGuestCode, request);
  }

  sendConfirmCode(request: ChangePasswordRequest) {
    return this.post<AddressListResponse>(URLs.sendConfirmCode, request);
  }


}
