import {Component, input} from '@angular/core';
import {NgClass} from "@angular/common";
import {AppSvgIconComponent} from "@smartdining/lib-sd-web-shared";

@Component({
  selector: 'app-individual-rating',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    NgClass
],
  templateUrl: './individual-rating.component.html',
  styleUrl: './individual-rating.component.scss'
})
export class IndividualRatingComponent {
  // rating = input<Rating>();
  readonly = input<boolean>(false);
  rating = input<number | null>(0);
}
