<div class="min-w-72 bg-white p-5">
  <div class="flex flex-col gap-2">
    <p class="text-body2 text-neutral-600" i18n="@@dineInTime.label">Time</p>
    <div class="grid grid-cols-3 w-full gap-2 max-h-60 overflow-y-scroll">
      @for (time of availableTimes; track time) {
        <div class="inline-flex justify-center items-center cursor-pointer" (click)="onTimeSelected(time)">
          <p
            class="text-caption hover:border-primary-500 border-2 border-neutral-200 hover:border-2 p-2 rounded">
            {{ time.timeString12 }}
          </p>
        </div>
      }
    </div>
  </div>
</div>
