<mat-dialog-content class="padding-0">
  <div class="relative mx-0 px-0">

    <google-map
      width="100%"
      [center]="center()"
      [zoom]="zoom()"
      [options]="mapOptions()"
      mapId="googleMap"
      (idle)="onMapPositionChanged()">
    </google-map>

    <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <lib-svg-icon
        src="assets/icons/location-pin.svg"
        [size]="60">
      </lib-svg-icon>
    </div>

    <div class="absolute top-6 inset-x-0">
      <div class="flex items-center bg-white ring-2 ring-blue-500 px-5 mx-3 shadow-3 rounded-xl">
        <lib-svg-icon
          src="assets/icons/location.svg"
          [size]="24"
          class="text-blue-500 px-3">
        </lib-svg-icon>
        <input #addressInputField
               type="text"
               name=""
               placeholder="Search Location"
               i18n-placeholder="Search Placeholder|Placeholder text for address search input@@address.searchPlaceholder"
               class="block border-transparent border-neutral-100 focus:outline-0 focus:border-0 focus:border-transparent focus:ring-0 flex-grow py-6 pr-6 placeholder-neutral-500 bg-white rounded-full sm:py-5"/>
      </div>
    </div>

    <span class="flex h-12 w-12 justify-center items-center shadow-3 rounded-full absolute bottom-4 right-4 bg-blue-500 cursor-pointer"
          (click)="gotoCurrentLocation()">
      <lib-svg-icon
        [size]="18"
        class="text-white"
        src="assets/icons/gps.svg">
      </lib-svg-icon>
    </span>
  </div>

  <div class="flex flex-col gap-3 py-3 px-4">
    <p class="text-primary-500"
       i18n="Address Label|Label above current selected address@@address.currentAddressLabel">
      Address
    </p>
    <div class="flex items-center gap-3 border py-3 px-2 rounded-md">
      <lib-svg-icon src="assets/icons/location.svg" class="text-neutral-500"></lib-svg-icon>
      <p class="text-body2 max-w-max">{{ fullAddress() }}</p>
    </div>
  </div>
</mat-dialog-content>

<div class="border-t">
  <mat-dialog-actions>
    <div class="flex gap-4 justify-end w-full py-2">
      <lib-button
        appearance="outline"
        (click)="onCancelPressed()"
        i18n="Cancel Button|Cancel selecting/updating address@@address.cancelButton">
        Cancel
      </lib-button>
      <lib-button
        appearance="primary"
        (click)="onSelectAddressClicked()"
        i18n="Select Address Button|Confirm selected address@@address.selectAddressButton">
        Select Address
      </lib-button>
    </div>
  </mat-dialog-actions>
</div>
