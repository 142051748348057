import {Component, signal} from '@angular/core';
import {BaseOverlayComponent, OverlayWizard, OverlayWizardUtils} from "@smartdining/lib-sd-web-shared";
import {AuthWizardStep, AuthComponent} from "../../../../auth/presentation/auth.component";
import {ReserveTableOverlayComponent} from "./reserve-table-overlay/reserve-table-overlay.component";

@Component({
  selector: 'app-make-reservation-overlay',
  imports: [
    BaseOverlayComponent,
    AuthComponent,
    ReserveTableOverlayComponent
  ],
  templateUrl: './make-reservation-overlay.component.html',
  styleUrl: './make-reservation-overlay.component.scss'
})
export class MakeReservationOverlayComponent {
  wizards = signal<OverlayWizard[]>([
    {title: 'Complete Your Reservation', key: ReserveWizard.reserve}
  ]);

  wizardUtil?: OverlayWizardUtils;

  onWizardUpdated($event: OverlayWizardUtils) {
    this.wizardUtil = $event;
  }

  onLoginNeeded() {
    this.wizardUtil?.append(AuthWizardStep.login);
  }

  ReserveWizard = ReserveWizard;

}

export enum ReserveWizard {
  reserve = 'reserve'
}
