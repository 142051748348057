import {AfterViewInit, Component, inject, input, OnInit, output, signal} from '@angular/core';
import {LoginOverlayComponent, SignupData} from "./overlays/login-overlay/login-overlay.component";
import {SignupInfo, SignupOverlayComponent} from "./overlays/signup-overlay/signup-overlay.component";
import {
  VerifySignupPhoneOverlayComponent
} from "./overlays/verify-signup-phone-overlay/verify-signup-phone-overlay.component";
import {
  VerifySignupEmailOverlayComponent
} from "./overlays/verify-signup-email-overlay/verify-signup-email-overlay.component";
import {ForgotPasswordOverlayComponent} from "./overlays/forgot-password-overlay/forgot-password-overlay.component";
import {ResetPasswordOverlayComponent} from "./overlays/reset-password-overlay/reset-password-overlay.component";
import {OverlayWizard, OverlayWizardUtils} from "@smartdining/lib-sd-web-shared";
import {DialogRef} from "@angular/cdk/dialog";

@Component({
  selector: 'app-common-auth',
  imports: [
    LoginOverlayComponent,
    SignupOverlayComponent,
    VerifySignupPhoneOverlayComponent,
    VerifySignupEmailOverlayComponent,
    ForgotPasswordOverlayComponent,
    ResetPasswordOverlayComponent
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})
export class AuthComponent implements OnInit, AfterViewInit {
  wizardSteps = input.required<OverlayWizard[]>();

  onWizardUpdated = output<OverlayWizardUtils>();

  dialogRef = inject(DialogRef);

  allWizards = signal<OverlayWizard[]>([]);

  wizardUtil!: OverlayWizardUtils;

  signupData: SignupData | null = null;

  forgotPasswordEmailData: string | null = null;
  signInfoData: SignupInfo | null = null;

  ngOnInit(): void {
    let wizardSteps = this.wizardSteps() ?? [];
    this.allWizards.set([...wizardSteps, ...authWizard()]);
    this.wizardUtil = new OverlayWizardUtils(this.allWizards);
  }

  ngAfterViewInit(): void {
    this.onWizardUpdated.emit(this.wizardUtil);
  }

  onSignupClicked($event: SignupData) {
    this.signupData = $event;
    this.wizardUtil.append(AuthWizardStep.signup);
    this.onWizardUpdated.emit(this.wizardUtil);
  }

  onForgotPasswordClicked() {
    this.wizardUtil.append(AuthWizardStep.forgotPassword);
    this.onWizardUpdated.emit(this.wizardUtil);
  }

  onForgotPasswordPerformed($event: { email: string }) {
    this.forgotPasswordEmailData = $event.email;
    this.wizardUtil.append(AuthWizardStep.resetPassword);
    this.onWizardUpdated.emit(this.wizardUtil);
  }

  onResetPasswordCompleted() {
    this.wizardUtil.closeUntil(AuthWizardStep.login);
    this.onWizardUpdated.emit(this.wizardUtil);
  }

  onSignupPerformed($event: SignupInfo) {
    this.signInfoData = $event;
    this.wizardUtil.append(AuthWizardStep.verifySignupPhone);
    this.onWizardUpdated.emit(this.wizardUtil);
  }

  onSignupPhoneVerified() {
    this.wizardUtil.append(AuthWizardStep.verifySignupEmail);
    this.onWizardUpdated.emit(this.wizardUtil);
  }

  onSignupEmailVerified() {
    this.dialogRef.close(true);
  }

  onLoginSuccessful() {
    this.dialogRef.close(true);
  }

  AuthWizardStep = AuthWizardStep;
}

export enum AuthWizardStep {
  signup = 'signup',
  verifySignupPhone = 'verifySignupPhone',
  verifySignupEmail = 'verifySignupEmail',
  login = 'login',
  forgotPassword = 'forgotPassword',
  resetPassword = 'resetPassword',
  activateAccount = 'activateAccount',
}

export const authWizard = signal<OverlayWizard[]>([
  {title: 'Login', key: AuthWizardStep.login},
  {title: 'Signup', key: AuthWizardStep.signup},
  {title: 'Signup Phone Verification', key: AuthWizardStep.verifySignupPhone},
  {title: 'Signup Email Verification', key: AuthWizardStep.verifySignupEmail},
  {title: 'Forgot Password', key: AuthWizardStep.forgotPassword},
  {title: 'Reset Password', key: AuthWizardStep.resetPassword},
  {title: 'Activate account', key: AuthWizardStep.activateAccount}
]);
