<div class="min-w-72 p-5">
  <div class="flex flex-col gap-3">
    <p class="text-body2 text-neutral-600" i18n="@@partySize.label">Party Size</p>
    <div class="grid grid-cols-4 w-full gap-2 max-h-[250px] overflow-y-scroll">
      @for (partySize of partySizeList(); track $index;) {
        <div class="inline-flex justify-center items-center cursor-pointer"
             (click)="onPartySizeSelected(partySize)">
          <p
            class="text-caption hover:border-primary-500 border-2 border-neutral-200 hover:border-2 py-2 px-4 min-w-14 rounded text-center">
            {{ partySize }}
          </p>
        </div>
      }
    </div>
  </div>
</div>
