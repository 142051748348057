import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-clear-filter',
  standalone: true,
  imports: [],
  templateUrl: './clear-filter.component.html',
  styleUrl: './clear-filter.component.scss'
})
export class ClearFilterComponent {

  @Output()
  clearFilterClicked = new EventEmitter();

  onClearFilterClicked() {
    this.clearFilterClicked.emit();
  }

}
