<div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6 flex justify-center">

  <form [formGroup]="reservationForm" (ngSubmit)="onReserveClicked()" class="flex flex-col p-4 gap-6">

    <div class="flex gap-4 bg-white rounded shadow-1 overflow-hidden">
      <lib-network-image class="h-auto w-32 object-cover"
                         [imageUrl]="restaurantDetailState.response()?.data?.banner?.url">
      </lib-network-image>
      <div class="flex flex-col gap-2 py-4">
        <p class="text-body2 text-primary-500">
          {{ restaurantDetailState.response()?.data?.restaurantName }}
        </p>
        <p class="text-body2 text-neutral-400">
          {{
            arrayToCSV([
              restaurantDetailState.response()?.data?.location?.address1,
              restaurantDetailState.response()?.data?.location?.city,
              restaurantDetailState.response()?.data?.location?.state
            ])
          }}
        </p>
      </div>
    </div>

    <div class="flex flex-col gap-6">
      <div class="flex items-start gap-24">
        <lib-date-selector-input
          formControlName="date"
          [title]="'Date'"
          i18n-title="Booking Date Label|Title for the date picker@@makeReservation.dateTitle"
          [minDate]="minimumBookingDate"
          (valueChanged)="onDateSelected($event)">
        </lib-date-selector-input>
      </div>

      <lib-single-selection-field
        formControlName="time"
        [title]="'Select Time'"
        i18n-title="Select Time Label|Title for time selection field@@makeReservation.timeTitle"
        [items]="availabilities()"
        [display]="'timeString'"
        [itemWidth]="90"
        [maximumDisplayItems]="10"
        [state]="tableAvailabilitiesState"
        noDataMessage="No tables available"
        i18n-noDataMessage="No Tables Message|Message shown when no time slots are available@@makeReservation.noTablesMessage">
      </lib-single-selection-field>

      <lib-single-selection-field
        formControlName="occasion"
        [title]="'Select Occasion'"
        i18n-title="Occasion Label|Title for occasion selection field@@makeReservation.occasionTitle"
        [items]="occasionsState.response()?.data ?? []"
        [display]="'name'"
        value="name"
        [dynamicIconPath]="'icon'"
        [dynamicIconColor]="'iconColor'">
      </lib-single-selection-field>

      <lib-single-selection-field
        formControlName="seatingPreferences"
        [title]="'Select Seating Preferences'"
        i18n-title="Seating Preferences Label|Title for seating preferences field@@makeReservation.seatingTitle"
        [items]="seatingPreferenceState.response()?.data ?? []"
        [display]="'name'"
        value="name"
        [dynamicIconPath]="'icon'"
        [dynamicIconColor]="'iconColor'">
      </lib-single-selection-field>

      <lib-multi-selection-field
        formControlName="tags"
        [title]="'Select Tags'"
        i18n-title="Tags Label|Title for tags selection field@@makeReservation.tagsTitle"
        [items]="tagsState.response()?.data ?? []"
        [display]="'name'"
        value="name"
        identifier="name"
        [dynamicIconPath]="'icon'"
        [dynamicIconColor]="'iconColor'">
      </lib-multi-selection-field>

      <lib-outline-text-input
        formControlName="note"
        class="mt-2"
        viewType="text-area"
        [label]="'Special notes'"
        i18n-label="Special Notes Label|Label for special notes textarea@@makeReservation.notesLabel"
        placeholder="Ex: Needs high chair"
        i18n-placeholder="Special Notes Placeholder|Example for special note input@@makeReservation.notesPlaceholder"
        type="text">
      </lib-outline-text-input>
    </div>

    <p class="text-body2 text-black"
       i18n="Cancellation Policy Title|Label before cancellation policy@@makeReservation.cancellationTitle">
      Cancellation Policy
    </p>

    <p class="text-body2"
       i18n="Cancellation Policy Message|Text explaining cancellation policy@@makeReservation.cancellationMessage">
      While you won’t be charged if you need to cancel, we ask that you notify us at least 24 hours in advance.
    </p>

    <p class="text-body2"
       i18n="Terms Agreement Message|Agreement terms for making a reservation@@makeReservation.termsMessage">
      By clicking reserve now, you agree to the Smart Dining LLC User Agreement, Privacy Policy, and Cookie Policy
    </p>

    <lib-button
      type="submit"
      class="w-full"
      [fullWidth]="true"
      [loading]="reservationState.loading()"
      i18n="Reserve Button Label|Button to confirm reservation@@makeReservation.reserveButton">
      Reserve
    </lib-button>
  </form>
</div>
