<div class="container-fluid mx-auto bg-neutral-100 py-20">
  <div class="container mx-auto px-8 sm:px-16 py-8">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

      <div class="flex justify-center items-center gap-4">
        <div class="flex flex-col gap-5">
          <p class="text-h6 font-bold text-neutral-500"
             i18n="Feature 2 Intro|Small headline above main@@homeFeature2.intro">
            Easy and Fast
          </p>
          <p class="text-h4 text-neutral-800 font-bold"
             i18n="Feature 2 Title|Main headline for ordering section@@homeFeature2.title">
            Order ahead next meals in 3 easy steps
          </p>
          <div class="flex gap-5">
            <img src="assets/images/home/order.svg" alt="Order Ahead" class="w-15">
            <div class="flex flex-col gap-2">
              <p class="text-h6 text-neutral-500"
                 i18n="Step 1 Title@@homeFeature2.step1.title">
                Order Ahead
              </p>
              <p class="text-body1 text-neutral-500"
                 i18n="Step 1 Description@@homeFeature2.step1.description">
                Order ahead takeout from your favorite restaurants.
              </p>
            </div>
          </div>
          <div class="flex gap-5">
            <img src="assets/images/home/waiter.svg" alt="Pay in seconds" class="w-15">
            <div class="flex flex-col gap-2">
              <p class="text-h6 font-bold text-neutral-500"
                 i18n="Step 2 Title@@homeFeature2.step2.title">
                Pay in seconds
              </p>
              <p class="text-body1 text-neutral-500"
                 i18n="Step 2 Description@@homeFeature2.step2.description">
                and save your info for easy re-order
              </p>
            </div>
          </div>
          <div class="flex gap-5">
            <img src="assets/images/home/pickup.svg" alt="Pickup in store" class="w-15">
            <div class="flex flex-col gap-2">
              <p class="text-h6 text-neutral-500"
                 i18n="Step 3 Title@@homeFeature2.step3.title">
                Pickup in store
              </p>
              <p class="text-body1 text-neutral-500"
                 i18n="Step 3 Description@@homeFeature2.step3.description">
                Pick up in store or get delivered and never wait in line.
              </p>
            </div>
          </div>

        </div>
      </div>
      <div class="relative -top-10 py-20">
        <img src="assets/images/home/image-e" alt="Feature 2 Illustration">
      </div>
    </div>
  </div>
</div>

