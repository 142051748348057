<!--  dine in and pickup Order for mobile view-->
<!--<div class="fixed bottom-0 z-50 lg:hidden py-3 bg-white shadow-1 w-full">-->
<!--  <div class="flex justify-around">-->
<!--    <div class="flex items-center gap-3 cursor-pointer px-6 py-4 rounded-full"-->
<!--         [ngClass]="selectedDineInOption() == DineInOption.dineIn ? 'bg-neutral-100 text-primary-500' : 'bg-white text-primary-500'"-->
<!--         (click)="onDineOptionSelected(DineInOption.dineIn)">-->
<!--      <lib-svg-icon src="assets/icons/dine-in.svg"-->
<!--                    [ngClass]="selectedDineInOption() == DineInOption.dineIn ? 'text-primary-500' : 'text-neutral-500'">-->
<!--      </lib-svg-icon>-->
<!--      <p class="text-body2">Dine In</p>-->
<!--    </div>-->
<!--    <div class="flex items-center gap-3 cursor-pointer px-6 py-4 rounded-full"-->
<!--         [ngClass]="selectedDineInOption() == DineInOption.pickupOrder ? 'bg-neutral-100 text-primary-500' : 'bg-white text-neutral-500'"-->
<!--         (click)="onDineOptionSelected(DineInOption.pickupOrder)">-->
<!--      <lib-svg-icon src="assets/icons/food-orders.svg"-->
<!--                    [ngClass]="selectedDineInOption() == DineInOption.pickupOrder ? 'text-primary-500' : 'text-neutral-500'">-->
<!--      </lib-svg-icon>-->
<!--      <p class="text-body2">Pickup Order</p>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<div class="fixed bottom-0 z-40 md:hidden py-4 bg-white shadow-1 border-t border-neutral-200 w-full">
  <div class="flex justify-around">
    <lib-button appearance="textType" [fullWidth]="true" iconSrc="assets/icons/clock.svg"
                (click)="onMobileJoinWaitListClicked()">Join Waitlist
    </lib-button>
    <lib-button appearance="primaryRounded" [fullWidth]="true" (click)="onMobileReserveTableClicked()">Reserve Table
    </lib-button>
  </div>
</div>

<div class="relative container-fluid lg:container mx-auto px-4 py-6 space-y-6">

  <lib-breadcrumb></lib-breadcrumb>

  <div class="grid grid-cols-7 gap-5">
    <div class="col-span-7 h-[200px] md:h-[400px] rounded-xl md:rounded-3xl overflow-hidden relative">
      <div class="flex items-center gap-4 w-full h-full">
        <lib-network-image class="w-full h-full object-cover"
                           [placeholderImage]="'assets/images/background/restaurant-placeholder.png'"
                           [imageUrl]="restaurantDetailState.response()?.data?.banner?.url">
        </lib-network-image>
      </div>
      <div class="flex items-center gap-2">
        @if (isPhotosAvailable()) {
          <button (click)="onViewPhotosClicked()"
                  class="absolute bottom-4 right-4 py-2 bg-white px-4 rounded-lg flex items-center space-x-2">
            <!-- <lib-svg-icon src="assets/icons/gallery.svg" [size]="15"></lib-svg-icon> -->
            <span class="text-body2"
                  i18n="See All Photos|CTA to open restaurant photo gallery@@restaurantDetails.seeAllPhotos">
            See all Photos
          </span>
          </button>
        }
      </div>
    </div>
  </div>

  <!--  -------------------- -->

  <div class="grid grid-cols-6 gap-4 w-full relative">

    <div class="col-span-6 md:col-span-4 space-y-4 flex-grow sm:space-y-8">

      @if (restaurantDetailState.loading() && restaurantDetailState.initialLoading()) {
        <div class="flex flex-col gap-5">
          <lib-shimmer type="list"></lib-shimmer>
          <lib-shimmer type="list"></lib-shimmer>
          <lib-shimmer type="list"></lib-shimmer>
        </div>
      }
      <!--dineIn and foodOrder chip-->
      @if (restaurantDetailState.success() || restaurantDetailState.loading()) {
        <div class="flex flex-col gap-5 py-3 col-span-3">
          <div class="flex flex-col sm:flex-row sm:items-center justify-between gap-2">
            <div class="flex flex-col gap-4">
              <div class="flex items-center gap-4">
                <p
                  class="text-neutral-800 text-h6 font-medium">{{ restaurantDetailState.response()?.data?.restaurantName }}</p>

                <span class="h-1 w-1 rounded-full bg-neutral-600"></span>

                <p
                  class="text-caption">{{ numberToPricePoint(restaurantDetailState.response()?.data?.pricePoint ?? 0) }}</p>

                <span class="h-1 w-1 rounded-full bg-neutral-600"></span>

                <app-rating-count [rating]="restaurantDetailState.response()?.data?.rating ?? 0"
                                  [count]="restaurantDetailState.response()?.data?.ratingCount ?? 0"></app-rating-count>
                <!--                <p class="text-red-500 text-body2 rounded-full shadow-1 text-center py-2 px-2">Closed Now</p>-->
              </div>
            </div>
          </div>

          <div class="flex flex-wrap items-center gap-3">
            @for (cuisine of restaurantDetailState.response()?.data?.cuisines; track cuisine) {
              <app-chip [name]="cuisine.name"></app-chip>
            }
          </div>

          <div class="flex flex-col gap-2">
            <p class="text-sky-400 text-body2">{{ distanceInMiles() }}</p>
            <p class="text-neutral-400 text-body2">
              {{ arrayToCSV([restaurantDetailState.response()?.data?.location?.address1, restaurantDetailState.response()?.data?.location?.city, restaurantDetailState.response()?.data?.location?.state]) }}
            </p>
          </div>

          <div class="flex items-center gap-3 container mx-auto">

            <button class="p-3 border rounded-md text-body2" (click)="onFavoriteClicked($event)">
              <div class="flex gap-3 justify-center items-center">
                <lib-svg-icon
                  [src]="restaurantLiked() ? 'assets/icons/heart-filled.svg' : 'assets/icons/heart-outline.svg'"
                  class="w-6 h-6 items-center"
                  [ngClass]="restaurantLiked() ? 'text-pink-500' : 'text-neutral-400'">
                </lib-svg-icon>
                <p class="text-body2"
                   i18n="Favorite Button|Button to mark restaurant as a favorite@@restaurantDetails.favoriteButton">
                  Favorite
                </p>
              </div>
            </button>

            @if (destinationGeoPoint().latitude && destinationGeoPoint().longitude) {
              <app-direction-button
                [destinationLatitude]="destinationGeoPoint().latitude"
                [destinationLongitude]="destinationGeoPoint().longitude">
              </app-direction-button>
            }

            <button class="p-3 bg-blue-100 border rounded-md text-body2" (click)="onShareClicked()">
              <div class="flex gap-3 justify-center items-center">
                <lib-svg-icon src="assets/icons/share.svg" class="w-6 h-6 items-center text-blue-700"></lib-svg-icon>
                <p class="text-body2"
                   i18n="Share Button|Button to share restaurant details@@restaurantDetails.shareButton">
                  Share
                </p>
              </div>
            </button>

          </div>
          <!--  -------------------- -->

          <div class="flex flex-col md:flex-row gap-2">
            <div class="flex items-center gap-3">
              <lib-svg-icon src="assets/icons/phone.svg" class="text-neutral-400"></lib-svg-icon>
              <p class="text-neutral-700 text-body2">{{ restaurantDetailState.response()?.data?.phoneNumber }}</p>
            </div>
            <div class="flex items-center gap-3">
              <lib-svg-icon src="assets/icons/mail.svg" class="text-neutral-400"></lib-svg-icon>
              <p class="text-neutral-700 text-body2">{{ restaurantDetailState.response()?.data?.email }}</p>
            </div>
          </div>
        </div>

        <!-- Tab bar-->
        <div class="w-full overflow-x-auto sticky px-3 bg-white" [ngClass]="stickyClass()">
          <div class="border-b border-neutral-200">
            <nav class="flex -mb-px space-x-10">

              <a (click)="scrollToSection('overview-section'); toggleSection('overview')"
                 class="overview-section nav-link nav-link-active"
                 i18n="Overview Tab|Tab navigation to restaurant overview section@@restaurantDetails.overviewTab">
                Overview
              </a>

              <a (click)="scrollToSection('reviews-section'); toggleSection('reviews')"
                 class="reviews-section nav-link nav-link-inactive"
                 i18n="Reviews Tab|Tab navigation to restaurant reviews section@@restaurantDetails.reviewsTab">
                Reviews
              </a>

            </nav>
          </div>
        </div>

        <!--overview section starts-->
        @if (showOverview || (showReviews && !showMenu)) {
          <div id="overview-section" class="section overview-section flex flex-col gap-4">

            @if (restaurantDetailState.response()?.data?.description) {
              <p class="text-body2 text-neutral-600">
                {{ restaurantDetailState.response()?.data?.description }}
              </p>
            }

            <p class="text-body1 text-primary-500 font-medium"
               i18n="Open Hours Heading|Heading for restaurant business hours@@restaurantDetails.openHours">
              Open Hours
            </p>

            <div class="flex flex-col gap-3">
              <div class="flex">
                <p class="text-body2 text-neutral-600 min-w-56"
                   i18n="Day Sunday|Label for Sunday in open hours@@restaurantDetails.sunday">
                  Sunday
                </p>
                <p class="text-body2">
                  {{ restaurantDetailState.response()?.data?.businessHours?.sunday?.openTime | minutesToTime }}
                  -
                  {{ restaurantDetailState.response()?.data?.businessHours?.sunday?.closeTime | minutesToTime }}
                </p>
              </div>
              <div class="flex">
                <p class="text-body2 text-neutral-600 min-w-56"
                   i18n="Day Monday|Label for Monday in open hours@@restaurantDetails.monday">
                  Monday
                </p>
                <p class="text-body2">
                  {{ restaurantDetailState.response()?.data?.businessHours?.monday?.openTime | minutesToTime }}
                  -
                  {{ restaurantDetailState.response()?.data?.businessHours?.monday?.closeTime | minutesToTime }}
                </p>
              </div>
              <div class="flex">
                <p class="text-body2 text-neutral-600 min-w-56"
                   i18n="Day Tuesday|Label for Tuesday in open hours@@restaurantDetails.tuesday">
                  Tuesday
                </p>
                <p class="text-body2">
                  {{ restaurantDetailState.response()?.data?.businessHours?.tuesday?.openTime | minutesToTime }}
                  -
                  {{ restaurantDetailState.response()?.data?.businessHours?.tuesday?.closeTime | minutesToTime }}
                </p>
              </div>
              <div class="flex">
                <p class="text-body2 text-neutral-600 min-w-56"
                   i18n="Day Wednesday|Label for Wednesday in open hours@@restaurantDetails.wednesday">
                  Wednesday
                </p>
                <p class="text-body2">
                  {{ restaurantDetailState.response()?.data?.businessHours?.wednesday?.openTime | minutesToTime }}
                  -
                  {{ restaurantDetailState.response()?.data?.businessHours?.wednesday?.closeTime | minutesToTime }}
                </p>
              </div>
              <div class="flex">
                <p class="text-body2 text-neutral-600 min-w-56"
                   i18n="Day Thursday|Label for Thursday in open hours@@restaurantDetails.thursday">
                  Thursday
                </p>
                <p class="text-body2">
                  {{ restaurantDetailState.response()?.data?.businessHours?.thursday?.openTime | minutesToTime }}
                  -
                  {{ restaurantDetailState.response()?.data?.businessHours?.thursday?.closeTime | minutesToTime }}
                </p>
              </div>
              <div class="flex">
                <p class="text-body2 text-neutral-600 min-w-56"
                   i18n="Day Friday|Label for Friday in open hours@@restaurantDetails.friday">
                  Friday
                </p>
                <p class="text-body2">
                  {{ restaurantDetailState.response()?.data?.businessHours?.friday?.openTime | minutesToTime }}
                  -
                  {{ restaurantDetailState.response()?.data?.businessHours?.friday?.closeTime | minutesToTime }}
                </p>
              </div>
              <div class="flex">
                <p class="text-body2 text-neutral-600 min-w-56"
                   i18n="Day Saturday|Label for Saturday in open hours@@restaurantDetails.saturday">
                  Saturday
                </p>
                <p class="text-body2">
                  {{ restaurantDetailState.response()?.data?.businessHours?.saturday?.openTime | minutesToTime }}
                  -
                  {{ restaurantDetailState.response()?.data?.businessHours?.saturday?.closeTime | minutesToTime }}
                </p>
              </div>
            </div>
          </div>

          <div class="border-b border-neutral-200"></div>
        }

        <!--overview section ends-->
        @if (showReviews || (showOverview && !showMenu)) {
          <div id="reviews-section" class="section reviews-section flex flex-col gap-4">

            <app-review-summary [review]="rating()"></app-review-summary>

            @if (reviewsState.response() && reviewsState.response()!.data && reviewsState.response()!.data.length > 0) {
              <div class="border-b border-neutral-200"></div>
            }

            @for (review of reviewsState.response()?.data ?? []; track review) {
              <div class="grid lg:grid-cols-5 gap-8">
                <div class="lg:col-span-1 flex flex-col gap-3">
                  <app-individual-rating [rating]="review.rating?.totalRating ?? 0"></app-individual-rating>
                  <p class="text-body2 text-neutral-400">{{ review.user?.firstName }} {{ review.user?.lastName }}</p>
                </div>
                <div class="lg:col-span-4 flex flex-col gap-3">
                  <p class="text-body2 text-primary-500">{{ review.createdDate | date:'mediumDate' }}</p>
                  <p class="text-body2"
                     [ngClass]="(review.feedback != null && review.feedback != '') ? 'text-neutral-700' : 'text-neutral-400'">
                    {{ review?.feedback ?? restaurantDetailsFeedback }}
                  </p>
                </div>
              </div>
              <div class="border-b border-neutral-100"></div>
            }

            <!-- review section ends -->

            <div class="flex gap-6 py-4">
              <!-- <lib-button appearance="outline"> CHECK ALL REVIEWS</lib-button> -->
              @if (authStoreService.isAuthenticated()) {
                <lib-button
                  (click)="onWriteReviewClicked()"
                  i18n="Write Review Button|CTA to leave a review for the restaurant@@restaurantDetails.writeReviewButton">
                  WRITE A REVIEW
                </lib-button>
              }
            </div>
          </div>

          @if (reviewsState.response() && reviewsState.response()!.data && reviewsState.response()!.data.length > 0) {
            <div class="border-b border-neutral-200"></div>
          }
        }
      }

      <!--      food menu section starts-->

      <!--      @if (showMenu) {-->
      <!--        @if (dineInOption() == DineInOption.pickupOrder || dineInOption() == DineInOption.dineIn) {-->
      <!--          <div id="menu-section" class="section menu-section flex flex-col gap-4">-->
      <!--            <div class="food-menu">-->
      <!--              <div class="flex flex-col container mx-auto gap-4 py-4 bg-white sticky top-16 z-30">-->
      <!--                <p class="text-neutral-800 text-body1 font-medium">Menu</p>-->

      <!--                <div class="flex items-center whitespace-nowrap overflow-x-scroll space-x-4">-->
      <!--                  @for (menu of menuState.response()?.data; track menu._id) {-->
      <!--                    <div (click)="onMenuTabClicked(menu)" class="flex">-->
      <!--                      <p-->
      <!--                        [ngClass]="selectedMenu() === menu ? 'px-2 rounded-md bg-primary-600 text-white'-->
      <!--                         : 'px-2 rounded-md text-neutral-500 bg-neutral-100'"-->
      <!--                        class="text-body2 cursor-pointer py-2">-->
      <!--                        {{ menu.name }}-->
      <!--                      </p>-->
      <!--                    </div>-->
      <!--                  }-->
      <!--                </div>-->
      <!--                <div class="flex items-center whitespace-nowrap overflow-x-auto space-x-6 border-b">-->
      <!--                  @for (category of filteredMenu()?.categories; track category) {-->
      <!--                    @if (category.filterMatched()) {-->
      <!--                      <div (click)="onCategoryTabClicked(toDashCase(category.category?.name))" class="flex">-->
      <!--                        <p [ngClass]="toDashCase(category.category?.name)"-->
      <!--                           class="nav-link nav-link-inactive text-body2 text-neutral-500 cursor-pointer">{{ category.category?.name }}</p>-->
      <!--                      </div>-->
      <!--                    }-->
      <!--                  }-->
      <!--                </div>-->
      <!--              </div>-->


      <!--              @if (menuState.loading()) {-->
      <!--                <div class="flex flex-col gap-10">-->
      <!--                  <lib-shimmer type="list"></lib-shimmer>-->
      <!--                  <lib-shimmer type="list"></lib-shimmer>-->
      <!--                  <lib-shimmer type="list"></lib-shimmer>-->
      <!--                  <lib-shimmer type="list"></lib-shimmer>-->
      <!--                </div>-->
      <!--              }-->

      <!--              @for (category of filteredMenu()?.categories; track category) {-->
      <!--                @if (category.filterMatched()) {-->
      <!--                  @if (category.menuItems && category.menuItems.length > 0) {-->
      <!--                    <div class="section" [ngClass]="toDashCase(category.category?.name)"-->
      <!--                         [id]="toDashCase(category.category?.name)">-->
      <!--                      <div (click)="onCategoryToggle(category)"-->
      <!--                           class="flex justify-between items-center text-body2-->
      <!--                     text-neutral-500 cursor-pointer whitespace-nowrap-->
      <!--                      py-3 mb-4 select-none">-->
      <!--                        <p class="text-body2 text-primary-500 font-bold">{{ category.category?.name }}</p>-->
      <!--                        @if (category.isExpanded()) {-->
      <!--                          <lib-svg-icon src="assets/icons/toggle-up.svg" [size]="10"-->
      <!--                                        class="text-primary-500 mr-3 cursor-pointer"></lib-svg-icon>-->
      <!--                        } @else {-->
      <!--                          <lib-svg-icon src="assets/icons/toggle-down.svg" [size]="10"-->
      <!--                                        class="text-primary-500 mr-3 cursor-pointer"></lib-svg-icon>-->
      <!--                        }-->
      <!--                      </div>-->

      <!--                      @if (category.isExpanded()) {-->
      <!--                        <div class="flex flex-col gap-3 py-3">-->
      <!--                          <div class="grid grid-cols-1 gap-6 md:grid-cols-2">-->
      <!--                            @for (menuItem of category?.menuItems ?? []; track menuItem) {-->
      <!--                              @if (menuItem.filterMatched()) {-->
      <!--                                <div class="flex items-center gap-4 border rounded-xl">-->
      <!--                                  <div class="h-[100px] rounded-md overflow-hidden">-->
      <!--                                    <lib-network-image class="select-none"-->
      <!--                                                       [placeholderImage]="'assets/images/background/restaurant-placeholder.png'"-->
      <!--                                                       [imageUrl]="menuItem.image?.url">-->
      <!--                                    </lib-network-image>-->
      <!--                                  </div>-->
      <!--                                  <div class="flex gap-2 w-full px-4 py-3">-->
      <!--                                    <div class="flex flex-col items-start gap-3">-->
      <!--                                      <p class="text-neutral-900 text-body2 select-none">{{ menuItem.name }}</p>-->
      <!--                                      <p class="text-primary-900 text-body2 select-none">-->
      <!--                                        ${{ menuItem.price?.basePrice }}</p>-->
      <!--                                      <p class="text-neutral-400 text-body2">{{ menuItem.description }}</p>-->
      <!--                                    </div>-->
      <!--                                    <div class="flex-grow"></div>-->
      <!--                                    <div class="flex flex-col items-center gap-3">-->
      <!--                                      @if (menuItem.isCustomizableItem()) {-->
      <!--                                        <p class="text-green-500 text-caption">Customisable</p>-->
      <!--                                      }-->
      <!--                                    </div>-->
      <!--                                  </div>-->

      <!--                                </div>-->
      <!--                              }-->
      <!--                            }-->
      <!--                          </div>-->
      <!--                        </div>-->
      <!--                      }-->
      <!--                    </div>-->
      <!--                  }-->
      <!--                }-->
      <!--              }-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        }-->
      <!--      }-->
    </div>


    <div class="col-span-2 hidden md:block space-y-3 w-full justify-end">
      <div class="sticky py-4" [ngClass]="stickyClass()">

        @if (selectedDineInOption() === DineInOption.dineIn) {
          <div class="flex flex-col gap-5 px-6 py-5 rounded-lg shadow-2 w-full">

            @if (restaurantDetailState.loading()) {
              <lib-shimmer type="multiline"></lib-shimmer>
            }

            @if (canShowOnlineReservation()) {
              <form [formGroup]="reservationForm" (ngSubmit)="onReserveClicked()" class="flex flex-col gap-3">

                <div class="flex flex-wrap items-start gap-6">
                  <div class="flex-1 bg-neutral-50 px-4 py-2 rounded-lg">
                    <lib-date-selector-input
                      [minDate]="minimumDate()"
                      formControlName="date"
                      [title]="'Date'"
                      i18n-title="Date Label|Label for selecting reservation date@@restaurantDetails.dateLabel"
                      (valueChanged)="onDateSelected($event)">
                    </lib-date-selector-input>
                  </div>

                  <div class="flex-1 bg-neutral-50 px-4 py-2 rounded-lg">
                    <app-party-size-input
                      formControlName="partySize"
                      (valueChanged)="onPartSizeChanged($event)">
                    </app-party-size-input>
                  </div>
                </div>

                <div class="flex-1 bg-neutral-50 px-4 py-2 rounded-lg">
                  <lib-single-selection-field
                    formControlName="time"
                    [title]="'Select Time'"
                    i18n-title="Select Time Label|Label for selecting reservation time@@restaurantDetails.selectTimeLabel"
                    [itemWidth]="90"
                    [maximumDisplayItems]="10"
                    [items]="availabilities()"
                    [display]="'timeString'"
                    [state]="tableAvailabilitiesState"
                    noDataMessage="No tables available"
                    i18n-noDataMessage="No Tables Message|Message when no tables are available for selected time@@restaurantDetails.noTablesMessage">
                  </lib-single-selection-field>
                </div>

                <lib-button type="submit" [fullWidth]="true"
                            i18n="Reserve Button|CTA to reserve a table@@restaurantDetails.reserveButton">
                  Reserve
                </lib-button>
              </form>

              @if (nextUpcomingReservation()) {
                <div class="flex items-center gap-3">
                  <lib-svg-icon src="assets/icons/info.svg" class="text-neutral-500" [size]="16"></lib-svg-icon>
                  <p class="text-body2 text-sky-500"
                     i18n="Upcoming Reservation Info|Message showing the user's next reservation@@restaurantDetails.upcomingReservationInfo">
                    You have another reservation
                    at {{ nextUpcomingReservation()?.startTime | date:'d MMM y - h:mm a' }}
                  </p>
                </div>
              }

              @if (canShowJoinWaitList()) {
                <div class="flex items-center gap-3">
                  <div class="border-b flex-1 border-neutral-300"></div>
                  <p class="text-caption"
                     i18n="OR Separator|Visual divider between reserve and waitlist@@restaurantDetails.orText">
                    OR
                  </p>
                  <div class="border-b flex-1 border-neutral-300"></div>
                </div>
                <lib-button (click)="onJoinWaitlistClicked()" appearance="outline" [fullWidth]="true">
                  <div class="flex gap-3 items-center justify-center">
                    <lib-svg-icon src="assets/icons/clock.svg" [size]="15"></lib-svg-icon>
                    <span
                      i18n="Join Waitlist Button|CTA to join restaurant waitlist@@restaurantDetails.joinWaitlistButton">
                  Join Waitlist
                </span>
                  </div>
                </lib-button>
              }
            }

            @if (!canShowOnlineReservation() && canShowJoinWaitList()) {
              <lib-button (click)="onJoinWaitlistClicked()" appearance="outline" [fullWidth]="true">
                <div class="flex gap-3 items-center justify-center">
                  <lib-svg-icon src="assets/icons/clock.svg" [size]="15"></lib-svg-icon>
                  <span
                    i18n="Join Waitlist Button|CTA to join restaurant waitlist@@restaurantDetails.joinWaitlistButton">
                Join Waitlist
              </span>
                </div>
              </lib-button>
            }

            @if (restaurantDetailState.success()) {
              @if (!canShowOnlineReservation() && !canShowJoinWaitList()) {
                <lib-button (click)="onCallForReservationClicked()" appearance="outline" [fullWidth]="true">
                  <div class="flex gap-3 items-center justify-center">
                    <lib-svg-icon src="assets/icons/phone.svg" [size]="15"></lib-svg-icon>
                    <span
                      i18n="Call Reservation Button|CTA to call restaurant for reservation@@restaurantDetails.callButton">
                  Call for Reservation
                </span>
                  </div>
                </lib-button>
              }
            }

          </div>
        }

        @if (selectedDineInOption() === DineInOption.pickupOrder) {
          <lib-button [fullWidth]="true" appearance="outline" (click)="onPlacePickupOrderPressed()"
                      i18n="Pickup Order Button|CTA to place a pickup order@@restaurantDetails.pickupOrderButton">
            Pickup Order
          </lib-button>
        }

      </div>
    </div>

  </div>
  @if (pastReservation()) {
    <div
      class="fixed bottom-4 border border-neutral-300 rounded-xl flex items-center justify-center transform -translate-x-1/2 left-1/2 mx-auto bg-white gap-4 shadow-2 px-3 py-4 whitespace-nowrap">

      <div class="h-12 w-12 rounded-xl overflow-hidden">
        <lib-network-image
          [placeholderImage]="'assets/images/background/restaurant-placeholder.png'"
          [imageUrl]="pastReservation()?.restaurant?.banner?.url">
        </lib-network-image>
      </div>

      <div class="flex items-center">
        <p class="text-neutral-500 text-body2"
           i18n="Meal Feedback Prompt|Prompt asking if user enjoyed the meal@@restaurantDetails.mealEnjoyedPrompt">
          Enjoyed your meal at
        </p>
        <p class="text-primary-500 text-body2 font-semibold px-1">
          {{ pastReservation()?.restaurant?.restaurantName }}?
        </p>

        <lib-button (click)="onWriteSurveyClicked()"
                    appearance="outline"
                    class="px-2"
                    i18n="Write Review Button|CTA to write a restaurant review@@restaurantDetails.writeReviewButton">
          WRITE REVIEW
        </lib-button>

        <lib-svg-icon src="assets/icons/close-border.svg"
                      class="text-neutral-400 px-1 cursor-pointer"
                      (click)="onNotInterestedClicked()"
                      i18n-title="Dismiss Icon|Tooltip for dismissing the review prompt@@restaurantDetails.dismissPrompt"
                      title="Dismiss">
        </lib-svg-icon>
      </div>

    </div>
  }
</div>
