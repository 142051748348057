import {Component, inject, OnInit, output, signal} from '@angular/core';
import {
  ButtonComponent,
  OutlineTextInputComponent,
  State
} from "@smartdining/lib-sd-web-shared";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "@core/base/base-component";
import {LoginResponse} from "../../../data/login/login-response";
import {AuthService} from "../../../data/auth.service";
import {LoginWithEmailPasswordRequest} from "../../../data/login/login-with-email-password-request";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {AuthenticatedUserResponse} from "../../../data/authenticated-user/authenticated-user-response";
import {DialogRef} from "@angular/cdk/dialog";
import {SendEmailOtpResponse} from "../../../data/send-email-otp/send-email-otp-response";
import {SendPhoneOtpResponse} from "../../../data/send-phone-otp/send-phone-otp-response";
import {SendEmailOtpRequest} from "../../../data/send-email-otp/send-email-otp-request";
import {SendPhoneOtpRequest} from "../../../data/send-phone-otp/send-phone-otp-request";
import {LoginWithEmailOtpRequest} from "../../../data/login/login-with-email-otp-request";
import {LoginWithPhoneOtpRequest} from "../../../data/login/login-with-phone-otp-request";

@Component({
  selector: 'app-login-overlay',
  imports: [
    ReactiveFormsModule,
    ButtonComponent,
    OutlineTextInputComponent
  ],
  templateUrl: './login-overlay.component.html',
  styleUrl: './login-overlay.component.scss'
})
export class LoginOverlayComponent extends BaseComponent implements OnInit {

  signupClicked = output<SignupData>();
  forgotPasswordClicked = output<void>();
  onLoginSuccessful = output<void>();

  dialogRef = inject(DialogRef);
  formBuilder = inject(FormBuilder);
  authService = inject(AuthService);
  authStoreService = inject(AuthStoreService);

  sendEmailOtpState = new State<SendEmailOtpResponse>();
  sendPhoneOtpState = new State<SendPhoneOtpResponse>();
  emailPasswordLoginState = new State<LoginResponse>();
  loginWithEmailOtpState = new State<LoginResponse>();
  loginWithPhoneOtpState = new State<LoginResponse>();
  authenticatedUserState = new State<AuthenticatedUserResponse>();

  emailPasswordLoginForm!: FormGroup;
  emailLoginForm!: FormGroup;
  phoneNumberLoginForm!: FormGroup;
  verifyEmailOtpForm!: FormGroup;
  verifyPhoneOtpForm!: FormGroup;

  uiState = signal(LoginTypeUIState.enterEmailPassword);

  ngOnInit(): void {
    this.initEmailOtpForm();
    this.initEmailPasswordForm();
    this.initPhoneNumberOtpForm();
    this.initEmailOtpVerificationForm();
    this.initPhoneOtpVerificationForm();
  }


  private initEmailPasswordForm() {
    this.emailPasswordLoginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
    });
  }

  private initEmailOtpForm() {
    this.emailLoginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
    });
  }

  private initPhoneNumberOtpForm() {
    this.phoneNumberLoginForm = this.formBuilder.group({
      phoneNumber: [null, [Validators.required]],
    });
  }

  private initEmailOtpVerificationForm() {
    this.verifyEmailOtpForm = this.formBuilder.group({
      otp: [null, [Validators.required]]
    });
  }

  private initPhoneOtpVerificationForm() {
    this.verifyPhoneOtpForm = this.formBuilder.group({
      otp: [null, [Validators.required]]
    });
  }

  onLoginUsingEmailPasswordOTPClicked() {
    this.uiState.set(LoginTypeUIState.enterEmailPassword);
  }

  onLoginUsingEmailClicked() {
    this.uiState.set(LoginTypeUIState.enterEmail);
  }

  onLoginUsingPhoneNumberClicked() {
    this.uiState.set(LoginTypeUIState.enterPhoneNumber);
  }

  onSendEmailOtpClicked() {
    if (this.emailLoginForm.invalid) {
      return;
    }

    let formValue = this.emailLoginForm.value;
    let request: SendEmailOtpRequest = {
      email: formValue.email,
    };

    this.executeRequest<SendEmailOtpResponse>({
      state: this.sendEmailOtpState,
      request: this.authService.sendEmailOtp(request),
      onSuccess: (response) => {
        if (response.data?.accountExist) {
          this.uiState.set(LoginTypeUIState.verifyEmailOTP);
        } else {
          this.signupClicked.emit({type: 'signupWithEmail', email: request.email});
        }
      }
    });
  }

  onSendPhoneOtpClicked() {
    if (this.phoneNumberLoginForm.invalid) {
      return;
    }

    let formValue = this.phoneNumberLoginForm.value;
    let request: SendPhoneOtpRequest = {
      phoneNumber: formValue.phoneNumber,
    };

    this.executeRequest<SendPhoneOtpResponse>({
      state: this.sendPhoneOtpState,
      request: this.authService.sendPhoneOtp(request),
      onSuccess: (response) => {
        if (response.data?.accountExist) {
          this.uiState.set(LoginTypeUIState.verifyPhoneNumberOTP);
        } else {
          this.signupClicked.emit({type: 'signupWithPhoneNumber', email: request.phoneNumber});
        }
      }
    });
  }

  onEmailPasswordLoginClicked() {
    if (this.emailPasswordLoginForm.invalid) {
      return;
    }

    let formValue = this.emailPasswordLoginForm.value;
    let request: LoginWithEmailPasswordRequest = {
      email: formValue.email,
      password: formValue.password
    };

    this.executeRequest<LoginResponse>({
      state: this.emailPasswordLoginState,
      request: this.authService.loginWithEmailPassword(request),
      onSuccess: response => {
        this.handleLogin(response);
      }
    });
  }

  onEmailOtpVerificationClicked() {
    if (this.verifyEmailOtpForm.invalid) {
      return;
    }

    let formValue = this.verifyEmailOtpForm.value;
    let request: LoginWithEmailOtpRequest = {
      email: this.emailLoginForm.value.email,
      otp: formValue.otp,
    };

    this.executeRequest<LoginResponse>({
      state: this.loginWithEmailOtpState,
      request: this.authService.loginWithEmailOtp(request),
      onSuccess: response => {
        this.handleLogin(response);
      }
    });
  }

  onPhoneOtpVerificationClicked() {
    if (this.verifyPhoneOtpForm.invalid) {
      return;
    }

    let formValue = this.verifyPhoneOtpForm.value;
    let request: LoginWithPhoneOtpRequest = {
      phoneNumber: this.phoneNumberLoginForm.value.phoneNumber,
      otp: formValue.otp,
    };

    this.executeRequest<LoginResponse>({
      state: this.loginWithPhoneOtpState,
      request: this.authService.loginWithPhoneOtp(request),
      onSuccess: response => {
        this.handleLogin(response);
      }
    });
  }

  private handleLogin(response: LoginResponse) {
    this.authStoreService.saveToken(response.data.refreshToken, response.data.token);
    this.executeRequest<AuthenticatedUserResponse>({
      state: this.authenticatedUserState,
      request: this.authService.getAuthenticatedUser(),
      onSuccess: response => {
        this.dialogRef.close(true);
        if (response.data.userType == 'USER_TYPE_USER') {
          this.authStoreService.saveAppUser(response);
          this.onLoginSuccessful.emit();
        } else {
          this.authStoreService.logout();
          this.toasterService.error('Invalid credentials, kindly use a valid credentials');
        }
      }
    });
  }

  onSignupClicked() {
    this.signupClicked.emit({type: 'signupWithEmailPassword'});
  }

  onForgotPasswordClicked() {
    this.forgotPasswordClicked.emit();
  }

  LoginTypeUIState = LoginTypeUIState;
}


export enum LoginTypeUIState {
  enterEmailPassword,
  enterEmail,
  enterPhoneNumber,
  verifyEmailOTP,
  verifyPhoneNumberOTP
}

export interface SignupData {
  type: 'signupWithEmailPassword' | 'signupWithEmail' | 'signupWithPhoneNumber';
  email?: string;
  phoneNumber?: string;
}
