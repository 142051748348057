<div class="flex gap-3">
  <p class="py-2 px-5 border border-primary-500 bg-primary-500 rounded cursor-pointer"
     [ngClass]="isReceivedInvitation() ? 'bg-primary-500 text-white' : 'bg-transparent text-primary-500'"
     (click)="onReceivedInvitationClicked()"
     i18n="Received Tab|Label for tab showing received invitations@@invitationsTab.received">
    Received
  </p>
  <p class="py-2 px-5 border border-primary-500 rounded cursor-pointer"
     [ngClass]="isSentInvitation() ? 'bg-primary-500 text-white' : 'bg-transparent text-primary-500'"
     (click)="onSenInvitationClicked()"
     i18n="Sent Tab|Label for tab showing sent invitations@@invitationsTab.sent">
    Sent
  </p>
</div>

