<div class="container mx-auto">
  <div class="relative">
    <input type="text"
           [formControl]="searchControl"
           [matAutocomplete]="auto"
           class="w-full pl-4 pr-10 py-2 border text-caption border-neutral-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
           placeholder="Search for Restaurant..."
           i18n-placeholder="Restaurant Search Placeholder@@restaurantSuggestion.searchPlaceholder">
    <div class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
      <lib-svg-icon src="assets/icons/search.svg" class="text-neutral-400" [size]="16"></lib-svg-icon>
    </div>
  </div>

  <mat-autocomplete #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="onRestaurantSelected($event.option.value)">
    @if (filteredRestaurants().length > 0) {
      @for (restaurant of filteredRestaurants(); track restaurant) {
        <mat-option [value]="restaurant" class="p-2">
          <div class="flex items-center gap-3">
            <div class="w-[36px] h-[36px] rounded-full overflow-clip object-cover">
              <lib-network-image class="w-[36px] h-[36px] object-contain"
                                 [imageUrl]="restaurant?.logo?.url"
                                 placeholderImage="assets/images/background/restaurant-placeholder.png">
              </lib-network-image>
            </div>
            <span class="text-caption">{{ restaurant.restaurantName }}</span>
          </div>
        </mat-option>
      }
    } @else {
      <p disabled class="p-2 text-caption text-neutral-500"
         i18n="No Restaurants Found Message@@restaurantSuggestion.noRestaurantsFound">
        No restaurants found
      </p>
    }
  </mat-autocomplete>
</div>
