<app-base-dialog
  [title]="'Select Address'"
  i18n-title="Select Address Dialog Title|Title for the location selection dialog@@locationSelectionOverlay.title"
  [showAction]="false">

  <div body>
    <div class="flex flex-col gap-3">

      <div class="flex flex-col cursor-pointer" (click)="onDetectLocationClicked()">
        <div class="flex items-center gap-4">
          <lib-svg-icon src="assets/icons/gps.svg" class="text-primary-500" [size]="16"></lib-svg-icon>
          <div class="flex flex-col">
            <p class="text-body2 text-primary-500"
               i18n="Locate Current Place Label|Primary action to detect current location@@locationSelectionOverlay.locateTitle">
              Locate current place
            </p>
            <p class="text-body2 text-neutral-400"
               i18n="Locate Using GPS Subtitle|Explanation for GPS action@@locationSelectionOverlay.locateSubtitle">
              Using Device GPS
            </p>
          </div>
        </div>
        <div class="border border-b border-neutral-100 my-3"></div>
      </div>

      <div class="flex flex-col gap-2 py-2">
        <div class="flex justify-between items-center w-full">
          @if (authStoreService.isAppUserLoggedIn()) {
            <p class="text-body2 font-semibold"
               i18n="Saved Addresses Heading|Heading for the saved addresses section@@locationSelectionOverlay.savedAddresses">
              Saved Addresses
            </p>
            <div class="flex gap-3 items-center cursor-pointer" (click)="onAddAddressClicked()">
              <lib-svg-icon src="assets/icons/plus.svg" class="text-primary-500" [size]="11"></lib-svg-icon>
              <p class="text-body2 text-primary-500"
                 i18n="Add Address Label|Button label to add new address@@locationSelectionOverlay.addAddress">
                Add address
              </p>
            </div>
          }
        </div>

        @if (addressState.loading()) {
          <lib-shimmer type="list"></lib-shimmer>
        }

        @if (authStoreService.isAuthenticated()) {
          @for (address of addressState.response()?.data; track address) {
            <div class="flex items-center text-body2 gap-2 py-3 cursor-pointer" (click)="onAddressSelected(address)">
              <lib-svg-icon src="assets/icons/location.svg" class="text-primary-500" [size]="18"></lib-svg-icon>
              <p class="text-body2 px-2 flex-grow">
                {{ arrayToCSV([address.address, address.city, address.state, address.country, address.zipCode]) }}
              </p>
              <lib-svg-icon src="assets/icons/arrow-top-right.svg" class="text-neutral-400" [size]="18"></lib-svg-icon>
            </div>
          }
        }
      </div>

    </div>
  </div>
</app-base-dialog>
