import {Component, ElementRef, inject, input, OnDestroy, ViewChild} from '@angular/core';
import {
  AppSvgIconComponent,
  BaseControlValueAccessorV3,
  BaseInputComponent,
  OverlayService
} from "@smartdining/lib-sd-web-shared";
import {TimeObject} from "@shared/utils/date-utils";
import {Subscription} from "rxjs";
import {DineInTimeOverlayComponent} from "./dine-in-time-overlay/dine-in-time-overlay.component";

@Component({
  selector: 'app-dine-in-time-filter',
  imports: [
    AppSvgIconComponent,
    BaseInputComponent,
  ],
  templateUrl: './dine-in-time-filter.component.html',
  standalone: true,
  styleUrl: './dine-in-time-filter.component.scss'
})
export class DineInTimeFilterComponent extends BaseControlValueAccessorV3<TimeObject> implements OnDestroy {
  @ViewChild('trigger', {static: true}) trigger!: ElementRef;

  overlayService = inject(OverlayService);
  availableTimes = input<TimeObject[]>([]);

  private subscription?: Subscription;

  protected override onValueReady(value: TimeObject): void {
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  onOpenTimeOverlayClicked() {
    let dialogRef = this.overlayService.openNearElement(DineInTimeOverlayComponent, this.trigger.nativeElement, {
      data: this.availableTimes()
    });

    this.subscription = dialogRef.closed.subscribe((time: TimeObject) => {
      if (time) {
        this.onValueChange(time);
      }
    });
  }

}
