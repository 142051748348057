import {Component, inject, OnInit} from '@angular/core';
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {TimeObject} from "@shared/utils/date-utils";

@Component({
  selector: 'app-dine-in-time-overlay',
  imports: [],
  templateUrl: './dine-in-time-overlay.component.html',
  styleUrl: './dine-in-time-overlay.component.scss'
})
export class DineInTimeOverlayComponent implements OnInit{
  availableTimes: TimeObject[] = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);

  ngOnInit(): void {

  }

  onTimeSelected(time: TimeObject) {
    this.dialogRef.close(time);
  }
}
