import {Component, inject, input, OnInit, signal, ChangeDetectorRef, computed} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {
  RestaurantPhotoGalleryData,
  RestaurantPhotoGalleryResponse
} from "../../../data/photo-gallery/restaurant-photo-gallery-response";
import {RestaurantService} from "../../../data/restaurant.service";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {
  RestaurantDetailResponse,
  RestaurantDetailsResponseDataCuisines
} from "../../../data/restaurant-details/restaurant-detail-response";
import {calculateDistance} from "@shared/utils/geo-utils";
import {AppDataService} from "@core/services/app-data/app-data.service";
import {GeoPoint} from "@core/services/location-service/location.service";
import {ShareLinkComponent} from "../../../../common/presentation/overlay/share-link/share-link.component";
import {DirectionButtonComponent} from "@core/components/direction-button/direction-button.component";
import {DOCUMENT, NgClass} from "@angular/common";
import {RatingCountComponent} from "@core/components/rating-count/rating-count.component";
import {numberToPricePoint} from "@core/utils/price-point-utils";
import {arrayToCSV} from "@shared/utils/string-utils";
import {MatDialog} from "@angular/material/dialog";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {FavoriteRestaurantRequest} from "../../../data/favourite-restaurant/favorite-restaurant-request";
import {RestaurantFavoriteService} from "../../../../settings/data/restaurant-favorite.service";
import {GenericResponse} from "@core/models/generic-response";
import {ChipComponent} from "@core/components/chip/chip.component";
import {
  AppSvgIconComponent,
  NetworkImageComponent,
  NoDataComponent,
  OverlayService,
  State
} from '@smartdining/lib-sd-web-shared';
import {
  AuthWizardOverlayComponent
} from "../../../../auth/presentation/overlays/auth-wizard-overlay/auth-wizard-overlay.component";

@Component({
  selector: 'app-gallery',
  standalone: true,
  imports: [
    InfiniteScrollModule,
    NoDataComponent,
    AppSvgIconComponent,
    DirectionButtonComponent,
    NgClass,
    RatingCountComponent,
    NetworkImageComponent,
    ChipComponent
  ],
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.scss'
})
export class GalleryComponent extends BaseComponent implements OnInit {
  restaurantId = input('');

  photoGalleryState = new State<RestaurantPhotoGalleryResponse>();
  restaurantDetailState = new State<RestaurantDetailResponse>();
  removeFavoriteRestaurantState = new State<GenericResponse>();
  state = new State<GenericResponse>();

  photos = signal<RestaurantPhotoGalleryData[]>([]);
  restaurantLiked = signal(false);
  cuisines = signal<RestaurantDetailsResponseDataCuisines[]>([]);


  restaurantService = inject(RestaurantService);
  cdr = inject(ChangeDetectorRef);
  appDataService = inject(AppDataService);
  document = inject(DOCUMENT);
  dialog = inject(MatDialog);
  overlayService = inject(OverlayService);
  authStoreService = inject(AuthStoreService);
  favoritesService = inject(RestaurantFavoriteService);

  ngOnInit(): void {
    this.getPhotos(true);
    this.getRestaurantDetails();
  }

  getRestaurantDetails() {
    this.executeRequest<RestaurantDetailResponse>({
      state: this.restaurantDetailState,
      request: this.restaurantService.getRestaurantDetails(this.restaurantId()),
      onSuccess: response => {
        const isRestaurantLiked = this.appDataService.isRestaurantLiked(response.data._id);
        this.restaurantLiked.set(isRestaurantLiked);
        const cuisines = response.data?.cuisines ?? [];
        this.cuisines.set(cuisines);
      }
    });
  }

  getPhotos(resetPagination = false) {
    let page: number;
    const size = 10;

    if (resetPagination) {
      page = 1;
      this.photoGalleryState.clearState();
      this.photos.set([]);
    } else {
      const currentPage = this.photoGalleryState.response()?.metadata.page.current_page;
      page = (currentPage ?? 0) + 1;
    }

    this.executeRequest<RestaurantPhotoGalleryResponse>({
      state: this.photoGalleryState,
      request: this.restaurantService.getPhotoGallery(this.restaurantId(), page, size),
      onSuccess: response => {
        this.photos.update(prevPhotos => [...prevPhotos, ...response.data]);
        this.cdr.detectChanges();
      }
    });
  }

  onScroll() {
    if (!this.photoGalleryState.loading()) {
      this.getPhotos(false);
    }
  }

  distanceInMiles = computed<string | null>(() => {
    const restaurant = this.restaurantDetailState.response()?.data;
    let destinationLatitude = restaurant?.location?.location?.coordinates[1];
    let destinationLongitude = restaurant?.location?.location?.coordinates[0];

    let currentLocation = this.appDataService.getAddress();
    let sourceLatitude = currentLocation?.latitude;
    let sourceLongitude = currentLocation?.longitude;
    if (sourceLatitude && sourceLongitude && destinationLatitude && destinationLongitude) {
      const distanceInMiles = calculateDistance(sourceLatitude, sourceLongitude, destinationLatitude, destinationLongitude).miles.toFixed(2);
      return `${distanceInMiles} miles away`
    } else {
      return 'N/A';
    }
  });
  destinationGeoPoint = computed(() => {
    let geoPoint: GeoPoint = {
      latitude: this.restaurantDetailState.response()?.data?.location?.location?.coordinates[1],
      longitude: this.restaurantDetailState.response()?.data?.location?.location?.coordinates[0],
    };
    return geoPoint;
  });

  onShareClicked() {
    const fullUrl = this.document.URL;
    this.dialog.open(ShareLinkComponent, {
      minWidth: '400px',
      maxWidth: '400px',
      maxHeight: '95vh',
      data: {
        url: fullUrl,
        mailSubject: 'Checkout this restaurant',
        message: `Discover something delicious! Checkout this restaurant - ${fullUrl}`
      }
    });
  }

  onFavoriteClicked(event: MouseEvent) {
    event.stopPropagation();
    if (!this.authStoreService.isAuthenticated()) {
      this.overlayService.openModal(AuthWizardOverlayComponent);
      this.authStoreService.loginSuccessful = () => {
        this.handleFavoriteRestaurant();
      };
      return;
    }

    this.handleFavoriteRestaurant();

  }

  handleFavoriteRestaurant() {
    if (this.restaurantLiked()) {
      this.removeRestaurantFromFavorite();
    } else {
      this.addRestaurantToFavorite();
    }
  }

  removeRestaurantFromFavorite() {
    const restaurant = this.restaurantDetailState.response()?.data;
    const favoriteRestaurantId = this.appDataService.getFavoriteRestaurantId(restaurant?._id ?? '');
    this.executeRequest({
      state: this.removeFavoriteRestaurantState,
      request: this.favoritesService.deleteFavoriteRestaurant(favoriteRestaurantId ?? ''),
      showLoader: true,
      successMessage: 'Restaurant removed from favorite list',
      onSuccess: response => {
        this.appDataService.saveFavoriteRestaurants();
        this.restaurantLiked.set(false);
      }
    });
  }

  addRestaurantToFavorite() {
    const appUser = this.authStoreService.getAppUser()?.data;
    const restaurant = this.restaurantDetailState.response()?.data;
    let request: FavoriteRestaurantRequest = {
      companyUID: restaurant?.companyUID ?? '',
      user: appUser?.user ?? '',
      restaurant: restaurant?._id ?? ''
    };
    this.executeRequest({
      state: this.state,
      request: this.favoritesService.addToFavorite(request),
      showLoader: true,
      successMessage: 'Restaurant added as favorite',
      onSuccess: response => {
        this.appDataService.saveFavoriteRestaurants();
        this.restaurantLiked.set(true);
      }
    });
  }

  protected readonly numberToPricePoint = numberToPricePoint;
  protected readonly arrayToCSV = arrayToCSV;
}
