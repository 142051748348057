
<lib-base-overlay [title]="'Write Review'" i18n-title="@@writeReview.title">

  <div body>
    <form [formGroup]="reviewForm">
      <div class="flex flex-col gap-2 text-center justify-center items-center">
        <div>
          <p class="text-body2 text-neutral-900" i18n="@@writeReview.intro">
            Would you please tell us about your experience with this restaurant
          </p>
        </div>
      </div>

      <div class="flex flex-col py-10 items-center gap-3">
        <div class="grid grid-cols-4 border-t border-b border-neutral-100 py-3">
          <div class="col-span-2 flex items-center gap-3">
            <lib-svg-icon src="assets/icons/question-mark-circle.svg" [size]="20"></lib-svg-icon>
            <p class="text-body2 text-neutral-500" i18n="@@writeReview.atmosphere">Atmosphere</p>
          </div>
          <div class="col-span-2">
            <lib-rating-input formControlName="atmosphereRating"></lib-rating-input>
          </div>
        </div>

        <div class="grid grid-cols-4 border-b border-neutral-100 pb-3">
          <div class="col-span-2 flex items-center gap-3">
            <lib-svg-icon src="assets/icons/question-mark-circle.svg" [size]="20"></lib-svg-icon>
            <p class="text-body2 text-neutral-500" i18n="@@writeReview.food">Food</p>
          </div>
          <div class="col-span-2">
            <lib-rating-input formControlName="foodRating"></lib-rating-input>
          </div>
        </div>

        <div class="grid grid-cols-4 border-b border-neutral-100 pb-3">
          <div class="col-span-2 flex items-center gap-3">
            <lib-svg-icon src="assets/icons/question-mark-circle.svg" [size]="20"></lib-svg-icon>
            <p class="text-body2 text-neutral-500" i18n="@@writeReview.quality">Quality</p>
          </div>
          <div class="col-span-2">
            <lib-rating-input formControlName="qualityRating"></lib-rating-input>
          </div>
        </div>

        <div class="grid grid-cols-4 border-b border-neutral-100 pb-3">
          <div class="col-span-2 flex items-center gap-3">
            <lib-svg-icon src="assets/icons/question-mark-circle.svg" [size]="20"></lib-svg-icon>
            <p class="text-body2 text-neutral-500" i18n="@@writeReview.waitTime">Wait time</p>
          </div>
          <div class="col-span-2">
            <lib-rating-input formControlName="waitTimeRating"></lib-rating-input>
          </div>
        </div>

        <div class="grid grid-cols-4 border-b border-neutral-100 pb-3">
          <div class="col-span-2 flex items-center gap-3">
            <lib-svg-icon src="assets/icons/question-mark-circle.svg" [size]="20"></lib-svg-icon>
            <p class="text-body2 text-neutral-500" i18n="@@writeReview.service">Service</p>
          </div>
          <div class="col-span-2">
            <lib-rating-input formControlName="serviceRating"></lib-rating-input>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-3">
        <lib-text-area-input
          formControlName="feedback"
          class="w-full"
          label="Feedback"
          i18n-label="@@writeReview.reviewLabel"
          [fullWidth]="true"
          placeholder="Please write your feedback."
          i18n-placeholder="@@writeReview.feedbackPlaceholder">
        </lib-text-area-input>
      </div>
    </form>
  </div>

  <div actions>
    <div class="flex gap-4 items-center justify-end">
      <lib-button appearance="outline" (click)="onCancelClicked()" i18n="@@writeReview.cancel">CANCEL</lib-button>
      <lib-button (click)="onSubmitPressed()" [loading]="writeReviewState.loading()" i18n="@@writeReview.submit">SUBMIT REVIEW</lib-button>
    </div>
  </div>
</lib-base-overlay>
