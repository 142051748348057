import {Component, inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "@core/base/base-component";
import {PickupOrderService} from "../../../data/pickup-order.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GenericResponse} from "@core/models/generic-response";
import {GetOrderSurveyQuestionsResponse} from "../../../data/survey-questions/get-order-survey-questions-response";
import {OrderSurveyStatus} from "../../pages/order-information/order-information.component";
import {
  AppSvgIconComponent,
  BaseDialogComponent,
  ButtonComponent, RatingInputComponent, State,
  TextAreaInputComponent
} from '@smartdining/lib-sd-web-shared';
import {MatTooltip} from "@angular/material/tooltip";

@Component({
  selector: 'app-order-survey-overlay',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    TextAreaInputComponent,
    ButtonComponent,
    BaseDialogComponent,
    ReactiveFormsModule,
    RatingInputComponent,
    MatTooltip,
  ],
  templateUrl: './order-survey-overlay.component.html',
  styleUrl: './order-survey-overlay.component.scss'
})
export class OrderSurveyOverlayComponent extends BaseComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  ordersService = inject(PickupOrderService);
  dialogRef = inject(MatDialogRef);
  orderId: string = inject(MAT_DIALOG_DATA);

  orderSurveyQuestionsResponseState = new State<GetOrderSurveyQuestionsResponse>();
  createSurveyState = new State<GenericResponse>();

  surveyForm: FormGroup = new FormGroup({});

  ngOnInit() {
    this.initSurveyForm();
    this.getOrderSurveyQuestions();
  }

  private initSurveyForm() {
    this.surveyForm = this.formBuilder.group({
      order: [this.orderId],
      orderAccuracyRating: [0],
      waitTimeRating: [0],
      foodQualityRating: [0],
      customerServiceRating: [0],
      cleanlinessRating: [0],
      feedback: [''],
    });

    this.surveyForm = this.formBuilder.group({
      order: [this.orderId],
      scale: [''],
      surveyAnswers: this.formBuilder.array([]),
      comments: ['', [Validators.required]]
    });
  }

  addSurveyQuestions(surveyName: string, surveyRating: number) {
    const surveyAnswers = this.surveyForm.get('surveyAnswers') as FormArray;
    surveyAnswers.push(
      this.formBuilder.group({
        surveyName: [surveyName, [Validators.required]],
        surveyRating: [0, [Validators.required]],
      })
    );
  }

  getOrderSurveyQuestions() {
    this.executeRequest<GetOrderSurveyQuestionsResponse>({
      state: this.orderSurveyQuestionsResponseState,
      request: this.ordersService.getOrderSurveyQuestions(),
      onSuccess: (response) => {
        this.surveyForm.controls['scale'].setValue(response.data?.surveyScale);

        let surveyQuestions = response.data.surveyQuestions ?? [];
        for (let surveyQuestion of surveyQuestions) {
          this.addSurveyQuestions(surveyQuestion.surveyName, 0);
        }
      }
    });
  }

  onCancelClicked() {
    this.dialogRef.close();
  }

  onSubmitPressed() {
    this.validateForm(this.surveyForm);
    if (this.surveyForm.invalid) {
      return;
    }

    this.executeRequest({
      state: this.createSurveyState,
      request: this.ordersService.submitCustomerSurvey(this.surveyForm.value),
      successMessage: 'Thank you for submitting your survey',
      onSuccess: response => {
        this.dialogRef.close(OrderSurveyStatus.success);
      }
    });
  }
}
