import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnInit,
  PLATFORM_ID,
  signal,
  ViewChild
} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef
} from "@angular/material/dialog";
import {AddressService} from "../../../../data/address.service";
import {AddAddressRequest} from "../../../../data/add-address/add-address-request";
import {GoogleMap} from "@angular/google-maps";
import LatLngLiteral = google.maps.LatLngLiteral;
import {Address} from "../../../../data/address-list/address-list-response";
import {EditAddressRequest} from "../../../../data/edit-address/edit-address-request";
import {AppSvgIconComponent, ButtonComponent, State} from "@smartdining/lib-sd-web-shared";
import { AppDataService } from '@core/services/app-data/app-data.service';
import {GeoPoint, LocationAddress, LocationService} from "@core/services/location-service/location.service";
import { GenericResponse } from '@core/models/generic-response';

@Component({
  selector: 'app-add-address',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    MatDialogContent,
    ButtonComponent,
    MatDialogActions,
    ReactiveFormsModule,
    GoogleMap
],
  templateUrl: './add-or-update-address.component.html',
  styleUrl: './add-or-update-address.component.scss'
})
export class AddOrUpdateAddressComponent extends BaseComponent implements OnInit, AfterViewInit {
  addressService = inject(AddressService);
  formBuilder = inject(FormBuilder);
  dialogRef = inject(MatDialogRef<AddOrUpdateAddressComponent>);
  data: Address = inject(MAT_DIALOG_DATA);
  appDataService = inject(AppDataService);
  platformId = inject(PLATFORM_ID);
  locationService = inject(LocationService);
  changeDetectorRef = inject(ChangeDetectorRef);

  addressForm!: FormGroup;


  state = new State<GenericResponse>();

  fullAddress = signal('');

  @ViewChild('addressInputField') addressInputField?: ElementRef;

  ngAfterViewInit(): void {
    this.initAddressAutoComplete();
  }

  ngOnInit(): void {
    this.initAddressForm();
  }

  initAddressForm() {
    if (this.data != null) {
      let latitude: number | null = null;
      let longitude: number | null = null;
      if (this.data.location.coordinates.length == 2) {
        latitude = this.data.location.coordinates[1];
        longitude = this.data.location.coordinates[0];
        this.center.set({
          lat: latitude,
          lng: longitude
        });
        this.updateLocationAddress(latitude!, longitude!);
      }
      this.addressForm = this.formBuilder.group({
        fullAddress: [this.data.address, [Validators.required]],
        addressNumber: [this.data.address],
        address: [this.data.address],
        city: [this.data.city],
        country: [this.data.country],
        state: [this.data.state],
        zipCode: [this.data.zipCode],
        latitude: [latitude],
        longitude: [longitude]
      });
    } else {
      this.addressForm = this.formBuilder.group({
        fullAddress: ['', [Validators.required]],
        addressNumber: [''],
        address: [''],
        city: [''],
        country: [''],
        state: [''],
        zipCode: [''],
        latitude: [],
        longitude: []
      });
      this.gotoCurrentLocation();
    }
  }

  @ViewChild(GoogleMap) googleMap!: GoogleMap;
  center = signal<LatLngLiteral>({lat: 0, lng: 0});
  zoom = signal(20);
  mapOptions = signal<google.maps.MapOptions>({
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    fullscreenControl: false
  });

  gotoCurrentLocation() {
    this.executeRequest<GeoPoint>({
      state: new State<GeoPoint>(),
      request: this.locationService.getCurrentLocation(),
      showLoader: true,
      onSuccess: (response) => {
        this.googleMap.googleMap?.panTo({
          lat: response.latitude ?? 0,
          lng: response.longitude ?? 0
        });
        this.updateLocationAddress(response.latitude ?? 0, response.longitude ?? 0);
      }
    });
  }

  onMapPositionChanged() {
    const mapCenter = this.googleMap.googleMap?.getCenter();
    this.updateLocationAddress(mapCenter!.lat(), mapCenter!.lng());
  }

  updateLocationAddress(latitude: number, longitude: number) {
    this.executeRequest<LocationAddress>({
      state: new State(),
      request: this.locationService.reverseGeocode(latitude, longitude),
      handleError: false,
      onSuccess: response => {
        this.fullAddress.set(response.formattedAddress ?? 'Unknown location');
      }
    });
  }


  initAddressAutoComplete() {
    if (isPlatformBrowser(this.platformId)) {
      const autocomplete = new google.maps.places.Autocomplete(
        this.addressInputField!.nativeElement,
        {
          componentRestrictions: {country: ['US', 'BJ', 'SN', 'IN']},
          types: ['geocode'],
        }
      );
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place: any = autocomplete.getPlace();

        const locationAddress = this.locationService.getAddressFromComponents(place, place.geometry?.location?.lat(), place.geometry?.location?.lng());

        this.fullAddress.set(locationAddress.formattedAddress);

        this.center.set({
          lat: locationAddress.latitude,
          lng: locationAddress.longitude
        });
        this.changeDetectorRef.detectChanges();

        this.addressForm.patchValue({
          'addressNumber': locationAddress.streetNumber,
          'address': locationAddress.addressLine1,
          'country': locationAddress.country,
          'city': locationAddress.city,
          'state': locationAddress.state,
          'zipCode': locationAddress.zipcode,
          'latitude': locationAddress.latitude,
          'longitude': locationAddress.longitude
        });
      });
    }
  }


  onSelectAddressClicked() {
    if (this.data != null) {
      this.updateAddress();
    } else {
      this.addAddress();
    }

  }

  private addAddress() {
    const request: AddAddressRequest = {
      address: this.addressForm.value.address,
      addressNumber: this.addressForm.value.addressNumber,
      state: this.addressForm.value.state,
      city: this.addressForm.value.city,
      country: this.addressForm.value.country,
      zipCode: this.addressForm.value.zipCode,
      location: {
        type: "Point",
        coordinates: [
          this.addressForm.value.longitude,
          this.addressForm.value.latitude
        ],
        isLocationExact: true
      }
    };
    this.executeRequest({
      state: this.state,
      request: this.addressService.saveAddress(request),
      successMessage: 'Address saved successfully!',
      handleSuccess: true,
      onSuccess: response => {
        this.dialogRef.close(true);
      },
    });
  }

  private updateAddress() {
    const request: EditAddressRequest = {
      address: this.addressForm.value.address,
      addressNumber: this.addressForm.value.addressNumber,
      state: this.addressForm.value.state,
      city: this.addressForm.value.city,
      country: this.addressForm.value.country,
      zipCode: this.addressForm.value.zipCode,
      location: {
        type: "Point",
        coordinates: [
          this.addressForm.value.longitude,
          this.addressForm.value.latitude
        ],
        isLocationExact: true
      }
    };
    this.executeRequest({
      state: this.state,
      request: this.addressService.editAddress(this.data._id, request),
      successMessage: 'Address updated successfully!',
      handleSuccess: true,
      onSuccess: response => {
        this.dialogRef.close(true);
      },
    });
  }

  onCancelPressed() {
    this.dialogRef.close(false);
  }
}









































