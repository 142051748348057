<!--  dine in and pickup Order for mobile view-->
<div class="fixed bottom-0 z-50 lg:hidden py-3 bg-white shadow-1 w-full">
  <div class="flex justify-around">
    <div class="flex items-center gap-3 cursor-pointer px-6 py-4 rounded-full"
         [ngClass]="selectedDineInOption() == DineInOption.dineIn ? 'bg-neutral-100 text-primary-500' : 'bg-white text-primary-500'"
         (click)="onDineOptionSelected(DineInOption.dineIn)">
      <lib-svg-icon src="assets/icons/dine-in.svg"
                    [ngClass]="selectedDineInOption() == DineInOption.dineIn ? 'text-primary-500' : 'text-neutral-500'">
      </lib-svg-icon>
      <p class="text-body2">Dine In</p>
    </div>
    <div class="flex items-center gap-3 cursor-pointer px-6 py-4 rounded-full"
         [ngClass]="selectedDineInOption() == DineInOption.pickupOrder ? 'bg-neutral-100 text-primary-500' : 'bg-white text-neutral-500'"
         (click)="onDineOptionSelected(DineInOption.pickupOrder)">
      <lib-svg-icon src="assets/icons/food-orders.svg"
                    [ngClass]="selectedDineInOption() == DineInOption.pickupOrder ? 'text-primary-500' : 'text-neutral-500'">
      </lib-svg-icon>
      <p class="text-body2">Pickup Order</p>
    </div>
  </div>
</div>

@if (restaurantDetailState.success()) {
  <div class="container mx-auto py-4 px-4 sm:px-6 lg:px-8">
    <div class="flex flex-col lg:flex-row lg:items-start lg:space-x-6">
      <div class="w-full lg:w-2/3">
        <div class="flex flex-col sm:flex-row items-start sm:items-center gap-4">
          <div class="w-full sm:w-40 rounded-3xl overflow-hidden">
            <lib-network-image [placeholderImage]="'assets/images/background/restaurant-placeholder.png'"
                               [imageUrl]="restaurantDetailState.response()?.data?.banner?.url">
            </lib-network-image>
          </div>
          <div class="flex flex-col gap-3">
            <div class="flex flex-wrap gap-2 items-center">
              <p class="text-body2">{{ restaurantDetailState.response()?.data?.restaurantName }}</p>
              <app-rating-count [rating]="restaurantDetailState.response()?.data?.rating ?? 0"
                                [count]="restaurantDetailState.response()?.data?.ratingCount ?? 0"></app-rating-count>
            </div>
            <div class="flex flex-wrap gap-2">
              <app-chip [name]="numberToPricePoint(restaurantDetailState.response()?.data?.pricePoint ?? 0)"></app-chip>
              @for (cuisine of restaurantDetailState.response()?.data?.cuisines; track cuisine) {
                <app-chip [name]="cuisine.name"></app-chip>
              }
            </div>
            <div class="flex items-center gap-2 flex-wrap">
              <lib-svg-icon src="assets/icons/location.svg" class="text-neutral-400"></lib-svg-icon>
              <p class="text-sky-400 text-body2">{{ distanceInMiles() }}</p>
              <p class="text-neutral-400 text-body2">-
                {{ arrayToCSV([restaurantDetailState.response()?.data?.location?.address1, restaurantDetailState.response()?.data?.location?.city, restaurantDetailState.response()?.data?.location?.state]) }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-1/3 mt-6 lg:mt-0">
        <div class="flex flex-col gap-4">
          <div class="flex flex-wrap gap-3">
            <button class="flex-grow p-3 border rounded-md text-body2" (click)="onFavoriteClicked($event)">
              <div class="flex gap-3 justify-center items-center">
                <lib-svg-icon
                  [src]="restaurantLiked() ? 'assets/icons/heart-filled.svg' : 'assets/icons/heart-outline.svg'"
                  class="w-6 h-6 items-center"
                  [ngClass]="restaurantLiked() ? 'text-pink-500' : 'text-neutral-400'">
                </lib-svg-icon>
                <p class="text-body2"> Favorite </p>
              </div>
            </button>
            @if (destinationGeoPoint().latitude && destinationGeoPoint().longitude) {
              <app-direction-button
                class="flex-grow"
                [destinationLatitude]="destinationGeoPoint().latitude"
                [destinationLongitude]="destinationGeoPoint().longitude">
              </app-direction-button>
            }
            <button class="flex-grow p-3 bg-blue-100 border rounded-md text-body2" (click)="onShareClicked()">
              <div class="flex gap-3 justify-center items-center">
                <lib-svg-icon src="assets/icons/share.svg" class="w-6 h-6 items-center text-blue-700"></lib-svg-icon>
                <p class="text-body2"> Share </p>
              </div>
            </button>
          </div>
          <div class="flex flex-wrap gap-3 items-center">
            <div class="flex items-center gap-2">
              <lib-svg-icon src="assets/icons/phone.svg" class="text-neutral-400"></lib-svg-icon>
              <p class="text-sky-400 text-body2">{{ restaurantDetailState.response()?.data?.phoneNumber }}</p>
            </div>
            <div class="flex items-center gap-2">
              <lib-svg-icon src="assets/icons/mail.svg" class="text-neutral-400"></lib-svg-icon>
              <p class="text-sky-400 text-body2">{{ restaurantDetailState.response()?.data?.email }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="border-b border-neutral-100 container mx-auto"></div>
}

<div class="container mx-auto py-4 px-4 sm:px-6 lg:px-8">
  <div class="lg:grid lg:grid-cols-7 lg:gap-6">
    <!-- Pickup Options Section -->
    <div class="pickup-options lg:col-span-2 lg:order-last">
      <div class="lg:sticky lg:top-20">
        <form [formGroup]="filterForm" class="flex flex-col gap-8 shadow-2 px-6 py-6 rounded-md mb-6 lg:mb-0">
          @if (!restaurantDetailState.response()?.data?.orderSettings?.enableOnlineOrdering) {
            <div class="flex flex-col gap-6">
              <p class="text-body2 text-center text-neutral-500">We accept in-house orders only. Please visit our
                restaurant or call us to place your order</p>
              <lib-button (click)="onCallForOrderClicked()" appearance="outline" [fullWidth]="true">
                <div class="flex gap-3 items-center justify-center">
                  <lib-svg-icon src="assets/icons/phone.svg" [size]="15"></lib-svg-icon>
                  <span>Call to Order</span>
                </div>
              </lib-button>
            </div>
          } @else {
            <div class="flex flex-col gap-3">
              <p class="text-neutral-400 text-body2">Pickup Options</p>
              <div class="flex sm:flex-row lg:flex-row lg:gap-4">
                <lib-radio-button formControlName="pickupOption" groupName="pickup-options" title="ASAP"
                                  value="ASAP" class="w-full"></lib-radio-button>
                <lib-radio-button formControlName="pickupOption" groupName="pickup-options" title="Schedule"
                                  value="LATER" class="w-full"></lib-radio-button>
              </div>
            </div>
          }

          @if (filterForm.value['pickupOption'] == 'LATER') {
            <div class="flex gap-12 items-center">
              <lib-date-selector-input formControlName="date" title="Select Date"
                                       (valueChanged)="onDateChanged($event)" class="w-full"></lib-date-selector-input>
              <lib-single-item-picker-input formControlName="time" [title]="'Select Time'" [display]="'formattedTime'"
                                            [items]="timeAvailabilities" class="w-full"></lib-single-item-picker-input>
            </div>
          }
          @if (restaurantDetailState.response()?.data?.orderSettings?.enableOnlineOrdering) {
            <lib-button [fullWidth]="true" [disabled]="cartService.cart().quantity === 0" type="submit"
                        (click)="onViewCartPressed()">Checkout
              - {{ cartService.cart().quantity }} Item(s)
            </lib-button>
          }
        </form>
      </div>
    </div>

    <!-- Menu Section -->
    <div class="food-menu lg:col-span-5 lg:order-first">
      <div class="flex flex-col gap-4 py-4 bg-white sticky top-16 z-30">

        <div class="flex items-center whitespace-nowrap overflow-x-scroll space-x-4">
          @for (menu of pickupMenuState.response()?.data; track menu) {
            <div (click)="onMenuTabClicked(menu)" class="flex">
              <p
                [ngClass]="selectedMenu() === menu ? 'px-2 rounded-md bg-primary-500 text-white' : 'px-2 rounded-md text-neutral-500 bg-neutral-100'"
                class="text-body2 cursor-pointer py-2">
                {{ menu.name }}
              </p>
            </div>
          }
        </div>

        <div class="flex items-center justify-between">
          <lib-outline-text-input (changeValue)="onMenuSearch($event)"
                                  (actionIconClicked)="onSearchFilterCleared()"
                                  [(ngModel)]="searchFilter"
                                  placeholder="Search..."
                                  iconSrc="assets/icons/search.svg"
                                  actionIcon="assets/icons/close-circle.svg">
          </lib-outline-text-input>
        </div>

        <!-- Categories for selected menu -->
        @if (selectedMenu()) {
          <div class="flex items-center whitespace-nowrap overflow-x-auto space-x-6 border-b">
            @for (category of filteredMenu()?.categories ?? []; track category.category?._id) {
              <div (click)="onCategoryTabClicked(toDashCase(category.category?.name))" class="flex">
                <p [ngClass]="toDashCase(category.category?.name)"
                   class="nav-link nav-link-inactive text-body2 text-neutral-500 cursor-pointer">
                  {{ category.category?.name }}
                </p>
              </div>
            }
          </div>
        }

      </div>

      @if (pickupMenuState.loading()) {
        <div class="flex flex-col gap-10">
          <lib-shimmer type="list"></lib-shimmer>
          <lib-shimmer type="list"></lib-shimmer>
          <lib-shimmer type="list"></lib-shimmer>
          <lib-shimmer type="list"></lib-shimmer>
        </div>
      }

      @if (filteredMenu()) {
        @for (category of filteredMenu()?.categories ?? []; track category.category?._id) {
          @if (category.menuItems && category.menuItems.length > 0) {
            <div class="section" [ngClass]="toDashCase(category.category?.name)"
                 [id]="toDashCase(category.category?.name)">
              <div (click)="onCategoryToggle(category)"
                   class="flex justify-between items-center text-body2
                 text-neutral-500 cursor-pointer whitespace-nowrap
                  py-3 mb-4 select-none">
                <p class="text-body2 text-primary-500 font-bold">{{ category.category?.name }}</p>
                @if (category.isExpanded()) {
                  <lib-svg-icon src="assets/icons/toggle-up.svg" [size]="10"
                                class="text-primary-500 mr-3 cursor-pointer"></lib-svg-icon>
                } @else {
                  <lib-svg-icon src="assets/icons/toggle-down.svg" [size]="10"
                                class="text-primary-500 mr-3 cursor-pointer"></lib-svg-icon>
                }
              </div>

              @if (category.isExpanded()) {
                <div class="flex flex-col gap-3 py-3">
                  <div class="grid grid-cols-1 gap-6 md:grid-cols-2">
                    @for (menuItem of category?.menuItems ?? []; track menuItem._id) {
                      @if (menuItem.filterMatched()) {
                        <div class="flex items-center gap-4 border rounded-xl">
                          <div class="h-[100px] rounded-md overflow-hidden">
                            <lib-network-image class="select-none"
                                               [placeholderImage]="'assets/images/background/restaurant-placeholder.png'"
                                               [imageUrl]="menuItem.image?.url">
                            </lib-network-image>
                          </div>

                          <div class="flex gap-2 w-full px-4 py-3">
                            <div class="flex flex-col items-start gap-3">
                              <p class="text-neutral-900 text-body2 select-none">{{ menuItem.name }}</p>
                              <p class="text-primary-900 text-body2 select-none">{{ menuItem.price?.getPriceString() }}</p>
                              <p class="text-neutral-400 text-body2">{{ menuItem.description }}</p>
                            </div>

                            <div class="flex-grow"></div>

                            <div class="flex flex-col items-center gap-3">
                              @if (restaurantDetailState.response()?.data?.orderSettings?.enableOnlineOrdering) {
                                <span
                                  class="text-primary-500 border border-primary-500 text-body2 px-4 py-2 rounded-md cursor-pointer"
                                  (click)="onAddButtonClicked(menuItem)">
                                <span
                                  class="flex items-center gap-1 text-body2 select-none"><p>ADD</p> <sup>+</sup></span>
                              </span>

                                @if (menuItem.isCustomizableItem()) {
                                  <p class="text-green-500 text-caption">Customisable</p>
                                }
                              }
                            </div>
                          </div>
                        </div>
                      }
                    }
                  </div>
                </div>
              }
            </div>
          }
        }
      }

    </div>
  </div>

  @if (restaurantDetailState.response()?.data?.orderSettings?.enableOnlineOrdering) {
    <div class="fixed bottom-4 transform -translate-x-1/2 left-1/2 w-full max-w-lg z-50">
      <div
        class="flex items-center justify-between px-4 py-3 gap-4 sm:gap-32 rounded-3xl bg-primary-600 bg-opacity-95">
        <div class="flex items-center gap-4">
        <span
          class="flex justify-center items-center text-black bg-white h-5 w-5 rounded-full text-body2">{{ cartService.cart().quantity }}</span>
          <p class="text-body2 text-white">Items</p>
          <p class="text-body2 text-white">|</p>
          <p class="text-body2 text-white font-medium">$ {{ cartService.cart().getSubtotal | number:'1.2-2' }}</p>
        </div>
        <div class="flex items-center gap-2 cursor-pointer px-3 py-2 rounded-3xl bg-primary-500" (click)="onViewCartPressed()">
          <lib-svg-icon src="assets/icons/cart.svg" class="text-white"></lib-svg-icon>
          <p class="text-body2 text-white">View Cart</p>
        </div>
      </div>
    </div>
  }
</div>
