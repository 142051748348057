<app-base-dialog
  title="Select Address"
  i18n-title="Select Address Title|Dialog title for selecting a location@@selectAddressOverlay.title">

  <div body>
    <div class="flex flex-col gap-3 h-full">
      <div class="flex flex-col gap-3 relative mx-0 px-0 h-full">

        <google-map
          width="100%"
          height="380px"
          [center]="center()"
          [zoom]="zoom()"
          [options]="mapOptions()"
          mapId="googleMap"
          (idle)="onMapPositionChanged()">
        </google-map>

        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <lib-svg-icon
            src="assets/icons/location-pin.svg"
            [size]="60">
          </lib-svg-icon>
        </div>

        <div class="absolute top-4 inset-x-0">
          <div class="flex items-center bg-white ring-2 ring-blue-500 px-2 mx-3 shadow-3 rounded-xl">
            <lib-svg-icon src="assets/icons/location.svg" [size]="24" class="text-blue-500"></lib-svg-icon>
            <input
              #addressInputField
              type="text"
              placeholder="Search Location"
              i18n-placeholder="Search Location Placeholder|Placeholder text in location search bar@@selectAddressOverlay.searchPlaceholder"
              class="w-full border-transparent border-neutral-100 focus:outline-0 focus:border-0 focus:border-transparent focus:ring-0 flex-grow py-3 px-2 placeholder-neutral-500 bg-white rounded-full sm:py-5" />
          </div>
        </div>

        <span
          class="flex h-12 w-12 justify-center items-center shadow-3 rounded-full absolute bottom-4 right-4 bg-blue-500 cursor-pointer"
          (click)="gotoCurrentLocation()">
          <lib-svg-icon [size]="18" class="text-white" src="assets/icons/gps.svg"></lib-svg-icon>
        </span>

      </div>

      <div class="flex items-center gap-3 border py-3 px-2 rounded-md w-full">
        <lib-svg-icon src="assets/icons/location.svg" class="text-neutral-500"></lib-svg-icon>
        <p class="text-body2 max-w-max">
          {{ fullAddress() }}
        </p>
      </div>
    </div>
  </div>

  <div actions>
    <div class="whitespace-nowrap grid grid-cols-5">

      <div class="flex flex-col cursor-pointer col-span-4 py-2" (click)="onDetectLocationClicked()">
        <div class="flex items-center gap-4">
          <lib-svg-icon src="assets/icons/gps.svg" class="text-primary-500" [size]="16"></lib-svg-icon>
          <div class="flex flex-col">
            <p class="text-body2 text-primary-500"
               i18n="Locate Current Place Title|Button to use device GPS to detect location@@selectAddressOverlay.locateTitle">
              Locate current place
            </p>
            <p class="text-body2 text-neutral-400"
               i18n="Locate Current Place Subtitle|Subtext for GPS location detection@@selectAddressOverlay.locateSubtitle">
              Using Device GPS
            </p>
          </div>
        </div>
      </div>

      <div class="flex gap-4 justify-end w-full py-2 col-span-1">
        <lib-button appearance="primary"
                    (click)="onSelectClicked()"
                    i18n="Select Button|Confirm address selection button@@selectAddressOverlay.selectButton">
          Select
        </lib-button>
      </div>
    </div>
  </div>
</app-base-dialog>
