import {Component, computed, inject, OnInit, signal} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {FormsModule} from "@angular/forms";
import {FoodItem, FoodItemModifierGroup} from "../../../data/menu/menu";
import {DecimalPipe, NgClass} from "@angular/common";
import {ModifierGroupSelectionType} from "@core/constants/food-item-constants";
import {
  AppSvgIconComponent,
  ButtonComponent,
  CheckboxComponent, CloseButtonComponent,
  CounterInputComponent,
  NetworkImageComponent, RadioButtonComponent
} from '@smartdining/lib-sd-web-shared';
import {BaseComponent} from "@core/base/base-component";

@Component({
  selector: 'app-customizable-item-selector-overlay',
  standalone: true,
  imports: [
    NetworkImageComponent,
    CounterInputComponent,
    FormsModule,
    DecimalPipe,
    ButtonComponent,
    CheckboxComponent,
    RadioButtonComponent,
    AppSvgIconComponent,
    NgClass,
    CloseButtonComponent
  ],
  templateUrl: './customizable-item-selector-overlay.component.html',
  styleUrl: './customizable-item-selector-overlay.component.scss'
})
export class CustomizableItemSelectorOverlayComponent extends BaseComponent implements OnInit {

  foodItem: FoodItem = inject(MAT_BOTTOM_SHEET_DATA);
  bottomSheetRef = inject(MatBottomSheetRef);
  activeModifierGroup = signal<FoodItemModifierGroup | null>(null);

  noOfItems = signal(1);

  selectionViewType = signal<SelectionViewType | null>(null);

  totalSteps = signal(0);
  currentStepIndex = 0;

  ngOnInit(): void {
    this.handleFoodItem();
  }

  handleFoodItem() {

    let modifierGroups = this.foodItem.modifierGroups ?? [];

    if (this.foodItem.price?.type == 'SIZE_PRICE') {
      this.selectionViewType.set(SelectionViewType.sizePrice);
      this.totalSteps.set(modifierGroups.length + 1);
    } else {
      this.selectionViewType.set(SelectionViewType.modifierGroups);
      this.totalSteps.set(modifierGroups.length);
    }
  }

  currentGroupIndex = computed(() => {
    let currentGroupIndex = this.foodItem.modifierGroups.indexOf(this.activeModifierGroup()!);
    return currentGroupIndex;
  });

  isNextAvailable() {
    if (this.currentStepIndex < (this.totalSteps() - 1)) {
      return true;
    }

    return false;
  }

  isLastOption() {
    if (this.currentGroupIndex() == this.foodItem.modifierGroups.length - 1) {
      return true;
    }
    return false;
  }

  isPreviousAvailable() {
    if (this.currentStepIndex > 0) {
      return true;
    }

    return false;
  }

  onPreviousPressed() {

    if (this.foodItem.price?.type == 'SIZE_PRICE' && this.currentStepIndex == 0) {
      this.selectionViewType.set(SelectionViewType.sizePrice);
      this.activeModifierGroup.set(null);
      this.currentStepIndex = this.currentStepIndex - 1;
      return;
    }

    if (this.currentGroupIndex() > 0) {
      this.currentStepIndex = this.currentStepIndex - 1;
      this.activeModifierGroup.set(this.foodItem.modifierGroups[this.currentGroupIndex() - 1]);
    }
  }

  onNextPressed() {

    if (this.selectionViewType() == SelectionViewType.sizePrice) {

      let selectedSizePrice = this.foodItem.price?.sizePrices.find(x => x.isSelected() == true);
      console.log(this.foodItem.price?.sizePrices);
      if(selectedSizePrice == null) {
        this.toasterService.warning('Please select a variant to proceed.');
        return;
      }

      let modifierGroups = this.foodItem.modifierGroups ?? [];
      if (modifierGroups.length > 0) {
        this.activeModifierGroup.set(this.foodItem.modifierGroups[0]);
      }
      this.selectionViewType.set(SelectionViewType.modifierGroups);
      this.currentStepIndex = this.currentStepIndex + 1;
      return;
    }


    if (this.currentGroupIndex() < this.foodItem.modifierGroups.length) {
      this.activeModifierGroup.set(this.foodItem.modifierGroups[this.currentGroupIndex() + 1]);
      this.currentStepIndex = this.currentStepIndex + 1;
    }
  }

  onCloseClicked() {
    this.bottomSheetRef.dismiss();
  }

  onAddItemPressed() {
    let selection: CustomizableItemSelection = {
      quantity: this.noOfItems(),
      foodItem: this.foodItem
    };
    this.bottomSheetRef.dismiss(selection);
  }

  protected readonly ModifierGroupSelectionType = ModifierGroupSelectionType;
  SelectionViewType = SelectionViewType;
}

export interface CustomizableItemSelection {
  quantity: number;
  foodItem: FoodItem;
}

export enum SelectionViewType {
  sizePrice,
  modifierGroups
}
