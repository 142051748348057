import {Component, inject, input, OnInit, output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CommonDataService} from "@core/services/common-data/common-data.service";
import {BookingsService} from "../../../data/bookings.service";
import {
  BaseInputComponent,
  ButtonComponent,
  MultiSelectionFieldComponent,
  NetworkImageComponent, OverlayService, ShimmerComponent,
  SingleSelectionFieldComponent,
  State,
  TextInputComponent
} from "@smartdining/lib-sd-web-shared";
import {OccasionsResponse} from "@core/services/common-data/occasions/occasions-response";
import {TagsResponse} from "@core/services/common-data/tags/tags-response";
import {WaitTimesResponse} from "../../../data/wait-times/wait-times-response";
import {WaitTimesRequest} from "../../../data/wait-times/wait-times-request";
import {BaseComponent} from "@core/base/base-component";
import {RestaurantDetailResponse} from "../../../../restaurants/data/restaurant-details/restaurant-detail-response";
import {RestaurantService} from "../../../../restaurants/data/restaurant.service";
import {arrayToCSV} from "@shared/utils/string-utils";
import {GenericResponse} from "@core/models/generic-response";
import {LoginUserType} from "@core/models/login-user-type";
import {
  MakeReservationRequestCustomerInfo
} from "../../../data/make-reservation/make-reservation-request";
import {
  JoinWaitListRequest
} from "../../../data/join-wait-list/join-wait-list-request";
import {
  WaitlistConfirmationOverlayComponent
} from "../../overlays/waitlist-confirmation-overlay/waitlist-confirmation-overlay.component";
import {Constants} from "@core/constants/constants";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {MatDialog} from "@angular/material/dialog";
import {
  SeatingPreferenceResponse
} from "@core/services/common-data/seating-preference/seating-preference-response";
import {
  PartySizeSelectorInputComponent
} from "../../../../restaurants/presentation/components/dine-in-global-filter/party-size-selector-input/party-size-selector-input.component";
import {
  AuthWizardOverlayComponent
} from "../../../../auth/presentation/overlays/auth-wizard-overlay/auth-wizard-overlay.component";

@Component({
  selector: 'app-join-waitlist',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    SingleSelectionFieldComponent,
    TextInputComponent,
    NetworkImageComponent,
    ButtonComponent,
    BaseInputComponent,
    ShimmerComponent,
    MultiSelectionFieldComponent,
    PartySizeSelectorInputComponent
  ],
  templateUrl: './join-waitlist.component.html',
  styleUrl: './join-waitlist.component.scss'
})
export class JoinWaitlistComponent extends BaseComponent implements OnInit {
  restaurantId = input('');
  partySize = input<number | null>(4);

  onLoginNeeded = output();

  dialog = inject(MatDialog);
  formBuilder = inject(FormBuilder);
  commonDataService = inject(CommonDataService);
  bookingService = inject(BookingsService);
  restaurantService = inject(RestaurantService);
  authStoreService = inject(AuthStoreService);
  overlayService = inject(OverlayService);

  waitListForm!: FormGroup;

  occasionsState = new State<OccasionsResponse>();
  tagsState = new State<TagsResponse>();
  waitTimesState = new State<WaitTimesResponse>();
  restaurantDetailState = new State<RestaurantDetailResponse>();
  joinWaitListState = new State<GenericResponse>();
  seatingPreferenceState = new State<SeatingPreferenceResponse>();

  protected readonly arrayToCSV = arrayToCSV;

  ngOnInit(): void {
    this.initForm();
    this.getOccasions();
    this.getTags();
    this.getWaitTimes();
    this.getRestaurantDetails();
    this.getSeatingPreference();
  }

  initForm() {
    this.waitListForm = this.formBuilder.group({
      partySize: [4, [Validators.required]],
      occasion: [],
      seatingPreferences: [null],
      tags: [[]],
      note: [''],
      seatingOption: [],
    });
  }

  private getOccasions() {
    this.executeRequest<OccasionsResponse>({
      state: this.occasionsState,
      request: this.commonDataService.getOccasions(),
    });
  }

  private getSeatingPreference() {
    this.executeRequest({
      state: this.seatingPreferenceState,
      request: this.commonDataService.getSeatingPreference(),
    });
  }

  getRestaurantDetails() {
    this.executeRequest({
      state: this.restaurantDetailState,
      request: this.restaurantService.getRestaurantDetails(this.restaurantId()),
    });
  }

  private getTags() {
    this.executeRequest<TagsResponse>({
      state: this.tagsState,
      request: this.commonDataService.getTags(),
    });
  }

  onPartSizeChanged($event: number) {
    this.getWaitTimes();
  }

  private getWaitTimes() {
    const request: WaitTimesRequest = {
      restaurantId: this.restaurantId(),
      partySize: this.waitListForm.value.partySize
    };
    this.executeRequest<WaitTimesResponse>({
      state: this.waitTimesState,
      request: this.bookingService.getWaitTimes(request)
    });
  }

  onJoinWaitListClicked() {
    this.validateForm(this.waitListForm);
    if (this.waitListForm.invalid) {
      return;
    }

    this.handleJoinWaitList();

  }

  handleJoinWaitList() {
    if (this.authStoreService.isAppUserLoggedIn()) {
      this.joinWaitList(LoginUserType.appUser);
      return;
    }

    this.overlayService.openModal(AuthWizardOverlayComponent);
    this.authStoreService.loginSuccessful = () => {
      this.onJoinWaitListClicked();
    };
  }

  private joinWaitList(userType: LoginUserType) {
    const tableWaitTime = this.waitTimesState.response()?.data.tableWaitTime;
    if (tableWaitTime == null) {
      this.toasterService.error('Tables are not available at this moment, kindly contact the restaurant host.');
      return;
    }
    const appUser = this.authStoreService.getAppUser();

    let customerInfo: MakeReservationRequestCustomerInfo;
    switch (userType) {
      case LoginUserType.appUser:
        customerInfo = {
          firstName: appUser?.data.firstName ?? '',
          lastName: appUser?.data.lastName ?? '',
          phoneNumber: appUser?.data.phoneNumber ?? '',
          email: appUser?.data.email ?? ''
        };
        break;
    }
    const waitTime = this.waitTimesState.response()?.data;

    let formValue = this.waitListForm.value;

    let tags = formValue.tags ?? [];

    if (tags.length <= 0) {
      tags = ['NO'];
    }

    const occasion = formValue.occasion ?? 'NO';

    const seatingOption = formValue.seatingPreferences;

    const currentTime = new Date().toISOString();
    const request: JoinWaitListRequest = {
      restaurantId: waitTime?.restaurantId,
      partySize: formValue.partySize,
      autoAssignTable: true,
      time: currentTime,
      quotedWaitTime: tableWaitTime,
      customerInfo: customerInfo,
      occasion: occasion,
      seatingOption: seatingOption,
      tags: tags,
      note: this.waitListForm.value.note,
      coverSource: 'GUEST_WEB_APP',
      tableIds: []
    };

    this.executeRequest({
      state: this.joinWaitListState,
      request: this.bookingService.joinWaitList(request),
      onSuccess: response => {
        this.waitListForm.reset();
        let dialogRef = this.dialog.open(WaitlistConfirmationOverlayComponent, {
          ...Constants.defaultDialogConfig,
          data: response
        });

        dialogRef.afterClosed().subscribe(() => {
          this.router.navigate([`dine-in/${this.restaurantId()}`]);
        });
      }
    });
  }
}
