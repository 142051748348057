<div class="header flex justify-center items-center px-3 py-6 border-b border-neutral-200">
  <p class="text-body1 font-bold" i18n="@@filter.title">Filter</p>
  <p class="absolute right-3 rounded-lg p-3 border border-neutral-300 cursor-pointer hover:bg-neutral-50"
     (click)="onCloseClicked()">
    <lib-svg-icon src="assets/icons/close.svg" class="text-neutral-800" [size]="12"></lib-svg-icon>
  </p>
</div>

<mat-dialog-content class="body overflow-y-auto max-h-60vh py-6 px-8 h-50 my-12">

  <form [formGroup]="filterForm" class="flex flex-col gap-5" (ngSubmit)="onApplyClicked()">

    <lib-single-selection-field
      formControlName="rating"
      [title]="'Rating'"
      [display]="''"
      [items]="ratings()"
      [iconSrc]="'assets/icons/star.svg'"
      iconColor="text-warning-500">
    </lib-single-selection-field>

    <lib-multi-selection-field
      formControlName="cuisines"
      [title]="'Cuisines'"
      [items]="cuisines()?.data ?? []"
      display="name"
      value="name"
      identifier="name">
    </lib-multi-selection-field>

    <lib-single-selection-field
      formControlName="pricePoint"
      [title]="'Price Point'"
      display="displayName"
      [items]="pricePointsState.response()?.data ?? []">
    </lib-single-selection-field>

    <!--    <div class="space-y-6">-->
    <!--      <p class="text-body1 text-neutral-900" i18n="@@filter.rating.label">Rating</p>-->
    <!--      <div class="flex gap-3">-->
    <!--        @for (rating of ratings(); track rating) {-->
    <!--          <div class="flex items-center rounded-lg px-4 py-3 gap-3 cursor-pointer" (click)="onRatingSelected(rating)"-->
    <!--               [ngClass]="rating == selectedRating() ? 'border-2 border-primary-600' : 'border-2 border-neutral-300'">-->
    <!--            <lib-svg-icon src="assets/icons/star.svg" class="text-warning-500" [size]="18"></lib-svg-icon>-->
    <!--            <p class="text-body2 text-black">{{ rating }}</p>-->
    <!--          </div>-->
    <!--        }-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="border-b border-neutral-100"></div>-->

    <!--    <div class="space-y-3">-->
    <!--      <p class="text-body1 text-neutral-900" i18n="@@filter.cuisines.label">Cuisines</p>-->
    <!--      <div class="flex flex-wrap gap-3">-->
    <!--        @for (cuisine of cuisines()?.data; track cuisine) {-->
    <!--          <div class="flex items-center rounded-lg px-4 py-3 gap-3 cursor-pointer"-->
    <!--               (click)="onCuisineSelected(cuisine)"-->
    <!--               [ngClass]="false ? 'border-2 border-primary-600' : 'border-2 border-neutral-300'">-->
    <!--            <p class="text-body2 text-black">{{ cuisine.name }}</p>-->
    <!--          </div>-->
    <!--        }-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="space-y-3">-->
    <!--      <p class="text-body1 text-neutral-900" i18n="@@filter.price.label">Price Points</p>-->
    <!--      <div class="flex flex-wrap gap-3">-->
    <!--        @for (pricePoint of pricePoints(); track pricePoint) {-->
    <!--          <div class="flex items-center rounded-lg px-4 py-3 gap-3 cursor-pointer"-->
    <!--               (click)="onPricePointSelected(pricePoint.value)"-->
    <!--               [ngClass]="pricePoint == selectedPricePoint() ? 'border-2 border-primary-600' : 'border-2 border-neutral-300'">-->
    <!--            <p class="text-body2 text-black">{{ pricePoint.displayName }}</p>-->
    <!--          </div>-->
    <!--        }-->
    <!--      </div>-->
    <!--    </div>-->

  </form>

</mat-dialog-content>

<div class="border-t">
  <mat-dialog-actions>
    <div class="action flex w-full justify-end gap-4 items-center px-3 py-2 border-neutral-200">
      <lib-button appearance="outline" (click)="onClearClicked()" i18n="@@filter.clear">Clear All</lib-button>
      <lib-button (click)="onApplyClicked()" i18n="@@filter.apply">Apply</lib-button>
    </div>
  </mat-dialog-actions>
</div>
