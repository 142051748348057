import {Component, inject, OnInit, output} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {FormBuilder, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {
  ReservationDataForView
} from "../../../../../restaurants/presentation/pages/dine-in-restaurant-details/dine-in-restaurant-details.component";
import {CommonDataService} from "@core/services/common-data/common-data.service";
import {OccasionsResponse} from "@core/services/common-data/occasions/occasions-response";
import {
  AppSvgIconComponent,
  ButtonComponent, MultiSelectionFieldComponent, NetworkImageComponent,
  SingleSelectionFieldComponent,
  State, TextInputComponent
} from "@smartdining/lib-sd-web-shared";
import {TagsResponse} from "@core/services/common-data/tags/tags-response";
import {
  MakeReservationRequest,
  MakeReservationRequestCustomerInfo
} from "../../../../data/make-reservation/make-reservation-request";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {BookingsService} from "../../../../data/bookings/bookings.service";
import {DatePipe} from "@angular/common";
import {LoginUserType} from "@core/models/login-user-type";
import {MakeReservationResponse} from "../../../../data/make-reservation/make-reservation-response";
import {
  BookingConfirmationOverlayComponent
} from "../../booking-confirmation-overlay/booking-confirmation-overlay.component";
import {Constants} from "@core/constants/constants";
import {
  SeatingPreferenceResponse
} from "@core/services/common-data/seating-preference/seating-preference-response";
import {AppDataService} from "@core/services/app-data/app-data.service";
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';

@Component({
  selector: 'app-reserve-table-overlay',
  standalone: true,
  imports: [
    ButtonComponent,
    SingleSelectionFieldComponent,
    ReactiveFormsModule,
    TextInputComponent,
    DatePipe,
    MultiSelectionFieldComponent,
    NetworkImageComponent,
    AppSvgIconComponent
  ],
  templateUrl: './reserve-table-overlay.component.html',
  styleUrl: './reserve-table-overlay.component.scss'
})
export class ReserveTableOverlayComponent extends BaseComponent implements OnInit {

  onLoginNeeded = output();

  data: ReservationDataForView = inject(DIALOG_DATA);
  dialog = inject(MatDialog);
  dialogRef = inject(DialogRef<ReserveTableOverlayComponent>);
  formBuilder = inject(FormBuilder);
  commonDataService = inject(CommonDataService);
  authStoreService = inject(AuthStoreService);
  bookingsService = inject(BookingsService);
  appDataService = inject(AppDataService);

  reservationForm!: FormGroup;

  occasionsState = new State<OccasionsResponse>();
  tagsState = new State<TagsResponse>();
  seatingPreferenceState = new State<SeatingPreferenceResponse>();
  reservationState = new State<MakeReservationResponse>();


  ngOnInit(): void {
    this.getOccasions();
    this.getTags();
    this.getSeatingPreference();
    this.reservationForm = this.formBuilder.group({
      tags: [[]],
      occasion: [],
      note: [''],
      seatingPreferences: []
    });
  }

  reserveClicked() {
    if (this.reservationForm.invalid) {
      return;
    }

    this.handleReservation();
  }

  handleReservation() {
    if (this.authStoreService.isAppUserLoggedIn()) {
      this.reserveTable(LoginUserType.appUser);
      return;
    }

    this.onLoginNeeded.emit();
    this.authStoreService.loginSuccessful = () => {
      this.reserveClicked();
    };
  }

  reserveTable(userType: LoginUserType) {
    const appUser = this.authStoreService.getAppUser();

    let customerInfo: MakeReservationRequestCustomerInfo;
    switch (userType) {
      case LoginUserType.appUser:
        customerInfo = {
          firstName: appUser?.data.firstName ?? '',
          lastName: appUser?.data.lastName ?? '',
          phoneNumber: appUser?.data.phoneNumber ?? '',
          email: appUser?.data.email ?? ''
        };
        break;
    }

    let formValue = this.reservationForm.value;

    let tags = formValue.tags ?? [];

    if (tags.length <= 0) {
      tags = ['NO'];
    }

    const occasion = formValue.occasion ?? 'NO';

    const seatingOption = formValue.seatingPreferences;


    const request: MakeReservationRequest = {
      restaurantId: this.data.restaurantId,
      shiftId: this.data.availability?.shiftId,
      partySize: this.data.partySize,
      time: this.data.availability?.time,
      autoAssignTable: true,
      customerInfo: customerInfo,
      note: this.reservationForm.value.note,
      tags: tags,
      occasion: occasion,
      seatingOption: seatingOption,
      coverSource: 'GUEST_WEB_APP',
      tableIds: []
    };

    this.executeRequest<MakeReservationResponse>({
      state: this.reservationState,
      request: this.bookingsService.makeReservation(request),
      onSuccess: response => {
        this.dialogRef.close();
        this.dialog.open(BookingConfirmationOverlayComponent, {
          ...Constants.defaultDialogConfig,
          data: response
        });
      }
    });
  }


  private getOccasions() {
    this.executeRequest<OccasionsResponse>({
      state: this.occasionsState,
      request: this.commonDataService.getOccasions(),
    });
  }

  private getTags() {
    this.executeRequest<TagsResponse>({
      state: this.tagsState,
      request: this.commonDataService.getTags(),
    });
  }

  private getSeatingPreference() {
    this.executeRequest<SeatingPreferenceResponse>({
      state: this.seatingPreferenceState,
      request: this.commonDataService.getSeatingPreference(),
    });
  }
}
