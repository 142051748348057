import {Component, inject, OnInit, signal} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "@core/base/base-component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {TableAvailabilitiesRequest} from "../../../data/table-availabilities/table-availabilities-request";
import {TableAvailabilitiesResponse} from "../../../data/table-availabilities/table-availabilities-response";
import {
  TimeAvailabilityForView
} from "../../../../restaurants/presentation/pages/dine-in-restaurant-details/dine-in-restaurant-details.component";
import {
  BaseDialogComponent,
  ButtonComponent,
  DateSelectorInputComponent, SingleSelectionFieldComponent,
  State,
  TextInputComponent
} from "@smartdining/lib-sd-web-shared";
import {BookingsService} from "../../../data/bookings/bookings.service";
import {DatePipe} from "@angular/common";
import {
  BookingDetailsResponse,
  BookingDetailsResponseData
} from "../../../data/bookings/bookings/booking-details-response";
import {OccasionsResponse} from "@core/services/common-data/occasions/occasions-response";
import {TagsResponse} from "@core/services/common-data/tags/tags-response";
import {CommonDataService} from "@core/services/common-data/common-data.service";
import {
  UpdateReservationRequest,
} from "../../../data/update-reservation/update-reservation-request";
import {GenericResponse} from "@core/models/generic-response";
import {
  SeatingPreferenceResponse
} from "@core/services/common-data/seating-preference/seating-preference-response";
import {
  PartySizeSelectorInputComponent
} from "../../../../restaurants/presentation/components/dine-in-global-filter/party-size-selector-input/party-size-selector-input.component";

@Component({
  selector: 'app-update-reservation-overlay',
  standalone: true,
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    DateSelectorInputComponent,
    FormsModule,
    ReactiveFormsModule,
    SingleSelectionFieldComponent,
    TextInputComponent,
    PartySizeSelectorInputComponent
],
  providers: [
    DatePipe
  ],
  templateUrl: './update-reservation-overlay.component.html',
  styleUrl: './update-reservation-overlay.component.scss'
})
export class UpdateReservationOverlayComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  dialogRef = inject(MatDialogRef);
  dialog = inject(MatDialog);
  datePipe = inject(DatePipe);
  bookingId: string = inject(MAT_DIALOG_DATA);
  bookingService = inject(BookingsService);
  bookingsService = inject(BookingsService);
  commonDataService = inject(CommonDataService);

  bookingDetailsState = new State<BookingDetailsResponse>();
  tableAvailabilitiesState = new State<TableAvailabilitiesResponse>();
  occasionsState = new State<OccasionsResponse>();
  tagsState = new State<TagsResponse>();
  updateReservationState = new State<GenericResponse>();
  seatingPreferenceState = new State<SeatingPreferenceResponse>();


  booking = signal<BookingDetailsResponseData | null>(null);
  availabilities = signal<TimeAvailabilityForView[]>([]);

  minimumBookingDate = new Date();

  updateReservationForm!: FormGroup;

  ngOnInit(): void {
    this.getOccasions();
    this.getTags();
    this.getSeatingPreference();
    this.updateReservationForm = this.formBuilder.group({
      date: [new Date(), [Validators.required]],
      partySize: [0, [Validators.required]],
      time: [null, [Validators.required]],
      tags: [['NO']],
      occasion: 'NO',
      note: [''],
      seatingPreferences: []
    });
    this.getBookingDetails();
  }

  private getOccasions() {
    this.executeRequest<OccasionsResponse>({
      state: this.occasionsState,
      request: this.commonDataService.getOccasions(),
    });
  }

  private getTags() {
    this.executeRequest<TagsResponse>({
      state: this.tagsState,
      request: this.commonDataService.getTags(),
    });
  }

  private getSeatingPreference() {
    this.executeRequest<SeatingPreferenceResponse>({
      state: this.seatingPreferenceState,
      request: this.commonDataService.getSeatingPreference(),
    });
  }

  getBookingDetails() {
    this.executeRequest<BookingDetailsResponse>({
      state: this.bookingDetailsState,
      request: this.bookingsService.getBookingDetails(this.bookingId),
      onSuccess: response => {
        this.booking.set(response.data);

        let date = new Date(response.data.startTime);
        date.setHours(0, 0, 0, 0);
        let midnightISO = date.toISOString();

        this.updateReservationForm.patchValue({
          date: new Date(midnightISO),
          partySize: response.data.partySize
        });

        this.getTableAvailabilities();
      }
    });
  }

  getTableAvailabilities() {
    this.availabilities.set([]);
    const formDate = this.updateReservationForm.get('date')?.value;
    const isoDate = formDate ? new Date(formDate).toISOString() : new Date().toISOString();
    const partySize = Number(this.updateReservationForm.get('partySize')?.value);

    const request: TableAvailabilitiesRequest = {
      restaurantId: this.booking()?.restaurant._id ?? '',
      date: isoDate,
      partySize: partySize,
      tableIds: []
    };

    this.executeRequest<TableAvailabilitiesResponse>({
      state: this.tableAvailabilitiesState,
      request: this.bookingService.getTableAvailabilities(request),
      onSuccess: response => {
        let availabilities: TimeAvailabilityForView[] = [];
        response.data.forEach(shift => {
          shift.availabilities.forEach(time => {
            availabilities.push({
              shiftId: shift.shiftId,
              partySize: time.partySize,
              time: new Date(time.time),
              timeString: this.datePipe.transform(time.time, 'hh:mm a'),
              estimatedEndTime: new Date(time.estimatedEndTime),
              cancellationCutOffTime: new Date(time.cancellationCutOffTime),
              changeCutOffTime: new Date(time.changeCutOffTime),
              tableTypes: time.tableTypes
            });
          });
        });
        this.availabilities.set(availabilities);
      }
    });
  }

  onDateSelected($event: Date) {
    this.getTableAvailabilities();
  }

  onPartSizeChanged(event: number) {
    this.getTableAvailabilities();
  }

  onUpdateReservationClicked() {
    this.validateForm(this.updateReservationForm);
    if (this.updateReservationForm.invalid) {
      return;
    }

    const formValue = this.updateReservationForm.value;
    const selectedAvailability: TimeAvailabilityForView = formValue.time;
    const seatingOption = this.updateReservationForm.value.seatingPreferences?.name;
    const request: UpdateReservationRequest = {
      bookingId: this.booking()?._id,
      updateTypes: [
        "changePartySize",
        "changeOccasion",
        "changeTable",
        "changeNote",
        "changeTime",
        "changeTags",
        "changeStatus",
        "changeSeatingOption"
      ],
      params: {
        tableIds: [],
        shiftId: selectedAvailability.shiftId,
        partySize: formValue.partySize.toString(),
        time: selectedAvailability.time,
        tags: [formValue.tags.name],
        occasion: formValue.occasion.name,
        note: this.updateReservationForm.value.note,
        seatingOption: seatingOption
      }
    };

    this.executeRequest({
      state: this.updateReservationState,
      request: this.bookingsService.updateReservation(request),
      onSuccess: response => {
        this.dialogRef.close(UpdateReservationStatus.success);
      }
    });

  }
}

export enum UpdateReservationStatus {
  success,
  failed
}
