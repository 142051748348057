import {Component, inject, input, OnInit, output, signal} from '@angular/core';
import {ButtonComponent, OutlineTextInputComponent, State} from "@smartdining/lib-sd-web-shared";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "@core/base/base-component";
import {AuthService} from "../../../data/auth.service";
import {GenericResponse} from "@core/models/generic-response";

@Component({
  selector: 'app-reset-password-overlay',
  imports: [
    ButtonComponent,
    ReactiveFormsModule,
    OutlineTextInputComponent
  ],
  templateUrl: './reset-password-overlay.component.html',
  standalone: true,
  styleUrl: './reset-password-overlay.component.scss'
})
export class ResetPasswordOverlayComponent extends BaseComponent implements OnInit {

  email = input<string | null>(null);

  onResetPasswordCompleted = output<void>();

  count = signal(0);

  private fb = inject(FormBuilder);
  private authService = inject(AuthService);

  form!: FormGroup;

  forgotPasswordState = new State<GenericResponse>();

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [this.email(), [Validators.required, Validators.email]],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      otp: ['', Validators.required]
    });
  }

  resetPassword() {
    if (this.form.invalid) {
      return;
    }
    this.executeRequest<GenericResponse>({
      state: this.forgotPasswordState,
      request: this.authService.resetPassword(this.form.value),
      handleSuccess: true,
      onSuccess: response => {
        this.onResetPasswordCompleted.emit();
      }
    })
  }
}

