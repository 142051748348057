<div class="flex flex-col">
  <p class="text-body2 text-primary-700"
     i18n="Security Section Title|Heading for security settings@@security.title">
    Security
  </p>

  <!-- Uncomment if needed and apply i18n:
  <div class="flex flex-col py-4 text-body2 gap-2 cursor-pointer" (click)="onChangePasswordClicked()">
    <p class="text-neutral-800 cursor-pointer"
       i18n="Change Password|Label for changing password option@@security.changePassword">
      Change password
    </p>
    <p class="text-neutral-400"
       i18n="Change Password Description|Description text for change password option@@security.changePasswordDescription">
      Update your current password
    </p>
  </div>
  -->

  <div class="flex flex-col py-4 text-body2 gap-2 cursor-pointer" (click)="onEnable2FAClicked()">
    <div class="flex items-center justify-between">
      <p class="text-neutral-800"
         i18n="Enable 2FA|Label for enabling two-factor authentication@@security.enable2FA">
        Enable 2FA
      </p>
      @if (isMfaEnabled()) {
        <span class="px-2 py-1 text-white rounded-full bg-green-500"
              i18n="2FA Enabled Status|Badge shown when 2FA is enabled@@security.statusEnabled">
          Enabled
        </span>
      } @else {
        <span class="px-2 py-1 text-white rounded-full bg-red-500"
              i18n="2FA Disabled Status|Badge shown when 2FA is disabled@@security.statusDisabled">
          Disabled
        </span>
      }
    </div>
    <p class="text-neutral-400"
       i18n="Enable 2FA Description|Explanation of 2FA security feature@@security.enable2FADescription">
      Enable two factor authentication
    </p>
  </div>

  <hr>
</div>
