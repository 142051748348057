<div class="flex flex-col overflow-hidden bg-neutral-100">

  <div class="flex flex-col gap-5 bg-white px-8 py-6">
    <div class="flex gap-4 items-center">

      @if (isPreviousAvailable()) {
        <div class="rounded-full p-4 border border-neutral-300 cursor-pointer hover:bg-neutral-50"
             (click)="onPreviousPressed()">
          <lib-svg-icon src="assets/icons/chevron-left.svg" class="text-neutral-800" [size]="8"></lib-svg-icon>
        </div>
      }


      <p class="text-body1 text-primary-500">Customise your food</p>
      <div class="grow"></div>
      <lib-close-button (click)="onCloseClicked()"></lib-close-button>
    </div>
    <div class="flex gap-3">
      <lib-network-image [imageUrl]="foodItem.image?.url" class="w-20"></lib-network-image>
      <div class="flex flex-col gap-2">
        <p class="text-body1">{{ foodItem.name }}</p>
        <p class="text-body2">{{ foodItem.description ?? '' }}</p>
      </div>
    </div>
  </div>

  <div class="flex flex-col gap-5 mx-6 overflow-y-auto max-h-96 my-4">
    <div class="flex gap-5 items-center">
      <p class="text-body1">{{ activeModifierGroup()?.name }}</p>
    </div>
    <div class="flex flex-col gap-3 px-6 py-6 bg-white rounded-3xl">

      @if (selectionViewType() == SelectionViewType.sizePrice) {
        @if (foodItem.price?.type == 'SIZE_PRICE') {
          @for (sizePrice of foodItem.price?.sizePrices ?? []; track $index; let last = $last) {
            <div class="flex gap-3 justify-between  border-neutral-100 py-2"
                 [ngClass]="last ? 'border-none' : 'border-b'">
              <div class="flex flex-col gap-3">
                <p class="text-body2">{{ sizePrice.size }}</p>
                <p class="text-body2 text-primary-500">$ {{ sizePrice.price | number: '1.2-2' }}</p>
              </div>

              <lib-radio-button [value]="sizePrice.size"
                                [(ngModel)]="sizePrice.isSelected"
                                [groupName]="'size-price'"
                                (valueChanged)="sizePrice.onSelected()">
              </lib-radio-button>

            </div>
          }
        }
      }


      @if (selectionViewType() == SelectionViewType.modifierGroups) {
        @for (modifier of activeModifierGroup()?.modifierItems ?? []; track modifier; let last = $last) {
          <div class="flex gap-3 justify-between  border-neutral-100 py-2"
               [ngClass]="last ? 'border-none' : 'border-b'">
            <div class="flex flex-col gap-3">
              <p class="text-body2">{{ modifier.name }}</p>
              <p class="text-body2 text-primary-500">$ {{ modifier.price | number: '1.2-2' }}</p>
            </div>
            @switch (activeModifierGroup()?.selection) {
              @case (ModifierGroupSelectionType.SINGLE_CHOICE) {
                <lib-radio-button [value]="modifier._id"
                                  [ngModel]="modifier.isSelected()"
                                  (valueChanged)="activeModifierGroup()?.onSingleChoiceModifierSelected($event)"
                                  [groupName]="activeModifierGroup()?._id ?? 'modifier-group'">
                </lib-radio-button>
              }
              @case (ModifierGroupSelectionType.MULTI_CHOICE) {
                <lib-checkbox (valueChanged)="modifier.onItemSelectedOrUnSelected($event)"
                              [ngModel]="modifier.isSelected()">
                </lib-checkbox>
              }
              @case (ModifierGroupSelectionType.QUANTITY) {
                <lib-counter-input (valueChanged)="modifier.onQuantityChanged($event)"
                                   [ngModel]="modifier.selectedQuantity()">
                </lib-counter-input>
              }
            }
          </div>
        }
      }

    </div>
  </div>

  <div class="relative flex gap-4 items-center py-4 px-4 bg-white shadow-1">
    <p class="text-body px-2 py-1 bg-white">Option {{ currentStepIndex + 1 }}
      / {{ totalSteps() }}</p>
    <div class="grow"></div>


    @if (isNextAvailable()) {
      <lib-button (click)="onNextPressed()">Next</lib-button>
    }


    @if (isLastOption()) {
      <div class="flex items-center gap-3">
        <lib-counter-input [minValue]="1" [(ngModel)]="noOfItems"></lib-counter-input>
        <lib-button iconSrc="assets/icons/bag.svg" (click)="onAddItemPressed()">Add Item</lib-button>
      </div>
    }

  </div>

</div>


