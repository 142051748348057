import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {StripeElementsDirective, StripePaymentElementComponent, StripeService} from "ngx-stripe";
import {ConfirmPaymentData, StripeElements, StripeElementsOptions} from "@stripe/stripe-js";
import {PaymentService} from "../../../data/payment.service";
import {BaseComponent} from "@core/base/base-component";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {MatDialogRef} from "@angular/material/dialog";
import {
  CreateSetupPaymentIntentResponse
} from "../../../data/create-setup-payment-intent/create-setup-payment-intent-response";
import {
  CreateSetupPaymentIntentRequest
} from "../../../data/create-setup-payment-intent/create-setup-payment-intent-request";
import {BaseDialogComponent, ButtonComponent, State} from '@smartdining/lib-sd-web-shared';

@Component({
  selector: 'app-add-payment-method',
  standalone: true,
  imports: [
    BaseDialogComponent,
    StripePaymentElementComponent,
    ButtonComponent
  ],
  templateUrl: './add-payment-method.component.html',
  styleUrl: './add-payment-method.component.scss'
})
export class AddPaymentMethodComponent extends BaseComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent) paymentElement!: StripePaymentElementComponent;
  @ViewChild(StripeElementsDirective) elements!: StripeElementsDirective;

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  stripeService = inject(StripeService);
  paymentService = inject(PaymentService);
  authStoreService = inject(AuthStoreService);
  dialogRef = inject(MatDialogRef);

  setupPaymentIntentState = new State<CreateSetupPaymentIntentResponse>();

  ngOnInit(): void {
    this.createSetupPaymentIntent();
  }

  createSetupPaymentIntent() {
    let user = this.authStoreService.getAppUser()?.data;
    let request: CreateSetupPaymentIntentRequest = {
      userId: user?.user
    };
    this.executeRequest<CreateSetupPaymentIntentResponse>({
      state: this.setupPaymentIntentState,
      request: this.paymentService.createSetupPaymentIntent(request),
      onSuccess: (response) => {
        this.elementsOptions.clientSecret = response.data.client_secret;
      }
    });
  }

  onAddPaymentMethodClicked() {
    let user = this.authStoreService.getAppUser()?.data;
    let addressList = user?.addresses ?? [];
    let address: any;
    if (addressList.length > 0) {
      let address = user?.addresses[0];
    }

    let request: {
      elements: StripeElements;
      confirmParams?: Partial<ConfirmPaymentData>;
      redirect: 'if_required';
    } = {
      redirect: "if_required",
      elements: this.paymentElement.elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: `${user?.firstName} ${user?.lastName}`,
            email: user?.email,
            phone: user?.phoneNumber,
            address: {
              line1: address?.address,
              postal_code: address?.zipCode,
              city: address?.city
            }
          }
        }
      }
    };

    this.stripeService.confirmSetup(request).subscribe((response) => {
      if (response.error) {
        this.toasterService.error(response.error.message ?? 'Unknown error, please contact administrator.');
      } else {
        this.toasterService.success('Payment method added successfully.');
        this.dialogRef.close(AddPaymentMethodStatus.success);
      }
    });

  }

  onCancelClicked() {
    this.dialogRef.close();
  }
}

export enum AddPaymentMethodStatus {
  success,
  failed
}

export enum PaymentMethodOperationStatus {
  success,
  failed
}
