export interface TimeObject {
  timeString24: string;
  timeString12: string;
  time: Date;
  minutes: number;
  hour24: number;
  minute: number;
}

export function generate30MinuteIntervals(startTime: Date, endTime: Date): TimeObject[] {
  const result: TimeObject[] = [];

  // Normalize the start time to the next closest interval (00:00, 00:30, etc.)
  const startMinutes = startTime.getMinutes();
  const adjustedStartMinutes = startMinutes < 30 ? 30 : 0;
  const normalizedStart = new Date(startTime);
  if (startMinutes % 30 !== 0) {
    normalizedStart.setMinutes(adjustedStartMinutes, 0, 0);
    if (adjustedStartMinutes === 0) {
      normalizedStart.setHours(normalizedStart.getHours() + 1);
    }
  }

  let current = new Date(normalizedStart);

  while (current <= endTime) {
    const hours = current.getHours();
    const minutes = current.getMinutes();
    const timeString24 = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    const period = hours >= 12 ? 'PM' : 'AM';
    const hour12 = hours % 12 === 0 ? 12 : hours % 12;
    const timeString12 = `${hour12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;

    result.push({
      timeString24,
      timeString12,
      time: new Date(current),
      minutes: hours * 60 + minutes,
      hour24: hours,
      minute: minutes,
    });
    current.setMinutes(current.getMinutes() + 30);
  }

  return result;
}

export function isPastDate(date: Date): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time to start of the day
  return date < today;
}

export function isToday(date: Date): boolean {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
}


export function roundToNextFifteenMinutes(date: Date): Date {
  // Create a new Date object to avoid modifying the original date
  const result = new Date(date.getTime());

  // Get the minutes of the given date
  const minutes = result.getMinutes();

  // Calculate how many minutes to add to reach the next quarter-hour
  const minutesToAdd = 15 - (minutes % 15);

  // Add the calculated minutes to the date
  result.setMinutes(minutes + minutesToAdd, 0, 0);

  // If the minutes to add are 15, it means we need to round to the next hour
  if (minutesToAdd === 15) {
    result.setHours(result.getHours() + 1, 0, 0);
  }

  return result;
}

export class DateUtils {
  convertToMMDDYYYY(dateString: string | undefined | null): string | null {
    if (!dateString) {
      return null;
    }

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return null;
    }

    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  convertTohhmm(dateString: string | undefined | null): string | null {
    if (!dateString) {
      return null;
    }

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return null;
    }

    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);

    return `${hours}:${minutes}`;
  }

  convertToDateTimeString(date: string, time?: string): string | undefined {
    if (!date) {
      return undefined;
    }
    const [month, day, year] = date.split('/').map(Number);  // mm/dd/yyyy format

    const [hours, minutes] = time ? time.split(':').map(Number) : [0, 0];  // 0 hours, 0 minutes (12:00 AM)
    const localDate = new Date(year, month - 1, day, hours, minutes);

    return localDate.toISOString();
  }

  convertToMMYYYYString(mmyyyy: string): string | null {
    if (!mmyyyy || mmyyyy.length !== 7) {
      return null;
    }

    const [month, year] = mmyyyy.split('/');

    return `${month}/${year}`;

  }

  getCurrentDateMMDDYYYY() {
    let today = new Date();
    let currentDate = this.convertToMMDDYYYY(today.toDateString());
    return currentDate;
  }
}


