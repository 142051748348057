<div class="flex gap-5 shadow-1 bg-white border border-neutral-200 rounded-xl overflow-hidden cursor-pointer">
  <lib-network-image class="h-auto w-32 object-cover"
                     [imageUrl]="invitation()?.restaurant?.logo?.url">
  </lib-network-image>

  <div class="flex flex-grow py-3 pr-4">
    <div class="flex flex-col flex-grow gap-2">

      <div class="flex gap-3 items-center">
        @if(isReceivedInvitation()) {
          <p class="text-body1 text-success-500"
             i18n="Invitation Received Message|Text shown when user is invited@@invitationCard.received">
            {{ whoInvited() }} invited you!
          </p>
        }
        @if(isSentInvitation()) {
          <p class="text-body1 text-success-500"
             i18n="Invitation Sent Message|Text shown when user sends an invite@@invitationCard.sent">
            You have invited {{ inviter() }}
          </p>
        }
        <div class="flex-grow"></div>
      </div>

      <div class="flex gap-3 items-center">
        <p class="text-body2 text-primary-500">{{ invitation()?.restaurant?.restaurantName }}</p>
      </div>

      <!-- Address -->
      <p class="text-body2 text-neutral-400">
        {{
          arrayToCSV([
            invitation()?.restaurant?.location?.address1,
            invitation()?.restaurant?.location?.address2,
            invitation()?.restaurant?.location?.city,
            invitation()?.restaurant?.location?.state,
            invitation()?.restaurant?.location?.country,
            invitation()?.restaurant?.location?.zipCode
          ])
        }}
      </p>

      <div class="flex items-center gap-4">
        <div class="flex items-center gap-2">
          <lib-svg-icon src="assets/icons/calendar.svg" [size]="16" class="text-primary-500"></lib-svg-icon>
          <p class="text-caption text-neutral-600">{{ invitation()?.startTime | date:'d MMM y - h:mm a' }}</p>
        </div>
        <div class="flex items-center gap-2">
          <lib-svg-icon src="assets/icons/guest.svg" [size]="16" class="text-primary-500"></lib-svg-icon>
          <p class="text-caption text-neutral-600">{{ invitation()!.partySize }} Guests</p>
        </div>
      </div>

      <div class="flex items-center">
        <p class="rounded-md px-3 py-2 text-caption bg-opacity-5"
           [ngClass]="getBookingColor(invitation()?.status!)">
          {{ getBookingStatusText(invitation()!.status) }}
        </p>

        <div class="flex-grow"></div>

        <div class="flex items-center gap-4">
          <div class="flex gap-3 items-center">

            @if(isReceivedInvitation()) {
              @switch (currentStatus()) {
                @case ('ACCEPTED') {
                  <p class="text-body2 text-success-500"
                     i18n="Accepted Status|Label when invitation is accepted@@invitationCard.accepted">
                    Invitation accepted
                  </p>
                }
                @case ('DECLINED') {
                  <p class="text-body2 text-error-500"
                     i18n="Declined Status|Label when invitation is declined@@invitationCard.declined">
                    Invitation declined
                  </p>
                }
                @default {
                  <span class="flex gap-2 items-center text-body2 rounded px-3 py-2 bg-green-100 text-green-900"
                        (click)="onAcceptInvitationClicked()"
                        i18n="Accept Invitation Button@@invitationCard.accept">
                    Accept
                  </span>
                  <span class="flex gap-2 items-center text-body2 rounded px-3 py-2 bg-red-100 text-red-900"
                        (click)="onDeclineInvitationClicked()"
                        i18n="Decline Invitation Button@@invitationCard.decline">
                    Decline
                  </span>
                }
              }
            }

            @if(isSentInvitation() && currentStatus() !== 'DECLINED') {
              <span class="flex gap-2 items-center text-body2 rounded px-3 py-2 bg-red-100 text-red-900"
                    (click)="onDeclineInvitationClicked()"
                    i18n="Cancel Invitation Button@@invitationCard.cancel">
                Cancel
              </span>
            }
            @if(isSentInvitation() && currentStatus() == 'DECLINED') {
              <p class="text-body2 text-error-500"
                 i18n="Cancelled Invitation Status|Shown when a sent invite is cancelled@@invitationCard.cancelled">
                Invitation cancelled
              </p>
            }

          </div>

          @if (restaurantCoordinates().latitude && restaurantCoordinates().longitude) {
            <app-direction-button appearance="iconButton"
                                  [destinationLatitude]="restaurantCoordinates().latitude"
                                  [destinationLongitude]="restaurantCoordinates().longitude">
            </app-direction-button>
          }

        </div>
      </div>
    </div>
  </div>
</div>
