@switch (type()) {
  @case ('PICK_UP') {
    <p class="py-1 px-3 text-white bg-info-500 text-caption rounded">Pickup</p>
  }
  @case ('CURBSIDE_PICK_UP') {
    <p class="py-1 px-3 text-white bg-info-500 text-caption rounded">Curbside pickup</p>
  }
  @case ('RESERVATION_DINE_IN') {
    <p class="py-1 px-3 text-white bg-info-500 text-caption rounded">Reservation</p>
  }
  @case ('DINE_IN') {
    <p class="py-1 px-3 text-white bg-info-500 text-caption rounded">Dine In</p>
  }
  @case ('WAIT_LIST_DINE_IN') {
    <p class="py-1 px-3 text-white bg-info-500 text-caption rounded">Wait list Dine In</p>
  }
  @case ('TAKE_OUT') {
    <p class="py-1 px-3 text-white bg-info-500 text-caption rounded">Take out</p>
  }
  @case ('DELIVERY') {
    <p class="py-1 px-3 text-white bg-info-500 text-caption rounded">Delivery</p>
  }
  @default {
    <p class="py-1 px-3 text-white bg-info-500 text-caption rounded">Unknown</p>
  }
}

