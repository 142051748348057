<mat-dialog-content>
  <div class="flex flex-col gap-2 text-start">
    <p class="text-body1 text-error-500">Cancel Order</p>
    <p class="text-body2">Are you sure, do you want to cancel the order?</p>
    <div class="flex justify-end gap-4 mt-3">
      <lib-button appearance="outline" (click)="onNoClicked()">No
      </lib-button>
      <lib-button buttonColor="bg-error-500"
                  (click)="onYesClicked()">Yes
      </lib-button>
    </div>
  </div>
</mat-dialog-content>










