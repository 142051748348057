<div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-3">

  @if (favoriteResponseState.loading()) {
    <lib-shimmer></lib-shimmer>
    <lib-shimmer></lib-shimmer>
    <lib-shimmer></lib-shimmer>
  }

  @for (favorite of favoriteResponseState.response()?.data; track favorite) {
    <div class="flex flex-col gap-3 rounded-md shadow-1 overflow-hidden">
      <div class="image-container">
        <lib-network-image
          placeholderImage="assets/images/background/restaurant-placeholder.png"
          [imageUrl]="favorite?.restaurant?.banner?.url">
        </lib-network-image>
      </div>
      <div class="flex p-4 gap-2">
        <div class="flex flex-col gap-2 justify-between">
          <p class="text-body2 font-bold">{{ favorite?.restaurant?.restaurantName }}</p>
          <p class="text-body2">{{ favorite?.restaurant?.location?.address1 }}</p>
        </div>
        <div class="flex-grow"></div>
        <div class="flex justify-center items-center h-auto">
          <lib-svg-icon
            src="assets/icons/heart-filled.svg"
            [size]="18"
            class="text-red-500 p-3 cursor-pointer hover:bg-neutral-50 hover:rounded-full rounded-md"
            (click)="removeFavorite(favorite._id)">
          </lib-svg-icon>
        </div>
      </div>
    </div>
  }

</div>

@if (favoriteResponseState.success() && (favoriteResponseState.response()?.data?.length ?? 0) <= 0) {
  <div class="flex flex-col w-full h-full justify-center items-center">
    <lib-no-data
      class="self-center py-12"
      message="No Favorites found"
      i18n-message="No Favorites Message|Shown when no favorites are available@@favorites.noFavoritesMessage">
    </lib-no-data>
  </div>
}




