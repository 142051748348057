import {Component, signal} from '@angular/core';
import {BaseOverlayComponent, OverlayWizard, OverlayWizardUtils} from "@smartdining/lib-sd-web-shared";
import {AuthWizardStep, AuthComponent} from "../../../../auth/presentation/auth.component";
import {JoinWaitListComponent} from "./join-wait-list/join-wait-list.component";

@Component({
  selector: 'app-join-waitlist-overlay',
  imports: [
    BaseOverlayComponent,
    AuthComponent,
    JoinWaitListComponent
  ],
  templateUrl: './join-waitlist-overlay.component.html',
  styleUrl: './join-waitlist-overlay.component.scss'
})
export class JoinWaitlistOverlayComponent {
  wizards = signal<OverlayWizard[]>([
    {title: 'Join Waitlist', key: WaitlistWizard.joinWaitlist}
  ]);

  wizardUtil?: OverlayWizardUtils;

  onWizardUpdated($event: OverlayWizardUtils) {
    this.wizardUtil = $event;
  }

  onLoginNeeded() {
    this.wizardUtil?.append(AuthWizardStep.login);
  }

  ReserveWizard = WaitlistWizard;

}

export enum WaitlistWizard {
  joinWaitlist = 'joinWaitlist'
}
