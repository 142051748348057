<div class="text-end">
  <lib-button
    (click)="onAddAddressClicked()"
    i18n="Add Address Button|CTA to add a new address@@addresses.addAddressButton">
    Add Address
  </lib-button>
</div>
<div class="flex flex-col gap-2 pt-5">
  @if (addressState.loading()) {
    <div class="flex flex-col gap-3">
      <lib-shimmer type="list"></lib-shimmer>
      <lib-shimmer type="list"></lib-shimmer>
      <lib-shimmer type="list"></lib-shimmer>
    </div>
  }
  @for (address of addressState.response()?.data; track address) {
    <div class="flex items-center text-body2 space-x-1">
      <p
        class="text-body2 px-2 flex-grow">{{ arrayToCSV([address.address, address.city, address.state, address.country, address.zipCode]) }}</p>
      <div class="flex-grow"></div>
      <lib-svg-icon src="assets/icons/pencil.svg"
                    class="hover:bg-neutral-100 rounded-full px-2 text-neutral-400 py-2 cursor-pointer"
                    (click)="onEditClicked(address)"></lib-svg-icon>
      <lib-svg-icon src="assets/icons/delete.svg"
                    class="hover:bg-neutral-100 rounded-full px-2 text-neutral-400 py-2 cursor-pointer"
                    (click)="onDeleteClicked(address)"></lib-svg-icon>
    </div>
    <hr class="opacity-50">
  }

  @if (addressState.success() && (addressState.response()?.data?.length ?? 0) == 0) {
    <div class="flex justify-center items-center w-full h-full">
      <lib-no-data message="No address found"></lib-no-data>
    </div>
  }
</div>
