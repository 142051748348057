import {Component, inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "@core/base/base-component";
import {
  AppSvgIconComponent,
  BaseOverlayComponent, ButtonComponent,
  RatingInputComponent,
  State,
  TextAreaInputComponent
} from "@smartdining/lib-sd-web-shared";
import {GenericResponse} from "@core/models/generic-response";
import {BookingsService} from "../../../data/bookings.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  GetBookingSurveyQuestionsResponse
} from "@core/services/common-data/get-survey-questions/get-booking-survey-questions-response";
import {MatTooltip} from "@angular/material/tooltip";
import {DIALOG_DATA} from "@angular/cdk/dialog";


@Component({
  selector: 'app-booking-survey-overlay',
  standalone: true,
  imports: [
    RatingInputComponent,
    ReactiveFormsModule,
    AppSvgIconComponent,
    TextAreaInputComponent,
    ButtonComponent,
    MatTooltip,
    BaseOverlayComponent
],
  templateUrl: './booking-survey-overlay.component.html',
  styleUrl: './booking-survey-overlay.component.scss'
})
export class BookingSurveyOverlayComponent extends BaseComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  bookingsService = inject(BookingsService);
  dialogRef = inject(MatDialogRef);
  bookingId: string = inject(DIALOG_DATA);

  createSurveyState = new State<GenericResponse>();
  bookingSurveyQuestionsResponseState = new State<GetBookingSurveyQuestionsResponse>();

  surveyForm: FormGroup = new FormGroup({});

  ngOnInit() {
    this.initSurveyForm();
    this.getBookingSurveyQuestions();
  }

  private initSurveyForm() {

    this.surveyForm = this.formBuilder.group({
      booking: [this.bookingId],
      guestSentimentRating: [0],
      experienceRating: [0],
      serviceRating: [0],
      foodRating: [0],
      atmosphereRating: [0],
      feedback: [''],
    });

    this.surveyForm = this.formBuilder.group({
      booking: [this.bookingId],
      scale: [''],
      surveyAnswers: this.formBuilder.array([]),
      comments: ['', [Validators.required]]
    });
  }


  addSurveyQuestions(surveyName: string, surveyRating: number) {
    const surveyAnswers = this.surveyForm.get('surveyAnswers') as FormArray;
    surveyAnswers.push(
      this.formBuilder.group({
        surveyName: [surveyName, [Validators.required]],
        surveyRating: [0, [Validators.required]],
      })
    );
  }


  getBookingSurveyQuestions() {
    this.executeRequest<GetBookingSurveyQuestionsResponse>({
      state: this.bookingSurveyQuestionsResponseState,
      request: this.bookingsService.getBookingSurveyQuestions(),
      onSuccess: (response) => {

        this.surveyForm.controls['scale'].setValue(response.data?.surveyScale);

        let surveyQuestions = response.data.surveyQuestions ?? [];
        for (let surveyQuestion of surveyQuestions) {
          this.addSurveyQuestions(surveyQuestion.surveyName, 0);
        }
      }
    });
  }

  onCancelClicked() {
    this.dialogRef.close();
  }

  onSubmitPressed() {
    this.validateForm(this.surveyForm);
    if (this.surveyForm.invalid) {
      return;
    }

    this.executeRequest({
      state: this.createSurveyState,
      request: this.bookingsService.submitCustomerSurvey(this.surveyForm.value),
      successMessage: 'Thank you for your survey',
      onSuccess: response => {
        this.dialogRef.close(BookingSurveyStatus.success);
      }
    });
  }
}

export enum BookingSurveyStatus {
  noActionTaken,
  success,
  failed,
}



