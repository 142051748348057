<div (click)="onSettingSelected()"
     class="flex mx-6 hover:bg-neutral-50 px-2 py-2 cursor-pointer items-center gap-3 shadow-1 rounded-xl border w-64"
     [ngClass]="isSelected() ? 'border-primary-600 bg-primary-50' : 'border-neutral-300 bg-white'">
  <lib-svg-icon class="rounded-xl p-2 block"
                [ngClass]="isSelected() ? 'text-white bg-primary-500' : 'text-neutral-500 bg-neutral-100'"
                [size]="16"
                src="{{ icon() }}">
  </lib-svg-icon>
  <div class="flex flex-col gap-2">
    <p class="text-body2">{{ title() }}</p>
    <p class="text-body2 text-neutral-500 sm:block">{{ description() }}</p>
  </div>
</div>






