<div class="flex flex-col gap-4">
  <form [formGroup]="reservationForm" class="flex flex-col gap-3">

    <!-- Reservation Summary -->
    <div class="flex flex-wrap gap-16 bg-neutral-50 rounded-2xl py-3 px-4">
      <div class="h-[50px] rounded-xl overflow-hidden">
        <lib-network-image
          [placeholderImage]="'assets/images/background/restaurant-placeholder.png'"
          [imageUrl]="data.restaurantImage">
        </lib-network-image>
      </div>

      <div class="flex gap-4 items-center">
        <lib-svg-icon src="assets/icons/date.svg" [size]="18"></lib-svg-icon>
        <div class="flex flex-col gap-2">
          <p class="text-caption" i18n="Date Label@@makeReservationOverlay.dateLabel">Date</p>
          <p class="text-caption text-black">{{ data.availability?.time | date: 'mediumDate' }}</p>
        </div>
      </div>

      <div class="flex gap-4 items-center">
        <lib-svg-icon src="assets/icons/clock.svg" [size]="18"></lib-svg-icon>
        <div class="flex flex-col gap-2">
          <p class="text-caption" i18n="Time Label@@makeReservationOverlay.timeLabel">Time</p>
          <p class="text-caption text-black">{{ data.availability?.timeString }}</p>
        </div>
      </div>

      <div class="flex gap-4 items-center">
        <lib-svg-icon src="assets/icons/group.svg" [size]="18"></lib-svg-icon>
        <div class="flex flex-col gap-2">
          <p class="text-caption" i18n="Party Size Label@@makeReservationOverlay.partySizeLabel">Party size</p>
          <p class="text-caption text-black">{{ data.partySize }}</p>
        </div>
      </div>
    </div>

    <!-- Occasion, Seating, Tags -->
    <lib-single-selection-field
      formControlName="occasion"
      [title]="'Select Occasion'"
      i18n-title="Occasion Title@@makeReservationOverlay.occasionTitle"
      [items]="occasionsState.response()?.data ?? []"
      [display]="'name'"
      value="name"
      [dynamicIconPath]="'icon'"
      [dynamicIconColor]="'iconColor'">
    </lib-single-selection-field>

    <lib-single-selection-field
      formControlName="seatingPreferences"
      [title]="'Select Seating Preferences'"
      i18n-title="Seating Preferences Title@@makeReservationOverlay.seatingTitle"
      [items]="seatingPreferenceState.response()?.data ?? []"
      [display]="'name'"
      value="name"
      [dynamicIconPath]="'icon'"
      [dynamicIconColor]="'iconColor'">
    </lib-single-selection-field>

    <lib-multi-selection-field
      formControlName="tags"
      [title]="'Select Tags'"
      i18n-title="Tags Title@@makeReservationOverlay.tagsTitle"
      [items]="tagsState.response()?.data ?? []"
      [display]="'name'"
      [dynamicIconPath]="'icon'"
      value="name"
      identifier="name"
      [dynamicIconColor]="'iconColor'">
    </lib-multi-selection-field>

    <!-- Special Note -->
    <lib-text-input
      formControlName="note"
      class="mt-2"
      [label]="'Special notes'"
      i18n-label="Special Notes Label@@makeReservationOverlay.notesLabel"
      type="text">
    </lib-text-input>

    <!-- Policy Text -->
    <p class="text-body2 text-black"
       i18n="Cancellation Title@@makeReservationOverlay.cancellationTitle">
      Cancellation Policy
    </p>

    <p class="text-body2"
       i18n="Cancellation Message@@makeReservationOverlay.cancellationMessage">
      While you won’t be charged if you need to cancel, we ask that you notify us at least 24 hours in advance
    </p>

  </form>


  <lib-button
    class="w-full"
    [fullWidth]="true"
    [loading]="reservationState.loading()"
    (click)="reserveClicked()"
    i18n="Join Waitlist Button@@makeReservationOverlay.joinButton">
    Reserve
  </lib-button>

</div>



