<div class="container-fluid bg-neutral-50">

  <div class="relative container-fluid lg:container mx-auto px-4 py-6">

    @if (bookingDetailsState.loading()) {
      <div class="flex flex-col gap-10 p-4 bg-white">
        <lib-shimmer type="list"></lib-shimmer>
        <lib-shimmer type="list"></lib-shimmer>
        <lib-shimmer type="list"></lib-shimmer>
      </div>
    }

    @if (bookingDetailsState.success()) {
      <div class="flex flex-col gap-4 py-2">
        <div class="flex flex-col gap-2">
          <div class="flex justify-center py-2">
            @switch (getBookingStatusSummary(booking()?.data?.status ?? '')) {
              @case (BookingStatusSummary.confirmed) {

                <span
                  class="flex justify-center items-center self-center rounded-full h-20 w-20 bg-success-500 bg-opacity-10 flex-shrink-0">
                  <lib-svg-icon [size]="64" class="text-success-500" src="assets/icons/check-circle.svg"></lib-svg-icon>
                </span>

              }
              @case (BookingStatusSummary.finished) {
                <span
                  class="flex justify-center items-center self-center rounded-full h-20 w-20 bg-success-500 bg-opacity-10 flex-shrink-0">
                <lib-svg-icon [size]="64" class="text-success-500" src="assets/icons/check-circle.svg"></lib-svg-icon>
              </span>
              }
              @case (BookingStatusSummary.cancelled) {
                <span
                  class="flex justify-center items-center self-center rounded-full h-20 w-20 bg-error-500 bg-opacity-10 flex-shrink-0">
          <lib-svg-icon [size]="30" class="text-error-500" src="assets/icons/close.svg"></lib-svg-icon>
        </span>
              }
            }
          </div>


          <div class="flex justify-center">
            <p class="text-body-2"
               [ngClass]="getStatusColor()"
               i18n="Booking Status Text|Displays current status of the booking@@bookingInfo.statusText">
              {{ getBookingStatusText(booking()?.data?.status ?? '') }}
            </p>
          </div>

          <p class="text-body2 text-neutral-700 text-center pb-2">
            <span i18n="Booking ID Prefix|Label for booking ID@@bookingInfo.bookingIdPrefix">Booking ID -</span>
            #{{ booking()?.data?.confirmationNumber }}
          </p>

        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">

          <div class="col-span-2 flex flex-col gap-4 px-4 py-6 bg-white">

            <div class="flex flex-col gap-3">
              <p class="text-body2 text-primary-500 font-medium"
                 i18n="Restaurant Details Heading|Section title for restaurant information@@bookingInfo.restaurantDetails">
                Restaurant Details
              </p>
              <div class="flex gap-3">
                <lib-network-image class="h-auto w-32 object-cover"
                                   [imageUrl]="booking()?.data?.restaurant?.banner?.url">
                </lib-network-image>
                <div class="flex flex-col">
                  <p class="text-body2 text-neutral-700">{{ booking()?.data?.restaurant?.restaurantName }}</p>
                  <div class="flex flex-col gap-2">
                    <p class="text-body2 text-neutral-400">
                      {{
                        arrayToCSV([
                          booking()?.data?.restaurant?.location?.address1,
                          booking()?.data?.restaurant?.location?.city,
                          booking()?.data?.restaurant?.location?.state
                        ])
                      }}
                    </p>

                    <div class="flex gap-5">
                      @if (booking()?.data?.restaurant?.phoneNumber) {
                        <div
                          class="flex h-10 w-10 items-center justify-center rounded-full hover:bg-neutral-50 cursor-pointer"
                          (click)="onCallClicked()">
                          <lib-svg-icon src="assets/icons/phone.svg"
                                        class="text-blue-500 cursor-pointer"></lib-svg-icon>
                        </div>
                      }
                      @if (destinationGeoPoint().latitude && destinationGeoPoint().longitude) {
                        <app-direction-button appearance="iconButton"
                                              class="text-blue-500 cursor-pointer"
                                              [destinationLatitude]="destinationGeoPoint().latitude"
                                              [destinationLongitude]="destinationGeoPoint().longitude">
                        </app-direction-button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="border-b border-neutral-100 my-3"></div>

            <div class="flex flex-col gap-3">
              <p class="text-body2 text-primary-500 font-medium"
                 i18n="Booking Details Heading|Section title for booking details@@bookingInfo.bookingDetails">
                Booking Details
              </p>

              <div class="flex flex-wrap items-center gap-6">

                <!-- Date -->
                <div class="flex gap-4 justify-start rounded-2xl ">
                  <lib-svg-icon src="assets/icons/calendar.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
                  <div class="flex flex-col gap-2">
                    <p class="text-caption" i18n="Date Label@@bookingInfo.dateLabel">Date</p>
                    <p class="text-body2">{{ booking()?.data?.startTime | date:'d MMMM y' }}</p>
                  </div>
                </div>

                <!-- Waitlist Join Time -->
                @if (booking()?.data?.type === 'TYPE_WAITLIST') {
                  <div class="flex gap-4 justify-start rounded-2xl ">
                    <lib-svg-icon src="assets/icons/clock.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
                    <div class="flex flex-col gap-2">
                      <p class="text-caption" i18n="Joined Waitlist Label@@bookingInfo.joinedWaitlist">Joined
                        Waitlist</p>
                      <p class="text-body2">{{ booking()?.data?.startTime ?? '' | timeAgo }}</p>
                    </div>
                  </div>
                }

                <!-- Time or Quoted Wait Time -->
                <div class="flex gap-4 justify-start rounded-2xl ">
                  <lib-svg-icon src="assets/icons/clock.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
                  <div class="flex flex-col gap-2">
                    @if (booking()?.data?.type === 'TYPE_WAITLIST') {
                      <p class="text-caption" i18n="Quoted Wait Time Label@@bookingInfo.quotedWaitTime">Quoted Wait
                        Time</p>
                      <p class="text-body2">{{ booking()?.data?.quotedWaitTime }} minutes</p>
                    } @else {
                      <p class="text-caption" i18n="Time Label@@bookingInfo.timeLabel">Time</p>
                      <p class="text-body2">{{ booking()?.data?.startTime | date:'h:mm a' }}</p>
                    }
                  </div>
                </div>

                <!-- Party Size -->
                <div class="flex gap-4 justify-start rounded-2xl ">
                  <lib-svg-icon src="assets/icons/group.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
                  <div class="flex flex-col gap-2">
                    <p class="text-caption" i18n="Guest Count Label@@bookingInfo.guestCount">No. of Guest(s)</p>
                    <p class="text-body2">{{ booking()?.data?.partySize }} Guest(s)</p>
                  </div>
                </div>
              </div>

              <!-- Modify / Cancel Booking -->
              @if (!isPastBooking() || booking()?.data?.type == 'TYPE_WAITLIST') {
                <div class="flex gap-8 mt-4">
                  @switch (getBookingStatusSummary(booking()?.data?.status ?? '')) {
                    @case (BookingStatusSummary.confirmed) {

                      @if (booking()?.data?.type != 'TYPE_WAITLIST') {
                        <lib-button appearance="outline" (click)="updateBookingClicked()"
                                    i18n="Modify Booking Button@@bookingInfo.modifyBooking">
                          Modify Booking
                        </lib-button>
                      }



                      <span (click)="onCancelClicked()" class="flex gap-2 items-center cursor-pointer text-error-500">
                        <lib-svg-icon src="assets/icons/close.svg" [size]="12"></lib-svg-icon>
                        <p class="text-body2" i18n="Cancel Booking Text@@bookingInfo.cancelBooking">Cancel Booking</p>
                      </span>
                    }
                  }
                </div>
              }
            </div>

            <div class="border-b border-neutral-100 my-3"></div>

            <!-- Tags -->
            <div class="flex flex-col gap-2">
              <p class="text-body2" i18n="Tags Label@@bookingInfo.tagsLabel">Tags</p>
              <div class="inline-flex">
                <div class="flex gap-2 items-center px-2 py-1 bg-neutral-100 rounded-xl">
                  <lib-svg-icon src="assets/icons/tag.svg" [size]="20" class="text-neutral-500"></lib-svg-icon>
                  <p class="text-body2 px-1">{{ booking()?.data?.tags }}</p>
                </div>
              </div>
            </div>

            <div class="flex items-center gap-4">
              <!-- Occasion -->
              <div class="flex flex-col gap-2">
                <p class="text-body2"
                   i18n="Occasion Label|Label for occasion info@@bookingInfo.occasionLabel">
                  Occasion
                </p>
                <div class="inline-flex">
                  <div class="flex gap-2 items-center text-body2 px-2 py-1 bg-neutral-100 rounded-xl">
                    <lib-svg-icon src="assets/icons/calendar.svg" [size]="20" class="text-neutral-500"></lib-svg-icon>
                    <p class="text-body2 px-1">{{ booking()?.data?.occasion }}</p>
                  </div>
                </div>
              </div>

              <!-- Seating Preference -->
              <div class="flex flex-col gap-2">
                <p class="text-body2"
                   i18n="Seating Preference Label|Label for seating preference info@@bookingInfo.seatingLabel">
                  Seating Preference
                </p>
                <div class="inline-flex">
                  <div class="flex gap-2 items-center px-2 py-1 bg-neutral-100 rounded-xl">
                    <lib-svg-icon src="assets/icons/seating-preference.svg" [size]="20"
                                  class="text-neutral-500"></lib-svg-icon>
                    <p class="text-body2 px-1">{{ booking()?.data?.seatingOption }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="border-b border-neutral-100 my-3"></div>

            <!-- Guest Details -->
            <div class="flex flex-col gap-3">
              <p class="text-body2 text-primary-500 font-medium"
                 i18n="Guest Details Title|Section heading for guest details@@bookingInfo.guestDetails">
                Guest Details
              </p>
              <p class="text-body2">
                {{ booking()?.data?.customerInfo?.firstName }} {{ booking()?.data?.customerInfo?.lastName }}
              </p>
              <p class="text-body2">{{ booking()?.data?.customerInfo?.phoneNumber }}</p>
            </div>

            <!-- Rate Review CTA -->
            @if (booking()?.data?.status == "FINISHED" && booking()?.data?.surveyStatus == "PENDING") {
              <div class="border-t py-6 border-neutral-100">
                <lib-button appearance="outline"
                            [iconSrc]="'assets/icons/done.svg'"
                            (click)="onRateReviewClicked()"
                            i18n="Rate Booking Button|Button to leave a review after booking@@bookingInfo.rateBooking">
                  RATE YOUR BOOKING
                </lib-button>
              </div>
            }

            <!-- Survey Thank You -->
            @if (booking()?.data?.surveyStatus == "SUBMITTED") {
              <div class="border-t py-6 flex items-center border-neutral-100">
                <div class="flex items-center gap-4">
                  <lib-svg-icon src="assets/icons/done.svg" class="text-success-500"></lib-svg-icon>
                  <p class="text-body2"
                     i18n="Survey Thank You Message|Text shown after survey is submitted@@bookingInfo.thankYou">
                    Thank you for submitting your survey
                  </p>
                </div>
              </div>
            }


          </div>

          <div class="col-span-1 flex flex-col gap-4 bg-white px-4 py-6">

            @let isBookingCancelled = getBookingStatusSummary(booking()?.data?.status!) == BookingStatusSummary.cancelled;
            @let bookingType = booking()?.data?.type;

            @if (bookingType !== 'TYPE_WAITLIST' && (isPastBooking() || isBookingCancelled)) {
              <!-- Do not show invite UI -->
            } @else {
              <!-- Invite Header -->
              <div class="flex flex-col gap-1">
                <p class="text-body2 text-primary-500 font-medium"
                   i18n="Invite Guests Title|Title for invite guest section@@bookingInfo.inviteGuestsTitle">
                  Invite Guest(s)
                </p>
                <p class="text-caption text-neutral-400"
                   i18n="Invite Guests Description|Subtext for inviting guests@@bookingInfo.inviteGuestsDescription">
                  Invite your friends to dine in with you!
                </p>
              </div>

              <!-- Invite Form -->
              <form [formGroup]="inviteForm"
                    class="flex gap-6 items-center w-full"
                    (ngSubmit)="onInvitePressed()">
                <input formControlName="emailOrPhone"
                       class="rounded py-3 px-3 border-neutral-400 focus:border-primary-500 focus:outline-primary-500 border w-full"
                       placeholder="Phone (or) Email"
                       i18n-placeholder="Invite Input Placeholder|Placeholder for phone/email field@@bookingInfo.inviteInputPlaceholder"/>

                <lib-button iconSrc="assets/icons/invite-guest.svg" type="submit"
                            i18n="Invite Button Text|Submit button to invite a guest@@bookingInfo.inviteButton">
                  Invite
                </lib-button>
              </form>
            }

            <!-- Invited Guests List -->
            <div class="flex flex-col gap-2">
              <p class="text-body2 text-neutral-700"
                 i18n="Invited Guests Title|Title above invited guests list@@bookingInfo.invitedGuestsTitle">
                Invited Guests
              </p>

              @if (invitedGuestsState.success() && invitationReceivers().length <= 0) {
                <div class="flex justify-center my-8">
                  <lib-no-data [size]="80"
                               message="No guests invited"
                               i18n-message="No Invites Message|Shown when no guests have been invited@@bookingInfo.noGuestsInvited">
                  </lib-no-data>
                </div>
              }

              @for (receiver of invitationReceivers(); track receiver) {
                <div class="flex items-center gap-4 py-2">
                  <lib-svg-icon class="text-neutral-500" src="assets/icons/account-circle.svg"></lib-svg-icon>
                  <p class="break-words whitespace-normal text-body2 max-w-xl min-w-xl">
                    {{ receiver?.email ?? receiver?.phoneNumber ?? unknownGuest }}
                  </p>
                  <div class="grow"></div>
                  <p
                    class="px-3 py-2 text-caption rounded-full bg-error-500 bg-opacity-10 text-error-500 cursor-pointer"
                    (click)="onInviteCancelClicked(receiver)"
                    i18n="Cancel Invite Button|Label to cancel a guest invitation@@bookingInfo.cancelInvite">
                    Cancel
                  </p>
                </div>
              }
            </div>
          </div>

        </div>
      </div>
    }
  </div>
</div>

