import {Component, inject, OnInit, signal} from '@angular/core';
import {generate30MinuteIntervals, isToday, TimeObject} from "@shared/utils/date-utils";
import {RestaurantsFilterService} from "../../../data/restaurants-filter.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DineInOption} from "../../../../layout/top-nav/top-nav.component";
import {DateSelectorInputComponent} from "@smartdining/lib-sd-web-shared";
import {PartySizeSelectorInputComponent} from "./party-size-selector-input/party-size-selector-input.component";
import {DineInTimeFilterComponent} from "./dine-in-time-filter/dine-in-time-filter.component";


@Component({
  selector: ' app-dine-in-global-filter',
  standalone: true,
  imports: [
    DateSelectorInputComponent,
    FormsModule,
    ReactiveFormsModule,
    PartySizeSelectorInputComponent,
    DineInTimeFilterComponent
  ],
  templateUrl: './dine-in-global-filter.component.html',
  styleUrl: './dine-in-global-filter.component.scss'
})
export class DineInGlobalFilterComponent implements OnInit {

  restaurantsFilterService = inject(RestaurantsFilterService);

  currentDate: Date;

  availableTimes = signal<TimeObject[]>([]);

  constructor() {
    this.currentDate = new Date();
    this.currentDate.setHours(0, 0, 0, 0);
  }

  dineInOption = this.restaurantsFilterService.dineInOption;

  ngOnInit(): void {
    let now = new Date();
    this.updateTimeSlots(now);
  }

  updateTimeSlots(date: Date) {
    let _isToday = isToday(date);

    let startDate = new Date();
    let endDate = new Date(startDate);
    endDate.setDate(startDate.getDate());
    endDate.setHours(23, 59, 59, 0);

    if (!_isToday) {
      startDate = new Date(date);
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date(date);
      endDate.setHours(23, 59, 59, 999);
    }

    let timeSlots = generate30MinuteIntervals(startDate, endDate);
    this.availableTimes.set(timeSlots);

    if (timeSlots.length > 0) {
      this.restaurantsFilterService.setTimeFilter(timeSlots[0]);
    }
  }

  onDateSelected(event: Date) {
    const selectedDate = new Date(event);
    selectedDate.setHours(0, 0, 0, 0);

    if (selectedDate >= this.currentDate) {
      this.restaurantsFilterService.setDateFilter(event);
    }

    this.updateTimeSlots(selectedDate);
  }

  onTimeChanged(time: TimeObject) {
    this.restaurantsFilterService.setTimeFilter(time);
  }

  onPartySizeChanged(partySize: number) {
    this.restaurantsFilterService.setPartSizeFilter(partySize);
  }

  protected readonly DineInOption = DineInOption;
}












