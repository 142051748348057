import {Component, computed, EventEmitter, inject, Input, input, OnInit, Output, signal} from '@angular/core';
import {RatingCountComponent} from "@core/components/rating-count/rating-count.component";
import {LikeButtonComponent} from "@core/components/like-button/like-button.component";
import {RestaurantSearchResponseData} from "../../../data/restaurant-search/restaurant-search-response";
import {NgOptimizedImage} from "@angular/common";
import {BaseComponent} from "@core/base/base-component";
import {calculateDistance} from "@shared/utils/geo-utils";
import {GenericResponse} from "@core/models/generic-response";
import {FavoriteRestaurantRequest} from "../../../data/favourite-restaurant/favorite-restaurant-request";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {AppDataService} from "@core/services/app-data/app-data.service";
import {RestaurantFavoriteService} from "../../../../settings/data/restaurant-favorite.service";
import {ChipComponent} from "@core/components/chip/chip.component";
import {DineInOption} from "../../../../layout/top-nav/top-nav.component";
import {OverlayService, State} from "@smartdining/lib-sd-web-shared";
import {AuthComponent} from "../../../../auth/presentation/auth.component";
import {
  AuthWizardOverlayComponent
} from "../../../../auth/presentation/overlays/auth-wizard-overlay/auth-wizard-overlay.component";

@Component({
  selector: 'app-dine-in-restaurant-card',
  standalone: true,
  templateUrl: './dine-in-restaurant-card.component.html',
  styleUrl: './dine-in-restaurant-card.component.scss',
  imports: [RatingCountComponent, ChipComponent, LikeButtonComponent, NgOptimizedImage]
})
export class DineInRestaurantCardComponent extends BaseComponent implements OnInit {
  dineInOption = input<DineInOption>();

  @Output()
  restaurantSelected = new EventEmitter<RestaurantSearchResponseData>();
  private restaurantFavoritesService = inject(RestaurantFavoriteService);
  authStoreService = inject(AuthStoreService);
  overlayService = inject(OverlayService);
  appDataService = inject(AppDataService);

  restaurant = input<RestaurantSearchResponseData>();


  removeFavoriteRestaurantState = new State<GenericResponse>();
  state = new State<GenericResponse>();

  distanceInMiles = computed<string | null>(() => {
    let destinationLatitude = this.restaurant()?.location?.location?.coordinates[1];
    let destinationLongitude = this.restaurant()?.location?.location?.coordinates[0];

    let currentLocation = this.appDataService.getAddress();
    let sourceLatitude = currentLocation?.latitude;
    let sourceLongitude = currentLocation?.longitude;
    if (sourceLatitude && sourceLongitude && destinationLatitude && destinationLongitude) {
      return calculateDistance(sourceLatitude, sourceLongitude, destinationLatitude, destinationLongitude).miles.toFixed(2);
    } else {
      return null;
    }
  });

  isError = signal(false);
  restaurantLiked = signal(false);

  ngOnInit(): void {
    const isLiked = this.appDataService.isRestaurantLiked(this.restaurant()?._id ?? '');
    this.restaurantLiked.set(isLiked);
  }

  handleImageError() {
    this.isError.update((c) => true);
  }

  onRestaurantClicked() {
    this.restaurantSelected.emit(this.restaurant());
  }

  onLikeClicked(event: MouseEvent) {
    event.stopPropagation();
    if (!this.authStoreService.isAuthenticated()) {
      this.overlayService.openModal(AuthWizardOverlayComponent);
      this.authStoreService.loginSuccessful = () => {
        this.handleLikeRestaurant();
      };
      return;
    }
    this.handleLikeRestaurant();
  }

  handleLikeRestaurant() {
    if (this.restaurantLiked()) {
      this.removeRestaurantFromFavorite();
    } else {
      this.addRestaurantToFavorite();
    }
  }


  addRestaurantToFavorite() {
    const appUser = this.authStoreService.getAppUser()?.data;
    let request: FavoriteRestaurantRequest = {
      companyUID: this.restaurant()?.companyUID ?? '',
      user: appUser?.user ?? '',
      restaurant: this.restaurant()?._id ?? ''
    };
    this.executeRequest({
      state: this.state,
      request: this.restaurantFavoritesService.addToFavorite(request),
      showLoader: true,
      successMessage: 'Restaurant added as favorite',
      onSuccess: response => {
        this.appDataService.saveFavoriteRestaurants();
        this.restaurantLiked.set(true);
      }
    });
  }

  removeRestaurantFromFavorite() {
    const favoriteRestaurantId = this.appDataService.getFavoriteRestaurantId(this.restaurant()?._id ?? '');
    this.executeRequest({
      state: this.removeFavoriteRestaurantState,
      request: this.restaurantFavoritesService.deleteFavoriteRestaurant(favoriteRestaurantId ?? ''),
      showLoader: true,
      successMessage: 'Restaurant removed from favorite list',
      onSuccess: response => {
        this.appDataService.saveFavoriteRestaurants();
        this.restaurantLiked.set(false);
      }
    });
  }

  protected readonly DineInOption = DineInOption;
}
