<app-base-dialog
  [title]="'Booking Confirmed'"
  i18n-title="Booking Confirmation Title|Title of the booking confirmation overlay@@bookingConfirmationOverlay.title"
  [showAction]="false">

  <div body class="flex flex-col justify-center items-center gap-3">

    <!-- Success Icon -->
    <span class="flex justify-center items-center self-center rounded-full h-12 w-12 bg-success-500 bg-opacity-10 flex-shrink-0">
      <lib-svg-icon [size]="36" class="text-success-500" src="assets/icons/check-circle.svg"></lib-svg-icon>
    </span>

    <!-- Confirmation Message -->
    <p class="text-subtitle1 text-neutral-500"
       i18n="Booking Confirmed Text|Subtitle shown after booking confirmation@@bookingConfirmationOverlay.confirmedText">
      Booking confirmed!
    </p>

    <!-- Booking ID -->
    <span class="flex gap-2 items-center">
      <p class="text-body2 text-black"
         i18n="Booking ID Label|Label before showing confirmation number@@bookingConfirmationOverlay.bookingIdLabel">
        Booking ID -
      </p>
      <p class="text-body2 text-info-500">#{{ data.data.confirmationNumber }}</p>
    </span>

    <!-- Reservation Details -->
    <div class="flex items-center justify-center gap-6 w-full px-4 py-3 bg-neutral-50 rounded-2xl">
      <div class="flex gap-2 items-center">
        <lib-svg-icon src="assets/icons/calendar.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
        <p class="text-body2">{{ data.data.startTime | date:'d MMMM y' }}</p>
      </div>
      <div class="flex gap-2 items-center">
        <lib-svg-icon src="assets/icons/clock.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
        <p class="text-body2">{{ data.data.startTime | date:'h:mm a' }}</p>
      </div>
      <div class="flex gap-2 items-center">
        <lib-svg-icon src="assets/icons/group.svg" [size]="18" class="text-neutral-500"></lib-svg-icon>
        <p class="text-body2"
           i18n="Party Size Text|Number of guests@@bookingConfirmationOverlay.partySize">
          {{ data.data.partySize }} Guest(s)
        </p>
      </div>
    </div>

    <!-- Invite Guests Title -->
    <p class="text-body1 text-primary-500 mt-6 self-start"
       i18n="Invite Guests Title|Title for invite section@@bookingConfirmationOverlay.inviteTitle">
      Invite Guest(s)
    </p>

    <!-- Invite Form -->
    <form [formGroup]="inviteForm" class="flex gap-6 items-center w-full" (ngSubmit)="onInvitePressed()">
      <input formControlName="emailOrPhone"
             class="rounded py-3 px-3 border-neutral-400 focus:border-primary-500 focus:outline-primary-500 border w-full"
             placeholder="Phone (or) Email"
             i18n-placeholder="Invite Placeholder|Placeholder for invite input@@bookingConfirmationOverlay.invitePlaceholder"/>
      <lib-button [loading]="invitedGuestsState.loading()"
                  iconSrc="assets/icons/invite-guest.svg"
                  type="submit"
                  i18n="Invite Button|Invite a guest@@bookingConfirmationOverlay.inviteButton">
        Invite
      </lib-button>
    </form>

    <!-- Invited Guests Section -->
    <div class="flex flex-col gap-4 self-start w-full">
      <p class="text-body2 text-primary-500"
         i18n="Invited Guests Label|Label above invited guests list@@bookingConfirmationOverlay.invitedGuests">
        Invited Guests
      </p>

      @for (receiver of invitationReceivers(); track receiver) {
        <div class="flex items-center gap-4">
          <lib-svg-icon class="text-neutral-500" src="assets/icons/account-circle.svg"></lib-svg-icon>
          <p class="break-words whitespace-normal text-body2 max-w-xl min-w-xl">
            {{ receiver?.email ?? receiver?.phoneNumber ?? unknownGuest }}
          </p>
          <div class="grow"></div>
          <p class="px-3 py-1 text-caption rounded-full bg-error-500 bg-opacity-10 text-error-500 cursor-pointer"
             (click)="onCancelInvitationPressed(receiver)"
             i18n="Cancel Invite Button|Cancels a guest invitation@@bookingConfirmationOverlay.cancelInvite">
            Cancel
          </p>
        </div>
      }

      <!-- No Guests Message -->
      <div class="flex self-center w-full justify-center items-center">
        @if (invitedGuestsState.success() && invitationReceivers().length <= 0) {
          <lib-no-data [size]="56"
                       [message]="'No guests invited'"
                       i18n-message="No Guests Message|Shown when no guests have been invited@@bookingConfirmationOverlay.noGuests">
          </lib-no-data>
        }
      </div>
    </div>
  </div>
</app-base-dialog>
