import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "@core/base/base-component";
import {
  BaseOverlayComponent,
  ButtonComponent,
  State,
  TextInputComponent
} from "@smartdining/lib-sd-web-shared";
import {LoginResponse} from "../../../data/login/login-response";
import {AuthenticatedUserResponse} from "../../../data/authenticated-user/authenticated-user-response";
import {AuthService} from "../../../data/auth.service";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {Dialog, DialogRef} from "@angular/cdk/dialog";
import {LoginWithEmailPasswordRequest} from "../../../data/login/login-with-email-password-request";

@Component({
  selector: 'app-user-login-overlays',
  standalone: true,
  imports: [
    TextInputComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    BaseOverlayComponent,
  ],
  templateUrl: './user-login-overlay.component.html',
  styleUrl: './user-login-overlay.component.scss'
})
export class UserLoginOverlayComponent extends BaseComponent implements OnInit {

  form!: FormGroup;
  loginState = new State<LoginResponse>();
  authenticatedUserState = new State<AuthenticatedUserResponse>();
  private fb = inject(FormBuilder);
  private authService = inject(AuthService);
  private authStoreService = inject(AuthStoreService);
  dialogRef = inject(DialogRef);
  dialog = inject(Dialog);

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', Validators.required]
    });
  }

  loginClicked() {
    if (this.form.invalid) {
      return;
    }

    const request: LoginWithEmailPasswordRequest = {
      email: this.form.value.email,
      password: this.form.value.password,
    };

    this.executeRequest<LoginResponse>({
      state: this.loginState,
      request: this.authService.loginWithEmailPassword(this.form.value),
      onSuccess: response => {
        this.dialog.closeAll();
        let isMfaEnabled = response.data.enabledMFA ?? false;
        if (isMfaEnabled) {
          this.router.navigate(['/mfa'], {
            queryParams: {
              userInfo: request.email,
              formatPhoneNumber: response.data.formatPhoneNumber,
              emailFormat: response.data.emailFormat
            }
          });
        } else {
          this.authStoreService.saveToken(response.data.refreshToken, response.data.token);
          this.handleLogin();
        }
      }
    });
  }

  handleLogin() {
    this.executeRequest<AuthenticatedUserResponse>({
      state: this.authenticatedUserState,
      request: this.authService.getAuthenticatedUser(),
      onSuccess: response => {
        if (response.data.userType == 'USER_TYPE_USER') {
          this.authStoreService.saveAppUser(response, false);
          this.dialogRef.close(LoginStatus.loginSuccess);
        } else {
          this.authStoreService.logout();
          this.toasterService.error('Invalid credentials, kindly use a valid credentials');
        }
      }
    });
  }

  onForgotPasswordClicked() {
    this.dialog.closeAll();
    this.router.navigate(['/forgot-password']);
  }

  onSignupClicked() {
    this.dialog.closeAll();
    this.router.navigate(['/signup']);
  }
}

export enum LoginStatus {
  loggedOut,
  loginSuccess,
  loginFailed
}
