import {Component, inject, input, OnInit, signal} from '@angular/core';
import {RouterLink} from "@angular/router";
import {LocationSelectorComponent} from "@core/components/location-selector/location-selector.component";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";
import {AppDataService} from "@core/services/app-data/app-data.service";
import {MatDialog} from "@angular/material/dialog";
import {BaseComponent} from "@core/base/base-component";
import {
  LocationSelectionOverlayComponent
} from "../../common/presentation/overlay/location-selection-overlay/location-selection-overlay.component";
import {Constants} from "@core/constants/constants";
import {
  SelectAddressOverlayComponent
} from "../../common/presentation/overlay/select-address-overlay/select-address-overlay.component";
import {TopNavService} from "./top-nav.service";
import {DatePipe, NgClass} from "@angular/common";
import {RestaurantsFilterService} from "../../restaurants/data/restaurants-filter.service";
import {CartService} from "../../pickup-order/data/cart.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PickupScheduleRequest} from "../../pickup-order/data/pickup-schedule/pickup-schedule-request";
import {PickupScheduleResponse} from "../../pickup-order/data/pickup-schedule/pickup-schedule-response";
import {PickupOrderService} from "../../pickup-order/data/pickup-order.service";
import {
  AvailableTimeForView
} from "../../pickup-order/presentation/pages/pickup-order-restaurant-details/pickup-order-restaurant-details.component";
import {RestaurantService} from "../../restaurants/data/restaurant.service";
import {
  RestaurantSuggestionResponse
} from "../../restaurants/data/restaurant-suggestion/restaurant-suggestion-response";
import {RestaurantSuggestionComponent} from "../../restaurant-suggestion/restaurant-suggestion.component";
import {
  AppSvgIconComponent,
  ButtonComponent,
  ClickOutsideDirective,
  OverlayService,
  State
} from "@smartdining/lib-sd-web-shared";
import {
  AuthWizardOverlayComponent
} from "../../auth/presentation/overlays/auth-wizard-overlay/auth-wizard-overlay.component";

@Component({
  selector: 'app-top-nav',
  standalone: true,
  imports: [
    LocationSelectorComponent,
    RouterLink,
    AppSvgIconComponent,
    ButtonComponent,
    MatMenu,
    MatMenuTrigger,
    ClickOutsideDirective,
    NgClass,
    RestaurantSuggestionComponent,
    ClickOutsideDirective,
    ClickOutsideDirective,
    ClickOutsideDirective
  ],
  templateUrl: './top-nav.component.html',
  styleUrl: './top-nav.component.scss',
  providers: [
    DatePipe
  ]
})
export class TopNavComponent extends BaseComponent implements OnInit {
  restaurantId = input('');

  authStoreService = inject(AuthStoreService);
  restaurantsFilterService = inject(RestaurantsFilterService);
  appDataService = inject(AppDataService);
  dialog = inject(MatDialog);
  topNavService = inject(TopNavService);
  cartService = inject(CartService);
  formBuilder = inject(FormBuilder);
  pickupOrderService = inject(PickupOrderService);
  datePipe = inject(DatePipe);
  restaurantService = inject(RestaurantService);
  overlayService = inject(OverlayService);


  pickupScheduleState = new State<PickupScheduleResponse>();
  suggestionState = new State<RestaurantSuggestionResponse>();


  filterForm!: FormGroup;

  timeAvailabilities: AvailableTimeForView[] = [];


  protected readonly DineInOption = DineInOption;

  dineInOption = this.restaurantsFilterService.dineInOption;
  expanded = signal(false);


  ngOnInit(): void {
    this.initPickupOrderForm();
  }


  initPickupOrderForm() {
    this.filterForm = this.formBuilder.group({
      pickupOption: ['ASAP'],
      date: [Date()],
      time: []
    });

    this.filterForm.controls['pickupOption'].valueChanges.subscribe(value => {
      switch (value) {
        case 'ASAP':
          this.filterForm.controls['date'].setValidators([]);
          this.filterForm.controls['time'].setValidators([]);
          this.filterForm.controls['date'].updateValueAndValidity();
          this.filterForm.controls['time'].updateValueAndValidity();
          break;
        case 'LATER':
          this.filterForm.controls['time'].setValue(null);
          this.filterForm.controls['date'].setValidators([Validators.required]);
          this.filterForm.controls['time'].setValidators([Validators.required]);
          this.filterForm.controls['date'].updateValueAndValidity();
          this.filterForm.controls['time'].updateValueAndValidity();
          this.filterForm.controls['time'].markAsUntouched();
          this.getPickupSchedules(new Date());
          break;
      }
    });
  }

  private getPickupSchedules(date: Date) {
    const request: PickupScheduleRequest = {
      restaurantId: this.restaurantId(),
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
    this.executeRequest<PickupScheduleResponse>({
      state: this.pickupScheduleState,
      request: this.pickupOrderService.getPickupSchedule(request),
      onSuccess: response => {
        this.timeAvailabilities = [];
        for (const pickupTime of response.data.orderPickupTimes ?? []) {
          let ta: AvailableTimeForView = {
            pickupTime: pickupTime,
            formattedTime: this.datePipe.transform(pickupTime.pickupTime, 'hh:mm a')
          };
          this.timeAvailabilities.push(ta);
        }
      }
    });
  }

  onHamburgerClick() {
    this.expanded.update(prev => !prev);
  }

  onLogoutClick() {
    this.authStoreService.logout();
  }

  onLocationClicked() {
    if (this.authStoreService.isAuthenticated()) {
      this.dialog.open(LocationSelectionOverlayComponent, {
        ...Constants.defaultDialogConfig
      });
    } else {
      this.dialog.open(SelectAddressOverlayComponent, {
        ...Constants.defaultDialogConfig
      });
    }
  }


  clickedOutSideMobileNav() {
    this.expanded.set(false);
  }

  onLoginClicked() {
    this.overlayService.openModal(AuthWizardOverlayComponent);
  }

  onDineOptionSelected(dineInOption: DineInOption) {
    this.restaurantsFilterService.setDineInOptionFilter(dineInOption);

    const restaurantId = this.route.snapshot.paramMap.get('restaurantId');
    if (restaurantId) {

      switch (dineInOption) {
        case DineInOption.dineIn:
          this.router.navigate([`/dine-in/${restaurantId}`], {
            queryParams: {}
          }).then();
          break;
        case DineInOption.pickupOrder:
          this.router.navigate([`/pickup/${restaurantId}`], {
            queryParams: {}
          }).then();
          break;
      }
    }
  }

  onViewCartPressed() {
    this.validateForm(this.filterForm);

    if (this.cartService.cart().quantity <= 0) {
      this.toasterService.warning('Your cart is empty please add items to cart.');
      return;
    }

    if (!this.filterForm.valid) {
      this.toasterService.warning('Please choose the schedule time');
      return;
    }

    let pickupOption = this.filterForm.value.pickupOption;
    let pickupTime = this.filterForm.value.time?.pickupTime?.pickupTime;
    this.cartService.setPickupOptions(pickupOption, pickupTime);

    this.router.navigate([`/checkout/${this.cartService.restaurantId}`]);

  }

  onForBusinessClicked() {
    window.open('https://restaurant.gosmartdining.com', '_blank');
  }
}

export enum DineInOption {
  dineIn = 'dine-in',
  pickupOrder = 'pickup-order'
}
