import { Component } from '@angular/core';
import {RouterLink} from "@angular/router";
import {ButtonComponent} from "@smartdining/lib-sd-web-shared";

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [
    ButtonComponent,
    RouterLink
  ],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent {
}
