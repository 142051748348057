import {Component, computed, ElementRef, inject, input, OnDestroy, OnInit, signal} from '@angular/core';
import {BaseComponent} from "@core/base/base-component";
import {RestaurantDetailResponse} from "../../../data/restaurant-details/restaurant-detail-response";
import {RestaurantService} from "../../../data/restaurant.service";
import {RatingCountComponent} from "@core/components/rating-count/rating-count.component";
import {DirectionButtonComponent} from "@core/components/direction-button/direction-button.component";
import {fromEvent, Subscription, throttleTime} from 'rxjs';
import {DatePipe, DOCUMENT, Location, NgClass} from "@angular/common";
import {pixelToTopCss} from "@shared/utils/html-utils/css-top-utils";
import {OverallReview, ReviewSummaryComponent} from "@core/components/review-summary/review-summary.component";
import {MinutesToTimePipe} from "@shared/pipes/minutes-to-time.pipe";
import {TableAvailabilitiesResponse} from "../../../../booking/data/table-availabilities/table-availabilities-response";
import {TableAvailabilitiesRequest} from "../../../../booking/data/table-availabilities/table-availabilities-request";
import {GeoPoint} from "@core/services/location-service/location.service";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Constants} from "@core/constants/constants";
import {FavoriteRestaurantRequest} from "../../../data/favourite-restaurant/favorite-restaurant-request";
import {AuthStoreService} from "@core/services/auth-store-service/auth-store.service";
import {GenericResponse} from "@core/models/generic-response";
import {ShareLinkComponent} from "../../../../common/presentation/overlay/share-link/share-link.component";
import {BookingsService} from "../../../../booking/data/bookings/bookings.service";
import {calculateDistance} from "@shared/utils/geo-utils";
import {AppDataService} from "@core/services/app-data/app-data.service";
import {arrayToCSV, toDashCase} from "@shared/utils/string-utils";
import {RestaurantFavoriteService} from "../../../../settings/data/restaurant-favorite.service";
import {
  RatingActionStatus,
  WriteReviewOverlayComponent
} from "../../overlay/write-review-overlay/write-review-overlay.component";
import {ReviewsService} from "../../../data/reviews.service";
import {RestaurantReviewsResponse} from "../../../data/restaurant-reviews/RestaurantReviewsResponse";
import {RestaurantReviewsRequest} from "../../../data/restaurant-reviews/RestaurantReviewsRequest";
import {IndividualRatingComponent} from "@core/components/individual-rating/individual-rating.component";
import {BookingsResponse, BookingsResponseData} from "../../../../booking/data/bookings/bookings/bookings-response";
import {BookingsRequest} from "../../../../booking/data/bookings/common/bookings-request";
import {
  BookingSurveyOverlayComponent,
  BookingSurveyStatus
} from "../../../../booking/presentation/overlays/booking-review-overlay/booking-survey-overlay.component";
import {numberToPricePoint} from "@core/utils/price-point-utils";
import {ChipComponent} from "@core/components/chip/chip.component";

import {
  SetBookingStatusNotInterestedRequest
} from "../../../../booking/data/set-booking-survey-not-interested/set-booking-status-not-interested-request";
import {
  SetBookingStatusNotInterestedResponse
} from "../../../../booking/data/set-booking-survey-not-interested/set-booking-status-not-interested-response";
import {BookingInformationResponse} from "../../../../booking/data/bookings/bookings/booking-information-response";
import {DineInOption} from "../../../../layout/top-nav/top-nav.component";
import {RestaurantsFilterService} from "../../../data/restaurants-filter.service";
import {FoodMenu, MenuCategory} from "../../../../pickup-order/data/menu/menu";
import {MenuResponse, MenuResponseData} from "../../../../pickup-order/data/menu/menu-response";
import {
  AvailableTimeForView
} from "../../../../pickup-order/presentation/pages/pickup-order-restaurant-details/pickup-order-restaurant-details.component";
import {MenuResponseToMenuUtil} from "../../../../pickup-order/utilities/menu-response-to-menu-util";
import {DiningOrderService} from "../../../../pickup-order/data/dining-order.service";
import {CartService} from "../../../../pickup-order/data/cart.service";
import {
  AppSvgIconComponent,
  BreadcrumbComponent,
  ButtonComponent,
  DateSelectorInputComponent,
  NetworkImageComponent, OverlayService,
  ShimmerComponent,
  SingleSelectionFieldComponent,
  State
} from '@smartdining/lib-sd-web-shared';
import {RestaurantSuggestionService} from "../../../../restaurant-suggestion/restaurant-suggestion.service";
import {
  PartySizeSelectorInputComponent
} from "../../components/dine-in-global-filter/party-size-selector-input/party-size-selector-input.component";
import {
  MakeReservationOverlayComponent
} from "../../../../booking/presentation/overlays/make-reservation-overlay/make-reservation-overlay.component";
import {
  JoinWaitlistOverlayComponent
} from "../../../../booking/presentation/overlays/join-waitlist-overlay/join-waitlist-overlay.component";
import {
  AuthWizardOverlayComponent
} from "../../../../auth/presentation/overlays/auth-wizard-overlay/auth-wizard-overlay.component";

@Component({
  selector: 'app-dine-in-restaurant-details',
  standalone: true,
  imports: [
    RatingCountComponent,
    ChipComponent,
    AppSvgIconComponent,
    DirectionButtonComponent,
    NgClass,
    ReviewSummaryComponent,
    MinutesToTimePipe,
    DatePipe,
    ShimmerComponent,
    DateSelectorInputComponent,
    ButtonComponent,
    ReactiveFormsModule,
    IndividualRatingComponent,
    FormsModule,
    NetworkImageComponent,
    PartySizeSelectorInputComponent,
    SingleSelectionFieldComponent,
    BreadcrumbComponent
  ],
  providers: [
    DatePipe
  ],
  templateUrl: './dine-in-restaurant-details.component.html',
  styleUrl: './dine-in-restaurant-details.component.scss'
})

export class DineInRestaurantDetailsComponent extends BaseComponent implements OnInit, OnDestroy {

  restaurantId = input('');
  date = input<string | null>(null);
  partySize = input<number | null>(4);
  bookingId = input<string>('');

  overlayService = inject(OverlayService);
  elementRef = inject(ElementRef);
  document = inject(DOCUMENT);
  location = inject(Location);
  datePipe = inject(DatePipe);
  formBuilder = inject(FormBuilder);
  restaurantService = inject(RestaurantService);
  authStoreService = inject(AuthStoreService);
  bookingService = inject(BookingsService);
  appDataService = inject(AppDataService);
  favoritesService = inject(RestaurantFavoriteService);
  reviewsService = inject(ReviewsService);
  bookingsService = inject(BookingsService);
  diningOrderService = inject(DiningOrderService);
  restaurantsFilterService = inject(RestaurantsFilterService);
  cartService = inject(CartService);
  restaurantSuggestionService = inject(RestaurantSuggestionService);

  booking = signal<BookingInformationResponse | null>(null);
  stickyClass = signal('top-0');
  isPhotosAvailable = signal(false);
  filteredMenu = signal<FoodMenu | null>(null);
  selectedDineInOption = signal(DineInOption.dineIn);

  protected readonly numberToPricePoint = numberToPricePoint;
  protected readonly arrayToCSV = arrayToCSV;
  protected readonly toDashCase = toDashCase;


  protected readonly DineInOption = DineInOption;
  private filterObserver?: Subscription;

  showOverview = true;
  showReviews = true;
  showMenu = true;

  restaurantDetailState = new State<RestaurantDetailResponse>();
  tableAvailabilitiesState = new State<TableAvailabilitiesResponse>();
  state = new State<GenericResponse>();
  removeFavoriteRestaurantState = new State<GenericResponse>();
  reviewsState = new State<RestaurantReviewsResponse>();
  bookingState = new State<BookingsResponse>();
  reservationForm!: FormGroup;
  setBookingStatusNotInterestedState = new State<SetBookingStatusNotInterestedResponse>();
  menuState = new State<MenuResponse>();

  dineInOption = this.restaurantsFilterService.dineInOption;


  nextUpcomingReservation = signal<BookingsResponseData | null>(null);
  pastReservation = signal<BookingsResponseData | null>(null);

  distanceInMiles = computed<string | null>(() => {
    const restaurant = this.restaurantDetailState.response()?.data;
    let destinationLatitude = restaurant?.location?.location?.coordinates[1];
    let destinationLongitude = restaurant?.location?.location?.coordinates[0];

    let currentLocation = this.appDataService.getAddress();
    let sourceLatitude = currentLocation?.latitude;
    let sourceLongitude = currentLocation?.longitude;
    if (sourceLatitude && sourceLongitude && destinationLatitude && destinationLongitude) {
      const distanceInMiles = calculateDistance(sourceLatitude, sourceLongitude, destinationLatitude, destinationLongitude).miles.toFixed(2);
      return `${distanceInMiles} miles away`
    } else {
      return 'N/A';
    }
  });

  restaurantLiked = signal(false);
  selectedMenu = signal<MenuResponseData | null>(null);

  protected readonly Date = Date;

  rating = computed(() => {
    const restaurantDetails = this.restaurantDetailState.response()?.data;
    return {
      rating: restaurantDetails?.rating,
      ratingCount: restaurantDetails?.ratingCount,
      oneStarRatings: restaurantDetails?.ratings?.rating1Count ?? 0,
      twoStarRatings: restaurantDetails?.ratings?.rating2Count ?? 0,
      threeStarRatings: restaurantDetails?.ratings?.rating3Count ?? 0,
      fourStarRatings: restaurantDetails?.ratings?.rating4Count ?? 0,
      fiveStarRatings: restaurantDetails?.ratings?.rating5Count ?? 0
    } as OverallReview;
  });


  destinationGeoPoint = computed(() => {
    let geoPoint: GeoPoint = {
      latitude: this.restaurantDetailState.response()?.data?.location?.location?.coordinates[1],
      longitude: this.restaurantDetailState.response()?.data?.location?.location?.coordinates[0],
    };
    return geoPoint;
  });

  availabilities = signal<TimeAvailabilityForView[]>([]);
  private scrollSubscription!: Subscription;
  private formSubscription!: Subscription;

  minimumDate = signal(new Date());

  timeAvailabilities: AvailableTimeForView[] = [];

  restaurantDetailsFeedback = $localize`No Feedback Text|Shown when a user leaves no feedback@@restaurantDetails.noFeedback:No feedback give`

  ngOnInit(): void {
    this.initReservationForm();
    this.initUIWork();
    this.getRestaurantDetails(this.restaurantId());
    this.getRestaurantRatings();
    this.getPastReservation();
    this.getNextUpcomingReservation();
    this.initEventListeners();
    this.getTableAvailabilities();
    this.getDiningMenus();
    this.restaurantSelectedListener();

    this.filterObserver = this.restaurantsFilterService.dineInOptionUpdated$.subscribe((dineInOption) => {
      this.handleDineInOptionChange(dineInOption);
    });
  }

  ngOnDestroy(): void {
    try {
      this.scrollSubscription.unsubscribe();
      this.formSubscription.unsubscribe();
      this.filterObserver?.unsubscribe();
    } catch (e) {

    }
  }

  restaurantSelectedListener() {
    this.restaurantSuggestionService.restaurantSelected = (restaurantId) => {
      this.getRestaurantDetails(restaurantId);
    };
  }

  private initEventListeners() {
    this.filterObserver = this.restaurantsFilterService.filterUpdated$.subscribe((value) => {
      this.getDiningMenus();
    });
  }


  handleDineInOptionChange(dineInOption: DineInOption | null) {
    switch (dineInOption) {
      case DineInOption.dineIn:
        this.gotoDineInPage();
        break;
      case DineInOption.pickupOrder:
        this.gotoPickupOrderPage();
        break;
    }
  }

  gotoDineInPage() {
    let parameters = this.route.snapshot.queryParams;
    this.router.navigate(['/dine-in', this.restaurantId()], {queryParams: parameters});
  }

  gotoPickupOrderPage() {
    let parameters = this.route.snapshot.queryParams;
    const currentCartRestaurantId = this.cartService.restaurantId;
    if (currentCartRestaurantId && currentCartRestaurantId != this.restaurantId()) {
      this.cartService.resetCart();
      this.router.navigate([`pickup/${this.restaurantId()}`], {queryParams: parameters});
      return;
    }

    this.router.navigate(['/pickup', this.restaurantId()], {queryParams: parameters});
  }


  initReservationForm() {
    let date = this.date() ? new Date(this.date()!) : new Date();
    date.setHours(0, 0, 0, 0);

    this.reservationForm = this.formBuilder.group({
      date: [date, [Validators.required]],
      partySize: [Number(this.partySize() ?? 4), [Validators.required]],
      time: [null, [Validators.required]],
    });
    this.formSubscription = this.reservationForm.valueChanges.subscribe(data => {
      this.mergeQueryParams({date: data.date.toISOString(), partySize: data.partySize});
    });
  }

  toggleSection(section: 'overview' | 'reviews' | 'menu') {
    if (section === 'overview') {
      this.showOverview = true;
      this.showReviews = true;
    } else if (section === 'reviews') {
      this.showOverview = true;
      this.showReviews = true;
    } else if (section === 'menu') {
      this.showMenu = true;
      this.showMenu = true;
      this.showOverview = false;
      this.showReviews = false;
    }
  }

  initUIWork() {
    let topBarHeight = this.document.getElementById('topBar')?.clientHeight || 0;
    this.stickyClass.update((c) => pixelToTopCss(topBarHeight));
    this.scrollSubscription = fromEvent(this.document, 'scroll')
      .pipe(throttleTime(100))
      .subscribe((event) => this.onWindowScroll(event));
  }

  scrollToSection(id: string) {
    const element = this.elementRef.nativeElement.querySelector(`#${id}`);
    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
  }

  onWindowScroll(event: Event) {
    let scrollPosition = window.scrollY || document.documentElement.scrollTop;
    scrollPosition = scrollPosition + 160;

    const sections: any = this.document.querySelectorAll('.section');

    sections.forEach((section: HTMLElement) => {
      if (
        section.offsetTop <= scrollPosition &&
        section.offsetTop + section.offsetHeight > scrollPosition
      ) {
        let navLinks: any = this.elementRef.nativeElement.querySelectorAll('.nav-link');
        navLinks.forEach((link: HTMLAnchorElement) => {
          if (link.classList.contains(section.id)) {
            link.classList.add('nav-link-active');
            link.classList.remove('nav-link-inactive');
          } else {
            link.classList.add('nav-link-inactive');
            link.classList.remove('nav-link-active');
          }
        });
      }
    });
  }

  getRestaurantDetails(restaurantId: string) {
    this.executeRequest<RestaurantDetailResponse>({
      state: this.restaurantDetailState,
      request: this.restaurantService.getRestaurantDetails(restaurantId),
      onSuccess: response => {
        const isRestaurantLiked = this.appDataService.isRestaurantLiked(response.data._id);
        this.restaurantLiked.set(isRestaurantLiked);
        let photoGallery = response.data?.photoGallery ?? [];
        if (photoGallery.length > 0) {
          this.isPhotosAvailable.set(true);
        } else {
          this.isPhotosAvailable.set(false);
        }
        // const isOpen = this.isRestaurantOpen();
      }
    });
  }

  onDateSelected($event: Date) {
    this.reservationForm.controls['time'].setValue(null);
    this.getTableAvailabilities();
  }

  onPartSizeChanged(event: number) {
    this.getTableAvailabilities();
  }

  getTableAvailabilities() {
    this.availabilities.set([]);
    const formDate = this.reservationForm.get('date')?.value;
    const isoDate = formDate ? new Date(formDate).toISOString() : new Date().toISOString();
    const partySize = Number(this.reservationForm.get('partySize')?.value);

    const request: TableAvailabilitiesRequest = {
      restaurantId: this.restaurantId(),
      date: isoDate,
      partySize: partySize,
      tableIds: []
    };

    this.executeRequest<TableAvailabilitiesResponse>({
      state: this.tableAvailabilitiesState,
      request: this.bookingService.getTableAvailabilities(request),
      onSuccess: response => {
        let availabilities: TimeAvailabilityForView[] = [];
        response.data.forEach(shift => {
          shift.availabilities.forEach(time => {
            availabilities.push({
              shiftId: shift.shiftId,
              partySize: time.partySize,
              time: new Date(time.time),
              timeString: this.datePipe.transform(time.time, 'hh:mm a'),
              estimatedEndTime: new Date(time.estimatedEndTime),
              cancellationCutOffTime: new Date(time.cancellationCutOffTime),
              changeCutOffTime: new Date(time.changeCutOffTime),
              tableTypes: time.tableTypes
            });
          });
        });
        this.availabilities.set(availabilities);
      }, onFailed: () => {
        this.availabilities.set([]);
      }
    });
  }

  onFavoriteClicked(event: MouseEvent) {
    event.stopPropagation();
    if (!this.authStoreService.isAuthenticated()) {
      this.overlayService.openModal(AuthWizardOverlayComponent);
      this.authStoreService.loginSuccessful = () => {
        this.handleFavoriteRestaurant();
      };
      return;
    }

    this.handleFavoriteRestaurant();
  }

  handleFavoriteRestaurant() {
    if (this.restaurantLiked()) {
      this.removeRestaurantFromFavorite();
    } else {
      this.addRestaurantToFavorite();
    }
  }

  addRestaurantToFavorite() {
    const appUser = this.authStoreService.getAppUser()?.data;
    const restaurant = this.restaurantDetailState.response()?.data;
    let request: FavoriteRestaurantRequest = {
      companyUID: restaurant?.companyUID ?? '',
      user: appUser?.user ?? '',
      restaurant: restaurant?._id ?? ''
    };
    this.executeRequest({
      state: this.state,
      request: this.favoritesService.addToFavorite(request),
      showLoader: true,
      successMessage: 'Restaurant added as favorite',
      onSuccess: response => {
        this.appDataService.saveFavoriteRestaurants();
        this.restaurantLiked.set(true);
      }
    });
  }

  removeRestaurantFromFavorite() {
    const restaurant = this.restaurantDetailState.response()?.data;
    const favoriteRestaurantId = this.appDataService.getFavoriteRestaurantId(restaurant?._id ?? '');
    this.executeRequest({
      state: this.removeFavoriteRestaurantState,
      request: this.favoritesService.deleteFavoriteRestaurant(favoriteRestaurantId ?? ''),
      showLoader: true,
      successMessage: 'Restaurant removed from favorite list',
      onSuccess: response => {
        this.appDataService.saveFavoriteRestaurants();
        this.restaurantLiked.set(false);
      }
    });
  }


  onReserveClicked() {
    const isTimeAvailable = this.availabilities().length > 0;
    if (!isTimeAvailable) {
      this.toasterService.error('Tables are not available for booking, please select different ot lesser party size', {duration: 5000});
      return;
    }

    if (this.reservationForm.invalid) {
      return;
    }

    const selectedTime = this.reservationForm.get('time')?.value;
    const restaurantName = this.restaurantDetailState?.response()?.data?.restaurantName;
    const restaurantAddress = `${this.restaurantDetailState?.response()?.data?.location?.address1}, ${this.restaurantDetailState?.response()?.data?.location?.city}`;

    const restaurantImage = this.restaurantDetailState.response()?.data?.banner?.url;

    this.overlayService.openModal(MakeReservationOverlayComponent, {
      data: {
        restaurantId: this.restaurantId(),
        partySize: this.reservationForm.get('partySize')?.value,
        availability: selectedTime,
        restaurantImage: restaurantImage,
        restaurantName: restaurantName,
        restaurantAddress: restaurantAddress
      } as ReservationDataForView,
    });

  }

  onJoinWaitlistClicked() {
    this.overlayService.openModal(JoinWaitlistOverlayComponent, {
        ...Constants.defaultDialogConfig,
        data: {
          restaurantId: this.restaurantId(),
        }
      }
    );
  }

  onShareClicked() {
    const fullUrl = this.document.URL;
    this.overlayService.openModal(ShareLinkComponent, {
      data: {
        url: fullUrl,
        mailSubject: 'Checkout this restaurant',
        message: `Discover something delicious! Checkout this restaurant - ${fullUrl}`
      }
    });
  }

  onMobileReserveTableClicked() {
    this.router.navigate(['/reservation'], {queryParams: {restaurantId: this.restaurantId()}});
  }

  onMobileJoinWaitListClicked() {
    this.router.navigate(['/join-waitlist'], {queryParams: {restaurantId: this.restaurantId()}});
  }

  onWriteReviewClicked() {
    this.overlayService.openModal(WriteReviewOverlayComponent, {
      data: this.restaurantDetailState.response()?.data,
      ...Constants.defaultDialogConfig
    }).closed.subscribe((status: RatingActionStatus) => {
      if (status == RatingActionStatus.success) {
        this.getRestaurantRatings();
        this.getRestaurantDetails(this.restaurantId())
      }
    });
  }

  private getRestaurantRatings() {
    const request: RestaurantReviewsRequest = {
      restaurantId: this.restaurantId(),
      page: {
        size: 5,
        current: 1
      },
      sort: {
        _score: 'desc'
      }
    };
    this.executeRequest({
      state: this.reviewsState,
      request: this.reviewsService.getRestaurantReviews(request)
    });
  }

  getPastReservation() {
    const appUser = this.authStoreService.getAppUser()?.data;
    if (appUser) {
      const request: BookingsRequest = {
        restaurantId: this.restaurantId(),
        phoneNumber: appUser.phoneNumber,
        email: appUser.email,
        page: 1,
        size: 1,
        surveyStatus: 'PENDING'
      }
      this.executeRequest<BookingsResponse>({
        state: this.bookingState,
        request: this.bookingService.getPastReservation(request),
        onSuccess: response => {
          let pastReservations = response.data ?? [];
          if (pastReservations.length > 0) {
            this.pastReservation.set(pastReservations[0]);
          } else {
            this.pastReservation.set(null);
          }
        }
      });
    }
  }


  onWriteSurveyClicked() {
    const bookingId = this.pastReservation()?._id;
    this.overlayService.openModal(BookingSurveyOverlayComponent, {
      ...Constants.defaultDialogConfig,
      data: bookingId
    }).closed.subscribe((result: BookingSurveyStatus) => {
      this.pastReservation.set(null);
    });

  }

  private getNextUpcomingReservation() {
    const appUser = this.authStoreService.getAppUser()?.data;
    if (appUser) {
      const request: BookingsRequest = {
        restaurantId: this.restaurantId(),
        phoneNumber: appUser.phoneNumber,
        email: appUser.email,
        page: 1,
        size: 1,
        type: 'TYPE_RESERVATION',
        sort: 'startTime:asc',
      }

      this.executeRequest<BookingsResponse>({
        state: this.bookingState,
        request: this.bookingService.getUpcomingReservations(request),
        onSuccess: response => {
          let upcomingReservations = response.data ?? [];
          if (upcomingReservations.length > 0) {
            this.nextUpcomingReservation.set(upcomingReservations[0]);
          } else {
            this.nextUpcomingReservation.set(null);
          }
        },
      });
    }
  }


  onNotInterestedClicked() {
    const bookingId = this.pastReservation()?._id;
    const request: SetBookingStatusNotInterestedRequest = {
      bookingId: bookingId
    };
    this.executeRequest({
      state: this.setBookingStatusNotInterestedState,
      request: this.bookingsService.setBookingStatusNotInterested(request),
      onSuccess: () => {
        this.pastReservation.set(null);
      }
    });
  }

  onViewPhotosClicked() {
    this.router.navigate([`/gallery/${this.restaurantId()}`]);
  }

  getDiningMenus() {
    this.executeRequest<MenuResponse>({
      state: this.menuState,
      request: this.diningOrderService.getDiningMenus(this.restaurantId()),
      onSuccess: (response) => {
        this.processMenuItems(response.data);
        if (response.data.length > 0) {
          this.onMenuTabClicked(response.data[0]);
        }
      }
    });
  }

  onMenuTabClicked(menu: MenuResponseData) {
    this.selectedMenu.set(menu);
    this.filteredMenu.set(MenuResponseToMenuUtil.convert(menu));
  }


  onCategoryTabClicked(categoryId: string | undefined) {
    const element = this.elementRef.nativeElement.querySelector(`#${categoryId}`);
    if (element && categoryId) {
      element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }
  }

  onCategoryToggle(categoryName: MenuCategory) {
    categoryName.isExpanded.update(prev => !prev);
  }


  private processMenuItems(data: MenuResponseData[]) {
    if (data.length > 0) {
      this.filteredMenu.set(MenuResponseToMenuUtil.convert(data[0]));
    }
  }

  onCallForReservationClicked() {
    const phoneNumber = this.restaurantDetailState.response()?.data.phoneNumber;
    if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      this.toasterService.error('Phone number not available');
    }
  }

  onPlacePickupOrderPressed() {
    this.router.navigate([`/pickup/${this.restaurantId()}`]);
  }

  canShowOnlineReservation() {
    let restaurantDetails = this.restaurantDetailState.response()?.data;
    return restaurantDetails?.reservationSettings?.enableOnlineReservation ?? false;
  }

  canShowJoinWaitList() {
    const settings = this.restaurantDetailState.response()?.data?.waitListSettings;
    const selectedDate = this.reservationForm?.get('date')?.value;

    let isTodaySelected = new Date().toDateString() === new Date(selectedDate).toDateString();
    if (settings?.enableOnlineWaitList && isTodaySelected) {
      return true;
    }
    return false;
  }
}

export interface TimeAvailabilityForView {
  shiftId: string;
  partySize: number;
  time: Date;
  timeString: string | null;
  estimatedEndTime: Date;
  cancellationCutOffTime: Date;
  changeCutOffTime: Date;
  tableTypes: string[];
}

export interface ReservationDataForView {
  restaurantId?: string;
  partySize?: number;
  availability?: TimeAvailabilityForView;
  restaurantImage?: string;
  restaurantName?: string;
  restaurantAddress?: string;
}
