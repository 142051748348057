import {Component, inject} from '@angular/core';
import {MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ButtonComponent, State} from "@smartdining/lib-sd-web-shared";
import {GenericResponse} from "@core/models/generic-response";
import {BaseComponent} from "@core/base/base-component";

@Component({
  selector: 'app-cancel-invitation',
  standalone: true,
  imports: [
    ButtonComponent,
    MatDialogContent
  ],
  templateUrl: './cancel-invitation-overlay.component.html',
  styleUrl: './cancel-invitation-overlay.component.scss'
})
export class CancelInvitationOverlayComponent extends BaseComponent {
  state = new State<GenericResponse>();

  dialogRef = inject(MatDialogRef<CancelInvitationOverlayComponent>);


  onNoClicked() {
    this.dialogRef.close(InvitationCancelAction.noAction);

  }

  onYesClicked() {
    this.dialogRef.close(InvitationCancelAction.cancelInvitation);
  }
}

export enum InvitationCancelAction {
  cancelInvitation,
  noAction
}
