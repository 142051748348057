<div class="container mx-auto px-4 py-8 sm:py-14">
  <form [formGroup]="contactUsForm" class="flex flex-col justify-center gap-4 max-w-[900px] mx-auto">

    <p class="text-subtitle1 text-center text-primary-800 uppercase"
       i18n="Contact Us Title|Section header for contact form@@contactUs.title">
      Get in touch
    </p>

    <p class="text-body2 text-center text-neutral-400 max-w-2xl mx-auto"
       i18n="Contact Us Description|Description under the header@@contactUs.description">
      We'd love to hear from you! Whether you have a question, feedback, or need assistance, our team is here to help.
    </p>

    <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-6">
      <lib-text-input
        [fullWidth]="true"
        formControlName="firstName"
        type="text"
        placeholder="First Name"
        label="First Name"
        class="flex-1"
        i18n-placeholder="First Name Placeholder@@contactUs.firstNamePlaceholder"
        i18n-label="First Name Label@@contactUs.firstNameLabel">
      </lib-text-input>

      <lib-text-input
        [fullWidth]="true"
        formControlName="lastName"
        type="text"
        placeholder="Last Name"
        label="Last Name"
        class="flex-1"
        i18n-placeholder="Last Name Placeholder@@contactUs.lastNamePlaceholder"
        i18n-label="Last Name Label@@contactUs.lastNameLabel">
      </lib-text-input>
    </div>

    <lib-text-input
      [fullWidth]="true"
      formControlName="email"
      type="email"
      placeholder="user@company.com"
      label="Email"
      i18n-placeholder="Email Placeholder@@contactUs.emailPlaceholder"
      i18n-label="Email Label@@contactUs.emailLabel">
    </lib-text-input>

    <lib-text-input
      [fullWidth]="true"
      formControlName="phoneNumber"
      type="tel"
      placeholder="Ex: +1 123 4567 XXX"
      label="Phone Number"
      i18n-placeholder="Phone Placeholder@@contactUs.phonePlaceholder"
      i18n-label="Phone Label@@contactUs.phoneLabel">
    </lib-text-input>

    <lib-text-area-input
      [fullWidth]="true"
      formControlName="feedback"
      class="w-full"
      label="Feedback / issue"
      placeholder="Please write your feedback / issue."
      i18n-label="Feedback Label@@contactUs.feedbackLabel"
      i18n-placeholder="Feedback Placeholder@@contactUs.feedbackPlaceholder">
    </lib-text-area-input>

    <lib-button
      (click)="onSendMessageClicked()"
      [loading]="contactUsState.loading()"
      [fullWidth]="true"
      class="mt-4"
      i18n="Send Message Button@@contactUs.sendMessage">
      Send Message
    </lib-button>

  </form>
</div>
