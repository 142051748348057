<lib-base-overlay [title]="'Login'" i18n-title="Login Dialog Title|Title of the login dialog@@userLogin.title">
  <div body>
    <form [formGroup]="form" (ngSubmit)="loginClicked()" class="flex flex-col gap-4">

      <p class="text-subtitle1 text-center text-primary-800 uppercase"
         i18n="Login Header|Login overlay greeting message@@userLogin.welcome">
        Welcome Back
      </p>

      <p class="text-body2 text-center text-neutral-400"
         i18n="Login Instructions|Prompt to sign in with registered credentials@@userLogin.instructions">
        Sign in with email and password that you entered during your registration
      </p>

      <lib-text-input
        formControlName="email"
        type="email"
        placeholder="you@company.com"
        label="Email"
        i18n-label="Email Label|Label for email input@@userLogin.emailLabel"
        i18n-placeholder="Email Placeholder|Placeholder example for email input@@userLogin.emailPlaceholder">
      </lib-text-input>

      <lib-text-input
        formControlName="password"
        type="password"
        placeholder="Password"
        label="Password"
        i18n-label="Password Label|Label for password input@@userLogin.passwordLabel"
        i18n-placeholder="Password Placeholder|Placeholder example for password input@@userLogin.passwordPlaceholder">
      </lib-text-input>

      <lib-button
        type="submit"
        [fullWidth]="true"
        appearance="primary"
        [loading]="loginState.loading() || authenticatedUserState.loading()"
        i18n="Login Button|Button to log in with credentials@@userLogin.loginButton">
        Login
      </lib-button>

      <p class="text-body2 text-neutral-500 self-end cursor-pointer"
         (click)="onForgotPasswordClicked()"
         i18n="Forgot Password Link|Link to password reset screen@@userLogin.forgotPassword">
        Forgot Password?
      </p>

      <div class="flex item-center justify-center gap-2">
        <p class="text-subtitle1 text-neutral-600"
           i18n="No Account Prompt|Prompt text before signup link@@userLogin.noAccountPrompt">
          Don't have an account?
        </p>
        <p class="text-subtitle1 text-primary-500 font-bold cursor-pointer"
           (click)="onSignupClicked()"
           i18n="Signup Link|Clickable signup text@@userLogin.signupLink">
          Sign up
        </p>
      </div>

    </form>
  </div>
</lib-base-overlay>

