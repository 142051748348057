<lib-base-overlay [title]="wizardUtil?.activeWizard()?.title ?? ''"
                  [showBackButton]="wizardUtil?.showBackButton() ?? false"
                  (onBackClicked)="wizardUtil?.goBack()">
  <div body>

    @switch (wizardUtil?.activeWizard()?.key) {
      @case (ReserveWizard.reserve) {
        <app-reserve-table-overlay (onLoginNeeded)="onLoginNeeded()"></app-reserve-table-overlay>
      }
    }

    <app-common-auth [wizardSteps]="wizards()" (onWizardUpdated)="onWizardUpdated($event)"></app-common-auth>
  </div>

</lib-base-overlay>
