<mat-dialog-content>
  <div class="flex flex-col gap-2 text-start">
    <p class="text-body1 text-error-500">Clear cart</p>
    <p class="text-body2 text-neutral-700">Are you sure you want to clear the existing cart?</p>
  </div>
  <div class="flex justify-end gap-4 mt-3">
    <lib-button buttonColor="bg-error-500"
                (click)="onYesClicked()">Yes
    </lib-button>
    <lib-button appearance="outline" (click)="onNoClicked()">No</lib-button>
  </div>
</mat-dialog-content>
